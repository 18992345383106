import axios from "axios";

export const getBranchStreamingToday = async (branchCode: string) => {
  const response = await axios.get(
    `/api/public/branch/streaming?branchCode=${branchCode}&today=true`
  );

  return response.data;
};

export const getCurrentProgram = async (branchCode: string) => {
  const response = await axios.get(
    `/api/public/branch/streaming/${branchCode}/current-program`
  );

  return response.data;
};

export const getCurrentContentsIds = async (branchCode: string) => {
  const response = await axios.get(
    `/api/public/branch/streaming/${branchCode}/current-contentsIds`
  );

  return response.data;
};

import axios from "axios";

export const fetchBranches = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/branches/all`);
  return data;
};

export const fetchBrands = async () => {
  const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`);
  return data;
};

export async function fetchBrandIdByCode(
  branchCode: string
): Promise<string> {
  const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/branches/brandidnew/${branchCode}`);
  return data.id;
}


export async function fetchBranchNameByCode(
  branchCode: string
): Promise<string> {
  const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/branches/${branchCode}`);
  return data;
}

export async function fetchBranchIdByCode(branchCode: string) {
  const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/branches/get-id/${branchCode}`);
  return data;
}

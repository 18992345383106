
//--> 레이아웃 의 상세 UI
export default function LayoutDetailView({
  optionType,
  optionOver,
  optionAdd,
  optionMission,
  optionWod,
  tvCount,
  tvIds,
  tvTeams,
  zeroText,
  tv3StateVidible,
  tv3State,
}: {
  tvName:string,
  optionType:string,
  optionOver:number,
  optionAdd:number,
  optionMission:number,
  optionWod:number,
  tvCount:number,
  tvIds:string,
  tvTeams:string,
  isFirst?:boolean,
  zeroText?:string,
  tv3StateVidible?:boolean,
  tv3State?:number,
}) {
  const getTeams = ()=>{
    return tvTeams?tvTeams.split(','):['', '', '', '', '', '', '', ''];
  }
  const getIds = ()=>{
    return tvIds?tvIds.split(','):['0','0','0','0','0','0','0','0'];
  }
  

  const teamList = getTeams()
  const idList = getIds()

  let renderlist = []
  for (let i = 0; i < tvCount; i++) {
    renderlist.push('')
  }

  return (
    <div className=" w-48 mr-1">
      <div className=" w-48 h-96 bg-[#D5D6D9]">
        <div
          className={
            tvCount >= 1 && tvCount <= 4?"border-2 w-full h-full grid grid-cols-1":
            tvCount >= 6?"border-2 w-full h-full  grid grid-cols-2":
            "w-full h-full justify-center items-center"
          }
        >
          {
            renderlist.length == 0?
            <div
              className="flex flex-1 w-full h-full justify-center items-center text-center text-lg font-bold"
            >
              {
                tv3StateVidible?
                (tv3State == 1?'TV1과 동일':'TV2와 동일')
                :zeroText
              }
            </div>
            :null
          }
          {
            renderlist.map((renderItem, index)=>{
              return (
                <div
                  className={idList[index] == '-1'?" w-full h-full border-b border-r bg-[#D5D6D9] flex flex-col":" w-full h-full border-b border-r bg-white flex flex-col"}
                  key={`LayoutDetailItem_renderlist_${index}`}
                >
                  <div className="flex w-full h-full">
                    <div className="flex-1 flex flex-col">
                      <div className="flex">
                        {
                          optionType == 'team' && idList[index] != '-1'?
                          <div className="">
                            <label
                              className={
                                teamList[index] == 'A'?"btn  btn-xs m-1 bg-[#BFDBFE]":
                                teamList[index] == 'B'?"btn  btn-xs m-1 bg-[#FED7AA]":
                                teamList[index] == 'C'?"btn  btn-xs m-1 bg-[#61D78D]":
                                teamList[index] == 'D'?"btn  btn-xs m-1 bg-[#F87272]":
                                teamList[index] == 'E'?"btn  btn-xs m-1 bg-[#FACB02]":
                                teamList[index] == 'F'?"btn  btn-xs m-1 bg-[#6189D7]":
                                teamList[index] == 'G'?"btn  btn-xs m-1 bg-[#D27E4F]":
                                teamList[index] == 'H'?"btn  btn-xs m-1 bg-[#D761CB]":
                                teamList[index] == 'I'?"btn  btn-xs m-1 bg-[#A3D761]":
                                teamList[index] == 'J'?"btn  btn-xs m-1 bg-[#8061D7]":
                                "btn  btn-xs m-1 bg-[#D5D6D9]"}
                              tabIndex={0}
                            >
                              {teamList[index] == ''?'-':teamList[index]}팀
                            </label>
                          </div> 
                          :
                          <div className="">
                            <label
                              className={
                                "btn  btn-xs m-1 bg-[#D5D6D9] invisible"}
                              tabIndex={0}
                            >
                              {'-'}
                            </label>
                          </div> 
                        }
                        <div className="flex-1"/>
                        <div className="h-6"/>
                      </div>
                      {
                        idList[index] != '-1'?
                        <div className="flex m-1 flex-1 items-center justify-center text-center font-bold text-base">
                          {idList[index] == '0'?'':idList[index]}
                        </div>
                        :
                        <div className="m-1 flex-1 items-center justify-center text-[#D5D6D9] text-center font-bold text-base">
                          {"-"}
                        </div>
                      }
                      <div className=" flex w-full h-6 ">
                        <div className=" flex-1"/>
                        {
                          optionMission == 1 && idList[index] != '-1'?
                          <label className=" mr-1 mb-1 font-bold text-[#9138BB]">M</label>
                          :
                          optionWod == 1 && idList[index] != '-1'?
                          <label className=" mr-1 mb-1 font-bold text-[#00C7F2]">W</label>
                          :
                          null
                        }
                      </div>
                    </div>
                    {
                      optionOver == 1 || optionAdd == 1?
                      <div className={idList[index] == '-1'?"flex-1 flex":"flex-1 flex border-l"}>
                        <div className=" flex-1 flex justify-center items-center ml-5 font-bold">
                          {
                            idList[index] != '-1'?
                            (optionOver == 1?"OVER":"ADD")
                            :null
                          }
                        </div>
                      </div>
                      :null
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

import { useForm } from "react-hook-form";
import axios from "axios";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCirclePlus,
  faHouse,
  faIdCard,
  faKey,
  faTriangleExclamation,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../../components/Layout/AdminLayout";
import { Pagination } from "../../components/interface/Pagination";
import { useEffect, useState } from "react";
import Modal from "../../components/interface/Modal";
import Radio from "../../components/interface/Radio";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { StringToDate, cls } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { useUserStore } from "../../store";
import { Part, Tool } from "../../types/models";
import { checkRanderAdminMenu } from "../../constants/AdminMenu";

export default function ProgramCategory() {
  return (
    <>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            프로그램 분류관리
          </h1>
        </div>
          <div className="flex justify-center space-x-10 items-center h-full text-xl text-gray-700 font-bold">
            다음 개발 스펙
          </div>
      </AdminLayout>
    </>
  );
}

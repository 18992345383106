import AdminLayout from "../../components/Layout/AdminLayout";

import "moment/locale/ko";
import { useNavigate, useParams } from "react-router-dom";
import ScheduleExcel from "../../components/ScheduleExcel";
import NoticeDetail from "../../components/NoticeDetail";

export default function BranchNoticeDetail() {
  const navigate = useNavigate()
  const { noticeId, branchCode } = useParams();
  
  const onBackBtn = () => {
    navigate(`/branch/${branchCode}/notice`);
  };

  return (
    <AdminLayout>
      <div className="">
        <div className="flex space-x-5 ">
          <h1
            onClick={onBackBtn}
            className="text-gray-800 mb-5 font-bold text-2xl cursor-pointer"
          >
            운동 프로그램 안내 - 상세보기
          </h1>
        </div>
        <NoticeDetail noticeId={noticeId} readOnly/>
      </div>
    </AdminLayout>
  );
}

import { useForm } from "react-hook-form";
import axios from "axios";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCirclePlus,
  faHammer,
  faHouse,
  faIdCard,
  faKey,
  faTriangleExclamation,
  faUnlock,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../components/Layout/AdminLayout";

import { useEffect, useState } from "react";
import Modal from "../components/interface/Modal";
import Radio from "../components/interface/Radio";
import FormBox from "../components/interface/FormBox";
import FormInput from "../components/interface/FormInput";
import { StringToDate } from "../libs/utils";
import Table from "../components/interface/Table";
import { useUserStore } from "../store";
import { BranchDto, BrandDto, UserAccountDto } from "../types/dto";
import Button from "../components/interface/Button";
import { checkRanderAdminMenu } from "../constants/AdminMenu";

interface UserFormData {
  type: string | undefined;
  id: number | undefined;
  userId: string | undefined;
  password: string | undefined;
  manager: string | undefined;
  branchId?: number | undefined;
  brandId?: number | undefined;
  newPassword: string | undefined;
  role: string | undefined;
  menuAuth: string[];
  brandAuth: any;
}

interface AccountTableProps {
  apiUrl: string;
  newPasswordApiUrl: string;
  branchCode?: string;
}

export default function AccountTable({
  apiUrl,
  newPasswordApiUrl,
  branchCode,
}: AccountTableProps) {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  const [branchList, setBranchList] = useState<BranchDto[]>();
  const [brandList, setBrandList] = useState<BrandDto[]>();
  const [mutate, setMutate] = useState(false);
  const [isRemoveModal, setIsRemoveModal] = useState(false);

  useEffect(() => {
    if(user)
    {
      if(!checkRanderAdminMenu(user, '/admin'))
      {
        navigate(`/admin/brand`);
        return;
      }
    }

    //콘텐츠 정보 가져오기
    getBranch();
    getBrand();
  }, []);

  const getBranch = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/branches/all`, {
        withCredentials: true,
      });

      console.log(response.data);
      setBranchList(response.data);
    } catch (error) {
      console.error(error);
      window.location.reload();
    }
  };

  const getBrand = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`, {
        withCredentials: true,
      });

      console.log(response.data);
      setBrandList(response.data);
    } catch (error) {
      console.error(error);
      window.location.reload();
    }
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    resetField,
    formState: { errors, isValid },
  } = useForm<UserFormData>();

  const tableHeader = [
    // { title: "No." },
    // { title: "등록일" },
    // { title: "지점명" },
    // { title: "ID" },
    // { title: "역할" },
    // { title: "담당자명" },
    // { title: "관리구분" },

    { title: "No." },
    { title: "등록일" },
    { title: "ID" },
    { title: "담당자명" },
    { title: "구분" },
    { title: "브랜드" },
    { title: "지점명" },
    { title: "관리" },
  ];

  const resetFields = () => {
    setValue("type", "지점", { shouldValidate: true });
    setValue("id", undefined, { shouldValidate: true });
    setValue("userId", undefined, { shouldValidate: true });
    setValue("password", undefined, { shouldValidate: true });
    setValue("manager", undefined, { shouldValidate: true });
    setValue("newPassword", undefined, { shouldValidate: true });
    setValue("role", "admin", { shouldValidate: true });
    setValue("menuAuth", [], { shouldValidate: false });
    setValue("brandAuth", new Map(), { shouldValidate: false });

    if (branchCode && branchList) {
      const myBranchId = branchList.find((loc) => loc.code === branchCode);
      setValue("branchId", myBranchId?.id, { shouldValidate: true });
    }
  };

  const showModal = () => {
    resetFields();
    setIsRemoveModal(false);
    window.my_modal_2.showModal();
  };

  const closeModal = () => {
    resetFields();
    setIsRemoveModal(false);
    window.my_modal_2.close();
  };

  const showPasswordModal = () => {
    resetFields();
    setIsRemoveModal(false);
    window.password.showModal();
  };

  const onValid = async (formData: UserFormData) => {
    try {
      let menuAuth = formData.menuAuth.join(",")
      let brandAuthlist = []
      for (let [k, v] of formData.brandAuth) {
        brandAuthlist.push({
          id:k,
          auth:v
        })
      }

      const brandAuth = JSON.stringify(brandAuthlist)
      if (formData.id) {
        const res = await axios.patch(
          `${apiUrl}/${formData.id}`,
          {
            userId: formData.userId,
            manager: formData.manager,
            branchId: formData.type === "본사" ? 1 : formData.branchId,
            brandId: formData.type === "본사" ? 0 : formData.brandId,
            role: formData.role || "admin",
            menuAuth: menuAuth,
            brandAuth: brandAuth,
          },
          { withCredentials: true }
        );
      } else {
        await axios.post(
          apiUrl,
          {
            userId: formData.userId,
            password: formData.password,
            manager: formData.manager,
            branchId: formData.type === "본사" ? 1 : formData.branchId,
            brandId: formData.type === "본사" ? 0 : formData.brandId,
            role: formData.role || "admin",
            menuAuth: menuAuth,
            brandAuth: brandAuth,
          },
          { withCredentials: true }
        );
      }

      if (formData.id === user?.id) {
        // window.location.reload();
        await axios.post(`${process.env.REACT_APP_SERVER_URL}api/public/logout`, {}, { withCredentials: true });
        window.location.reload();
      } else {
        setMutate(!mutate);
        closeModal();
      }
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }
  };

  const openEditModal = (item: UserAccountDto, enableRemove: boolean) => {
    showModal();

    console.log(item.branchCode);

    setValue("id", item.id, { shouldValidate: true });
    setValue("type", item.branchCode === "main" ? "본사" : "지점", {
      shouldValidate: true,
    });
    setValue("manager", item.manager, { shouldValidate: true });
    setValue("userId", item.userId, { shouldValidate: true });
    setValue("branchId", item.branchId!, { shouldValidate: true });
    setValue("role", item.role, { shouldValidate: true });
    setValue("brandId", item.brandId!, { shouldValidate: true });

    if(item.menuAuth)
    {
      let menuAuth = item.menuAuth.split(',')
      setValue("menuAuth", menuAuth, { shouldValidate: false });
    }
    else
    {
      setValue("menuAuth", [], { shouldValidate: false });
    }

    let brandAuthMap = new Map()
    if(item.brandAuth)
    {
      let brandAuthList = JSON.parse(item.brandAuth)
      for (let i = 0; i < brandAuthList.length; i++) {
        if(brandAuthList[i].id)
        {
          brandAuthMap.set(brandAuthList[i].id, brandAuthList[i].auth)
        }
      }
      console.log('brandAuthMap : ', brandAuthMap)
    }
    
    setValue("brandAuth", brandAuthMap, { shouldValidate: false });

    setIsRemoveModal(enableRemove);
  };

  const openPaswordModal = (item: UserAccountDto) => {
    showPasswordModal();
    setValue("id", item.id);
  };

  const onDelete = async (item: UserFormData) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    try {
      await axios.delete(`${apiUrl}/${item.id}`, { withCredentials: true });
      closeModal();
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  const submitNewPassword = async () => {
    try {
      const response = await axios.post(
        newPasswordApiUrl,
        {
          userId: watch("id"),
          newPassword: watch("newPassword"),
        },
        { withCredentials: true }
      );

      alert("비밀번호 수정이 완료되었습니다.");

      if (watch("id") === user?.id) {
        await axios.post(`${process.env.REACT_APP_SERVER_URL}api/public/logout`, {}, { withCredentials: true });
        window.location.reload();
        // window.location.reload();
        // await axios.post("/api/logout", { withCredentials: true });
      }

      setMutate(!mutate);
      closeModal();
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }
  };
  
  const onChangeBrandAuth = (check:boolean, id:number, value:string)=>{

    let brandAuthMap = watch("brandAuth")

    console.log('brandAuthMap : ', brandAuthMap)
    if(brandAuthMap)
    {
      let brandAuth = brandAuthMap.get(id)
      if(!brandAuth)
      {
        brandAuth = []
      }
      
      if(check)
      {
        brandAuth.push(value)
      }
      else
      {
        for (let i = 0; i < brandAuth.length; i++) {
          if(brandAuth[i] == value)
          {
            brandAuth.splice(i, 1)
            break;
          }
        }
      }
      console.log('brandAuth : ', brandAuth)
      brandAuthMap.set(id, brandAuth)
    }
    setValue('brandAuth', brandAuthMap)
  }

  const isBrandAuthChecked = (auth:any, value:string)=>{
    if(auth)
    {
      for (let i = 0; i < auth.length; i++) {
        if(value == auth[i])
        {
          return true;
        }
      }  
    }
    console.log('isBrandAuthChecked false : ', auth, value)

    return false;
  }

  return (
    <>
      <Modal id="password" title={`비밀번호 수정`}>
        {watch("id") && watch("id") === user?.id && (
          <div className="alert alert-warning mt-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current shrink-0 h-6 w-6 "
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <span className="text-sm">
              내 계정을 수정하려고 하고 있어요. <br />
              계정 수정 후 로그아웃 됩니다.
            </span>
          </div>
        )}
        <FormBox title="비밀번호" icon={<FontAwesomeIcon icon={faKey} />}>
          <FormInput
            placeholder="새로운 비밀번호"
            register={register("newPassword")}
          />
        </FormBox>
        <div className="w-full flex flex-col justify-center mt-5">
          <button
            onClick={submitNewPassword}
            className=" btn-primary btn w-full mt-2   text-white  "
          >
            {`비밀번호 수정`}
          </button>
        </div>
      </Modal>
      <Modal size="xlarge" id="my_modal_2" title={`계정 ${watch("id") ? "수정" : "등록"}`}>
        {watch("id") && watch("id") === user?.id && (
          <div className="alert alert-warning mt-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current shrink-0 h-6 w-6 "
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <span className="text-sm">
              내 계정을 수정하려고 하고 있어요. <br />
              계정 수정 후 로그아웃 됩니다.
            </span>
          </div>
        )}

        <FormBox title="구분" icon={<FontAwesomeIcon icon={faBuilding} />}>
            <div className="flex space-x-5">
              {!branchCode && (
                <Radio
                  title="본사"
                  value="본사"
                  register={register("type", {
                    required: "지점 또는 본사를 선택해주세요",
                  })}
                />
              )}
              <Radio
                title="지점"
                value="지점"
                register={register("type", {
                  required: "지점 또는 본사를 선택해주세요",
                })}
                disabled={
                  branchCode ? false : watch("id") !== undefined && !isRemoveModal
                }
              />
            </div>
        </FormBox>
        <FormBox title="담당자명" icon={<FontAwesomeIcon icon={faUser} />}>
          <FormInput
            placeholder="홍길동"
            register={register("manager", {
              required: "지점 또는 본사를 선택해주세요",
            })}
          />
        </FormBox>
        <FormBox title="아이디" icon={<FontAwesomeIcon icon={faIdCard} />}>
          <FormInput
            placeholder="아이디"
            register={register("userId", {
              required: "아이디를 입력해주세요",
            })}
          />
        </FormBox>
        {!watch("id") && (
          <FormBox title="비밀번호" icon={<FontAwesomeIcon icon={faKey} />}>
            <FormInput
              placeholder="비밀번호"
              register={register("password", {
                required: watch("id") ? false : true,
              })}
            />
          </FormBox>
        )}
        {watch("type") === "본사" && (
          <FormBox title="공통메뉴 권한" icon={<FontAwesomeIcon icon={faUnlock} />}>
            <div className="flex space-x-5">
              <Radio
                title="브랜드 관리"
                value="1"
                type="checkbox"
                register={register("menuAuth", {
                  required: false,
                })}
              />
              <Radio
                title="관리자 계정관리"
                value="2"
                type="checkbox"
                register={register("menuAuth", {
                  required: false,
                })}
              />
              <Radio
                title="운동 카테고리 관리"
                value="3"
                type="checkbox"
                register={register("menuAuth", {
                  required: false,
                })}
              />
              <Radio
                title="운동 콘텐츠 관리"
                value="4"
                type="checkbox"
                register={register("menuAuth", {
                  required: false,
                })}
              />
            </div>
          </FormBox>
        )}
        {
          watch("type") === "본사"?
          <>
          {
            brandList && 
            brandList.map((item: BrandDto) =>{
              const brandAuthMap = watch('brandAuth')
              let branAuth
              if(brandAuthMap)
              {
                branAuth = brandAuthMap.get(item.id)
              }
              return (
                <FormBox title={item.name} icon={<FontAwesomeIcon icon={faHammer} />}>
                  <div className="flex space-x-5">
                    {
                      item.type == 2?
                      <div className="form-control">
                        <label className="label cursor-pointer">
                          <input
                            type={"checkbox"}
                            className={"checkbox "}
                            checked={isBrandAuthChecked(branAuth, '6')}
                            onChange={(e)=>{
                              onChangeBrandAuth(e.target.checked, item.id, '6')
                            }}
                          />
                          <div className="ml-2 label-text flex items-center justify-center space-x-1">
                            <p>{"레이아웃"}</p>
                          </div>
                        </label>
                      </div>
                      :null
                    }
                    <div className="form-control">
                      <label className="label cursor-pointer">
                        <input
                          type={"checkbox"}
                          className={"checkbox "}
                          checked={isBrandAuthChecked(branAuth, '7')}
                          onChange={(e)=>{
                            onChangeBrandAuth(e.target.checked, item.id, '7')
                          }}
                        />
                        <div className="ml-2 label-text flex items-center justify-center space-x-1">
                          <p>{"운동타입"}</p>
                        </div>
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="label cursor-pointer">
                        <input
                          type={"checkbox"}
                          className={"checkbox "}
                          checked={isBrandAuthChecked(branAuth, '8')}
                          onChange={(e)=>{
                            onChangeBrandAuth(e.target.checked, item.id, '8')
                          }}
                        />
                        <div className="ml-2 label-text flex items-center justify-center space-x-1">
                          <p>{"커리큘럼"}</p>
                        </div>
                      </label>
                    </div>
                    {/* <div className="form-control">
                      <label className="label cursor-pointer">
                        <input
                          type={"checkbox"}
                          className={"checkbox "}
                          checked={isBrandAuthChecked(branAuth, '1')}
                          onChange={(e)=>{
                            onChangeBrandAuth(e.target.checked, item.id, '1')
                          }}
                        />
                        <div className="ml-2 label-text flex items-center justify-center space-x-1">
                          <p>{"프로그램 분류 관리"}</p>
                        </div>
                      </label>
                    </div> */}
                    <div className="form-control">
                      <label className="label cursor-pointer">
                        <input
                          type={"checkbox"}
                          className={"checkbox "}
                          checked={isBrandAuthChecked(branAuth, '2')}
                          onChange={(e)=>{
                            onChangeBrandAuth(e.target.checked, item.id, '2')
                          }}
                        />
                        <div className="ml-2 label-text flex items-center justify-center space-x-1">
                          <p>{"운동 프로그램"}</p>
                        </div>
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="label cursor-pointer">
                        <input
                          type={"checkbox"}
                          className={"checkbox "}
                          checked={isBrandAuthChecked(branAuth, '9')}
                          onChange={(e)=>{
                            onChangeBrandAuth(e.target.checked, item.id, '9')
                          }}
                        />
                        <div className="ml-2 label-text flex items-center justify-center space-x-1">
                          <p>{"운동 프로그램 안내"}</p>
                        </div>
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="label cursor-pointer">
                        <input
                          type={"checkbox"}
                          className={"checkbox "}
                          checked={isBrandAuthChecked(branAuth, '3')}
                          onChange={(e)=>{
                            onChangeBrandAuth(e.target.checked, item.id, '3')
                          }}
                        />
                        <div className="ml-2 label-text flex items-center justify-center space-x-1">
                          <p>{"일별 프로그램"}</p>
                        </div>
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="label cursor-pointer">
                        <input
                          type={"checkbox"}
                          className={"checkbox "}
                          checked={isBrandAuthChecked(branAuth, '4')}
                          onChange={(e)=>{
                            onChangeBrandAuth(e.target.checked, item.id, '4')
                          }}
                        />
                        <div className="ml-2 label-text flex items-center justify-center space-x-1">
                          <p>{"프로그램 예약 통계"}</p>
                        </div>
                      </label>
                    </div>
                    <div className="form-control">
                      <label className="label cursor-pointer">
                        <input
                          type={"checkbox"}
                          className={"checkbox "}
                          checked={isBrandAuthChecked(branAuth, '5')}
                          onChange={(e)=>{
                            onChangeBrandAuth(e.target.checked, item.id, '5')
                          }}
                        />
                        <div className="ml-2 label-text flex items-center justify-center space-x-1">
                          <p>{"광고 관리"}</p>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div className="flex space-x-5">
                  </div>
                </FormBox>
              )
            })
          }
          </>
          :null
        }
        {watch("type") === "지점" && (
          <FormBox title="브랜드" icon={<FontAwesomeIcon icon={faHouse} />}>
            <select
              {...register("brandId", {
                required:
                  watch("type") === "지점" ? "브랜드를 선택해주세요" : false,
              })}
              className="select select-bordered w-full max-w-xs"
              disabled={branchCode ? true : false}
            >
              {brandList &&
                brandList.map(
                  (brand: BrandDto) => (
                      <option value={brand.id}>
                        {brand.name}
                      </option>
                    )
                )}
            </select>
          </FormBox>
        )}
        {watch("type") === "지점" && (
          <FormBox title="지점명" icon={<FontAwesomeIcon icon={faHouse} />}>
            <select
              {...register("branchId", {
                required:
                  watch("type") === "지점" ? "지점을 선택해주세요" : false,
              })}
              className="select select-bordered w-full max-w-xs"
              disabled={branchCode ? true : false}
            >
              {branchList &&
                branchList.map(
                  (branch: BranchDto) =>{
                    if(branch.code == "main" || watch("brandId") != branch.brandId)
                    {
                      return null
                    }

                    return (
                      <option value={branch.id}>
                        {branch.name} ({branch.code})
                      </option>
                    )
                  }
                )}
            </select>
          </FormBox>
        )}
        {watch("type") === "지점" && (
          <FormBox title="역할" icon={<FontAwesomeIcon icon={faUser} />}>
            <select
              {...register("role", {
                required: watch("type") === "지점" ? true : false,
              })}
              className="select select-bordered w-full max-w-xs"
            >
              <option value="admin">지점 관리자</option>
              <option value="employee">지점 직원</option>
            </select>
          </FormBox>
        )}
        <div className="w-full flex flex-col justify-center mt-5">
          {isRemoveModal && (
            <button
              className=" btn w-full bg-black text-white  "
              onClick={() => onDelete(watch())}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} />
              계정 삭제
            </button>
          )}
          <Button
            title={`계정 ${watch("id") ? "수정" : "등록"}`}
            onClick={handleSubmit(onValid)}
            iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
            disabled={isValid ? false : true}
          />
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            관리자 계정 관리
          </h1>
          <Table
            dataApiUrl={apiUrl}
            branchCode={branchCode}
            buttonComponent={
              <button
                className=" btn-primary btn   text-white  "
                onClick={showModal}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                계정 등록
              </button>
            }
            headerArray={tableHeader}
            mutate={mutate}
            initSort="registeredAt"
            initDirection="desc"
          >
            {(data: UserAccountDto[], startIndex: number) =>
              data.map((item: UserAccountDto, index: number) => {
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>
                    {/* <th>{startIndex?(startIndex - index):index}</th> */}
                    <td>
                      <StringToDate
                        dateString={item.registeredAt?.toString() || ""}
                      />
                    </td>
                    <td>{item.userId}</td>
                    <td>{item.manager}</td>
                    <td>{item.branchCode === "main" ? "본사" : "지점"}</td>
                    <td>{item.brandName?item.brandName:'-'}</td>
                    <td>{item.branchCode === "main" ?'-':item.branchName}</td>
                    <td className="flex">
                      <button
                        onClick={() =>
                          openEditModal(
                            item,
                            index === data.length - 1 || item.id === user?.id
                              ? false
                              : true
                          )
                        }
                        className="btn btn-xs"
                      >
                        수정
                      </button>
                      <button
                        onClick={() => openPaswordModal(item)}
                        className="btn btn-xs ml-2 truncate "
                      >
                        비밀번호 수정
                      </button>
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}

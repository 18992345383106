import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus,
  faHeading,
  faImage,
  faInfo,
  faSign,
} from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useMemo, useState } from "react";

import { useUserStore } from "../store";
import FormBox from "./interface/FormBox";
import FormInput from "./interface/FormInput";
import Button from "./interface/Button";
import { useNavigate } from "react-router-dom";
import { getUseEditFromAuth } from "../libs/utils";
import FullPageLoading from "./interface/FullPageLoading";
import { NoticeDto } from "../types/models";
import { BrandDto } from "../types/dto";
import { uploadNoticeFileToServer } from "../services/VimeoUpload";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { faCalendar, faNoteSticky } from "@fortawesome/free-regular-svg-icons";

interface UserFormData {
  name: string;
  brandId: number;
  brandName: string;
  source: string;
  fileUrls: string;
  fileNames: string;
  startTime: number;
  endTime: number;
}

interface NoticeDetailProps {
  readOnly?: boolean;
  noticeId?: string;
}

export default function NoticeDetail({
  readOnly = false,
  noticeId,
}: NoticeDetailProps) {
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [getLoading, setGetLoading] = useState(false);
  const [badgeUrl, setBadgeUrl] = useState("");
  const [files, setFiles] = useState<FileList>();
  const [brandList, setBrandList] = useState<BrandDto[]>();

  //--> 정보입력에 이미지 사용시 업로드
  const imageHandler = async () => {
    let container = document.querySelector('div.ql-editor');
    let fileInput = container?.querySelector('input.ql-image[type=file]') as HTMLInputElement;
    let img = document.createElement('img');

    if (fileInput == null) {
      fileInput = document.createElement('input') as HTMLInputElement;
      fileInput?.setAttribute('type', 'file');
      fileInput?.setAttribute('accept', 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
      fileInput?.classList.add('ql-image');
      fileInput?.addEventListener('change', async () => {
        if (fileInput?.files != null && fileInput.files[0] != null) {
            if(fileInput.files[0].type.includes("image/")) {
                let temp = await uploadImage(fileInput.files[0]);

                console.log('fileInput.files[0] : ', fileInput.files[0])
//                tempContentImages.push(temp)

                img.setAttribute('src', temp?temp:'')
                container?.appendChild(img);
            } else {
                alert("이미지 파일을 업로드 해주세요.");
                return false;
            }
        }
      });
    }
    fileInput.click();
}
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "size",
    "color",
    "bullet",
    "indent",
    "image",
    "video",
    "align"
];

const modules = useMemo(() => ({
    toolbar: {
        container: [
            ["bold", "italic", "underline", "strike"],
            [{ size: ["small", false, "large", "huge"] }],
            [
                { align: [] }, 
                { color: [] }
            ],
            ["image", "video"]
        ],
        handlers: {image: imageHandler}
    },
    clipboard: { matchVisual: false }
}), []);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<UserFormData>();

  //--> 이미지 업로드
  const uploadImage = async (file:File) => {
    try {
      let newImageUrl = badgeUrl ? badgeUrl : '';
      
      if (file) {
        try {
          newImageUrl = await uploadNoticeFileToServer(file);
        } catch (err) {
          alert("이미지 업로드 중 문제가 발생했습니다.");
          return;
        }
      }

      return newImageUrl
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }
  };
  
  const onValid = async (formData: UserFormData) => {
    setLoading(true);
    try {
      let _startTime = new Date(formData.startTime)
      let _endTime = new Date(formData.endTime)

      _startTime.setHours(0,0,0,0);
      _endTime.setHours(23, 59, 59, 0);
      
      let fileUrls = watch("fileUrls")
      let fileNames = watch('fileNames')

      if(files) {
        let fileUrlList = []
        let fileNameList = []

        for (let i = 0; i < files.length; i++) {
          fileUrlList.push(await uploadImage(files[i]))
          fileNameList.push(files[i].name) 
        }

        fileUrls = fileUrlList.toString()
        fileNames = fileNameList.toString()
      }
      const _brandName = brandList?.filter((brand:BrandDto)=>{
        if(brand.id == formData.brandId)
        {
          return true
        }

        return false;
      })
      if (noticeId) {
        
        await axios.patch(`${process.env.REACT_APP_SERVER_URL}api/admin/notice/${noticeId}`, {
          brandId: formData.brandId,
          brandName:_brandName?_brandName[0].name:'',
          name: formData.name,
          source: formData.source,
          startTime:Math.floor(_startTime.getTime()/1000),
          endTime:Math.floor(_endTime.getTime()/1000),
          fileUrls,
          fileNames
        }, { withCredentials: true });
      } else {
        // 2. DB 저장
        await axios.post(`${process.env.REACT_APP_SERVER_URL}api/admin/notice`, {
          brandId: formData.brandId,
          brandName:_brandName?_brandName[0].name:'',
          name: formData.name,
          source: formData.source,
          startTime:Math.floor(_startTime.getTime()/1000),
          endTime:Math.floor(_endTime.getTime()/1000),
          fileUrls,
          fileNames
        }, { withCredentials: true });
      }
      navigate(`/admin/notice`);
      setLoading(false);
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
      setLoading(false);
    }
  };

  //수정의 경우, 기존 데이터 불러오기
  useEffect(() => {
    if (noticeId) {
      getOriginalData();
      console.log('수정의 경우, 기존 데이터 불러오기 : ', noticeId)
    }
    else if (noticeId == undefined) {
      InitListData();
      console.log('새로 생성하기 : ', noticeId)
    }
  }, [noticeId]);
  

  const InitListData = async ()=>{
    try {
      //--> 브랜드 리스트 셋팅
      const {data:_brandlist} = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`, {
        withCredentials: true,
      });

      let brandAuth = []
      if(user) {
        brandAuth = JSON.parse(user.brandAuth);
      }
      let brandlist = []
      for (let i = 0; i < _brandlist.length; i++) {
        if(!getUseEditFromAuth('9', _brandlist[i].id, brandAuth)) {
          continue
        }
        brandlist.push(_brandlist[i])
      }
      setBrandList(brandlist);
      if(!noticeId && brandlist.length > 0) {
        setValue('brandId', brandlist[0].id);
      }

    } catch (error) {
      console.log(error);
    }
    setGetLoading(false);
  }

  const getOriginalData = async () => {
    setGetLoading(true);
    try {
      await InitListData();

      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/notice/${noticeId}`, { withCredentials: true });

      const responseData: NoticeDto = response.data;

      console.log('responseData : ', responseData)
      setValue("brandId", responseData.brandId);
      setValue("name", responseData.name);
      setValue("startTime", responseData.startTime);
      setValue("endTime", responseData.endTime);
      setValue("fileUrls", responseData.fileUrls);
      setValue("fileNames", responseData.fileNames);
      setValue("source", responseData.source);
      
    } catch (error) {
      console.log(error);
    }
    setGetLoading(false);
  };
  const onChangeContents = (contents:string) => {
    setValue('source', contents)
  };

  //--> 첨부파일 받기
  const downloadFile = async (url:string, filename:string) => {
    fetch(url)
        .then((res) => {
            return res.blob();
        })
        .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a); 
        })
        .catch((err) => {
            console.error('err: ', err);
        });
  };

  const _fileNames = watch("fileNames")
  let _fileNameList = (_fileNames && _fileNames != '' && _fileNames != 'undefined')?_fileNames.split(','):[]
  const _fileUrls = watch("fileUrls")
  let _fileUrlsList = (_fileUrls && _fileUrls != '' && _fileUrls != 'undefined')?_fileUrls.split(','):[]

  console.log('_fileNames : ', _fileNames)

  if(readOnly) {
    let source = watch("source");
    return (
      <>
        <div className="">
          <div className="bg-white drop-shadow-md p-10 ">
            <FormBox
              title="제목"
              required={false}
              icon={<FontAwesomeIcon icon={faHeading} />}
            >
              <div className=" text-base font-bold">{watch("name")}</div>
            </FormBox>
            {
              _fileNameList && _fileNameList.length > 0?
              <FormBox
                title="첨부파일"
                required={false}
                icon={<FontAwesomeIcon icon={faImage} />}
              >
                <div className="flex-col w-52 space-y-4 my-4">
                {
                  _fileNameList.map((_fileName:string, fileNameIndex:number)=>{
                    return (
                      <div
                        key={`notice_Detail_${fileNameIndex}`}
                        className=" btn btn-sm m-1"
                        onClick={()=>{downloadFile(_fileUrlsList[fileNameIndex], _fileName)}}
                      >
                        {_fileName}
                      </div>
                    )
                  })
                }
                </div>
              </FormBox>
              :null
            }
            <FormBox
              title="운영기간"
              required={false}
              icon={<FontAwesomeIcon icon={faCalendar} />}
            >
              <div className=" font-medium">
                {watch("startTime")} ~ {watch("endTime")}
              </div>
            </FormBox>
            <FormBox
              title="내용"
              required={false}
              icon={<FontAwesomeIcon icon={faNoteSticky} />}
            >
              <div className=" flex flex-col my-4 mr-36">
                <div dangerouslySetInnerHTML={ {__html: source} }>
                </div>
              </div>
            </FormBox>
          </div>
        </div>
      </>
    )
  }
  return (
    <>
      <div className="">
        <div className="bg-white drop-shadow-md p-10 ">
          {getLoading && <FullPageLoading />}
          <FormBox title="브랜드" icon={<FontAwesomeIcon icon={faSign} />}>
            <select
              {...register("brandId")}
              className="select select-bordered w-full max-w-xs"
            >
              {brandList &&
                brandList.map(
                  (brand: BrandDto) => (
                      <option value={brand.id}>
                        {brand.name}
                      </option>
                    )
                )}
            </select>
          </FormBox>
          <FormBox title="운영 기간" icon={<FontAwesomeIcon icon={faSign} />}>
            {
              readOnly?
              <div className="flex item-center">
                <div className="p-3">
                  {watch("startTime")}
                </div>
                <div className=" p-3 mx-2">
                  ~
                </div>
                <div className="p-3">
                  {watch("endTime")}
                </div>
              </div>
              :
              <div className="flex item-center">
                <div className="w-64">
                  <FormInput
                    type="date" 
                    register={register("startTime")}
                  />
                </div>
                <div className=" p-3 mx-2">
                  ~
                </div>
                <div className="w-64">
                  <FormInput
                    type="date" 
                    register={register("endTime")}
                  />
                </div>
              </div>
            }
          </FormBox>
          <FormBox title="제목" icon={<FontAwesomeIcon icon={faSign} />}>
            {readOnly ? (
              watch("name")
            ) : (
              <FormInput
                placeholder="제목"
                register={register("name", {
                  required: "제목을 입력해주세요",
                })}
              />
            )}
          </FormBox>
          <FormBox
            title="내용"
            required={false}
            icon={<FontAwesomeIcon icon={faInfo} />}
          >
            {readOnly ? (
              watch("source")
            ) : (
              <div className="w-full h-80 pb-10 mb-10">
                <ReactQuill
                  onChange={onChangeContents}
                  style={{ width: "100%", height: "100%" }}
                  modules={modules}
                  formats={formats}
                  value={watch("source")}
                />
              </div>
            )}
          </FormBox>
          <FormBox
            title="첨부파일"
            required={false}
            icon={<FontAwesomeIcon icon={faInfo} />}
          >
            {
              _fileNameList && _fileNameList.length > 0?
              <div>
                <div>
                  {
                    _fileNameList.map((_fileName:string, fineNameIndex:number)=>{
                      return (
                        <div
                          key={`notice_Detail_${fineNameIndex}`}
                        >
                          {_fileName}
                        </div>
                      )
                    })
                  }
                </div>
                <div
                  onClick={()=>{
                    setValue('fileNames', '')
                    setValue('fileUrls', '')
                  }}
                  className="btn btn-sm my-4 btn-neutral"
                >
                  비우기
                </div>
              </div>
              :
              <div>
                <input 
                  className='file-input'
                  type="file"
                  multiple
                  onChange={(e)=>{
                    setFiles(e.target.files || undefined)
                  }}
                />
              </div>
            }
          </FormBox>
          
          {!readOnly && (
            <div className="w-full flex flex-col justify-center mt-5">
              <Button
                loading={loading}
                title={`운동 프로그램 안내  ${noticeId ? "수정" : "등록"}`}
                onClick={handleSubmit(onValid)}
                iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

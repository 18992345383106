import AdminLayout from "../components/Layout/AdminLayout";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faClock,
  faGear,
  faPenToSquare,
  faPlus,
  faTrash,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";

import {
  GetHHMM,
  GetHHMMForTimeStamp,
  cls,
  getBoxBackgroundClass,
  getDayClassNames,
} from "../libs/utils";
import axios from "axios";
import WeekButton from "../components/WeekButton";
import BranchAddTimeModal from "../components/Modal/BranchAddTimeModal";
import { useNavigate, useParams } from "react-router-dom";
import { BranchSchedule } from "../types/models";
import ScheduleSettingsModal from "../components/Modal/ScheduleSettingsModal";
import { useQuery } from "@tanstack/react-query";

interface BranchScheduleBoxProps {
  daysOfWeek: any;
  scheduleByDay: any;
  handleProgramClick?: any;
  addTime?: any;
  programByDay: any;
  size?: string;
  isLoading?: boolean;
}

export default function BranchScheduleBox({
  daysOfWeek,
  scheduleByDay,
  handleProgramClick,
  addTime,
  programByDay,
  size,
  isLoading,
}: BranchScheduleBoxProps) {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-1 ">
      {daysOfWeek?.map((day: any, index: number) => (
        <div
          key={index}
          className={cls(
            "w-full  flex-col   flex  p-2  rounded-lg shadow-md overflow-hidden min-h-[300px]",
            getBoxBackgroundClass(day)
          )}
        >
          <div
            className={cls(
              "font-bold text-center pb-2  border-gray-300",
              size === "big" ? "text-7xl mb-10" : "mb-2 border-b"
            )}
          >
            <p className={getDayClassNames(day)}>
              {day.format("MM")}.{day.format("DD")} ({day.format("ddd")})
            </p>

            <div
              className={cls(
                "h-5 flex items-center mt-2 justify-center",
                size === "big" ? "mt-24" : ""
              )}
            >
              <div
                className={cls(
                  "  bg-gymmate text-white",
                  size === "big"
                    ? "text-6xl px-10 py-5 rounded-full"
                    : "text-sm badge"
                )}
              >
                {programByDay ? (
                  programByDay[day.format("YYYY-MM-DD")]
                ) : (
                  <span className="loading loading-bars loading-xs"></span>
                )}
              </div>
            </div>
          </div>
          <div className={cls("", size === "big" ? "" : "")}>
            {isLoading &&
              Array.from({ length: Math.floor(Math.random() * 7) + 2 }).map(
                (_, cellIndex) => (
                  <div className="w-full h-[20px] skeleton-box rounded-lg m-1 shadow-md"></div>
                )
              )}

            {scheduleByDay &&
              scheduleByDay[day.format("YYYY-MM-DD")] &&
              //@ts-ignore
              scheduleByDay[day.format("YYYY-MM-DD")].map(
                (program: BranchSchedule, index: number) => {
                  return (
                    <div
                      key={index}
                      onClick={(e) =>
                        handleProgramClick
                          ? handleProgramClick(e, program)
                          : null
                      }
                      className={cls(
                        "flex  px-2 bg-gymmate text-white   cursor-pointer     rounded-md  w-full   transition-all hover:bg-gray-300  items-center shadow-md",
                        size === "big"
                          ? "text-7xl mt-5 justify-center py-5"
                          : "text-sm h-5 mt-2 justify-between"
                      )}
                    >
                      <p className=" font-bold ">
                        <GetHHMMForTimeStamp dateTime={program.start_time} />
                      </p>
                      {size !== "big" && (
                        <div className="flex w-32 justify-end space-x-1 pl-1">
                          <div className="text-xs flex justify-end items-center">
                            <FontAwesomeIcon
                              icon={faClock}
                              width={8}
                              className="mr-1"
                            />
                            {program.duration}
                          </div>
                          <div className=" flex text-xs justify-end items-center">
                            <FontAwesomeIcon
                              icon={faUser}
                              width={8}
                              className="mr-1"
                            />
                            {program.headcount}
                          </div>
                        </div>
                      )}
                    </div>
                  );
                }
              )}
          </div>
          {addTime && (
            <div
              onClick={() => addTime(day.format("YYYY-MM-DD"))}
              className="flex opacity-30 cursor-pointer mt-2  rounded-md  w-full h-5 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
            >
              <FontAwesomeIcon icon={faPlus} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

import { useForm } from "react-hook-form";
import axios from "axios";
import {
  cls,
  getDayClassNames,
  getDayStringClassNames,
} from "../../libs/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faL,
  faPlus,
  faSave,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../interface/Button";
import { useEffect, useState } from "react";
import { ProgramCategory, ProgramType } from "../../constants/ProgramCategory";
import { RandomCategoryDto } from "../../types/dto";

interface RandomSettingsModalProps {
  randomCategoryMutate: boolean;
  setRandomCategoryMutate: (value: boolean) => void;
  brandId: number;
}

type WeeklySchedule = {
  SUNDAY: RandomCategoryDto[];
  MONDAY: RandomCategoryDto[];
  TUESDAY: RandomCategoryDto[];
  WEDNESDAY: RandomCategoryDto[];
  THURSDAY: RandomCategoryDto[];
  FRIDAY: RandomCategoryDto[];
  SATURDAY: RandomCategoryDto[];
};

type DaysType = {
  title: string;
  value: keyof WeeklySchedule;
};

export default function RandomSettingsModal({
  randomCategoryMutate,
  setRandomCategoryMutate,
  brandId
}: RandomSettingsModalProps) {
  const [data, setData] = useState<WeeklySchedule>({
    SUNDAY: [],
    MONDAY: [],
    TUESDAY: [],
    WEDNESDAY: [],
    THURSDAY: [],
    FRIDAY: [],
    SATURDAY: [],
  });

  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const days: DaysType[] = [
    { title: "일", value: "SUNDAY" },
    { title: "월", value: "MONDAY" },
    { title: "화", value: "TUESDAY" },
    { title: "수", value: "WEDNESDAY" },
    { title: "목", value: "THURSDAY" },
    { title: "금", value: "FRIDAY" },
    { title: "토", value: "SATURDAY" },
  ];

  const onSave = async () => {
    setSaveLoading(true);
    try {
      console.log(data);
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/admin/randomcategory/bulkcreate?brandId=${brandId}`,
        {
          data: data,
        }, { withCredentials: true }
      );
      alert('환경 설정이 저장 되었습니다.')
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
    setSaveLoading(false);
  };

  const addCategory = (dayToWeek: keyof WeeklySchedule) => {
    // 해당 요일의 카테고리 배열이 없으면 초기화
    if (!data[dayToWeek]) {
      data[dayToWeek] = [];
    }

    // 해당 요일의 카테고리 배열에 추가
    data[dayToWeek].push({
      type: ProgramType[0].name,
      category: ProgramCategory[0].name,
    });

    // 상태 업데이트
    setData({ ...data });
  };

  useEffect(() => {
    if (randomCategoryMutate === true) {
      getData();
    }
  }, [randomCategoryMutate]);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/admin/randomcategory?brandId=${brandId}`, { withCredentials: true });
      console.log('asdasd ', response.data);
      setData(response.data);
      setRandomCategoryMutate(false);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const deleteCategory = (value: keyof WeeklySchedule, index: number) => {
    // 현재의 data 상태 복사
    const newData = { ...data };

    // 선택한 value의 카테고리에서 해당 index 제거
    newData[value] = newData[value].filter((_: any, i: number) => i !== index);

    // 상태 업데이트
    setData(newData);
  };

  return (
    <div className="">
      <div className="flex justify-between items-center mb-5">
        <h2 className="font-bold mb-2">요일별 프로그램 카테고리 설정</h2>
        <div>
          <Button
            loading={saveLoading}
            title={`설정 저장`}
            onClick={onSave}
            iconComponent={<FontAwesomeIcon icon={faSave} />}
          />
        </div>
      </div>
      <div className="flex space-x-2">
        {days.map(({ title, value }: DaysType, index: number) => (
          <div
            key={index}
            className=" w-[250px]   flex justify-between rounded-lg shadow-md overflow-hidden"
          >
            <div className="flex p-2 flex-col space-y-2 w-full">
              <p
                className={cls(
                  "text-xl font-bold",
                  getDayStringClassNames(title)
                )}
              >
                {title}
              </p>

              {loading ? (
                <div className="flex cursor-pointer  rounded-md  w-full h-5 bg-gray-100 transition-all  shadow-md"></div>
              ) : (
                data[value] &&
                data[value].map(
                  (category: RandomCategoryDto, index: number) => (
                    <div className="flex items-center space-x-2" key={index}>
                      <select
                        className="select select-bordered select-xs w-full max-w-xs truncate"
                        value={category.type}
                        onChange={(event) => {
                          let newData = { ...data };
                          newData[value][index].type = event.target.value;
                          setData(newData);
                        }}
                      >
                        {ProgramType.map((type: { name: string }) => (
                          <option key={type.name}>{type.name}</option>
                        ))}
                      </select>
                      <select
                        className="select select-bordered select-xs w-full max-w-xs truncate"
                        value={category.category}
                        onChange={(event) => {
                          let newData = { ...data };
                          newData[value][index].category = event.target.value;
                          setData(newData);
                        }}
                      >
                        {ProgramCategory.map((type: { name: string }) => (
                          <option key={type.name}>{type.name}</option>
                        ))}
                      </select>
                      <FontAwesomeIcon
                        icon={faTrash}
                        width={10}
                        className=" hover:bg-gray-300 cursor-pointer w-3 h-3 p-1 rounded-md"
                        onClick={() => deleteCategory(value, index)}
                      />
                    </div>
                  )
                )
              )}

              <div
                onClick={() => addCategory(value)}
                className="flex cursor-pointer  rounded-md  w-full h-5 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
              >
                <FontAwesomeIcon icon={faPlus} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

import axios from "axios";
import QueryString from "qs";

export const getTableData = async (
  page: number,
  size: number,
  sortBy: string,
  direction: string,
  dataApiUrl: string,
  branchCode?: string
) => {
  try {
    const { data } = await axios.get(dataApiUrl, {
      withCredentials: true,
      params: {
        page,
        size,
        sortBy,
        direction,
        branchCode,
      },
    });
    return data;
  } catch (error) {
    window.location.reload();
  }
};

export const getTableSearchData = async (
  page: number,
  size: number,
  sortBy: string,
  direction: string,
  dataApiUrl: string,
  searchParams: any,
  startTime: string,
  endTime: string,
) => {
  let _startTime = 0;
  let _endTime = 0;

  if(startTime) {
    let sDate = new Date(startTime)
    sDate.setHours(0,0,0,0)
    _startTime = Math.floor(sDate.getTime()/1000)
  }
  if(endTime) {
    let eDate = new Date(endTime)
    eDate.setHours(23, 59, 59, 0)
    _endTime = Math.floor(eDate.getTime()/1000)
  }
  const { data } = await axios.get(dataApiUrl!, {
    withCredentials: true,
    params: {
      ...searchParams,
      page,
      size,
      sortBy,
      direction,
      startTime:_startTime,
      endTime:_endTime,
    },
    paramsSerializer: (params) => {
      return QueryString.stringify(params, { arrayFormat: "comma" });
    },
  });
  return data;
};

import { useForm } from "react-hook-form";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faDumbbell,
  faHouse,
  faPersonBiking,
  faSquarePollVertical,
  faTv,
  faUser,
  faPersonWalking,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { cls } from "../../libs/utils";
import { useStore } from "zustand";
import { useBranchStore, useUserStore } from "../../store";
import {
  adminMenu,
  branchMenu,
  branchMenuWithAdmin,
  checkBrandList,
} from "../../constants/AdminMenu";
import { Branch } from "../../types/models";
import { BrandDto } from "../../types/dto";
import { useQuery } from "@tanstack/react-query";
import { fetchBranchNameByCode, fetchBranches, fetchBrands } from "../../services/branch";
import AdminMenu from "../Menu/AdminMenu";
import MobileMenu from "../Menu/MobileMenu";
import { useWebSocket } from "../../hooks/useWebSocket";

export default function BrandLayout({
  //children,
  //title,
  selectBrand,
  setSelectBrand,
  target,
  brandType
}: {
  //children: React.ReactNode;
  //title: string;
  selectBrand: BrandDto | undefined;
  setSelectBrand: any;
  target:string;
  brandType?: number | undefined;
}) {
  
    const user = useUserStore((state) => state.user);
    const fetchBrandsFilter = async ()=>{
      const brandList = checkBrandList(user, target)
      const data = await fetchBrands();
      let result = []

      for (let i = 0; i < data.length; i++) {
        for (let j = 0; j < brandList.length; j++) {
          if(data[i].id == brandList[j])
          {
            if(brandType)
            {
              if(brandType == data[i].type)
              {
                result.push(data[i])
                break;
              }
            }
            else
            {
              result.push(data[i])
              break;
            }
          }
        }
      }

      return result;
    }
    const { data: brandData = [] } = useQuery<BrandDto[]>({
        queryKey: ["getAllBrand"],
        queryFn: fetchBrandsFilter
    });

    useEffect(() => {
        if(brandData && brandData.length > 0)
        {
          setSelectBrand(brandData[0])
        }
        else
        {
          setSelectBrand(undefined)
        }
        
      }, [brandData]);

  return (
    <div className="w-full h-full">
        <div className="w-full grid grid-cols-7 gap-1 my-8 mx-4">
            {brandData &&
            brandData?.map((brand) => (
                <div
                    className={brand.id == selectBrand?.id?` w-40 h-12 btn-sm btn bg-[#2ea7e0] text-white text-base`:` w-40 h-12 btn-sm btn btn-ghost text-[#AEAEAE] text-base border border-gray-200`}
                    onClick={()=>{setSelectBrand(brand)}}
                >
                    {brand.name}
                </div>
            ))}
        </div>
        {/* {children} */}
    </div>
  );
}

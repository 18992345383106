import { cls } from "../../libs/utils";
import React, { useRef, useEffect, useState } from 'react';

interface ModalProps {
  id?: string;
  title?: string;
  children: React.ReactNode;
  size?: string;
  closeClickedOutside?: boolean;
  overflow?: string;
}

interface InputProps {
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

export default function Modal({
  id,
  title,
  children,
  size = "default",
  closeClickedOutside = false,
  overflow = "visible",
}: ModalProps) {
  const modalRef = useRef<HTMLDialogElement>(null);
  const [hasChildFocus, setHasChildFocus] = useState(false);

  // const handleKeyUp = (e: React.KeyboardEvent<HTMLDialogElement>) => {
  //   // if (e.key === 'Enter' && !hasChildFocus || e.key === 'Escape') {
  //   if (e.key === 'Escape') {
  //     e.preventDefault(); // 모달 외부에서 Enter 키를 눌렀을 때 기본 동작을 막음
  //   }
  // };

  // const handleChildFocus = (e: React.FocusEvent<HTMLElement>) => {
  //   setHasChildFocus(true);
  // };

  // const handleChildBlur = (e: React.FocusEvent<HTMLElement>) => {
  //   setHasChildFocus(false);
  // };

  // useEffect(() => {
  //   const modalElement = modalRef.current;

  //   if (!modalElement) return;

  //   const handleKeyDown = (event: KeyboardEvent) => {
  //     // if (event.key === 'Enter' && !hasChildFocus || event.key === 'Escape') {
  //     if (event.key === 'Escape') {
  //       event.preventDefault(); // 모달 외부에서 Enter 키를 눌렀을 때 기본 동작을 막음
  //     }
  //   };

  //   modalElement.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     modalElement.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, [hasChildFocus]);

  return (
    <dialog
      id={id}
      className="modal"
      ref={modalRef}
      //onKeyUp={handleKeyUp}
    >
      <form
        method="dialog"
        className={cls(
          "modal-box",
          size === "large"
            ? `max-w-5xl min-w-[64rem] ${overflow === "visible" ? "" : ""}`
            : size === "xlarge"
            ? `max-w-7xl min-w-[64rem]  ${
                overflow === "visible" ? "overflow-visible" : ""
              }`
            : size === "2xlarge"
            ? ` max-w-[1600px] min-w-[64rem]  ${
                overflow === "visible" ? "overflow-visible" : ""
              }`
            : size === "fit"
            ? "max-w-fit min-w-[64rem] "
            : size === "full"
            ? " min-w-fit"
            : ""
        )}
      >
        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2 tv:btn-lg">
          ✕
        </button>
        <h3 className="font-bold text-lg text-gray-800 tv:text-5xl ">
          {title}
        </h3>
        {children}

        {/* {React.Children.map(children, (child) => {
          if (React.isValidElement(child)) {
            // 만약 자식 요소가 입력 요소인 경우에만 Enter 키 이벤트를 처리
            if (child.type === 'input') {
              return React.cloneElement(child, {
                onFocus: handleChildFocus,
                onBlur: handleChildBlur,
              } as InputProps);
            }
          }
          return child;
        })} */}
      </form>

      <form method="dialog" className="modal-backdrop">
        {closeClickedOutside && <button>close</button>}
      </form>
    </dialog>
  );
}

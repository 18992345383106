import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

//--> 레이아웃 의 상세 UI
export default function LayoutDetailItem({
  tvName,
  optionType,
  optionOver,
  optionAdd,
  optionMission,
  optionTeamCount,
  optionWod,
  tvCount,
  tvIds,
  tvTeams,
  setTVCount,
  setTVIds,
  setTVTeams,
  isFirst,
  zeroText,
  disabled,
  tv3StateVidible,
  tv3State,
  setTV3State,
}: {
  tvName:string,
  optionType:string,
  optionOver:number,
  optionAdd:number,
  optionMission:number,
  optionTeamCount:number,
  optionWod:number,
  tvCount:number,
  tvIds:string,
  tvTeams:string,
  setTVCount:any,
  setTVIds:any,
  setTVTeams:any,
  isFirst?:boolean,
  zeroText?:string,
  disabled?:boolean,
  tv3StateVidible?:boolean,
  tv3State?:number,
  setTV3State?:any,
}) {
  const getTeams = ()=>{
    return tvTeams?tvTeams.split(','):['', '', '', '', '', '', '', ''];
  }
  const getIds = ()=>{
    return tvIds?tvIds.split(','):['0','0','0','0','0','0','0','0'];
  }
  

  const teamList = getTeams()
  const idList = getIds()

  let renderlist = []
  for (let i = 0; i < tvCount; i++) {
    renderlist.push('')
  }

  const setTeam = (index:number, team:string)=>{
    let _teamList = getTeams()
    _teamList[index] = team

    setTVTeams(_teamList.toString())
    console.log('setTeam : ', index, team, _teamList)
  }

  const setId = (index:number, id:string)=>{
    let _idList = getIds()
    _idList[index] = id

    setTVIds(_idList.toString())
    console.log('setId : ', index, id, _idList, tvIds)
  }

  return (
    <div className=" w-64 mr-5">
      <div className="flex items-center mb-2 font-bold text-[#20A752]">
        {tvName}
        <select
          value={tvCount}
          onChange={(e)=>{setTVCount(e.target.value)}}
          className="select select-bordered w-20 max-w-xs ml-4 text-black"
          disabled={disabled}
        >
          {
            !isFirst?
            <option value={0}>{'0'}</option>
            :null
          }
          <option value={1}>{'1'}</option>
          <option value={2}>{'2'}</option>
          <option value={3}>{'3'}</option>
          <option value={4}>{'4'}</option>
          {
            optionOver != 1 && optionAdd != 1?
            <option value={6}>{'6'}</option>
            :null
          }
          {
            optionOver != 1 && optionAdd != 1?
            <option value={8}>{'8'}</option>
            :null
          }
        </select>
      </div>
      <div className=" w-64 h-96 bg-[#D5D6D9]">
        <div
          className={
            tvCount >= 1 && tvCount <= 4?"border-2 w-full h-full grid grid-cols-1":
            tvCount >= 6?"border-2 w-full h-full  grid grid-cols-2":
            "w-full h-full justify-center items-center"
          }
        >
          {
            renderlist.length == 0?
            <div
              className="flex flex-1 w-full h-full justify-center items-center text-center text-lg font-bold"
            >
              {
                tv3StateVidible?
                <select
                  value={tv3State}
                  onChange={(e)=>{setTV3State(e.target.value)}}
                  className="select select-bordered w-full max-w-xs mx-4 text-black"
                  disabled={disabled}
                >
                  <option value={0}>{'TV2와 동일'}</option>
                  <option value={1}>{'TV1과 동일'}</option>
                </select>
                :zeroText
              }
            </div>
            :null
          }
          {
            renderlist.map((renderItem, index)=>{
              return (
                <div
                  className={idList[index] == '-1'?" w-full h-full border-b border-r bg-[#D5D6D9] flex flex-col":" w-full h-full border-b border-r bg-white flex flex-col"}
                  key={`LayoutDetailItem_renderlist_${index}`}
                >
                  <div className="flex w-full h-full">
                    <div className="flex-1 flex flex-col">
                      <div className="flex">
                        {
                          optionType == 'team' && idList[index] != '-1'?
                          <div className="dropdown dropdown-bottom">
                            <label
                              className={
                                teamList[index] == 'A'?"btn  btn-xs m-1 bg-[#BFDBFE]":
                                teamList[index] == 'B'?"btn  btn-xs m-1 bg-[#FED7AA]":
                                teamList[index] == 'C'?"btn  btn-xs m-1 bg-[#61D78D]":
                                teamList[index] == 'D'?"btn  btn-xs m-1 bg-[#F87272]":
                                teamList[index] == 'E'?"btn  btn-xs m-1 bg-[#FACB02]":
                                teamList[index] == 'F'?"btn  btn-xs m-1 bg-[#6189D7]":
                                teamList[index] == 'G'?"btn  btn-xs m-1 bg-[#D27E4F]":
                                teamList[index] == 'H'?"btn  btn-xs m-1 bg-[#D761CB]":
                                teamList[index] == 'I'?"btn  btn-xs m-1 bg-[#A3D761]":
                                teamList[index] == 'J'?"btn  btn-xs m-1 bg-[#8061D7]":
                                "btn  btn-xs m-1 bg-[#D5D6D9]"}
                              tabIndex={0}
                            >
                              {teamList[index] == ''?'-':teamList[index]}팀
                            </label>
                            {
                              disabled?
                              null
                              :
                              <div>
                                <ul
                                  tabIndex={0}
                                  className={"dropdown-content z-[9999] menu p-2 shadow bg-base-100 rounded-box w-52"}
                                  onMouseDown={(e)=>{
                                    let targetEl = e.currentTarget;
                                    if(targetEl){
                                      setTimeout(function(){
                                        targetEl.blur();
                                      }, 500);
                                    }
                                  }}
                                >
                                  <li>
                                    { optionTeamCount >= 1?<a onClick={() =>{setTeam(index, 'A')}}>{'A팀'}</a>:null }
                                    { optionTeamCount >= 2?<a onClick={() =>{setTeam(index, 'B')}}>{'B팀'}</a>:null }
                                    { optionTeamCount >= 3?<a onClick={() =>{setTeam(index, 'C')}}>{'C팀'}</a>:null }
                                    { optionTeamCount >= 4?<a onClick={() =>{setTeam(index, 'D')}}>{'D팀'}</a>:null }
                                    { optionTeamCount >= 5?<a onClick={() =>{setTeam(index, 'E')}}>{'E팀'}</a>:null }
                                    { optionTeamCount >= 6?<a onClick={() =>{setTeam(index, 'F')}}>{'F팀'}</a>:null }
                                    { optionTeamCount >= 7?<a onClick={() =>{setTeam(index, 'G')}}>{'G팀'}</a>:null }
                                    { optionTeamCount >= 8?<a onClick={() =>{setTeam(index, 'H')}}>{'H팀'}</a>:null }
                                    { optionTeamCount >= 9?<a onClick={() =>{setTeam(index, 'I')}}>{'I팀'}</a>:null }
                                    { optionTeamCount >= 10?<a onClick={() =>{setTeam(index, 'J')}}>{'J팀'}</a>:null }
                                  </li>
                                </ul>
                              </div>
                            }
                          </div> 
                          :
                          null
                        }
                        <div className="flex-1"/>
                        {
                          optionOver != 1 && optionAdd != 1 && renderlist.length > 1?
                          <div
                            className=" rounded-full w-5 h-5 bg-black justify-center items-center text-white mt-1 mr-1 text-center"
                            onClick={()=>{
                              if(disabled) {
                                return
                              }
                              setId(index, idList[index] == '-1'?'0':'-1')
                            }}
                          >
                            {
                              idList[index] == '-1'?
                              <FontAwesomeIcon icon={faCircle} color={'#ffffff'} size={'xs'} />
                              :
                              <FontAwesomeIcon icon={faClose} color={'#ffffff'} size={'xs'} />
                            }
                          </div>
                          :
                          <div className="h-6"/>
                        }
                      </div>
                      {
                        idList[index] != '-1'?
                        <div className="m-1 flex-1 items-center justify-center">
                          <input
                            placeholder={`-`}
                            value={idList[index] == '0'?'':idList[index]}
                            style={{alignItems:'center', textAlign:'center', fontSize:'1.1rem', fontWeight:600, width:'100%', margin:0, padding:0, border:0, height:'100%'}}
                            // className="input text-center font-bold text-xl w-full"
                            onChange={(e)=>{
                              var regex = /[^0-9]/g;
                              var result = e.target.value.replace(regex, "");
                              
                              setId(index, parseInt(result?result:'0').toString())
                            }}
                            readOnly={disabled}
                          />
                        </div>
                        :
                        <div className="m-1 flex-1 bg-[#D5D6D9] invisible">
                          <input
                            placeholder={``}
                            style={{alignItems:'center', textAlign:'center', fontSize:'1.1rem', fontWeight:600, width:'100%', margin:0, padding:0, border:0, height:'100%'}}
                            readOnly
                          />
                        </div>
                      }
                      <div className=" flex w-full h-6 ">
                        <div className=" flex-1"/>
                        {
                          optionMission == 1 && idList[index] != '-1'?
                          <label className=" mr-1 mb-1 font-bold text-[#9138BB]">M</label>
                          :
                          optionWod == 1 && idList[index] != '-1'?
                          <label className=" mr-1 mb-1 font-bold text-[#00C7F2]">W</label>
                          :
                          null
                        }
                      </div>
                    </div>
                    {
                      optionOver == 1 || optionAdd == 1?
                      <div className={idList[index] == '-1'?"flex-1 flex":"flex-1 flex border-l"}>
                        <div className=" flex-1 flex justify-center items-center ml-5 font-bold">
                          {
                            idList[index] != '-1'?
                            (optionOver == 1?"OVER":"ADD")
                            :null
                          }
                        </div>
                        {
                          renderlist.length > 1?
                          <div
                            className=" rounded-full w-5 h-5 bg-black justify-center items-center text-white mt-1 mr-1 text-center"
                            onClick={()=>{
                              setId(index, idList[index] == '-1'?'0':'-1')
                            }}
                          >
                            {
                              idList[index] == '-1'?
                              <FontAwesomeIcon icon={faCircle} color={'#ffffff'} size={'xs'} />
                              :
                              <FontAwesomeIcon icon={faClose} color={'#ffffff'} size={'xs'} />
                            }
                          </div>
                          :null
                        }
                      </div>
                      :null
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  );
}

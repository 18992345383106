import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCirclePlus,
  faCircleXmark,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faLocation,
  faSign,
  faTriangleExclamation,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../../../components/Layout/AdminLayout";
import { Pagination } from "../../../components/interface/Pagination";
import { useEffect, useState } from "react";
import { SecondsToMinutes, StringToDate, getUseEditFromAuth } from "../../../libs/utils";
import Table from "../../../components/interface/Table";
import { useUserStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import { SearchDataForProgram } from "../../../constants/SearchData";
import { TableHeaderForProgram } from "../../../constants/TableHeader";
import BrandLayout from "../../../components/Layout/BrandLayout";
import { BrandDto } from "../../../types/dto";
import Modal from "../../../components/interface/Modal";
import { Programtype } from "../../../types/models";

export default function Program() {
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();
  const [mutate, setMutate] = useState(false);
  const [brandData, setBrandData] = useState<BrandDto[]>();
  const [programTypeAllData, setProgramTypeAllData] = useState<Programtype[]>();
  const [brandAuth, setBrandAuth] = useState<any[]>();

  const tableHeader = TableHeaderForProgram;

  const onAddBtn = () => {
    //navigate(`/admin/program/add`);
    
    window.my_modal_2.showModal();
  };
  useEffect(() => {
    const getBrands = async () => {
      try {
        const getbrand = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`, {
          withCredentials: true,
        });
        setBrandData(getbrand.data)
      } catch (error) {
      }
    };
    const getProgramTypes = async () => {
      try {
        const getProgramType = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/programtype/all`, {
          withCredentials: true,
        });
        let result = []
        if(getProgramType.data) {
          for (let i = 0; i < getProgramType.data.length; i++) {
            console.log(getProgramType.data[i])
            result.push({...getProgramType.data[i], sname:getProgramType.data[i].name, name:`[${getProgramType.data[i].brandName}] - ${getProgramType.data[i].name}`})
            
          }
          result.sort((a:Programtype, b:Programtype)=>{
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          })
        }
        setProgramTypeAllData(result)
      } catch (error) {
      }
    };

    getBrands()
    getProgramTypes()
  }, []);
  useEffect(() => {
    if(user) {
      const _brandAuth = JSON.parse(user.brandAuth);
      if(_brandAuth)
      {
        setBrandAuth(_brandAuth)
      }
    }
  }, [user]);

  
  const searchData = [
    { title: "브랜드", value: brandData, searchKey: "id", searchFiled: "brandIds" },
    {
      title: "프로그램 종류",
      value: [
        { name: "본운동" },
        { name: "준비운동" },
        { name: "마무리운동" },
        { name: "스트레칭" },
        { name: "추가운동" },
      ],
      searchKey: "name",
      searchFiled: "programType",
    },
    { title: "운동타입", value: programTypeAllData, searchKey: "sname", searchFiled: "programCategory" }
  ];

  const onEditProgram = (id: number, actionType: number) => {
    console.log('actionType : ', actionType)
    if(actionType == 1) {
      navigate(`/admin/program/edit/${id}`);
    }
    else {
      navigate(`/admin/program/dynamicedit/${id}`);
    }
    //navigate(`/admin/program/edit/${id}`);
  };

  const onDelete = async (item: any) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    try {
      if(item.actionType == 1) {
        const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}api/admin/program/${item.id}`, { withCredentials: true });
        console.log(response);
      }
      else {
        const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}api/admin/dynamicprogram/${item.id}`, { withCredentials: true });
        console.log(response);
      }
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  return (
    <>
      <Modal id="my_modal_2" title={`프로그램 등록`}>

        <div className="w-full justify-center flex mt-5">
          <button
            className=" btn-primary btn w-full   text-white  "
            onClick={()=>{navigate(`/admin/program/dynamicadd`);}}
          >
            <FontAwesomeIcon icon={faCirclePlus} />
            {`다분할 프로그램 등록`}
          </button>
        </div>
        <div className="w-full justify-center flex mt-5">
          <button
            className=" btn-primary btn w-full   text-white  "
            onClick={()=>{navigate(`/admin/program/add`);}}
          >
            <FontAwesomeIcon icon={faCirclePlus} />
            {`HIT35(4분할) 프로그램 등록`}
          </button>
        </div>
      </Modal>
      <AdminLayout>
        <div>
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">{'운동 프로그램 관리'}</h1>
          <Table
            dataApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/dynamicprogram`}
            buttonComponent={
              <button
                className=" btn-primary btn   text-white  "
                onClick={onAddBtn}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                프로그램 등록
              </button>
            }
            headerArray={tableHeader}
            mutate={mutate}
            initSort="createdAt"
            initDirection="desc"
            searchData={searchData}
            searchApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/dynamicprogram/search`}
          >
            {(data: any, startIndex: number) =>
              data.map((item: any, index: number) => {
                console.log(startIndex, index);
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>
                    <td>
                      <StringToDate dateString={item?.createdAt} />
                    </td>
                    <td>{!(item?.brandName)?'공통':item?.brandName}</td>
                    <td>{item?.programType}</td>
                    <td>{!(item?.programCategory)?'-':item?.programCategory}</td>
                    <td>{!(item?.curriculumName)?'-':item?.curriculumName}</td>
                    <td>{!(item?.layoutName)?'-':item?.layoutName}</td>
                    <td>{item?.name}</td>
                    <td className="">
                      <p> {item?.totalMotions}</p>

                      <p className="text-xs">{`(${item.exerciseTime}/${item.moveTime})`}</p>
                    </td>
                    <td className="">
                      <p>{item.totalSet}</p>

                      <p className="text-xs">
                        {" "}
                        <SecondsToMinutes seconds={item?.totalExerciseTime} />
                      </p>
                    </td>
                    {/* <td className="">
                      <p>
                        {item?.lastLectureDate
                          ? item?.lastLectureDate.toString()
                          : null}
                      </p>
                    </td> */}
                    <td className="flex space-x-2">
                      {item.code !== "main" && (
                        <>
                          <button
                            onClick={() => onEditProgram(item.id, item.actionType)}
                            className="btn btn-xs"
                            disabled={!getUseEditFromAuth('2', item?.brandId, brandAuth || [])}
                          >
                            수정
                          </button>
                          <button
                            onClick={() => onDelete(item)}
                            className="btn btn-xs btn-error"
                            disabled={!getUseEditFromAuth('2', item?.brandId, brandAuth || [])}
                          >
                            삭제
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}

import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import ErrorMsg from "../../components/ErrorMsg";
import { useQuery } from "@tanstack/react-query";
import { fetchBranchNameByCode } from "../../services/branch";
import Button from "../../components/interface/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCamera,
  faDumbbell,
  faQrcode,
  faSignIn,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useBranchStore } from "../../store";
import { useEffect, useState } from "react";
import { getClosestBranchScheduleId } from "../../services/group";

type SigninForm = {
  userId: string;
};

export default function ScreenGroup() {
  const { branchCode } = useParams();
  const storeBranchName = useBranchStore((state) => state.branchName);
  const navigate = useNavigate();

  const [scheduleId, setScheduleId] = useState();

  const [error, setError] = useState("");

  //서버에서 가장 가까운 시간대의 스케줄을 가져온 후 해당 URL로 리다이렉트
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}api/public/group/${branchCode}/closestfutureschedule`,
        { withCredentials: true }
      );

      setScheduleId(data);
    } catch (error) {
      console.log(error);
      setError("현재 예정된 스케줄이 없습니다.");
    }
  };

  //서버에서 가장 가까운 시간대의 스케줄을 가져온 후 해당 URL로 리다이렉트
  useEffect(() => {
    console.log('asdasd : ', scheduleId)
    if (scheduleId) {
      navigate(`/screen/${branchCode}/group/${scheduleId}`);
    }
  }, [scheduleId]);

  return (
    <div className="bg-black justify-center items-center min-h-screen flex p-5 md:p-20 flex-col    ">
      {error ? (
        <p className="text-white">{error}</p>
      ) : (
        <span className="loading loading-spinner loading-lg bg-white"></span>
      )}
    </div>
  );
}

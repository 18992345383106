import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsStaggered,
  faBook,
  faCircleMinus,
  faCirclePlus,
  faCircleXmark,
  faClock,
  faDroplet,
  faFolder,
  faHeading,
  faIcons,
  faLayerGroup,
  faLocation,
  faPerson,
  faPlus,
  faRepeat,
  faRotateRight,
  faSign,
  faSquarePlus,
  faStopwatch,
  faTableCells,
} from "@fortawesome/free-solid-svg-icons";

import { useEffect, useState } from "react";

import { useUserStore } from "../store";
import FormBox from "./interface/FormBox";
import Radio from "./interface/Radio";
import FormInput from "./interface/FormInput";
import Button from "./interface/Button";
import { useNavigate } from "react-router-dom";
import { getUseEditFromAuth } from "../libs/utils";
import Modal from "./interface/Modal";
import ContentsSearchModal from "./Modal/ContentsSearchModal";
import { v4 as uuidv4 } from "uuid";
import ContentsBoxWrap from "./ContentBoxDragWrap";
import ContentsGapDrag from "./ContentGapDrag";
import FullPageLoading from "./interface/FullPageLoading";
import { Tool } from "../types/models";
import { BrandDto, ContentsDtoWithId, CurriculumDto, DynamicProgramDto, LayoutDto, ProgramCategoryDto } from "../types/dto";
import ProgramsSelectModal from "./Modal/ProgramsSelectModal";
import LayoutDetailView from "./LayoutDetailView";
import AddProgramsSearchModal from "./Modal/AddProgramsSearchModal";

interface UserFormData {
  id: number;
  programType: string;
  programCategory: string;
  brandId: number;
  curriculumId: number;
  layoutId: number;
  name: string;
  totalMotions: number;
  timeType: string;
  exerciseTime: number;
  moveTime: number;
  exerciseTimes: string;
  moveTimes: string;
  comment: string;
  areaRepeatCount:number;
  areaRepeatTimes:string;
  teamRepeatCount:number;
  teamRepeatTimes:string;
  totalRepeatCount:number;
  totalRepeatTimes:string;
  waterTimes:string;
  addPrograms:string;
  bpm: number;
  bpmInfo: string;
}

interface ProgramDetailProps {
  readOnly?: boolean;
  programId?: string;
}

export default function DynamicProgramDetail({
  readOnly = false,
  programId,
}: ProgramDetailProps) {
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalExerciseTime, setTotalExerciseTime] = useState("");
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [moveTimeList, setMoveTimeList] = useState<string[]>();
  const [exerciseTimeList, setExerciseTimeList] = useState<string[]>();

  
  const [areaRepeatTimeList, setAreaRepeatTimeList] = useState<any[]>();
  const [teamRepeatTimeList, setTeamRepeatTimeList] = useState<any[]>();
  const [totalRepeatTimeList, setTotalRepeatTimeList] = useState<any[]>();
  const [waterTimeList, setWaterTimeList] = useState<any[]>();
  const [addProgramList, setAddProgramList] = useState<any[]>();
  const [addProgramIndex, setAddProgramIndex] = useState(-1);

  const [missionList, setMissionList] = useState<any[]>();
  const [wodList, setWodList] = useState<any[]>();
  const [wodTitleList, setWodTitleList] = useState<string[]>();

  const [getLoading, setGetLoading] = useState(false);

  const [programCategoryList, setProgramCategoryList] = useState<ProgramCategoryDto[]>();
  const [programCategory, setProgramCategory] = useState<ProgramCategoryDto[]>();
  
  const [curriculumList, setCurriculumList] = useState<CurriculumDto[]>();
  const [curriculum, setCurriculum] = useState<CurriculumDto[]>();

  const [layoutList, setLayoutList] = useState<LayoutDto[]>();
  const [layout, setLayout] = useState<LayoutDto[]>();
  const [currentLayout, setCurrentLayout] = useState<LayoutDto>();
  

  const [brandList, setBrandList] = useState<BrandDto[]>();

  //--> 운동 데이터
  const [zoneData, setZoneData] = useState<ContentsDtoWithId[][]>([]);
  const [totalSet, setTotalSet] = useState(0);
  const [tutorialId, setTutorialId] = useState<number[]>([]);
  const [zoneTools, setZoneTools] = useState<string[]>([]);
  
  const [modalZoneData, setModalZoneData] = useState<ContentsDtoWithId[]>([]);
  const [modalIndex, setModalIndex] = useState(0);
  const [bpmInfoCount, setBpmInfoCount] = useState(0);
  
  
  const ProgramType = [
    { name: "본운동" },
    { name: "준비운동" },
    { name: "마무리운동" },
    { name: "스트레칭" },
    { name: "추가운동" },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<UserFormData>({
    mode:"onChange",
     defaultValues: {
       timeType: '1',
       areaRepeatCount: 1,
       areaRepeatTimes: '',
       teamRepeatCount: 1,
       teamRepeatTimes: '',
       totalRepeatCount: 1,
       totalRepeatTimes: '',
       programCategory: '',
       bpm:0,
       bpmInfo: '',
     }});
  
  const onValid = async (formData: UserFormData) => {
    setLoading(true);
    try {
      const _brandName = brandList?.filter((brand:BrandDto)=>{
        if(brand.id == formData.brandId)
        {
          return true
        }

        return false;
      })
      const _curriculumName = curriculum?.filter((_curriculum:CurriculumDto)=>{
        if(_curriculum.id == formData.curriculumId)
        {
          return true
        }

        return false;
      })
      const _layoutName = layout?.filter((_layout:LayoutDto)=>{
        if(_layout.id == formData.layoutId)
        {
          return true
        }

        return false;
      })
      
      //
      //==> check
      if(formData.programType == '본운동') {
        if(!formData.layoutId || formData.layoutId == 0) {
          alert('레이아웃을 선택하세요.')
          setLoading(false);
          return;
        }

        if(!zoneData || zoneData[0].length == 0) {
          alert('동작을 추가하세요.')
          setLoading(false);
          return;
        }

        if(currentLayout && (currentLayout.optionMission == 0 && currentLayout.optionWod == 0 && currentLayout.optionAdd == 0 && currentLayout.optionOver == 0)) {
          const zonecount = zoneData[0].length
          for (let i = 0; i < zoneData.length; i++) {
            if(zonecount != zoneData[i].length) {
              alert('동작 수가 다름니다.')
              setLoading(false);
              return;
            }
            
          }
        }
        else {
          const maxcount = (currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1)?2:1;
          for (let i = 0; i < zoneData.length; i++) {
            if((zoneData[i].length == 0) || (zoneData[i].length > maxcount)) {
              alert('동작을 잘못 입력하였습니다.')
              setLoading(false);
              return;
            }
          }
        }
        if(watch("timeType") == '1') {
          const _exerciseTime = watch("exerciseTime")
          if(!_exerciseTime || _exerciseTime == 0) {
            alert('영역 세트시간을 입력하세요.')
            setLoading(false);
            return;
          }
        }
        else {
          if(!exerciseTimeList || (exerciseTimeList.length == 0)) {
            alert('영역 세트시간을 입력하세요.')
            setLoading(false);
            return;
          }
          for (let i = 0; i < exerciseTimeList.length; i++) {
            if(!parseInt(exerciseTimeList[i]) || parseInt(exerciseTimeList[i]) == 0) {
              alert('영역 세트시간을 입력하세요.')
              setLoading(false);
              return;
            }
          }
        }
      }
      else {
        if(!zoneData || zoneData[0].length == 0) {
          alert('동작을 추가하세요.')
          setLoading(false);
          return;
        }
        const zonecount = zoneData[0].length
        for (let i = 0; i < zoneData.length; i++) {
          if(zonecount != zoneData[i].length) {
            alert('동작 수가 다름니다.')
            setLoading(false);
            return;
          }
          
        }
        const _exerciseTime = watch("exerciseTime")
        if(!_exerciseTime || _exerciseTime == 0) {
          alert('영역 세트시간을 입력하세요.')
          setLoading(false);
          return;
        }
      }
      //

      let content = {};
      let exerciseNames = '';

      let _totalSet = Number(totalSet);
      let _totalExerciseTime = Number(totalSeconds);
      let _totalMotions = Number(totalSet)
      let _contentsIds:number[] = []
      

      _contentsIds = []
      for (let i = 0; i < zoneData.length; i++) {
        for (let j = 0; j < zoneData[i].length; j++) {
          _contentsIds.push(zoneData[i][j].content.id)
        }
      }

      _contentsIds = Array.from(new Set(_contentsIds))
      

      if(formData.programType == '본운동') {
        content = {
          type: formData.programType,
          data: null
        }
        if(!zoneData || zoneData.length == 0) {
          // error
        }

        const zoneIds = zoneData.map((itemList:ContentsDtoWithId[])=>{
          return itemList.map(
            (item: ContentsDtoWithId) => item.content.id
          );
        })
        const zoneHeartbeats = zoneData.map((itemList:ContentsDtoWithId[])=>{
          return itemList.map(
            (item: ContentsDtoWithId) => item.heartbeat
          );
        })
        
        content = {
          type: formData.programType,
          data: zoneIds,
          heartbeats:zoneHeartbeats,
          mission: missionList,
          wod: wodList,
          wodtitle: wodTitleList,
        }
      }
      else {
        if(!zoneData || zoneData.length == 0) {
          // error
        }
        const zoneIds = zoneData[0].map(
          (item: ContentsDtoWithId) => item.content.id
        );
        const zoneNames = zoneData[0].map(
          (item: ContentsDtoWithId) => " " + item.content.name
        );

        content = {
          type: formData.programType,
          data: zoneIds.toString()
        }

        exerciseNames = zoneNames.toString();
      }

      if(formData.programType != '본운동') {
        const zone1Ids = zoneData[0].map(
          (item: ContentsDtoWithId) => item.content.id
        );
  
        if (programId) {
          await axios.patch(`${process.env.REACT_APP_SERVER_URL}api/admin/program/${programId}`, {
            programType: formData.programType,
            programCategory: formData.programCategory,
            
            brandId: 0,
            brandName:'',
            curriculumId: 0,
            curriculumName:'',
  
            name: formData.name,
            totalMotions: formData.totalMotions,
            totalSet:_totalSet,
            zone1Ids: zone1Ids.toString(),
            zone2Ids: '',
            zone3Ids: '',
            exerciseTime: Number(formData.exerciseTime),
            moveTime: Number(formData.moveTime),
            totalExerciseTime: Number(totalSeconds),
            description: '',
            comment: formData.comment,
            tutorialIds: tutorialId.join(","),
            sets: '',
            cadioTimes: '',
            cadioPatterns: '',
            cadioStrength: '',
            floor:'',
            badgeUrl:'',
            exerciseNames:exerciseNames,
            contentsIds:_contentsIds.toString(),
          }, { withCredentials: true });
        } else {
          // 2. DB 저장
          await axios.post(`${process.env.REACT_APP_SERVER_URL}api/admin/program`, {
            programType: formData.programType,
            programCategory: formData.programCategory,
            
            brandId: 0,
            brandName:'',
            curriculumId: 0,
            curriculumName:'',
  
            name: formData.name,
            totalMotions: formData.totalMotions,
            totalSet:_totalSet,
            zone1Ids: zone1Ids.toString(),
            zone2Ids: '',
            zone3Ids: '',
            exerciseTime: Number(formData.exerciseTime),
            moveTime: Number(formData.moveTime),
            totalExerciseTime: Number(totalSeconds),
            description: '',
            comment: formData.comment,
            tutorialIds: tutorialId.join(","),
            sets: '',
            cadioTimes: '',
            cadioPatterns: '',
            cadioStrength: '',
            floor:'',
            badgeUrl:'',
            exerciseNames:exerciseNames,
            contentsIds:_contentsIds.toString(),
            bpm:formData.bpm,
            bpmInfo:formData.bpmInfo,
          }, { withCredentials: true });
        }
      }
      else if (programId) {
        
        await axios.patch(`${process.env.REACT_APP_SERVER_URL}api/admin/dynamicprogram/${programId}`, {
          programType: formData.programType,
          programCategory: formData.programCategory || '',
          
          brandId: formData.brandId,
          brandName:(_brandName && (_brandName.length>0))?_brandName[0].name:'',
          curriculumId: formData.curriculumId?formData.curriculumId:'0',
          curriculumName:(_curriculumName && (_curriculumName.length>0))?_curriculumName[0].name:'',
          layoutId: formData.layoutId?formData.layoutId:'0',
          layoutName:(_layoutName && (_layoutName.length>0))?_layoutName[0].name:'',

          name: formData.name,
          exerciseTimes:formData.exerciseTimes || '',
          moveTimes:formData.moveTimes || '',
          exerciseTime: Number(formData.exerciseTime),
          moveTime: Number(formData.moveTime),
          content: JSON.stringify(content),
          comment: formData.comment,
          timeType:formData.timeType || '1',
          areaRepeatCount:formData.areaRepeatCount || 1,
          teamRepeatCount:formData.teamRepeatCount || 1,
          totalRepeatCount:formData.totalRepeatCount || 1,
          areaRepeatTimes:formData.areaRepeatTimes || '',
          teamRepeatTimes:formData.teamRepeatTimes || '',
          totalRepeatTimes:formData.totalRepeatTimes || '',
          waterTimes:formData.waterTimes || '',
          totalSet: _totalSet,
          totalExerciseTime: _totalExerciseTime,
          totalMotions: _totalMotions,
          addPrograms:formData.addPrograms || '',
          exerciseNames:exerciseNames,
          tutorialIds: tutorialId.join(","),
          contentsIds:_contentsIds.toString(),
          bpm:formData.bpm,
          bpmInfo:formData.bpmInfo,
        }, { withCredentials: true });
      } else {
        // 2. DB 저장
        await axios.post(`${process.env.REACT_APP_SERVER_URL}api/admin/dynamicprogram`, {
          programType: formData.programType,
          programCategory: formData.programCategory || '',
          
          brandId: formData.brandId,
          brandName:(_brandName && (_brandName.length>0))?_brandName[0].name:'',
          curriculumId: formData.curriculumId?formData.curriculumId:'0',
          curriculumName:(_curriculumName && (_curriculumName.length>0))?_curriculumName[0].name:'',
          layoutId: formData.layoutId?formData.layoutId:'0',
          layoutName:(_layoutName && (_layoutName.length>0))?_layoutName[0].name:'',

          name: formData.name,
          exerciseTimes:formData.exerciseTimes || '',
          moveTimes:formData.moveTimes || '',
          exerciseTime: Number(formData.exerciseTime),
          moveTime: Number(formData.moveTime),
          content: JSON.stringify(content),
          comment: formData.comment,
          timeType:formData.timeType || '1',
          areaRepeatCount:formData.areaRepeatCount || 1,
          teamRepeatCount:formData.teamRepeatCount || 1,
          totalRepeatCount:formData.totalRepeatCount || 1,
          areaRepeatTimes:formData.areaRepeatTimes || '',
          teamRepeatTimes:formData.teamRepeatTimes || '',
          totalRepeatTimes:formData.totalRepeatTimes || '',
          waterTimes:formData.waterTimes || '',
          totalSet: _totalSet,
          totalExerciseTime: _totalExerciseTime,
          totalMotions: _totalMotions,
          addPrograms:formData.addPrograms || '',
          exerciseNames:exerciseNames,
          tutorialIds: tutorialId.join(","),
          contentsIds:_contentsIds.toString(),
          bpm:formData.bpm,
          bpmInfo:formData.bpmInfo,
        }, { withCredentials: true });
      }
      
      navigate(`/admin/program`);
      setLoading(false);
    } catch (error: any) {
      if (error && error.response && error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
      setLoading(false);
    }
  };
  
  function formatTime(seconds: number): string {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  //--> 시간과 세트수 다시 계산
  useEffect(() => {
    if(watch("programType") === "본운동") {
      let contentCount = (zoneData && zoneData.length > 0)?zoneData[0].length:0
      const _areaRepeatCount = Math.max(watch("areaRepeatCount"), 1)
      const _teamRepeatCount = Math.max(watch("teamRepeatCount"), 1)
      const _totalRepeatCount = Math.max(watch("totalRepeatCount"), 1)
      
      let addProgramTime = 0
      if(addProgramList) {
        for (let i = 0; i < addProgramList.length; i++) {
          addProgramTime += Number(addProgramList[i].programTime)
        }
      }

      let contentTime = 0
      let totalSecond = 0
      let totalSet = 0
      
      if(currentLayout && (currentLayout.optionAdd == 1 || currentLayout.optionOver == 1)) {
        contentCount = 1
      }

      const _layout = getLayoutContentInfo()
      let zoneLength = 0

      for (let i = 0; i < _layout.length; i++) {
        zoneLength = Math.max(zoneLength, parseInt(_layout[i].id))
      }

      if(currentLayout && currentLayout.optionTeamRotate == 1) {
        zoneLength = zoneLength * currentLayout.optionTeamCount
      }

      let prevSet = contentCount * zoneLength
      if(currentLayout && (currentLayout.optionType == 'team') && ((currentLayout.optionMission == 1) || (currentLayout.optionWod == 1))) {
        //prevSet = currentLayout.optionTeamCount
        //--> 기획 변경
        prevSet = 1
        if(currentLayout && currentLayout.optionTeamRotate == 1) {
          prevSet = currentLayout.optionTeamCount
        }
      }

      //==> 물먹는 시간
      let waterTime = 0
      const _getRestTime = (_set:any)=>{
        if(_set > 0) {
          if(watch("timeType") != '1') {
            if(exerciseTimeList && moveTimeList && exerciseTimeList.length == moveTimeList.length) {
              const _timeindex = ((_set - 1) % moveTimeList.length)
              return parseInt(moveTimeList[_timeindex])
            }
            return 0
          }
          else
          {
            let result = watch("moveTime") as number;
            console.log('result1111 = ', result)
            const __set = (_set - 1)
            if(areaRepeatTimeList && areaRepeatTimeList.length > 0) {
              let _timeindex = Math.floor(__set / contentCount)
              if(((__set % contentCount) < 0) && (__set > contentCount)) {
                _timeindex += 1
              }
              _timeindex = (_timeindex % areaRepeatTimeList.length)
              result = parseInt(areaRepeatTimeList[_timeindex].moveTime)
            }
            if(teamRepeatTimeList && teamRepeatTimeList.length > 0) {
              
              let _timeindex = Math.floor(__set / (contentCount * _areaRepeatCount))
              if(((__set % (contentCount * _areaRepeatCount)) < 0) && (__set > (contentCount * _areaRepeatCount))) {
                _timeindex += 1
              }
              _timeindex = (_timeindex % teamRepeatTimeList.length)
              result = parseInt(teamRepeatTimeList[_timeindex].moveTime)
            }
            if(totalRepeatTimeList && totalRepeatTimeList.length > 0) {
              let _timeindex = Math.floor(__set / (contentCount * _areaRepeatCount * _teamRepeatCount))
              if(((__set % (contentCount * _areaRepeatCount * _teamRepeatCount)) < 0) && (__set > (contentCount * _areaRepeatCount * _teamRepeatCount))) {
                _timeindex += 1
              }
              _timeindex = (_timeindex % totalRepeatTimeList.length)
              result = parseInt(totalRepeatTimeList[_timeindex].moveTime)
            }
            console.log('result = ', result)
            return result;
          }
        }
        return 0
      }
      
      if(waterTimeList && waterTimeList.length > 0) {
        //--> 물먹는 시간 세트의 쉬는 시간 값을 알아야 함;;;
        for (let i = 0; i < waterTimeList.length; i++) {
          waterTime += Number(waterTimeList[i].time)
          waterTime -= _getRestTime(Number(waterTimeList[i].set))
        }
      }
      console.log('waterTime = ', waterTime)
      ///

      //--> 영역 세트 시간 구하기
      if(watch("timeType") != '1') {
        console.log('eee')
        contentTime = 0
        let lastMoveTime = 0
        if(exerciseTimeList && moveTimeList && exerciseTimeList.length == moveTimeList.length) {
          for (let i = 0; i < exerciseTimeList.length; i++) {
            contentTime += parseInt(exerciseTimeList[i]?exerciseTimeList[i]:'0') + parseInt(moveTimeList[i]?moveTimeList[i]:'0')
            lastMoveTime = parseInt(moveTimeList[i]?moveTimeList[i]:'0')
          }
        }
        totalSecond = (contentTime * zoneLength * _areaRepeatCount * _teamRepeatCount * _totalRepeatCount) - lastMoveTime
      }
      else if(areaRepeatTimeList && areaRepeatTimeList.length > 0) {
        console.log('ddd : ', zoneLength * contentCount)
        contentTime = 0;
        totalSecond = 0;
        let lastMoveTime = 0
        for (let i = 0; i < areaRepeatTimeList.length; i++) {
          totalSecond += (Number(areaRepeatTimeList[i].exerciseTime) + Number(areaRepeatTimeList[i].moveTime)) * prevSet;
          lastMoveTime = Number(areaRepeatTimeList[i].moveTime)
        }
        // for (let i = 0; i < areaRepeatTimeList.length; i++) {
        //   contentTime += Number(areaRepeatTimeList[i].exerciseTime) + Number(areaRepeatTimeList[i].moveTime);
        // }
        totalSecond = (totalSecond * _teamRepeatCount * _totalRepeatCount) + lastMoveTime
        //totalSecond = contentTime * zoneLength * contentCount * _areaRepeatCount * _teamRepeatCount * _totalRepeatCount
      }
      else if(teamRepeatTimeList && teamRepeatTimeList.length > 0) {
        console.log('ccc : ', prevSet)
        contentTime = 0;
        totalSecond = 0;
        let lastMoveTime = 0
        for (let i = 0; i < teamRepeatTimeList.length; i++) {
          totalSecond += (Number(teamRepeatTimeList[i].exerciseTime) + Number(teamRepeatTimeList[i].moveTime)) * prevSet;
          lastMoveTime = Number(teamRepeatTimeList[i].moveTime)
        }
        totalSecond = (totalSecond * _areaRepeatCount * _totalRepeatCount) - lastMoveTime
        // for (let i = 0; i < teamRepeatTimeList.length; i++) {
        //   contentTime += Number(teamRepeatTimeList[i].exerciseTime) + Number(teamRepeatTimeList[i].moveTime);
        // }
        // totalSecond = contentTime * zoneLength * contentCount * _areaRepeatCount * _teamRepeatCount * _totalRepeatCount
      }
      else if(totalRepeatTimeList && totalRepeatTimeList.length > 0) {
        console.log('bbb')
        contentTime = 0;
        totalSecond = 0;
        let lastMoveTime = 0
        for (let i = 0; i < totalRepeatTimeList.length; i++) {
          totalSecond += (Number(totalRepeatTimeList[i].exerciseTime) + Number(totalRepeatTimeList[i].moveTime)) * prevSet;
          lastMoveTime = Number(totalRepeatTimeList[i].moveTime)
        }
        totalSecond = ((totalSecond * _areaRepeatCount * _teamRepeatCount)) - lastMoveTime
        // for (let i = 0; i < totalRepeatTimeList.length; i++) {
        //   contentTime += Number(totalRepeatTimeList[i].exerciseTime) + Number(totalRepeatTimeList[i].moveTime);
        // }
        // totalSecond = contentTime * zoneLength * contentCount * _areaRepeatCount * _teamRepeatCount * _totalRepeatCount
      }
      else {
        const _exerciseTime = watch("exerciseTime") as number
        const _moveTime = watch("moveTime") as number
        totalSecond = ((Number(_exerciseTime) + Number(_moveTime)) * prevSet * _areaRepeatCount * _teamRepeatCount * _totalRepeatCount) - Number(_moveTime)
      }

      totalSecond += waterTime + addProgramTime
      totalSet = prevSet * _areaRepeatCount * _teamRepeatCount * _totalRepeatCount
      setTotalSeconds(totalSecond);
      const formattedTime = formatTime(totalSecond);
      setTotalExerciseTime(formattedTime)
      setTotalSet(totalSet)
    }
    else {

      const contentCount = (zoneData && zoneData.length > 0)?zoneData[0].length:0
      const _exerciseTime = watch("exerciseTime") as number;
      const _moveTime = watch("moveTime") as number;
      const totalSecond = ((Number(_exerciseTime) + Number(_moveTime)) * contentCount) - _moveTime
      setTotalSeconds(totalSecond);
      const formattedTime = formatTime(totalSecond);
      setTotalExerciseTime(formattedTime)
      setTotalSet(contentCount)
      setValue("totalMotions", contentCount)
      setValue("brandId", 0)
      setValue("curriculumId", 0)
      setValue("layoutId", 0)
      if(!zoneData || zoneData.length == 0) {
        setZoneData([[]])
      }
    }
  }, [
    watch("areaRepeatCount"),
    watch("teamRepeatCount"),
    watch("totalRepeatCount"),
    areaRepeatTimeList,
    teamRepeatTimeList,
    totalRepeatTimeList,
    exerciseTimeList,
    moveTimeList,
    watch("exerciseTime"),
    watch("moveTime"),
    zoneData,
    watch("programType"),
    currentLayout,
    waterTimeList,
    addProgramList
  ]);

  //모달 열기
  const showModal = (zoneId: string) => {
    //@ts-ignore
    console.log('window[zoneId] : ', window[zoneId], zoneId)

    //@ts-ignore
    window[zoneId]?.showModal();
  };
  
  const openProgramLoadModal = () => {
    //setRandomCategoryMutate(true);
    
    if(!watch("brandId")  ||  watch("brandId") == 0) {
      // alert('브랜드를 먼저 선택해 주세요.')
      // return;
    }
    //@ts-ignore
    window.programLoad.showModal();
  };

  const onDelete = (id: string, zoneId: string, zoneIndex:number, zoneItemIndex:number) => {
    let _zoneData = [...zoneData]
    console.log('onDelete : ', id, zoneId)
    _zoneData[zoneIndex].splice(zoneItemIndex, 1);
    setZoneData(_zoneData)
    // for (let j = 0; j < _zoneData.length; j++) {
    //   for (let i = 0; i < _zoneData[j].length; i++) {
    //     if(_zoneData[j][i].id == id) {
    //       _zoneData[j].splice(i, 1);
    //       setZoneData(_zoneData)
    //       return;
    //     }
    //   }
    // }
  };

  const extractTools = (arr: ContentsDtoWithId[]) => {
    const toolNames = Array.from(
      new Set(
        arr
          .map((item: ContentsDtoWithId) =>
            item.tools.map((tool: Tool) => tool.name)
          )
          .flat()
      )
    );

    return toolNames;
  };

  // //도구 추출
  // useEffect(() => {
  //   const toolsName: string[] = extractTools(zone1Data);
  //   setZone1Tools(toolsName);
  // }, [zone1Data]);

  // //도구 추출
  // useEffect(() => {
  //   const toolsName = extractTools(zone2Data);
  //   setZone2Tools(toolsName);
  // }, [zone2Data]);

  // //도구 추출
  // useEffect(() => {
  //   const toolsName = extractTools(zone3Data);
  //   setZone3Tools(toolsName);
  // }, [zone3Data]);

  // const findZoneData = (id: string) => {
  //   const zoneItem = zone.find((item) => item.id === id);
  //   return zoneItem ? zoneItem.zoneData : null;
  // };

  const onDuplicate = (item: ContentsDtoWithId, zoneId: string) => {
    const _zoneData = [...zoneData];

    for (let i = 0; i < _zoneData.length; i++) {
      const zoneIndex = _zoneData[i].findIndex((i)=> i.id === zoneId);
      if(zoneIndex != -1) {
        const newItem = { ...item, id: uuidv4() }; // 새로운 id로 item 복사
        _zoneData[i].push(newItem);
        setZoneData(_zoneData)
        return;
      }
    }
  };

  //Drop시 실행되는 함수
  const moveContent = (
    draggedId: string,
    index: number,
    originalZoneId: string,
    newZoneId: string
  ) => {

    let draggedListIndex = -1;
    let draggedItemIndex = -1;
    let targetListIndex = -1;
    let targetItemIndex = -1;
    for (let i = 0; i < zoneData.length; i++) {
      draggedItemIndex = zoneData[i].findIndex(
        (item: ContentsDtoWithId) => item.id === draggedId
      );
      if(draggedItemIndex != -1) {
        draggedListIndex = i;
        break;
      }
    }
    for (let i = 0; i < zoneData.length; i++) {
      targetItemIndex = zoneData[i].findIndex(
        (item: ContentsDtoWithId) => item.id === newZoneId
      );
      if(targetItemIndex != -1) {
        targetListIndex = i;
        break;
      }
    }

    const _zoneData = [...zoneData]

    const draggedItem = zoneData[draggedListIndex][draggedItemIndex];
    _zoneData[draggedListIndex].splice(draggedItemIndex, 1);
    
    _zoneData[targetListIndex].splice(targetItemIndex, 0, draggedItem);

    setZoneData(_zoneData);

  };

  //수정의 경우, 기존 데이터 불러오기
  useEffect(() => {
    if (programId) {
      getOriginalData();
      console.log('수정의 경우, 기존 데이터 불러오기 : ', programId)
    }
    else if (programId == undefined) {
      InitListData();
      setValue("areaRepeatCount", 1);
      setValue("teamRepeatCount", 1);
      setValue("totalRepeatCount", 1);
      console.log('새로 생성하기 : ', programId)
    }
  }, [programId]);
  

  const InitListData = async ()=>{
    setGetLoading(true);
    try {
      //--> 브랜드 리스트 셋팅
      const {data:_brandlist} = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`, {
        withCredentials: true,
      });

      let brandAuth = []
      if(user) {
        brandAuth = JSON.parse(user.brandAuth);
      }

      let brandlist = []
      for (let i = 0; i < _brandlist.length; i++) {
        if(_brandlist[i].type == 2)
        {
          if(!getUseEditFromAuth('2', _brandlist[i].id, brandAuth)) {
            continue
          }
          brandlist.push(_brandlist[i])
        }
      }
      setBrandList(brandlist);
      if(!programId && brandlist.length > 0) {
        setValue('brandId', brandlist[0].id);
      }

      const {data:_programcategoryList} = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/programtype/all`, { withCredentials: true });
      setProgramCategoryList(_programcategoryList)

      const {data:_curriculumList} = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/admin/curriculum/all`, { withCredentials: true });
      setCurriculumList(_curriculumList)

      const {data:_layoutList} = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/admin/layout/all`, { withCredentials: true });
      setLayoutList(_layoutList)
    } catch (error) {
      console.log(error);
      setGetLoading(false);
    }
    setGetLoading(false);
  }

  //--> 브랜드 선택 시 프로그램 카데고리 셋팅
  useEffect(()=>{
    const _brandId = watch("brandId")
    const _programCategory = watch("programCategory")
    console.log('_brandId _programCategory : ', _brandId, _programCategory)
    if(_brandId && _brandId != 0 && programCategoryList && programCategoryList.length > 0) {
      let programcategory = []
      for (let i = 0; i < programCategoryList.length; i++) {
        if((programCategoryList[i].brandId == _brandId) && (programCategoryList[i].active == 1)) {
          programcategory.push(programCategoryList[i]);
        }
      }
      setProgramCategory(programcategory)
    }
    setTimeout(()=>{
      setValue('programCategory', watch('programCategory'))
    }, 100)
    
  }, [watch("brandId"), programCategoryList])

  //--> 프로그램 카데고리 선택 시 커리큘럼 셋팅
  useEffect(()=>{
    const _brandId = watch("brandId")
    const _programCategory = watch("programCategory")
    if(_brandId && _brandId != 0 && curriculumList && curriculumList.length > 0) {
      let _curriculum = []
      for (let i = 0; i < curriculumList.length; i++) {
        if(curriculumList[i].brandId == _brandId && curriculumList[i].programTypeName == _programCategory) {
          _curriculum.push(curriculumList[i]);
        }
      }
      console.log('_curriculum : ', _curriculum, watch('curriculumId'))
      setCurriculum(_curriculum)
    }
    setTimeout(()=>{
      setValue('curriculumId', watch('curriculumId'))
    }, 100)
    
  }, [programCategory, watch('programCategory')])
  ///

  //--> 커리큘럼 선택 시 레이아웃 셋팅
  useEffect(()=>{
    const _curriculumId = watch("curriculumId")
    if(_curriculumId && _curriculumId != 0 && curriculum && curriculum.length && layoutList && layoutList.length > 0) {
      
      const _curriculum = curriculum?.filter((_curriculum:CurriculumDto)=>{
        if(_curriculum.id == _curriculumId)
        {
          return true
        }
    
        return false;
      })
      if(_curriculum && _curriculum.length > 0) {
        let layoutIds = _curriculum[0].layoutIds.split(',')

        let _layout = []
        for (let i = 0; i < layoutIds.length; i++) {
          for (let j = 0; j < layoutList.length; j++) {
            if(layoutList[j].id == parseInt(layoutIds[i])) {
              _layout.push(layoutList[j]);
            }
          }
        }
        setLayout(_layout)
        
      }
    }
    setTimeout(()=>{
      setValue('layoutId', watch('layoutId'))
    }, 100)
    
  }, [watch("curriculumId"), curriculum])
  
  useEffect(()=>{
    const _layoutId = watch("layoutId")
    if(_layoutId && _layoutId != 0 && layout && layout.length) {
      let _currentLayout = null
      const __layouta = layout?.filter((__layout:LayoutDto)=>{
        if(__layout.id == _layoutId)
        {
          return true
        }
    
        return false;
      })
      if(__layouta && __layouta.length > 0) {
        
        _currentLayout = __layouta[0];
      }

      if(_currentLayout) {
        setCurrentLayout(_currentLayout)
      }
    }
    
  }, [watch("layoutId"), layout])

  //--> 기존 데이터 셋팅
  const getOriginalData = async () => {
    setGetLoading(true);
    try {
      await InitListData();

      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/dynamicprogram/${programId}`, { withCredentials: true });

      const responseData: DynamicProgramDto = response.data;

      const programData = responseData.program;
      const content = responseData.content;

      setValue("programType", programData.programType);
      setValue("brandId", programData.brandId);
      setValue("curriculumId", programData.curriculumId);
      setValue("layoutId", programData.layoutId);
      setValue("programCategory", programData.programCategory);
      setValue("name", programData.name);
      setValue("timeType", programData.timeType?programData.timeType.toString():'');
      setValue("totalMotions", programData.totalMotions);
      setValue("exerciseTime", programData.exerciseTime);
      setValue("moveTime", programData.moveTime);
      setValue("comment", programData.comment || "");

      setValue("moveTimes", programData.moveTimes || '');
      setValue("exerciseTimes", programData.exerciseTimes || '');
      setMoveTimeList(programData.moveTimes.split(','));
      setExerciseTimeList(programData.exerciseTimes.split(','));
      setValue("areaRepeatCount", programData.areaRepeatCount);
      setValue("teamRepeatCount", programData.teamRepeatCount);
      setValue("totalRepeatCount", programData.totalRepeatCount);
      setValue("areaRepeatTimes", programData.areaRepeatTimes || '');
      setValue("teamRepeatTimes", programData.teamRepeatTimes || '');
      setValue("totalRepeatTimes", programData.totalRepeatTimes || '');
      setValue("waterTimes", programData.waterTimes || '');
      setValue("addPrograms", programData.addPrograms || '');
      
      setValue("bpm", programData.bpm);
      setValue("bpmInfo", programData.bpmInfo || '');

      let _bpmInfo = programData.bpmInfo?programData.bpmInfo:''
      setBpmInfoCount(_bpmInfo.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length)

      setTotalSet(programData.totalSet)

      if(programData.areaRepeatTimes && programData.areaRepeatTimes != 'undefined') {
        const _areaRepeatTimesList = JSON.parse(programData.areaRepeatTimes)
        setAreaRepeatTimeList(_areaRepeatTimesList?_areaRepeatTimesList:[]);
      }
      if(programData.teamRepeatTimes && programData.teamRepeatTimes != 'undefined') {
        const _teamRepeatTimesList = JSON.parse(programData.teamRepeatTimes)
        setTeamRepeatTimeList(_teamRepeatTimesList?_teamRepeatTimesList:[]);
      }
      if(programData.totalRepeatTimes && programData.totalRepeatTimes != 'undefined') {
        const _totalRepeatTimesList = JSON.parse(programData.totalRepeatTimes)
        setTotalRepeatTimeList(_totalRepeatTimesList?_totalRepeatTimesList:[]);
      }
      if(programData.waterTimes && programData.waterTimes != 'undefined') {
        const _waterTimesList = JSON.parse(programData.waterTimes)
        setWaterTimeList(_waterTimesList?_waterTimesList:[]);
      }
      if(programData.addPrograms && programData.addPrograms != 'undefined') {
        const _addProgramsList = JSON.parse(programData.addPrograms)
        setAddProgramList(_addProgramsList?_addProgramsList:[]);
      }

      if(programData.programType == '본운동') {
        let _zoneData:ContentsDtoWithId[][] = []

        const _content = JSON.parse(programData.content)
        for (let i = 0; i < _content.data.length; i++) {
          _zoneData.push([])
          for (let j = 0; j < _content.data[i].length; j++) {
            for (let k = 0; k < content.length; k++) {
              if(_content.data[i][j] == content[k].content.id) {
                _zoneData[i].push({...content[k], heartbeat:_content.heartbeats[i][j]});
                break;
              }
            }
          }

          if(_content.mission && (_content.mission.length > 0)) {
            setMissionList(_content.mission)
          }

          if(_content.wod && (_content.wod.length > 0)) {
            setWodList(_content.wod)
          }

          if(_content.wodtitle && (_content.wodtitle.length > 0)) {
            setWodTitleList(_content.wodtitle)
          }
        }

        setZoneData(_zoneData);
      }
      else {
        setZoneData([content]);
      }
      
    } catch (error) {
      console.log(error);
    }
    setGetLoading(false);
  };

  const onAddContents = (_index:number, __zoneData:ContentsDtoWithId[])=>{
    const _zoneData = [...zoneData]
    if(!_zoneData || _zoneData.length > _index) {
      _zoneData[_index] = (__zoneData)
      if(currentLayout && (currentLayout.optionAdd == 1 || currentLayout.optionOver == 1)) {
        if(_zoneData[_index].length > 2) {
          _zoneData[_index].splice(2, _zoneData[_index].length - 2)
        }
      }
      else if(currentLayout && (currentLayout.optionMission == 1 || currentLayout.optionWod == 1)) {
        if(_zoneData[_index].length > 1) {
          _zoneData[_index].splice(1, _zoneData[_index].length - 1)
        }
      }
      setZoneData(_zoneData)
    }

  }

  //--> 프로그램 불러오기
  const onSelectProgram = async (item:any)=> {
    
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/dynamicprogram/${item.id}`, { withCredentials: true });

      const responseData: DynamicProgramDto = response.data;

      const programData = responseData.program;
      const content = responseData.content;
      
      await InitListData();

      setValue("programType", programData.programType);
      setValue("brandId", programData.brandId);
      setValue("curriculumId", programData.curriculumId);
      setValue("layoutId", programData.layoutId);
      setValue("programCategory", programData.programCategory);
      setValue("name", '');
      setValue("timeType", programData.timeType?programData.timeType.toString():'');
      setValue("totalMotions", programData.totalMotions);
      setValue("exerciseTime", programData.exerciseTime);
      setValue("moveTime", programData.moveTime);
      setValue("comment", programData.comment || "");

      setValue("moveTimes", programData.moveTimes || '');
      setValue("exerciseTimes", programData.exerciseTimes || '');
      setMoveTimeList(programData.moveTimes.split(','));
      setExerciseTimeList(programData.exerciseTimes.split(','));
      setValue("areaRepeatCount", programData.areaRepeatCount);
      setValue("teamRepeatCount", programData.teamRepeatCount);
      setValue("totalRepeatCount", programData.totalRepeatCount);
      setValue("areaRepeatTimes", programData.areaRepeatTimes || '');
      setValue("teamRepeatTimes", programData.teamRepeatTimes || '');
      setValue("totalRepeatTimes", programData.totalRepeatTimes || '');
      setValue("waterTimes", programData.waterTimes || '');
      setValue("addPrograms", programData.addPrograms || '');

      if(programData.areaRepeatTimes && programData.areaRepeatTimes != 'undefined') {
        const _areaRepeatTimesList = JSON.parse(programData.areaRepeatTimes)
        setAreaRepeatTimeList(_areaRepeatTimesList?_areaRepeatTimesList:[]);
      }
      if(programData.teamRepeatTimes && programData.teamRepeatTimes != 'undefined') {
        const _teamRepeatTimesList = JSON.parse(programData.teamRepeatTimes)
        setTeamRepeatTimeList(_teamRepeatTimesList?_teamRepeatTimesList:[]);
      }
      if(programData.totalRepeatTimes && programData.totalRepeatTimes != 'undefined') {
        const _totalRepeatTimesList = JSON.parse(programData.totalRepeatTimes)
        setTotalRepeatTimeList(_totalRepeatTimesList?_totalRepeatTimesList:[]);
      }
      if(programData.waterTimes && programData.waterTimes != 'undefined') {
        const _waterTimesList = JSON.parse(programData.waterTimes)
        setWaterTimeList(_waterTimesList?_waterTimesList:[]);
      }
      if(programData.addPrograms && programData.addPrograms != 'undefined') {
        const _addProgramsList = JSON.parse(programData.addPrograms)
        setAddProgramList(_addProgramsList?_addProgramsList:[]);
      }

      if(programData.programType == '본운동') {
        let _zoneData:ContentsDtoWithId[][] = []

        const _content = JSON.parse(programData.content)
        for (let i = 0; i < _content.data.length; i++) {
          _zoneData.push([])
          for (let j = 0; j < _content.data[i].length; j++) {
            for (let k = 0; k < content.length; k++) {
              if(_content.data[i][j] == content[k].content.id) {
                _zoneData[i].push({...content[k], heartbeat:_content.heartbeats[i][j]});
                break;
              }
            }
          }

          if(_content.mission && (_content.mission.length > 0)) {
            setMissionList(_content.mission)
          }

          if(_content.wod && (_content.wod.length > 0)) {
            setWodList(_content.wod)
          }

          if(_content.wodtitle && (_content.wodtitle.length > 0)) {
            setWodTitleList(_content.wodtitle)
          }
        }

        setZoneData(_zoneData);
      }
      else {
        setZoneData([content]);
      }
      
    } catch (error) {
      console.log(error);
    }
    setGetLoading(false);
  }

  
  const onAddProgram = async (item:any)=> {
    
    try {
      if(addProgramList) {

        let _addProgramList = [...addProgramList];
        _addProgramList[addProgramIndex].programName = item.name;
        _addProgramList[addProgramIndex].programId = item.id;
        _addProgramList[addProgramIndex].programTime = item.totalExerciseTime;

        setAddProgramList(_addProgramList)
        setValue("addPrograms", JSON.stringify(_addProgramList))
      }
    } catch (error) {
      console.log(error);
    }
    setGetLoading(false);
  }
  
  let _brandName = "";
  if(readOnly) {
    const _brand = brandList?.filter((brand:BrandDto)=>{
      if(brand.id == watch("brandId"))
      {
        return true
      }

      return false;
    })
    if(_brand && _brand.length > 0) {
      
      _brandName = _brand[0].name;
    }
  }
  let _curriculumName = ""
  if(readOnly) {
    const _curriculum = curriculum?.filter((_curriculum:CurriculumDto)=>{
      if(_curriculum.id == watch("curriculumId"))
      {
        return true
      }
  
      return false;
    })
    if(_curriculum && _curriculum.length > 0) {
      
      _curriculumName = _curriculum[0].name;
    }
  }

  useEffect(()=>{
    
    if(currentLayout) {
      //--> 레이아웃 바뀔때 운동 데이터 처리
      if(!zoneData) {
        
        let _zonedata = []
        for (let i = 0; i < currentLayout.totalCount; i++) {
  
          _zonedata.push([])
        }
        setZoneData(_zonedata);
      }
      else if(zoneData.length != currentLayout.totalCount) {
        if(zoneData.length > currentLayout.totalCount) {
          let _zonedata = [...zoneData]
          _zonedata.splice(currentLayout.totalCount, zoneData.length - currentLayout.totalCount)
          setZoneData(_zonedata);
        }
        else {
          let _zonedata = [...zoneData]
          for (let i = zoneData.length; i < currentLayout.totalCount; i++) {
    
            _zonedata.push([])
          }
          setZoneData(_zonedata);
        }
      }
      
      //--> 레이아웃 바뀔때 미션 데이터 처리
      if(currentLayout?.optionMission == 1) {
        if(!missionList || missionList.length == 0) {
          console.log('currentLayout !missionList : ')
          let _missionList:any[] = [[]]
          if(currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1) {
            _missionList = [[],[]]
          }
          for (let i = 0; i < currentLayout.totalCount; i++) {
            _missionList[0].push({value:0, min:0, max:0})
            if(currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1) {
              _missionList[1].push({value:0, min:0, max:0})
            }
          }
          console.log('mi1 : ', _missionList, currentLayout)
          setMissionList(_missionList);
        }
        else if(missionList.length > 0 && missionList[0].length != currentLayout.totalCount) {
          if(missionList[0].length > currentLayout.totalCount) {
            let _missionList = [...missionList]
            if((currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1) && _missionList.length == 1) {
              _missionList.push(_missionList[0])
            }
            _missionList[0].splice(currentLayout.totalCount, missionList[0].length - currentLayout.totalCount)
            if(_missionList[1]) {
              _missionList[1].splice(currentLayout.totalCount, missionList[1].length - currentLayout.totalCount)
            }
            console.log('mi2 : ', _missionList)
            setMissionList(_missionList);
          }
          else {
            let _missionList = [...missionList]
            if((currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1) && _missionList.length == 1) {
              _missionList.push(_missionList[0])
            }
            for (let i = missionList[0].length; i < currentLayout.totalCount; i++) {
              _missionList[0].push({value:0, min:0, max:0})
              if(currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1) {
                _missionList[1].push({value:0, min:0, max:0})
              }
            }
            console.log('mi3 : ', _missionList)
            setMissionList(_missionList);
          }
        }
      }
      else {
        setMissionList([[],[]]);
      }

      //--> 레이아웃 바뀔때 와드 데이터 처리
      if(currentLayout?.optionWod == 1) {

        let titleCount = currentLayout.totalCount
        if(currentLayout.optionType == 'team') {
          titleCount = currentLayout.optionTeamCount;
        }
        titleCount = Math.min(titleCount, 6)
        
        if(!wodTitleList) {
          
          let _wodTitleList:string[] = []

          for (let i = 0; i < titleCount; i++) {
            _wodTitleList.push('')
          }

          if(_wodTitleList.length > 6) {
            _wodTitleList = _wodTitleList.splice(6, _wodTitleList.length - 6)
          }

          setWodTitleList(_wodTitleList);
        }
        else if(wodTitleList.length != titleCount) {
          if(wodTitleList.length > titleCount) {
            let _wodTitleList = [...wodTitleList]
            _wodTitleList.splice(titleCount, wodTitleList.length - titleCount)
            
            if(_wodTitleList.length > 6) {
              _wodTitleList = _wodTitleList.splice(6, _wodTitleList.length - 6)
            }
            setWodTitleList(_wodTitleList);
          }
          else {
            let _wodTitleList = [...wodTitleList]
            for (let i = wodTitleList.length; i < titleCount; i++) {
      
              _wodTitleList.push('')
            }
            
            if(_wodTitleList.length > 6) {
              _wodTitleList = _wodTitleList.splice(6, _wodTitleList.length - 6)
            }
            setWodTitleList(_wodTitleList);
          }
        }
        else if(wodTitleList.length > 6) {
          let _wodTitleList = [...wodTitleList]
          
          _wodTitleList = _wodTitleList.splice(6, _wodTitleList.length - 6)
          setWodTitleList(_wodTitleList);
        }
        

        if(!wodList || wodList.length == 0) {
          
          let _wodList:any[] = [[]]
          if(currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1) {
            _wodList = [[],[]]
          }
          for (let i = 0; i < currentLayout.totalCount; i++) {
            _wodList[0].push({value:0, text:''})
            if(currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1) {
              _wodList[1].push({value:0, text:''})
            }
          }
          setWodList(_wodList);
        }
        else if(wodList.length > 0 && wodList[0].length != currentLayout.totalCount) {
          if(wodList[0].length > currentLayout.totalCount) {
            let _wodList = [...wodList]
            if((currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1) && _wodList.length == 1) {
              _wodList.push(_wodList[0])
            }
            _wodList[0].splice(currentLayout.totalCount, wodList[0].length - currentLayout.totalCount)
            _wodList[1].splice(currentLayout.totalCount, wodList[0].length - currentLayout.totalCount)
            setWodList(_wodList);
          }
          else {
            let _wodList = [...wodList]
            if((currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1) && _wodList.length == 1) {
              _wodList.push(_wodList[0])
            }
            for (let i = wodList[0].length; i < currentLayout.totalCount; i++) {
              _wodList[0].push({value:0, text:''})
              if(currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1) {
                _wodList[1].push({value:0, text:''})
              }
            }
            setWodList(_wodList);
          }
        }
      }
      else {
        setWodList([[],[]]);
      }
      ///
    }
  }, [currentLayout])

  //--> 옵션 출력
  const getOption = (item:LayoutDto)=>{
    let result = item.optionType == 'team'?'팀로테이션':'전체로테이션'
    if(item.optionType == 'team'){
      result += `/${item.optionTeamCount}팀`

      if(item.optionTeamRotate == 1) {
        result += `/팀교대`
      }
    }
    if(item.optionMission == 1) {
      result += `/미션`
    }
    if(item.optionOver == 1) {
      result += `/OVER`
    }
    if(item.optionAdd == 1) {
      result += `/ADD`
    }
    if(item.optionWod == 1) {
      result += `/WOD`
    }

    return result;
  }
  
  //--> 쉬는 시간 상태
  const getRest = (item:LayoutDto)=>{
    let result = ''
    if(item.stayFlag == 1){
      result += `STAY IN 출력`
    }
    if(item.moveFlag == 1) {
      if(result != '') {
        result += ' / '
      }
      result += `MOVE ON 출력`
    }
    if(item.waterFlag == 1) {
      if(result != '') {
        result += ' / '
      }
      result += `WATER 출력`
    }
    if(item.burningFlag == 1) {
      if(result != '') {
        result += ' / '
      }
      result += `BURNING 출력`
    }

    return result;
  }

  //--> 쉬는 시간 변형 수정
  const onChangeMoveTimes = (text:string, index:number)=>{
    if(moveTimeList && moveTimeList.length > index) {
      let _moveTimeList = [...moveTimeList]
      _moveTimeList[index] = text

      setMoveTimeList(_moveTimeList)
      setValue('moveTimes', _moveTimeList.toString())
    }
  }

  //--> 운동 시간 변형 수정
  const onChangeExerciseTimes = (text:string, index:number)=>{
    if(exerciseTimeList && exerciseTimeList.length > index) {
      let _exerciseTimeList = [...exerciseTimeList]
      _exerciseTimeList[index] = text

      setExerciseTimeList(_exerciseTimeList)
      setValue("exerciseTimes", _exerciseTimeList.toString())
    }
  }

  //--> 영역 반복 횟수 시간 추가 수정
  const onChangeAreaRepeatTimeState = ()=>{
    const _areaRepeatCount = watch("areaRepeatCount");
    const _areaRepeatTimes = watch("areaRepeatTimes");

    if(!_areaRepeatTimes || _areaRepeatTimes == "") {
      setValue("teamRepeatTimes", "");
      setValue("totalRepeatTimes", "");

      if(_areaRepeatCount > 0) {
        let _areaRepeatTimeList = []
        for (let i = 0; i < _areaRepeatCount; i++) {
          _areaRepeatTimeList.push({exerciseTime:"", moveTime:""})
        }
        setAreaRepeatTimeList(_areaRepeatTimeList)
        setValue("areaRepeatTimes", JSON.stringify(_areaRepeatTimeList));
      }
    }
    else
    {
      setValue("areaRepeatTimes", "");
      setAreaRepeatTimeList([])
    }
  }

  //--> 영역 반복 횟수 시간 추가의 운동 수정
  const onChangeAreaRepeatExerciseTimes = (text:string, index:number)=>{
    if(!areaRepeatTimeList) {
      return
    }
    let _areaRepeatTimeList = [...areaRepeatTimeList]
    _areaRepeatTimeList[index].exerciseTime = text;
    setAreaRepeatTimeList(_areaRepeatTimeList)
    setValue("areaRepeatTimes", JSON.stringify(_areaRepeatTimeList))
  }

  //--> 영역 반복 횟수 시간 추가의 휴식 수정
  const onChangeAreaRepeatMoveTimes = (text:string, index:number)=>{
    if(!areaRepeatTimeList) {
      return
    }
    let _areaRepeatTimeList = [...areaRepeatTimeList]
    _areaRepeatTimeList[index].moveTime = text;
    setAreaRepeatTimeList(_areaRepeatTimeList)
    setValue("areaRepeatTimes", JSON.stringify(_areaRepeatTimeList))
  }

  //--> 팀 반복 횟수 시간 추가 수정
  const onChangeTeamRepeatTimeState = ()=>{
    const _teamRepeatCount = watch("teamRepeatCount");
    const _teamRepeatTimes = watch("teamRepeatTimes");

    if(!_teamRepeatTimes || _teamRepeatTimes == "") {
      setValue("areaRepeatTimes", "");
      setValue("totalRepeatTimes", "");

      if(_teamRepeatCount > 0) {
        let _teamRepeatTimeList = []
        for (let i = 0; i < _teamRepeatCount; i++) {
          _teamRepeatTimeList.push({exerciseTime:"", moveTime:""})
        }
        setTeamRepeatTimeList(_teamRepeatTimeList)
        setValue("teamRepeatTimes", JSON.stringify(_teamRepeatTimeList));
      }
    }
    else
    {
      setValue("teamRepeatTimes", "");
      setTeamRepeatTimeList([])
    }
  }

  //--> 팀 반복 횟수 시간 추가의 운동시간 수정
  const onChangeTeamRepeatExerciseTimes = (text:string, index:number)=>{
    if(!teamRepeatTimeList) {
      return
    }
    let _teamRepeatTimeList = [...teamRepeatTimeList]
    _teamRepeatTimeList[index].exerciseTime = text;
    setTeamRepeatTimeList(_teamRepeatTimeList)
    setValue("teamRepeatTimes", JSON.stringify(_teamRepeatTimeList))
  }

  //--> 팀 반복 횟수 시간 추가의 휴식시간 수정
  const onChangeTeamRepeatMoveTimes = (text:string, index:number)=>{
    if(!teamRepeatTimeList) {
      return
    }
    let _teamRepeatTimeList = [...teamRepeatTimeList]
    _teamRepeatTimeList[index].moveTime = text;
    setTeamRepeatTimeList(_teamRepeatTimeList)
    setValue("teamRepeatTimes", JSON.stringify(_teamRepeatTimeList))
  }
  
  //--> 전체 반복 횟수 시간 추가 수정
  const onChangeTotalRepeatTimeState = ()=>{
    const _totalRepeatCount = watch("totalRepeatCount");
    const _totalRepeatTimes = watch("totalRepeatTimes");

    if(!_totalRepeatTimes || _totalRepeatTimes == "") {
      setValue("teamRepeatTimes", "");
      setValue("areaRepeatTimes", "");

      if(_totalRepeatCount > 0) {
        let _totalRepeatTimeList = []
        for (let i = 0; i < _totalRepeatCount; i++) {
          _totalRepeatTimeList.push({exerciseTime:"", moveTime:""})
        }
        setTotalRepeatTimeList(_totalRepeatTimeList)
        setValue("totalRepeatTimes", JSON.stringify(_totalRepeatTimeList));
      }
    }
    else
    {
      setValue("totalRepeatTimes", "");
      setTotalRepeatTimeList([])
    }
  }

  //--> 전체 반복 횟수 시간 추가의 운동시간 수정
  const onChangeTotalRepeatExerciseTimes = (text:string, index:number)=>{
    if(!totalRepeatTimeList) {
      return
    }
    let _totalRepeatTimeList = [...totalRepeatTimeList]
    _totalRepeatTimeList[index].exerciseTime = text;
    setTotalRepeatTimeList(_totalRepeatTimeList)
    setValue("totalRepeatTimes", JSON.stringify(_totalRepeatTimeList))
  }

  //--> 전체 반복 횟수 시간 추가의 휴식시간 수정
  const onChangeTotalRepeatMoveTimes = (text:string, index:number)=>{
    if(!totalRepeatTimeList) {
      return
    }
    let _totalRepeatTimeList = [...totalRepeatTimeList]
    _totalRepeatTimeList[index].moveTime = text;
    setTotalRepeatTimeList(_totalRepeatTimeList)
    setValue("totalRepeatTimes", JSON.stringify(_totalRepeatTimeList))
  }


  //--> 물먹는 시간 추가
  const onAddWaterTime = ()=>{
    if(waterTimeList) {
      let _waterTimeList = [...waterTimeList]
      _waterTimeList.push({set:'', time:''})
      setWaterTimeList(_waterTimeList)
      setValue("waterTimes", JSON.stringify(_waterTimeList))
    }
    else
    {
      setWaterTimeList([{set:'', time:''}])
      setValue("waterTimes", JSON.stringify([{set:'', time:''}]))
    }
  }

  //--> 물먹는 시간 삭제
  const onDeleteWaterTime = ()=>{
    if(waterTimeList) {
      let _waterTimeList = [...waterTimeList]
      _waterTimeList.splice(_waterTimeList.length - 1, 1)
      setWaterTimeList(_waterTimeList)
      setValue("waterTimes", JSON.stringify(_waterTimeList))
    }
  }
  
  //--> 추가운동 추가
  const onAdd_AddProgram = ()=>{
    if(addProgramList) {
      let _addProgramList = [...addProgramList]
      _addProgramList.push({set:'', programId:0, programName:'', programTime:0})
      setAddProgramList(_addProgramList)
      setValue("addPrograms", JSON.stringify(_addProgramList))
    }
    else
    {
      setAddProgramList([{set:'', programId:0, programName:'', programTime:0}])
      setValue("addPrograms", JSON.stringify([{set:'', programId:0, programName:'', programTime:0}]))
    }
  }

  //--> 추가운동 삭제
  const onDelete_AddProgram = ()=>{
    if(addProgramList) {
      let _addProgramList = [...addProgramList]
      _addProgramList.splice(_addProgramList.length - 1, 1)
      setAddProgramList(_addProgramList)
      setValue("addPrograms", JSON.stringify(_addProgramList))
    }
  }

  //--> 추가운동 세트 수정
  const onChangeAddProgramSet = (text:string, index:number)=>{
    if(!addProgramList) {
      return
    }
    let _addProgramList = [...addProgramList]
    _addProgramList[index].set = text;
    setAddProgramList(_addProgramList)
    setValue("addPrograms", JSON.stringify(_addProgramList))
  }

  //--> 추가운동 프로그램 삭제
  const onChangeAddProgramValue = (index:number, isDelete:boolean)=>{
    if(!addProgramList) {
      return
    }
    let _addProgramList = [...addProgramList]
    if(isDelete) {

      _addProgramList[index].programName = '';
      _addProgramList[index].programId = 0;
      _addProgramList[index].programTime = 0;
      setAddProgramList(_addProgramList)
      setValue("addPrograms", JSON.stringify(_addProgramList))
      return;
    }
    
    setAddProgramIndex(index);

    //@ts-ignore
    window.addProgram.showModal()
    
  }

  //--> 믈막는 시간 세트 수정
  const onChangeWaterTimeSet = (text:string, index:number)=>{
    if(!waterTimeList) {
      return
    }
    let _waterTimeList = [...waterTimeList]
    _waterTimeList[index].set = text;
    setWaterTimeList(_waterTimeList)
    setValue("waterTimes", JSON.stringify(_waterTimeList))
  }

  //--> 믈막는 시간 시간값 수정
  const onChangeWaterTimeValue = (text:string, index:number)=>{
    if(!waterTimeList) {
      return
    }
    let _waterTimeList = [...waterTimeList]
    _waterTimeList[index].time = text;
    setWaterTimeList(_waterTimeList)
    setValue("waterTimes", JSON.stringify(_waterTimeList))
  }

  //--> 레이아웃 정보
  const getLayoutContentInfo = ()=>{
    if(!currentLayout) {
      return []
    }

    const _tv1Teams = currentLayout.tv1Count > 0?currentLayout.tv1Teams.split(','):[]
    const _tv2Teams = currentLayout.tv2Count > 0?currentLayout.tv2Teams.split(','):[]
    const _tv3Teams = currentLayout.tv3Count > 0?currentLayout.tv3Teams.split(','):[]
    const _tv1Ids = currentLayout.tv1Count > 0?currentLayout.tv1Ids.split(','):[]
    const _tv2Ids = currentLayout.tv2Count > 0?currentLayout.tv2Ids.split(','):[]
    const _tv3Ids = currentLayout.tv3Count > 0?currentLayout.tv3Ids.split(','):[]

    let temptempList = [..._tv1Teams, ..._tv2Teams, ..._tv3Teams]
    let idtempList = [..._tv1Ids, ..._tv2Ids, ..._tv3Ids]

    let result = []

    for (let i = 0; i < idtempList.length; i++) {
      if(idtempList[i]!= '-1') result.push({team:temptempList[i]?temptempList[i]:'', id:idtempList[i]})
    }

    result.sort((a:any, b:any)=>{
      if(a.team != b.team) {
        if(a.team == '') return 1
        if(b.team == '') return -1
        if(a.team > b.team) return 1
        if(a.team < b.team) return -1
        return 0
      }
      else {
        if(a.id == '-1' || a.id == '0' || a.id == '') {
          return 1
        }
        else if(b.id == '-1' || b.id == '0' || b.id == '') {
          return -1
        }
        else {
          if(parseInt(a.id) > parseInt(b.id)) return 1
          if(parseInt(a.id) < parseInt(b.id)) return -1
          return 0
        }
      }
    })

    return result
  }

  //--> 레이아웃 팀 정보
  const getDataTeam = (listIndex:number)=>{
    if(currentLayout && currentLayout.optionType == 'team') {
      const infolist = getLayoutContentInfo()
      return (
        <div className="">
          <label
            className={
              infolist[listIndex].team == 'A'?"btn  btn-xs bg-[#BFDBFE] cursor-none":
              infolist[listIndex].team == 'B'?"btn  btn-xs bg-[#FED7AA] cursor-none":
              infolist[listIndex].team == 'C'?"btn  btn-xs bg-[#61D78D] cursor-none":
              infolist[listIndex].team == 'D'?"btn  btn-xs bg-[#F87272] cursor-none":
              infolist[listIndex].team == 'E'?"btn  btn-xs bg-[#FACB02] cursor-none":
              infolist[listIndex].team == 'F'?"btn  btn-xs bg-[#6189D7] cursor-none":
              infolist[listIndex].team == 'G'?"btn  btn-xs bg-[#D27E4F] cursor-none":
              infolist[listIndex].team == 'H'?"btn  btn-xs bg-[#D761CB] cursor-none":
              infolist[listIndex].team == 'I'?"btn  btn-xs bg-[#A3D761] cursor-none":
              infolist[listIndex].team == 'J'?"btn  btn-xs bg-[#8061D7] cursor-none":
              "btn  btn-xs m-1 bg-[#D5D6D9]"}
          >
            {infolist[listIndex].team}팀
          </label>
        </div>
      )
    }

    return null
  }

  //--> 레이아웃 번호 정보
  const getDataNumber = (listIndex:number)=>{
    if(currentLayout) {
      const infolist = getLayoutContentInfo()
      if(infolist.length > listIndex) {
        return infolist[listIndex].id
      }
    }

    return '1'
  }

  return (
    <>
      {!readOnly &&
        <Modal id='programLoad' title={`프로그램 불러오기`} size="xlarge" overflow="hidden">
          <div>
            <ProgramsSelectModal
              selectedData={onSelectProgram}
              brandId={watch('brandId')}
            />
          </div>
        </Modal>
      }
      {
        !readOnly &&
        <Modal id={`ContentsSearchModal`} title={`동작 추가`} size="xlarge" overflow="hidden">
            <div>
              <ContentsSearchModal
                zoneData={modalZoneData}
                exerciseTime={watch("exerciseTime")}
                setZoneData={(zoneData)=>{
                  onAddContents(modalIndex, zoneData)
                }}
              />
            </div>
          </Modal>
      }
      {/* {
        !readOnly &&
        zoneData.map((z, index) => {
          return (<Modal id={`zoneData_itemList_${index}`} title={`동작 추가`} size="xlarge" overflow="hidden">
            <div>
              <ContentsSearchModal
                zoneData={z}
                exerciseTime={watch("exerciseTime")}
                setZoneData={(zoneData)=>{
                  onAddContents(index, zoneData)
                }}
              />
            </div>
          </Modal>)
        })
      } */}
      {
        !readOnly &&
        <Modal id={"addProgram"} title={`추가운동 프로그램`} size="xlarge" overflow="hidden">
          <div>
            <AddProgramsSearchModal
              onAddItem={onAddProgram}
            />
          </div>
        </Modal>
      }
      {/* {!readOnly &&
        zone.map((z, index) => (
          <Modal id={z.id} title={`동작 추가`} size="xlarge" overflow="hidden">
            <div>
              <ContentsSearchModal
                zoneData={z.zoneData}
                exerciseTime={watch("exerciseTime")}
                setZoneData={(zoneData)=>{
                  onAddContents(zoneData, z.setZoneData, index)
                  
                }}
              />
            </div>
          </Modal>
        ))} */}
      <div className="">
        <div className="bg-white drop-shadow-md p-10 ">
          {getLoading && <FullPageLoading />}
          {
            !readOnly &&
            <div className="flex justify-end">
              <button
                className=" btn-primary btn   text-white  "
                onClick={()=>{openProgramLoadModal()}}
              >
                <FontAwesomeIcon icon={faFolder} />
                프로그램 불러오기
              </button>
            </div>
          }
          <FormBox
            title="프로그램 종류"
            icon={<FontAwesomeIcon icon={faLocation} />}
          >
            {
              readOnly?
              <div>{watch("programType")}</div>
              :
              <div className="flex space-x-5">
                {ProgramType.map((program) => (
                  <div className="form-control">
                    <label className="label cursor-pointer">
                      <input
                        type={'radio'}
                        className="radio checked:bg-gymmate"
                        disabled={readOnly}
                        value={program.name}
                        checked={program.name == watch("programType")}

                        onChange={(e)=>{
                          if((watch("programType") == '본운동' || e.target.value == '본운동') && (e.target.value != watch("programType"))) {
                            setZoneData([])
                            setMissionList([])
                            setWodList([])
                          }
                          setValue("programType", e.target.value)
                        }}
                      />
                      <div className="ml-2 label-text flex items-center justify-center space-x-1">
                        <p>{program.name}</p>
                      </div>
                    </label>
                  </div>
                ))}
              </div>
            }
          </FormBox>
          {
            watch("programType") == '본운동'?
            <FormBox title="브랜드" icon={<FontAwesomeIcon icon={faSign} />}>
              {
                readOnly?
                <div>
                  {
                    brandList &&
                    brandList.map(
                      (brand: BrandDto) => (watch('brandId') == brand.id?brand.name:null)
                    )
                  }
                </div>
                :
                <select
                  {...register("brandId")}
                  className="select select-bordered w-full max-w-xs"
                >
                  {brandList &&
                    brandList.map(
                      (brand: BrandDto) => (
                          <option value={brand.id}>
                            {brand.name}
                          </option>
                        )
                    )}
                </select>
              }
            </FormBox>
            :null
          }
          {
            watch("programType") == '본운동'?
            <div className="flex w-full space-x-2 ">
              <div className="w-1/2">
                <FormBox title="운동타입" icon={<FontAwesomeIcon icon={faIcons} />}>
                  {
                    readOnly?
                    <div>{watch("programCategory")}</div>
                    :
                    <select
                      {...register("programCategory")}
                      className="select select-bordered w-full max-w-xs"
                      onChange={(e)=>{
                        setLayout([])
                        setCurrentLayout(undefined)
                        setValue("programCategory", e.target.value)
                      }}
                    >
                      {programCategory &&
                        programCategory.map(
                          (programType: ProgramCategoryDto) => {
                            return (
                              <option value={programType.name}>
                                {programType.name}
                              </option>
                            )
                          }
                        )}
                    </select>
                  }
                </FormBox>
              </div>
              <div className="w-1/2">
                <FormBox title="커리큘럼" icon={<FontAwesomeIcon icon={faBarsStaggered} />}>
                  {
                    readOnly?
                    <div>
                      {
                        curriculum &&
                        curriculum.map(
                          (__curriculum: CurriculumDto) => (watch('curriculumId') == __curriculum.id?__curriculum.name:null)
                        )
                      }
                    </div>
                    :
                    <select
                      {...register("curriculumId")}
                      className="select select-bordered w-full max-w-xs"
                      onChange={(e)=>{
                        setLayout([])
                        setCurrentLayout(undefined)
                        setValue("curriculumId", Number(e.target.value))
                      }}
                    >
                      {curriculum &&
                        curriculum.map(
                          (__curriculum: CurriculumDto) => {
                            return (
                              <option value={__curriculum.id}>
                                {__curriculum.name}
                              </option>
                            )
                          }
                        )}
                    </select>
                  }
                </FormBox>
              </div>
            </div>
            :null
          }
          {
            watch("programType") == '본운동'?
            <FormBox title="레이아웃" icon={<FontAwesomeIcon icon={faTableCells} />}>
              <div className="flex w-full space-x-2 ">
                <div className=" w-2/5">
                  {
                    readOnly?
                    <div>{currentLayout?.name}</div>
                    :
                    <select
                      {...register("layoutId")}
                      className="select select-bordered w-full max-w-xs"
                    >
                      {layout &&
                        layout.map(
                          (__layout: LayoutDto) => {
                            return (
                              <option value={__layout.id}>
                                {__layout.name}
                              </option>
                            )
                          }
                        )}
                    </select>
                  }
                  <div className=" space-y-4 my-7">
                    <div className=" flex space-x-6">
                      <div className=" w-16 font-bold text-base text-[#4B5563]">옵션</div>
                      <div className=" text-base text-[#EF4444]">{currentLayout?getOption(currentLayout):'-'}</div>
                    </div>
                    <div className=" flex space-x-6">
                      <div className=" w-16 font-bold text-base text-[#4B5563]">영역</div>
                      <div className=" text-base">
                        {currentLayout?`${currentLayout.totalCount}영역`:'-'}
                        {
                          //currentLayout && ((currentLayout.tv1Count <= 1) && (currentLayout.tv2Count <= 1) && (currentLayout.tv3Count <= 1))?
                          currentLayout && ((currentLayout.tv1Count <= 1) || (currentLayout.tv2Count <= 1 && currentLayout.tv2Count > 0) || (currentLayout.tv3Count <= 1 && currentLayout.tv3Count > 0))?
                          ((currentLayout && currentLayout.nextFlag == 1)?'  (다음 운동 2개 표시)':'  (다음 운동 1개 표시)')
                          :null
                        }
                      </div>
                    </div>
                    {
                      currentLayout?
                      <div className=" flex space-x-6">
                        <div className=" w-16 font-bold text-base text-[#4B5563]">휴식화면</div>
                        <div className=" text-base">{currentLayout?getRest(currentLayout):'-'}</div>
                      </div>
                      :null
                    }
                    <div className=" flex space-x-6">
                      <div className=" w-16 font-bold text-base text-[#4B5563]">설명</div>
                      <div className=" text-base">{currentLayout?currentLayout.description:'-'}</div>
                    </div>
                  </div>
                </div>
                <div className="w-3/5 pl-16">
                  {
                    currentLayout?
                    <div className="flex">
                      <LayoutDetailView
                        tvName={'1TV'}
                        optionType={currentLayout.optionType}
                        optionOver={currentLayout.optionOver}
                        optionAdd={currentLayout.optionAdd}
                        optionMission={currentLayout.optionMission}
                        optionWod={currentLayout.optionWod}
                        tvCount={currentLayout.tv1Count}
                        tvIds={currentLayout.tv1Ids}
                        tvTeams={currentLayout.tv1Teams}
                        isFirst={true}
                      />
                      <LayoutDetailView
                        tvName={'2TV'}
                        optionType={currentLayout.optionType}
                        optionOver={currentLayout.optionOver}
                        optionAdd={currentLayout.optionAdd}
                        optionMission={currentLayout.optionMission}
                        optionWod={currentLayout.optionWod}
                        tvCount={currentLayout.tv2Count}
                        tvIds={currentLayout.tv2Ids}
                        tvTeams={currentLayout.tv2Teams}
                        zeroText={'TV1과 동일'}
                      />
                      <LayoutDetailView
                        tvName={'3TV'}
                        optionType={currentLayout.optionType}
                        optionOver={currentLayout.optionOver}
                        optionAdd={currentLayout.optionAdd}
                        optionMission={currentLayout.optionMission}
                        optionWod={currentLayout.optionWod}
                        tvCount={currentLayout.tv3Count}
                        tvIds={currentLayout.tv3Ids}
                        tvTeams={currentLayout.tv3Teams}
                        zeroText={currentLayout.tv2Count == 0?'TV1과 동일':'TV2와 동일'}
                        tv3StateVidible={currentLayout.tv2Count != 0}
                        tv3State={currentLayout.tv3State}
                      />
                    </div>
                    :
                    <div className="flex">
                      <div className=" w-48 h-96 bg-[#D5D6D9] mr-1"/>
                      <div className=" w-48 h-96 bg-[#D5D6D9] mr-1"/>
                      <div className=" w-48 h-96 bg-[#D5D6D9] mr-1"/>
                    </div>
                  }
                </div>
              </div>
            </FormBox>
            :null
          }
          
          <FormBox title="동작" icon={<FontAwesomeIcon icon={faPerson} />}>
            <div className=" mb-4">
              {
                watch("programType") == "본운동"?
                <>
                {
                  currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1 ?
                  <div className="flex items-center">
                    <div className="mr-10 w-10"/>
                    <div
                      className={"w-40 border-b-2 ml-2 p-3 items-center text-center font-bold text-[#797F87] border-[#797F87]"}
                    >
                      동작
                    </div>
                    <div
                      className={"w-40 border-b-2 ml-2 p-3 items-center text-center font-bold text-[#797F87] border-[#797F87]"}
                    >
                      {currentLayout?.optionAdd == 1?'ADD':'OVER'}{'(필수아님)'}
                    </div>
                  </div>
                  :null
                }
                {
                  zoneData.map((itemList:ContentsDtoWithId[], listIndex: number)=>{
                    return (
                      <div key={`zoneData_itemList_${listIndex}`} className="flex items-center space-y-6">
                        <div className=" flex-col mr-10 w-10">
                          {
                            currentLayout?.optionType == 'team'?
                            <div>{getDataTeam(listIndex)}</div>
                            :null
                          }
                          <div
                            className={
                              (currentLayout?.optionMission != 0 || currentLayout?.optionWod != 0 || currentLayout?.optionAdd != 0 || currentLayout?.optionOver != 0)?
                              (itemList.length == 0?"text-red-400":"text-green-600")
                              :
                              (((itemList.length != zoneData[0].length) || (itemList.length == 0))?"text-red-400":"text-green-600")
                            }
                          >
                            {getDataNumber(listIndex)}({itemList.length})
                          </div>
                        </div>
                        <div className={((currentLayout?.optionMission == 0 && currentLayout?.optionWod == 0 && currentLayout?.optionAdd == 0 && currentLayout?.optionOver == 0))?" grid grid-flow-row grid-cols-7":" grid grid-flow-row grid-cols-2"}>
                          {
                            itemList.map((item: ContentsDtoWithId, index: number) => {
                              return (
                                <div key={`zoneData_${listIndex}_${index}`} className=" flex mb-2">
                                  <ContentsGapDrag
                                    item={item}
                                    index={index}
                                    zoneId={item.id || ''}
                                    moveContent={moveContent}
                                    originalZoneId={listIndex.toString()}
                                  />
                                  <ContentsBoxWrap
                                    item={item}
                                    zoneId={item.id || ''}
                                    onDelete={onDelete}
                                    onDuplicate={onDuplicate}
                                    readOnly={readOnly}
                                    setOriginalZoneId={(v:string)=>{}}
                                    tutorialCheckbox={true}
                                    tutorialId={tutorialId}
                                    setTutorialId={setTutorialId}
                                    heartbeat={true}
                                    zoneIndex={listIndex}
                                    zoneItemIndex={index}
                                    onEdit={(_item:ContentsDtoWithId)=>{ 
                                     const _zoneData = [...zoneData]
                                     _zoneData[listIndex][index] = _item;
                                     setZoneData(_zoneData)
                                    }}
                                  />
                                </div>
                              );
                            })
                          }
                          { 
                            (!readOnly && (((currentLayout?.optionAdd == 1 || currentLayout?.optionOver == 1) && itemList.length < 2) ||
                            ((currentLayout?.optionMission == 1 || currentLayout?.optionWod == 1) && itemList.length < 1) ||
                            ((currentLayout?.optionMission == 0 && currentLayout?.optionWod == 0 && currentLayout?.optionAdd == 0 && currentLayout?.optionOver == 0))))?
                            <div
                              onClick={() =>{
                                setModalIndex(listIndex)
                                setModalZoneData(zoneData[listIndex])
                                showModal(`ContentsSearchModal`)
                              }}
                              className="flex ml-2 cursor-pointer rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </div>
                            :null
                          }
                        </div>
                        {
                          currentLayout?.optionMission == 1?
                          <div className=" ml-5 flex space-x-5">
                            <div className=" m-3">
                              <div className=" font-bold">미션</div>
                              <div className=" flex space-x-2">
                                <div className="form-control">
                                  <label className="label cursor-pointer">
                                    <input
                                      type={'radio'}
                                      className="radio checked:bg-gymmate"
                                      value={0}
                                      checked={missionList && (missionList.length > 0) && (missionList[0].length > listIndex)?missionList[0][listIndex].value == 0:false}
                                      onChange={(e)=>{
                                        if(missionList) {
                                          let _missionList = [...missionList]
                                          _missionList[0][listIndex].value = e.target.value
                                          setMissionList(_missionList)
                                        }
                                      }}
                                      disabled={readOnly}
                                    />
                                    <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                      <p>{'없음'}</p>
                                    </div>
                                  </label>
                                </div>
                                
                                <div className="form-control">
                                  <label className="label cursor-pointer">
                                    <input
                                      type={'radio'}
                                      className="radio checked:bg-gymmate"
                                      value={1}
                                      checked={missionList && (missionList.length > 0) && (missionList[0].length > listIndex)?missionList[0][listIndex].value == 1:false}
                                      onChange={(e)=>{
                                        if(missionList) {
                                          let _missionList = [...missionList]
                                          _missionList[0][listIndex].value = e.target.value
                                          setMissionList(_missionList)
                                        }
                                      }}
                                      disabled={readOnly}
                                    />
                                    <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                      <p>{'거리(meter)'}</p>
                                    </div>
                                  </label>
                                </div>
                                
                                <div className="form-control">
                                  <label className="label cursor-pointer">
                                    <input
                                      type={'radio'}
                                      className="radio checked:bg-gymmate"
                                      value={2}
                                      checked={missionList && (missionList.length > 0) && (missionList[0].length > listIndex)?missionList[0][listIndex].value == 2:false}
                                      onChange={(e)=>{
                                        if(missionList) {
                                          let _missionList = [...missionList]
                                          _missionList[0][listIndex].value = e.target.value
                                          setMissionList(_missionList)
                                        }
                                      }}
                                      disabled={readOnly}
                                    />
                                    <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                      <p>{'횟수(count)'}</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              {
                                (missionList && (missionList.length > 0) && (missionList[0].length > listIndex) && (missionList[0][listIndex].value != 0))?
                                <div className="flex items-center space-x-5">
                                  <div className="flex items-center whitespace-nowrap space-x-2">
                                    <p>MAX</p>
                                    <div className=" w-20 ">
                                      <input
                                        type="number"
                                        placeholder=""
                                        className="input input-sm w-full input-bordered"
                                        value={missionList[0][listIndex].max}
                                        onChange={(e)=>{
                                          let _missionList = [...missionList]
                                          _missionList[0][listIndex].max = e.target.value
                                          setMissionList(_missionList)
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="flex items-center whitespace-nowrap space-x-2">
                                    <p>MIN</p>
                                    <div className=" w-20 ">
                                      <input
                                        type="number"
                                        placeholder=""
                                        className="input input-sm w-full input-bordered"
                                        value={missionList[0][listIndex].min}
                                        onChange={(e)=>{
                                          let _missionList = [...missionList]
                                          _missionList[0][listIndex].min = e.target.value
                                          setMissionList(_missionList)
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                :<div className=" h-8"/>
                              }
                            </div>
                            {
                              currentLayout?.optionAdd == 1?
                              <div className=" m-3">
                                <div className=" font-bold">ADD 미션</div>
                                <div className=" flex space-x-2">
                                  <div className="form-control">
                                    <label className="label cursor-pointer">
                                      <input
                                        type={'radio'}
                                        className="radio checked:bg-gymmate"
                                        value={0}
                                        checked={missionList && (missionList.length > 1) && (missionList[1].length > listIndex)?missionList[1][listIndex].value == 0:false}
                                        onChange={(e)=>{
                                          if(missionList) {
                                            let _missionList = [...missionList]
                                            _missionList[1][listIndex].value = e.target.value
                                            setMissionList(_missionList)
                                          }
                                        }}
                                        disabled={readOnly}
                                      />
                                      <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                        <p>{'없음'}</p>
                                      </div>
                                    </label>
                                  </div>
                                  
                                  <div className="form-control">
                                    <label className="label cursor-pointer">
                                      <input
                                        type={'radio'}
                                        className="radio checked:bg-gymmate"
                                        value={1}
                                        checked={missionList && (missionList.length > 1) && (missionList[1].length > listIndex)?missionList[1][listIndex].value == 1:false}
                                        onChange={(e)=>{
                                          if(missionList) {
                                            let _missionList = [...missionList]
                                            _missionList[1][listIndex].value = e.target.value
                                            setMissionList(_missionList)
                                          }
                                        }}
                                        disabled={readOnly}
                                      />
                                      <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                        <p>{'거리(meter)'}</p>
                                      </div>
                                    </label>
                                  </div>
                                  
                                  <div className="form-control">
                                    <label className="label cursor-pointer">
                                      <input
                                        type={'radio'}
                                        className="radio checked:bg-gymmate"
                                        value={2}
                                        checked={missionList && (missionList.length > 1) && (missionList[1].length > listIndex)?missionList[1][listIndex].value == 2:false}
                                        onChange={(e)=>{
                                          if(missionList) {
                                            let _missionList = [...missionList]
                                            _missionList[1][listIndex].value = e.target.value
                                            setMissionList(_missionList)
                                          }
                                        }}
                                        disabled={readOnly}
                                      />
                                      <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                        <p>{'횟수(count)'}</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                {
                                  (missionList && (missionList.length > 1) && (missionList[1].length > listIndex) && (missionList[1][listIndex].value != 0))?
                                  <div className="flex items-center space-x-5">
                                    <div className="flex items-center whitespace-nowrap space-x-2">
                                      <p>MAX</p>
                                      <div className=" w-20 ">
                                        <input
                                          type="number"
                                          placeholder=""
                                          className="input input-sm w-full input-bordered"
                                          value={missionList[1][listIndex].max}
                                          onChange={(e)=>{
                                            let _missionList = [...missionList]
                                            _missionList[1][listIndex].max = e.target.value
                                            setMissionList(_missionList)
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="flex items-center whitespace-nowrap space-x-2">
                                      <p>MIN</p>
                                      <div className=" w-20 ">
                                        <input
                                          type="number"
                                          placeholder=""
                                          className="input input-sm w-full input-bordered"
                                          value={missionList[1][listIndex].min}
                                          onChange={(e)=>{
                                            let _missionList = [...missionList]
                                            _missionList[1][listIndex].min = e.target.value
                                            setMissionList(_missionList)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  :<div className=" h-8"/>
                                }
                              </div>
                              :null
                            }
                            {
                              currentLayout?.optionOver == 1?
                              <div className=" m-3">
                                <div className=" font-bold">OVER 미션</div>
                                <div className=" flex space-x-2">
                                  <div className="form-control">
                                    <label className="label cursor-pointer">
                                      <input
                                        type={'radio'}
                                        className="radio checked:bg-gymmate"
                                        value={0}
                                        checked={missionList && (missionList.length > 1) && (missionList[1].length > listIndex)?missionList[1][listIndex].value == 0:false}
                                        onChange={(e)=>{
                                          if(missionList) {
                                            let _missionList = [...missionList]
                                            _missionList[1][listIndex].value = e.target.value
                                            setMissionList(_missionList)
                                          }
                                        }}
                                        disabled={readOnly}
                                      />
                                      <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                        <p>{'없음'}</p>
                                      </div>
                                    </label>
                                  </div>
                                  
                                  <div className="form-control">
                                    <label className="label cursor-pointer">
                                      <input
                                        type={'radio'}
                                        className="radio checked:bg-gymmate"
                                        value={1}
                                        checked={missionList && (missionList.length > 1) && (missionList[1].length > listIndex)?missionList[1][listIndex].value == 1:false}
                                        onChange={(e)=>{
                                          if(missionList) {
                                            let _missionList = [...missionList]
                                            _missionList[1][listIndex].value = e.target.value
                                            setMissionList(_missionList)
                                          }
                                        }}
                                        disabled={readOnly}
                                      />
                                      <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                        <p>{'거리(meter)'}</p>
                                      </div>
                                    </label>
                                  </div>
                                  
                                  <div className="form-control">
                                    <label className="label cursor-pointer">
                                      <input
                                        type={'radio'}
                                        className="radio checked:bg-gymmate"
                                        value={2}
                                        checked={missionList && (missionList.length > 1) && (missionList[1].length > listIndex)?missionList[1][listIndex].value == 2:false}
                                        onChange={(e)=>{
                                          if(missionList) {
                                            let _missionList = [...missionList]
                                            _missionList[1][listIndex].value = e.target.value
                                            setMissionList(_missionList)
                                          }
                                        }}
                                        disabled={readOnly}
                                      />
                                      <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                        <p>{'횟수(count)'}</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                {
                                  (missionList && (missionList.length > 1) && (missionList[1].length > listIndex) && (missionList[1][listIndex].value != 0))?
                                  <div className="flex items-center space-x-5">
                                    <div className="flex items-center whitespace-nowrap space-x-2">
                                      <p>MAX</p>
                                      <div className=" w-20 ">
                                        <input
                                          type="number"
                                          placeholder=""
                                          className="input input-sm w-full input-bordered"
                                          value={missionList[1][listIndex].max}
                                          onChange={(e)=>{
                                            let _missionList = [...missionList]
                                            _missionList[1][listIndex].max = e.target.value
                                            setMissionList(_missionList)
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="flex items-center whitespace-nowrap space-x-2">
                                      <p>MIN</p>
                                      <div className=" w-20 ">
                                        <input
                                          type="number"
                                          placeholder=""
                                          className="input input-sm w-full input-bordered"
                                          value={missionList[1][listIndex].min}
                                          onChange={(e)=>{
                                            let _missionList = [...missionList]
                                            _missionList[1][listIndex].min = e.target.value
                                            setMissionList(_missionList)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  :<div className=" h-8"/>
                                }
                              </div>
                              :null
                            }
                          </div>
                          :null
                        }
                        {
                          currentLayout?.optionWod == 1?
                          <div className=" ml-5 flex space-x-5">
                            <div className=" m-3 w-52">
                              <div className=" font-bold">와드 미션</div>
                              <div className=" flex space-x-2">
                                <div className="form-control">
                                  <label className="label cursor-pointer">
                                    <input
                                      type={'radio'}
                                      className="radio checked:bg-gymmate"
                                      value={0}
                                      checked={wodList && (wodList.length > 0) && (wodList[0].length > listIndex)?wodList[0][listIndex].value == 0:false}
                                      onChange={(e)=>{
                                        if(wodList) {
                                          let _wodList = [...wodList]
                                          _wodList[0][listIndex].value = e.target.value
                                          setWodList(_wodList)
                                        }
                                      }}
                                      disabled={readOnly}
                                    />
                                    <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                      <p>{'없음'}</p>
                                    </div>
                                  </label>
                                </div>
                                
                                <div className="form-control">
                                  <label className="label cursor-pointer">
                                    <input
                                      type={'radio'}
                                      className="radio checked:bg-gymmate"
                                      value={1}
                                      checked={wodList && (wodList.length > 0) && (wodList[0].length > listIndex)?wodList[0][listIndex].value == 1:false}
                                      onChange={(e)=>{
                                        if(wodList) {
                                          let _wodList = [...wodList]
                                          _wodList[0][listIndex].value = e.target.value
                                          setWodList(_wodList)
                                        }
                                      }}
                                      disabled={readOnly}
                                    />
                                    <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                      <p>{'있음'}</p>
                                    </div>
                                  </label>
                                </div>
                              </div>
                              {
                                (wodList && (wodList.length > 0) && (wodList[0].length > listIndex) && (wodList[0][listIndex].value != 0))?
                                <div className="">
                                  
                                  <input
                                    placeholder=""
                                    className="input input-sm w-full input-bordered"
                                    value={wodList[0][listIndex].text}
                                    onChange={(e)=>{
                                      let _wodList = [...wodList]
                                      _wodList[0][listIndex].text = e.target.value
                                      setWodList(_wodList)
                                    }}
                                  />
                                </div>
                                :<div className=" h-8"/>
                              }
                            </div>
                            {
                              currentLayout?.optionAdd == 1?
                              <div className=" m-3">
                                <div className=" font-bold">ADD 와드 미션</div>
                                <div className=" flex space-x-2">
                                  <div className="form-control">
                                    <label className="label cursor-pointer">
                                      <input
                                        type={'radio'}
                                        className="radio checked:bg-gymmate"
                                        value={0}
                                        checked={wodList && (wodList.length > 1) && (wodList[1].length > listIndex)?wodList[1][listIndex].value == 0:false}
                                        onChange={(e)=>{
                                          if(wodList) {
                                            let _wodList = [...wodList]
                                            _wodList[1][listIndex].value = e.target.value
                                            setWodList(_wodList)
                                          }
                                        }}
                                        disabled={readOnly}
                                      />
                                      <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                        <p>{'없음'}</p>
                                      </div>
                                    </label>
                                  </div>
                                  
                                  <div className="form-control">
                                    <label className="label cursor-pointer">
                                      <input
                                        type={'radio'}
                                        className="radio checked:bg-gymmate"
                                        value={1}
                                        checked={wodList && (wodList.length > 1) && (wodList[1].length > listIndex)?wodList[1][listIndex].value == 1:false}
                                        onChange={(e)=>{
                                          if(wodList) {
                                            let _wodList = [...wodList]
                                            _wodList[1][listIndex].value = e.target.value
                                            setWodList(_wodList)
                                          }
                                        }}
                                        disabled={readOnly}
                                      />
                                      <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                        <p>{'있음'}</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                {
                                  (wodList && (wodList.length > 1) && (wodList[1].length > listIndex) && (wodList[1][listIndex].value != 0))?
                                  <div className="">
                                    
                                    <input
                                      placeholder=""
                                      className="input input-sm w-full input-bordered"
                                      value={wodList[1][listIndex].text}
                                      onChange={(e)=>{
                                        let _wodList = [...wodList]
                                        _wodList[1][listIndex].text = e.target.value
                                        setWodList(_wodList)
                                      }}
                                    />
                                  </div>
                                  :<div className=" h-8"/>
                                }
                              </div>
                              :null
                            }
                            {
                              currentLayout?.optionOver == 1?
                              <div className=" m-3">
                                <div className=" font-bold">OVER 와드 미션</div>
                                <div className=" flex space-x-2">
                                  <div className="form-control">
                                    <label className="label cursor-pointer">
                                      <input
                                        type={'radio'}
                                        className="radio checked:bg-gymmate"
                                        value={0}
                                        checked={wodList && (wodList.length > 1) && (wodList[1].length > listIndex)?wodList[1][listIndex].value == 0:false}
                                        onChange={(e)=>{
                                          if(wodList) {
                                            let _wodList = [...wodList]
                                            _wodList[1][listIndex].value = e.target.value
                                            setWodList(_wodList)
                                          }
                                        }}
                                        disabled={readOnly}
                                      />
                                      <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                        <p>{'없음'}</p>
                                      </div>
                                    </label>
                                  </div>
                                  
                                  <div className="form-control">
                                    <label className="label cursor-pointer">
                                      <input
                                        type={'radio'}
                                        className="radio checked:bg-gymmate"
                                        value={1}
                                        checked={wodList && (wodList.length > 1) && (wodList[1].length > listIndex)?wodList[1][listIndex].value == 1:false}
                                        onChange={(e)=>{
                                          if(wodList) {
                                            let _wodList = [...wodList]
                                            _wodList[1][listIndex].value = e.target.value
                                            setWodList(_wodList)
                                          }
                                        }}
                                        disabled={readOnly}
                                      />
                                      <div className="ml-2 label-text flex items-center justify-center space-x-1">
                                        <p>{'있음'}</p>
                                      </div>
                                    </label>
                                  </div>
                                </div>
                                {
                                  (wodList && (wodList.length > 1) && (wodList[1].length > listIndex) && (wodList[1][listIndex].value != 0))?
                                  <div className="">
                                    
                                    <input
                                      placeholder=""
                                      className="input input-sm w-full input-bordered"
                                      value={wodList[1][listIndex].text}
                                      onChange={(e)=>{
                                        let _wodList = [...wodList]
                                        _wodList[1][listIndex].text = e.target.value
                                        setWodList(_wodList)
                                      }}
                                    />
                                  </div>
                                  :<div className=" h-8"/>
                                }
                              </div>
                              :null
                            }
                          </div>
                          :null
                        }
                      </div>
                    )
                  })
                }
                </>
                :
                <div className="flex items-center">
                  <div className="text-green-600 mr-10">1({zoneData.length > 0?zoneData[0].length:'0'})</div>
                  <div className=" grid grid-flow-row grid-cols-7">
                    {
                      (zoneData.length > 0) && zoneData[0].map((item: ContentsDtoWithId, index: number) => {
                        return (
                          <div className=" flex mb-2">
                            <ContentsGapDrag
                              item={item}
                              index={index}
                              moveContent={moveContent}
                              zoneId={item.id || ''}
                              originalZoneId={'0'}
                            />
                            <ContentsBoxWrap
                              item={item}
                              zoneId={item.id || ''}
                              onDelete={onDelete}
                              onDuplicate={onDuplicate}
                              readOnly={readOnly}
                              setOriginalZoneId={(v:string)=>{}}
                              tutorialCheckbox={false}
                              zoneIndex={0}
                              zoneItemIndex={index}
                            />
                          </div>
                        );
                      })
                    }
                    {
                      !readOnly?
                      <div
                        onClick={() =>{
                          setModalIndex(0)
                          setModalZoneData(zoneData[0])
                          showModal(`ContentsSearchModal`)
                        }}
                        className="flex ml-2 cursor-pointer rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
                      >
                        <FontAwesomeIcon icon={faPlus} />
                      </div>
                      :null
                    }
                  </div>
                </div>
              }
            </div>
          </FormBox>
          {
            currentLayout?.optionWod && wodTitleList && wodTitleList.length > 0?
            <FormBox title="WOD 타이틀" icon={<FontAwesomeIcon icon={faHeading} />}>
            {
              wodTitleList.map((_wodTitle:string, _wodIndex:number)=>
              <div
                key={`wodTitleList_${_wodIndex}`}
                className=" flex items-center"
              >
                {
                  currentLayout?.optionType == 'team' && _wodIndex == 0?'A':
                  currentLayout?.optionType == 'team' && _wodIndex == 1?'B':
                  currentLayout?.optionType == 'team' && _wodIndex == 2?'C':
                  currentLayout?.optionType == 'team' && _wodIndex == 3?'D':
                  currentLayout?.optionType == 'team' && _wodIndex == 4?'E':
                  currentLayout?.optionType == 'team' && _wodIndex == 5?'F':
                  _wodIndex+1
                }
                <input
                  placeholder=""
                  className="input input-sm w-full input-bordered m-4"
                  value={_wodTitle}
                  onChange={(e)=>{
                    let _wodTitleList = [...wodTitleList]
                    _wodTitleList[_wodIndex] = e.target.value
                    setWodTitleList(_wodTitleList)
                  }}
                  disabled={readOnly}
                />
              </div>)
            }
          </FormBox>
          :null
          }
          <FormBox title="프로그램명" icon={<FontAwesomeIcon icon={faHeading} />}>
            {readOnly ? (
              watch("name")
            ) : (
              <FormInput
                placeholder="프로그램명"
                register={register("name", {
                  required: "프로그램명을 입력해주세요",
                })}
              />
            )}
          </FormBox>
          {
            watch("programType") == '본운동'?
            <FormBox
              title="영역 세트시간"
              icon={<FontAwesomeIcon icon={faClock} />}
            >
              <div className="flex space-x-5">
                <Radio
                  title={'공통'}
                  value={'1'}
                  register={register("timeType", {
                    required: "영역 세트 시간의 종류를 선택해주세요",
                    onChange: (e)=>{ 
                      setValue("moveTime", 0)
                      setValue("exerciseTime", 0)
                      setValue("moveTimes", "")
                      setValue("exerciseTimes", "")
                      setMoveTimeList([])
                      setExerciseTimeList([])
                      setAreaRepeatTimeList([])
                      setTeamRepeatTimeList([])
                      setTotalRepeatTimeList([])
                    }
                  })}
                  disabled={readOnly}
                />
                <Radio
                  title={'변형'}
                  value={'2'}
                  register={register("timeType", {
                    required: "영역 세트 시간의 종류를 선택해주세요",
                    onChange: (e)=>{
                      let _moveTimeList = []
                      let _exerciseTimeList = []
                      if(zoneData && (zoneData.length > 0)) {
                        for (let i = 0; i < zoneData[0].length; i++) {
                          _moveTimeList.push("")
                          _exerciseTimeList.push('')
                        }
                      }
                      setValue("moveTimes", _moveTimeList.toString())
                      setValue("exerciseTimes", _exerciseTimeList.toString())
                      setValue("areaRepeatTimes", "")
                      setValue("teamRepeatTimes", "")
                      setValue("totalRepeatTimes", "")
                      setMoveTimeList(_moveTimeList)
                      setExerciseTimeList(_exerciseTimeList)
                      setAreaRepeatTimeList([])
                      setTeamRepeatTimeList([])
                      setTotalRepeatTimeList([])
                    }
                  })}
                  disabled={readOnly}
                />
              </div>
              {
                !(watch("areaRepeatTimes") != '' || watch("teamRepeatTimes") != "" || watch("totalRepeatTimes") != "")?
                <div className="">
                  <div className=" flex my-2">
                    <div className=" w-16 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">SET</div>
                    <div className=" w-28 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">운동(S)</div>
                    <div className=" w-28 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">휴식(S)</div>
                  </div>
                  {
                    watch("timeType") == '1'?
                    <div className=" flex my-2">
                      <div className=" w-16 h-16 py-4 border-b border-[#f2f2f2] text-sm font-bold text-center justify-center">1</div>
                      <div className=" w-28 h-16 py-1 px-3 border-b border-[#f2f2f2]">
                        {readOnly ? (
                          <div className="m-3">{watch("exerciseTime")}</div>
                        ) : (
                          <FormInput
                            placeholder=""
                            type="number"
                            register={register("exerciseTime", {
                              required: "운동 시간을 입력해주세요",
                            })}
                          />
                        )}
                      </div>
                      <div className=" w-28 h-16 py-1 px-3 border-b border-[#f2f2f2]">
                            {readOnly ? (
                              <div className="m-3">{watch("moveTime")}</div>
                            ) : (
                              <FormInput
                                placeholder=""
                                type="number"
                                register={register("moveTime", {
                                  required: "휴식 시간을 입력해주세요",
                                })}
                              />
                            )}
                      </div>
                    </div>
                    :
                    <div className="">
                      {
                        exerciseTimeList?.map((item, index)=>{
                          return (
                            <div key={`exerciseTimeList_${index}`} className=" flex my-2">
                              <div className=" w-16 h-16 py-4 text-sm font-bold text-center justify-center">{index+1}</div>
                              <div className=" w-28 h-16 py-1 px-3">
                                {readOnly ? (
                                  <div className="m-3">{item}</div>
                                ) : (
                                  <input
                                    type={'number'}
                                    placeholder={""}
                                    className="input w-full input-bordered"
                                    value={item}
                                    onChange={(e)=>{
                                      onChangeExerciseTimes(e.target.value, index)
                                    }}
                                  />
                                )}
                              </div>
                              <div className=" w-28 h-16 py-1 px-3">
                                    {readOnly ? (
                                      <div className="m-3">{(moveTimeList && moveTimeList.length > index)?moveTimeList[index]:''}</div>
                                    ) : (
                                      <input
                                        type={'number'}
                                        placeholder={""}
                                        className="input w-full input-bordered"
                                        value={(moveTimeList && moveTimeList.length > index)?moveTimeList[index]:''}
                                        onChange={(e)=>{
                                          onChangeMoveTimes(e.target.value, index)
                                        }}
                                      />
                                    )}
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                  }
                </div>
                :null
              }
            </FormBox>
            :
            <FormBox
              title="세트 시간"
              icon={<FontAwesomeIcon icon={faClock} />}
            >
              <div className="flex items-center space-x-5">
                <div className="flex items-center whitespace-nowrap space-x-2">
                  <p>운동</p>
                  <div className=" w-20 ">
                    {readOnly ? (
                      <div className="m-3">{watch("exerciseTime")}</div>
                    ) : (
                      <FormInput
                        placeholder=""
                        type="number"
                        register={register("exerciseTime", {
                          required: "라운드당 시간을 입력해주세요",
                        })}
                      />
                    )}
                  </div>
                  <p>초</p>
                </div>
                <div className="flex items-center whitespace-nowrap space-x-2">
                  <p>휴식</p>
                  <div className=" w-20 ">
                    {readOnly ? (
                      <div className="m-3">{watch("moveTime")}</div>
                    ) : (
                      <FormInput
                        placeholder=""
                        type="number"
                        register={register("moveTime", {
                          required: "라운드당 시간을 입력해주세요",
                        })}
                      />
                    )}
                  </div>
                  <p>초</p>
                </div>
              </div>
            </FormBox>
          }
          {
            watch("programType") == '본운동'?
            <div className="flex w-full space-x-2 ">
              <div className="flex-1">
                <FormBox
                  title="영역 반복 횟수"
                  required={false}
                  heightMax={true}
                  icon={<FontAwesomeIcon icon={faLayerGroup} />}
                >
                  {
                    readOnly?
                    <div className="m-3">{watch("areaRepeatCount")}</div>
                    :
                    <div className="flex items-center">
                      <div className=" w-20">
                        <input
                          placeholder=""
                          type="number"
                          className="input w-full input-bordered"
                          value={watch("areaRepeatCount")}
                          onChange={(e)=>{
                            const count = Math.max(parseInt(e.target.value), 1)
                            if(areaRepeatTimeList && areaRepeatTimeList.length > 0) {
                              if(count > areaRepeatTimeList.length) {
                                let _areaRepeatTimeList = [...areaRepeatTimeList]
                                for (let i = _areaRepeatTimeList.length; i < count; i++) {
                                  _areaRepeatTimeList.push({exerciseTime:'', moveTime:''})
                                }
                                console.log('_areaRepeatTimeList : ', _areaRepeatTimeList)
                                setAreaRepeatTimeList(_areaRepeatTimeList)
                                setValue("areaRepeatTimes", JSON.stringify(_areaRepeatTimeList))
                              }
                              else
                              {
                                let _areaRepeatTimeList = [...areaRepeatTimeList]
                                _areaRepeatTimeList.splice(count, _areaRepeatTimeList.length - count)
                                setAreaRepeatTimeList(_areaRepeatTimeList)
                                setValue("areaRepeatTimes", JSON.stringify(_areaRepeatTimeList))
                              }
                            }
                            setValue('areaRepeatCount', count)
                          }}
                        />
                      </div>
                      <button
                        className="btn  btn-xs ml-2"
                        onClick={onChangeAreaRepeatTimeState}
                        disabled={watch("timeType") != '1' || watch("teamRepeatTimes") != "" || watch("totalRepeatTimes") != ""}
                      >
                        시간추가
                        {
                          (areaRepeatTimeList && areaRepeatTimeList.length > 0)?
                          <FontAwesomeIcon icon={faCircleXmark} />
                          :
                          <FontAwesomeIcon icon={faCirclePlus} />
                        }
                      </button>
                    </div>
                  }
                  {
                    (areaRepeatTimeList && areaRepeatTimeList.length > 0)?
                    <div className="">
                      <div className=" flex my-2">
                        <div className=" w-16 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">회차</div>
                        <div className=" w-28 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">운동(S)</div>
                        <div className=" w-28 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">휴식(S)</div>
                      </div>
                      {
                        areaRepeatTimeList.map((item, index)=>{
                          return (
                            <div key={`areaRepeatTimeList_${index}`} className=" flex my-2">
                              <div className=" w-16 h-16 py-4 text-sm font-bold text-center justify-center">{index+1}</div>
                              <div className=" w-28 h-16 py-1 px-3">
                                {readOnly ? (
                                  <div className="m-3">{item.exerciseTime}</div>
                                ) : (
                                  <input
                                    type={'number'}
                                    placeholder={""}
                                    className="input w-full input-bordered"
                                    value={item.exerciseTime}
                                    onChange={(e)=>{
                                      onChangeAreaRepeatExerciseTimes(e.target.value, index)
                                    }}
                                  />
                                )}
                              </div>
                              <div className=" w-28 h-16 py-1 px-3">
                                    {readOnly ? (
                                      <div className="m-3">{item.moveTime}</div>
                                    ) : (
                                      <input
                                        type={'number'}
                                        placeholder={""}
                                        className="input w-full input-bordered"
                                        value={item.moveTime}
                                        onChange={(e)=>{
                                          onChangeAreaRepeatMoveTimes(e.target.value, index)
                                        }}
                                      />
                                    )}
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                    :null
                  }
                </FormBox>
              </div>
              {
                currentLayout && currentLayout.optionType == 'team'?
                <div className="flex-1">
                  <FormBox
                    title="팀 반복 횟수"
                    required={false}
                    heightMax={true}
                    icon={<FontAwesomeIcon icon={faRepeat} />}
                  >
                    {
                      readOnly?
                      <div className="m-3">{watch("teamRepeatCount")}</div>
                      :
                      <div className="flex items-center">
                        <div className=" w-20">
                          <input
                            placeholder=""
                            type="number"
                            className="input w-full input-bordered"
                            value={watch("teamRepeatCount")}
                            onChange={(e)=>{
                              const count = Math.max(parseInt(e.target.value), 1)
                              if(teamRepeatTimeList && teamRepeatTimeList.length > 0) {
                                if(count > teamRepeatTimeList.length) {
                                  let _teamRepeatTimeList = [...teamRepeatTimeList]
                                  for (let i = _teamRepeatTimeList.length; i < count; i++) {
                                    _teamRepeatTimeList.push({exerciseTime:'', moveTime:''})
                                  }
                                  console.log('_teamRepeatTimeList : ', _teamRepeatTimeList)
                                  setTeamRepeatTimeList(_teamRepeatTimeList)
                                  setValue("teamRepeatTimes", JSON.stringify(_teamRepeatTimeList))
                                }
                                else
                                {
                                  let _teamRepeatTimeList = [...teamRepeatTimeList]
                                  _teamRepeatTimeList.splice(count, _teamRepeatTimeList.length - count)
                                  setTeamRepeatTimeList(_teamRepeatTimeList)
                                  setValue("teamRepeatTimes", JSON.stringify(_teamRepeatTimeList))
                                }
                              }
                              setValue('teamRepeatCount', count)
                            }}
                          />
                        </div>
                        <button
                          className="btn  btn-xs ml-2"
                          onClick={onChangeTeamRepeatTimeState}
                          disabled={watch("timeType") != '1' || watch("areaRepeatTimes") != "" || watch("totalRepeatTimes") != ""}
                        >
                          시간추가
                          {
                            (teamRepeatTimeList && teamRepeatTimeList.length > 0)?
                            <FontAwesomeIcon icon={faCircleXmark} />
                            :
                            <FontAwesomeIcon icon={faCirclePlus} />
                          }
                        </button>
                      </div>
                    }
                    {
                      (teamRepeatTimeList && teamRepeatTimeList.length > 0)?
                      <div className="">
                        <div className=" flex my-2">
                          <div className=" w-16 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">회차</div>
                          <div className=" w-28 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">운동(S)</div>
                          <div className=" w-28 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">휴식(S)</div>
                        </div>
                        {
                          teamRepeatTimeList.map((item, index)=>{
                            return (
                              <div key={`teamRepeatTimeList_${index}`} className=" flex my-2">
                                <div className=" w-16 h-16 py-4 text-sm font-bold text-center justify-center">{index+1}</div>
                                <div className=" w-28 h-16 py-1 px-3">
                                  {readOnly ? (
                                    <div className="m-3">{item.exerciseTime}</div>
                                  ) : (
                                    <input
                                      type={'number'}
                                      placeholder={""}
                                      className="input w-full input-bordered"
                                      value={item.exerciseTime}
                                      onChange={(e)=>{
                                        onChangeTeamRepeatExerciseTimes(e.target.value, index)
                                      }}
                                    />
                                  )}
                                </div>
                                <div className=" w-28 h-16 py-1 px-3">
                                      {readOnly ? (
                                        <div className="m-3">{item.moveTime}</div>
                                      ) : (
                                        <input
                                          type={'number'}
                                          placeholder={""}
                                          className="input w-full input-bordered"
                                          value={item.moveTime}
                                          onChange={(e)=>{
                                            onChangeTeamRepeatMoveTimes(e.target.value, index)
                                          }}
                                        />
                                      )}
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                      :null
                    }
                  </FormBox>
                </div>
                :null
              }
              <div className="flex-1">
                <FormBox
                  title="전체 반복 횟수"
                  required={false}
                  heightMax={true}
                  icon={<FontAwesomeIcon icon={faRotateRight} />}
                >
                  {
                    readOnly?
                    <div className="m-3">{watch("totalRepeatCount")}</div>
                    :
                    <div className="flex items-center">
                      <div className=" w-20">
                        <input
                          placeholder=""
                          type="number"
                          className="input w-full input-bordered"
                          value={watch("totalRepeatCount")}
                          onChange={(e)=>{
                            const count = Math.max(parseInt(e.target.value), 1)
                            if(totalRepeatTimeList && totalRepeatTimeList.length > 0) {
                              if(count > totalRepeatTimeList.length) {
                                let _totalRepeatTimeList = [...totalRepeatTimeList]
                                for (let i = _totalRepeatTimeList.length; i < count; i++) {
                                  _totalRepeatTimeList.push({exerciseTime:'', moveTime:''})
                                }
                                setTotalRepeatTimeList(_totalRepeatTimeList)
                                setValue("totalRepeatTimes", JSON.stringify(_totalRepeatTimeList))
                              }
                              else
                              {
                                let _totalRepeatTimeList = [...totalRepeatTimeList]
                                _totalRepeatTimeList.splice(count, _totalRepeatTimeList.length - count)
                                setTotalRepeatTimeList(_totalRepeatTimeList)
                                setValue("totalRepeatTimes", JSON.stringify(_totalRepeatTimeList))
                              }
                            }
                            setValue('totalRepeatCount', count)
                          }}
                        />
                      </div>
                      <button
                        className="btn  btn-xs ml-2"
                        onClick={onChangeTotalRepeatTimeState}
                        disabled={watch("timeType") != '1' || watch("teamRepeatTimes") != "" || watch("areaRepeatTimes") != ""}
                      >
                        시간추가
                        {
                          (totalRepeatTimeList && totalRepeatTimeList.length > 0)?
                          <FontAwesomeIcon icon={faCircleXmark} />
                          :
                          <FontAwesomeIcon icon={faCirclePlus} />
                        }
                      </button>
                    </div>
                  }
                  {
                    (totalRepeatTimeList && totalRepeatTimeList.length > 0)?
                    <div className="">
                      <div className=" flex my-2">
                        <div className=" w-16 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">회차</div>
                        <div className=" w-28 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">운동(S)</div>
                        <div className=" w-28 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">휴식(S)</div>
                      </div>
                      {
                        totalRepeatTimeList.map((item, index)=>{
                          return (
                            <div key={`totalRepeatTimeList_${index}`} className=" flex my-2">
                              <div className=" w-16 h-16 py-4 text-sm font-bold text-center justify-center">{index+1}</div>
                              <div className=" w-28 h-16 py-1 px-3">
                                {readOnly ? (
                                  <div className="m-3">{item.exerciseTime}</div>
                                ) : (
                                  <input
                                    type={'number'}
                                    placeholder={""}
                                    className="input w-full input-bordered"
                                    value={item.exerciseTime}
                                    onChange={(e)=>{
                                      onChangeTotalRepeatExerciseTimes(e.target.value, index)
                                    }}
                                  />
                                )}
                              </div>
                              <div className=" w-28 h-16 py-1 px-3">
                                    {readOnly ? (
                                      <div className="m-3">{item.moveTime}</div>
                                    ) : (
                                      <input
                                        type={'number'}
                                        placeholder={""}
                                        className="input w-full input-bordered"
                                        value={item.moveTime}
                                        onChange={(e)=>{
                                          onChangeTotalRepeatMoveTimes(e.target.value, index)
                                        }}
                                      />
                                    )}
                              </div>
                            </div>
                          )
                        })
                      }
                    </div>
                    :null
                  }
                </FormBox>
              </div>
            </div>
            :null
          }
          {
            watch("programType") == "본운동"?
            <FormBox
              title="추가운동"
              required={false}
              icon={<FontAwesomeIcon icon={faSquarePlus} />}
            >
              {
                !readOnly?
                <div className=" flex space-x-2 my-2 mt-5">
                  {
                    (addProgramList && addProgramList.length > 0)?
                    <button
                      className="btn  btn-xs ml-2"
                      onClick={onDelete_AddProgram}
                    >
                      <FontAwesomeIcon icon={faCircleMinus} />
                      삭제
                    </button>
                    :null
                  }
                  <button
                    className="btn  btn-xs ml-2"
                    onClick={onAdd_AddProgram}
                  >
                    <FontAwesomeIcon icon={faCirclePlus} />
                    추가
                  </button>
                </div>
                :null
              }
              {
                (addProgramList && addProgramList.length > 0)?
                <div className="">
                  <div className=" flex my-2">
                    <div className=" w-28 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">구간(SET)</div>
                    <div className=" w-28 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">추가운동 프로그램</div>
                  </div>
                  {
                    addProgramList.map((item, index)=>{
                      return (
                        <div key={`addProgramList_${index}`} className=" flex my-2 items-center">
                          <div className=" w-28 h-16 py-1 px-3">
                            {readOnly ? (
                              <div className=" m-3">{item.set}</div>
                            ) : (
                              <input
                                type={'number'}
                                placeholder={""}
                                className="input w-full input-bordered"
                                value={item.set}
                                onChange={(e)=>{
                                  onChangeAddProgramSet(e.target.value, index)
                                }}
                              />
                            )}
                          </div>
                          {
                            readOnly?
                            <div>{item.programName?item.programName:''}</div>
                            :
                            <>
                              {
                                item.programName?
                                <button
                                  className="ml-3 btn  btn-xs bg-[#BFDBFE]"
                                  onClick={()=>{
                                    if(readOnly) {
                                      return;
                                    }

                                    onChangeAddProgramValue(index, true)
                                  }}
                                >
                                  {item.programName}
                                  {
                                    !readOnly?
                                    <FontAwesomeIcon icon={faCircleXmark} />
                                    :null
                                  }
                                </button>
                                :
                                <button
                                  className="btn  btn-xs ml-2"
                                  onClick={()=>onChangeAddProgramValue(index, false)}
                                >
                                  <FontAwesomeIcon icon={faCirclePlus} />
                                  추가
                                </button>
                              }
                            </>
                          }
                        </div>
                      )
                    })
                  }
                </div>
                :null
              }
            </FormBox>
            :null
          }
          {
            watch("programType") == "본운동"?
            <FormBox
              title="물먹는 시간"
              required={false}
              icon={<FontAwesomeIcon icon={faDroplet} />}
            >
              {
                !readOnly?
                <div className=" flex space-x-2 my-2">
                  {
                    (waterTimeList && waterTimeList.length > 0)?
                    <button
                      className="btn  btn-xs ml-2"
                      onClick={onDeleteWaterTime}
                    >
                      <FontAwesomeIcon icon={faCircleMinus} />
                      삭제
                    </button>
                    :null
                  }
                  <button
                    className="btn  btn-xs ml-2"
                    onClick={onAddWaterTime}
                  >
                    <FontAwesomeIcon icon={faCirclePlus} />
                    추가
                  </button>
                </div>
                :null
              }
              {
                (waterTimeList && waterTimeList.length > 0)?
                <div className="">
                  <div className=" flex my-2">
                    <div className=" w-28 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">구간(SET)</div>
                    <div className=" w-28 py-2 border-b border-[#f2f2f2] text-sm font-bold text-center">시간(S)</div>
                  </div>
                  {
                    waterTimeList.map((item, index)=>{
                      return (
                        <div key={`waterTimeList_${index}`} className=" flex my-2">
                          <div className=" w-28 h-16 py-1 px-3">
                            {readOnly ? (
                              <div className="m-3">{item.set}</div>
                            ) : (
                              <input
                                type={'number'}
                                placeholder={""}
                                className="input w-full input-bordered"
                                value={item.set}
                                onChange={(e)=>{
                                  onChangeWaterTimeSet(e.target.value, index)
                                }}
                              />
                            )}
                          </div>
                          <div className=" w-28 h-16 py-1 px-3">
                                {readOnly ? (
                                  <div className="m-3">{item.time}</div>
                                ) : (
                                  <input
                                    type={'number'}
                                    placeholder={""}
                                    className="input w-full input-bordered"
                                    value={item.time}
                                    onChange={(e)=>{
                                      onChangeWaterTimeValue(e.target.value, index)
                                    }}
                                  />
                                )}
                          </div>
                        </div>
                      )
                    })
                  }
                </div>
                :null
              }
            </FormBox>
            :null
          }
          <div className="flex w-full space-x-2 ">
            <div className="w-1/2">
              <FormBox
                title="총 세트수"
                required={false}
                icon={<FontAwesomeIcon icon={faStopwatch} />}
              >
                <p className="font-bold">
                  {" "}
                  {totalSet}
                </p>
              </FormBox>
            </div>
            <div className="w-1/2">
              <FormBox
                title="총 운동시간"
                required={false}
                icon={<FontAwesomeIcon icon={faClock} />}
              >
                <p className="font-bold">{totalExerciseTime}</p>
              </FormBox>
            </div>
          </div>
          {
            watch("brandId") == 3?
            <FormBox
              title="BPM 정보"
              required={false}
              icon={<FontAwesomeIcon icon={faBook} />}
            >
              <div className=" w-36 h-16 py-1 flex items-center mb-3">
                  {readOnly ? (
                    <div className="m-3">{watch("bpm")}</div>
                  ) : (
                    <input
                      type={'number'}
                      placeholder={""}
                      className="input w-full input-bordered mr-3"
                      value={watch("bpm")}
                      onChange={(e)=>{
                        setValue("bpm", Number(e.target.value))
                      }}
                    />
                  )}
                  <div>BPM</div>
              </div>
              {readOnly ? (
                watch("bpmInfo")
              ) : (
                
                <>
                  <textarea
                    //{...register("bpmInfo")}
                    value={watch("bpmInfo")}
                    onChange={(e)=>{
                      let _floor = e.currentTarget.value
                      const _bpmInfoCount = _floor.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
                      if(_bpmInfoCount <= 140) {
                        setValue("bpmInfo", e.currentTarget.value)
                        setBpmInfoCount(_bpmInfoCount)
                      }
                    }}
                    placeholder={"BPM 설명"}
                    className="textarea textarea-bordered textarea-md w-full "
                    maxLength={140}
                  >
        
                  </textarea>
                  <div className=" flex">
                    <div className=" flex-1"/>
                    <div>{bpmInfoCount}/140 byte</div>
                  </div>
                </>
                // <FormInput
                //   placeholder="BPM 설명"
                //   type="textarea"
                //   register={register("bpmInfo")}
                // />
              )}
            </FormBox>
            :null
          }
          <FormBox
            title="수업 코멘트"
            required={false}
            icon={<FontAwesomeIcon icon={faBook} />}
          >
            {readOnly ? (
              watch("comment")
            ) : (
              <FormInput
                placeholder="수업 코멘트"
                type="textarea"
                register={register("comment")}
              />
            )}
          </FormBox>

          {!readOnly && (
            <div className="w-full flex flex-col justify-center mt-5">
              <Button
                loading={loading}
                title={`프로그램  ${programId ? "수정" : "등록"}`}
                onClick={handleSubmit(onValid)}
                iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import axios from "axios";
import { dateTimeToDateString, groupBy } from "../libs/utils";

export const getScheduleData = async (
  scheduleId: string,
  withCategory = false
) => {

  const { data } = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}api/public/branch/schedule/${scheduleId}`,
    {
      params: { withCategory },
    }
  );
  console.log('getScheduleData', data)
  return data.data;
};

export const getBranchSchedules = async (
  startDate: string,
  endDate: string,
  branchCode: string
) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}api/public/branch/schedule?startDate=${startDate}&endDate=${endDate}&branchCode=${branchCode}`, { withCredentials: true }
  );

  let result = [];
  for (let i = 0; i < response.data.data.length; i++) {
    result.push({
      ...response.data.data[i],
      startTime:dateTimeToDateString(response.data.data[i].startTime),
      start_time:response.data.data[i].startTime,
    })
  }

  return groupBy(result, 'startTime')
};

export const getBranchSchedulesSortTime = async (
  startDate: string,
  endDate: string,
  branchCode: string
) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}api/public/branch/schedule/sorttime?startDate=${startDate}&endDate=${endDate}&branchCode=${branchCode}`, { withCredentials: true }
  );

  let result = groupBy(response.data.data, 'startHour');
  Object.keys(result).forEach((key) => {
    console.log(key);
    result[key] = groupBy(result[key], 'startDate');
  });

  return result;
};

import { useForm } from "react-hook-form";
import axios from "axios";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCirclePlus,
  faCode,
  faHouse,
  faIdCard,
  faKey,
  faUser,
  faGrip,
  faImage,
  faPalette,
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../../components/Layout/AdminLayout";
import { Pagination } from "../../components/interface/Pagination";
import { useEffect, useState } from "react";
import Modal from "../../components/interface/Modal";
import Radio from "../../components/interface/Radio";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { StringToDate } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { BrandDto } from "../../types/dto";
import { uploadImageToServer } from "../../services/VimeoUpload";
import { useUserStore } from "../../store";
import { checkRanderAdminMenu } from "../../constants/AdminMenu";

interface BrandFormData {
  id?: number;
  name: string;
  type: string;
  logoFile: File[];
  logo_url: string;
  color: string;
}

export default function AdminBrand() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const [mutate, setMutate] = useState(false);
  const [imageUrl, setImageUrl] = useState<string>();

  useEffect(() => {
    if(user)
    {
      if(!checkRanderAdminMenu(user, '/admin/brand'))
      {
        navigate(`/admin/category`);
        return;
      }
    }
  }, []);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<BrandFormData>();

  const tableHeader = [
    { title: "No." },
    { title: "브랜드명" },
    { title: "분할" },
    { title: "로고" },

    { title: "컬러" },
    { title: "지점수" },
    { title: "지점관리" },
    { title: "관리" },
  ]
  //const tableHeader = ["No.", "지점명", "지점코드", "수정"];
  const showModal = () => {
    reset();
    window.my_modal_2.showModal();
  };

  const closeModal = () => {
    window.my_modal_2.close();
  };

  const onValid = async (formData: BrandFormData) => {
    try {
      if (formData.id) {
        if (formData.id && (formData.logoFile && formData.logoFile[0])) {
          var result = window.confirm(
            "이미지 파일이 교체됩니다\n계속 진행하시겠어요?"
          );
    
          if (!result) {
            return;
          }
        }
        let logo_url = await uploadImage(formData.logoFile, imageUrl)
        const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}api/admin/brand/${formData.id}`, {
          name: formData.name,
          type: formData.type,
          color: formData.color,
          logo_url: logo_url,
        }, { withCredentials: true });
      } else {
        let logo_url = await uploadImage(formData.logoFile, imageUrl)
        const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/admin/brand`, {
          name: formData.name,
          type: formData.type,
          color: formData.color,
          logo_url: logo_url,
        }, { withCredentials: true });
      }

      setMutate(!mutate);
      closeModal();
    } catch (error: any) {
      if (error.message) {
        console.log(error);
        alert(error.response.data);
      }
      console.error(error);
    }
  };

  const openEditModal = (item: BrandDto) => {
    
    showModal();
    setValue("id", item.id);
    setValue("type", item.type.toString());
    setValue("name", item.name);
    setValue("color", item.color);
    setImageUrl(item.logo_url);

    console.log('item : ', item)
    //setValue("logo_url", 'item.logo_url');
  };

  const onDelete = async (item: BrandDto) => {
    if (item.branchCount != 0) {
      alert("사용 중인 지점이 있어 삭제 할 수 없습니다.");
      return;
    }

    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}api/admin/brand/${item.id}`, { withCredentials: true });
      console.log(response);
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  const openBrand = async (item: BrandDto) => {

    navigate(`/admin/branch?id=${item.id}&name=${item.name}`);
  }

  const logoFile = watch("logoFile");
  useEffect(() => {
    if (logoFile && logoFile[0]) {
      setImageUrl(URL.createObjectURL(logoFile[0]));
    }
  }, [logoFile]);
  

  const uploadImage = async (filelist: File[], imgurl:string | undefined) => {
    try {
      let newImageUrl = imgurl ? imgurl : undefined;
      
      if (filelist && filelist[0]) {
        try {
          newImageUrl = await uploadImageToServer(filelist[0]);
        } catch (err) {
          alert("이미지 업로드 중 문제가 발생했습니다.");
          return;
        }
      }

      return newImageUrl
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }
  };

  return (
    <>
      <Modal id="my_modal_2" title={`브랜드 ${watch("id") ? "수정" : "등록"}`}>
        <FormBox title="브랜드명" icon={<FontAwesomeIcon icon={faHouse} />}>
          <FormInput
            placeholder="FX2"
            register={register("name", {
              required: "...",
            })}
          />
        </FormBox>
        <FormBox
          title="분할"
          icon={<FontAwesomeIcon icon={faGrip} />}
        >
          <div className="flex space-x-5">
            <Radio
              title="4분할"
              value="1"
              register={register("type", {
                required: ".",
              })}
            />
            <Radio
              title="다중분할"
              value="2"
              register={register("type", {
                required: ".",
              })}
            />
          </div>
        </FormBox>
        <FormBox
          title="로고"
          icon={<FontAwesomeIcon icon={faImage} />}
        >
          <div className="flex space-x-5">
            <label htmlFor="file-upload-platformA" className="btn">
              <span className="cursor-pointer">파일 선택</span>
            </label>
            <input
              id="file-upload-platformA"
              type="file"
              accept="image/png, image/jpeg, image/gif"
              className="hidden"
              {...register("logoFile", {
                required:watch("id")?false:true,
              })}
            />
            {
              imageUrl?
              <img src={imageUrl} className="border mt-5 w-1/2" alt="preview" />
              :null
            }
          </div>
          {
            watch("logoFile")?
            <p className="mt-2">{watch("logoFile")[0]?.name}</p>
            :null
          }
        </FormBox>
        <FormBox
          title="컬러"
          icon={<FontAwesomeIcon icon={faPalette} />}
        >
          <FormInput
            type='color'
            placeholder="gangnam"
            register={register("color")}
          />
        </FormBox>
        <div className="w-full justify-center flex mt-5">
          <button
            className=" btn-primary btn w-full   text-white  "
            onClick={handleSubmit(onValid)}
          >
            <FontAwesomeIcon icon={faCirclePlus} />
            {`브랜드 ${watch("id") ? "수정" : "등록"}`}
          </button>
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            브랜드 관리
          </h1>
          <Table
            dataApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/brand`}
            buttonComponent={
              <button
                className=" btn-primary btn   text-white "
                onClick={showModal}
                // disabled
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                브랜드 등록
              </button>
            }
            headerArray={tableHeader}
            mutate={mutate}
            initSort="id"
            initDirection="asc"
          >
            {(data: BrandDto[], startIndex: number) =>
              data.map((item: BrandDto, index: number) => {
                console.log('item : ', item)
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>

                    <td>{item.name}</td>
                    <td>{item.type == 1 ? '4분할' : '다중분할'}</td>
                    <td>
                      <img src={item.logo_url} className="border h-9 w-24" alt="preview" />
                    </td>
                    <td>
                      <input disabled type="color" value={item.color}/>
                      {/* <div
                        className={`h-6 w-10 pointer-events-none bg-[${item.color}]`}
                      ></div> */}
                    </td>
                    <td>{item.branchCount}</td>
                    <td className="space-x-2">
                      
                      <button
                        onClick={() => openBrand(item)}
                        className="btn btn-xs btn-primary text-white"
                      >
                        지점관리
                      </button>
                    </td>

                    <td className="flex space-x-2">

                      <button
                        onClick={() => openEditModal(item)}
                        className="btn btn-xs"
                      >
                        수정
                      </button>
                      <button
                        onClick={() => onDelete(item)}
                        className="btn btn-xs btn-error"
                      >
                        삭제
                      </button>
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}

import AdminLayout from "../../components/Layout/AdminLayout";

import "moment/locale/ko";
import { useNavigate, useParams } from "react-router-dom";
import ScheduleCalender from "../../components/ScheduleCalender";
import ScheduleExcel from "../../components/ScheduleExcel";

export default function BranchProgramDetail() {
  const navigate = useNavigate()
  const { date, branchCode } = useParams();
  
  const onBackBtn = () => {
    navigate(`/branch/${branchCode}/program`);
  };

  console.log('date : ', date)
  return (
    <AdminLayout>
      <div className="">
        <div className="flex space-x-5 ">
          <h1
            onClick={onBackBtn}
            className="text-gray-800 font-bold text-2xl cursor-pointer"
          >
            일별 프로그램
          </h1>
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            -
          </h1>
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            {date}
          </h1>
        </div>
        <ScheduleExcel/>
      </div>
    </AdminLayout>
  );
}

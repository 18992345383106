import axios from "axios";

export const getClosestBranchScheduleId = async (branchCode: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}api/public/group/${branchCode}/closestfutureschedule`,
    { withCredentials: true }
  );

  return data;
};

export const getGroupData = async (scheduleId: string) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}api/public/group/schedule/${scheduleId}`,
    { withCredentials: true }
  );

  console.log("Get Group Data Complete! ", data);
  return data;
};

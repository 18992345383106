import { useForm } from "react-hook-form";
import axios from "axios";

import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faDumbbell,
  faHouse,
  faPersonBiking,
  faSquarePollVertical,
  faTv,
  faUser,
  faPersonWalking,
  faUserGroup,
  faBars,
  faVolumeMute,
  faVolumeUp,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { cls } from "../../libs/utils";
import { useStore } from "zustand";
import { useBranchStore, useMuteStore, useUserStore } from "../../store";
import { adminMenu, branchMenu } from "../../constants/AdminMenu";
import { Branch } from "../../types/models";
import { BranchDto } from "../../types/dto";
import { useQuery } from "@tanstack/react-query";
import { fetchBranchNameByCode, fetchBranches } from "../../services/branch";
import Modal from "../interface/Modal";
import ScreenMenu from "../Menu/ScreenMenu";

export default function ScreenBottomNav() {
  const navigate = useNavigate();
  const { branchCode } = useParams();
  const storeBranchName = useBranchStore((state) => state.branchName);
  const isMuted = useMuteStore((state) => state.isMuted); // Zustand store에서 상태 가져오기
  const toggleMute = useMuteStore((state) => state.toggleMute); // Zustand store에서 액션 가져오기

  //모달 열기
  const showModal = () => {
    //@ts-ignore
    window.screenMenu.showModal();
  };

  const onMainPage = () => {
    navigate(`/screen/${branchCode}`);
  };

  return (
    <>
      <Modal
        id="screenMenu"
        title={`${storeBranchName} 메뉴 이동`}
        closeClickedOutside={true}
        size="full"
      >
        <div className="flex justify-center  ">
          <ScreenMenu />
        </div>
      </Modal>

      <div className="w-full fixed flex bottom-0 justify-between items-center bg-gymmate  text-white p-3 ">
        <div className="flex w-full text-black items-center space-x-5">
          <h1
            onClick={onMainPage}
            className="text-white font-bold cursor-pointer "
          >
            {storeBranchName ? `BODY CHANNEL ${storeBranchName} ` : "BODY CHANNEL"}
          </h1>
        </div>
        <div className="flex items-center space-x-5">
          <button onClick={toggleMute}>
            <FontAwesomeIcon
              icon={isMuted ? faVolumeMute : faVolumeUp}
              size="lg"
            />
          </button>
          <div onClick={() => showModal()}>
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>
      </div>
    </>
  );
}

import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBook,
  faCirclePlus,
  faCircleXmark,
  faClock,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faFolder,
  faImage,
  faInfo,
  faLocation,
  faPerson,
  faPlus,
  faSign,
  faStopwatch,
  faTriangleExclamation,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import React, { useEffect, useState } from "react";

import { useUserStore } from "../store";
import FormBox from "./interface/FormBox";
import Radio from "./interface/Radio";
import FormInput from "./interface/FormInput";
import Button from "./interface/Button";
import { useNavigate, useParams } from "react-router-dom";
import { cls, getUseEditFromAuth } from "../libs/utils";
import Modal from "./interface/Modal";
import ContentsSearchModal from "./Modal/ContentsSearchModal";
import { v4 as uuidv4 } from "uuid";
import ContentsBoxWrap from "./ContentBoxDragWrap";
import ContentsGapDrag from "./ContentGapDrag";
import FullPageLoading from "./interface/FullPageLoading";
import { ProgramType } from "../constants/ProgramCategory";
import { Contents, Tool } from "../types/models";
import { BrandDto, ContentsDto, ContentsDtoWithId, CurriculumDto, ProgramCategoryDto, ProgramDto } from "../types/dto";
import { uploadImageToServer } from "../services/VimeoUpload";
import ProgramsSearchModal from "./Modal/ProgramsSearchModal";
import ProgramsSelectModal from "./Modal/ProgramsSelectModal";

interface UserFormData {
  id: number;
  programType: string;
  programCategory: string;
  brandId: number;
  curriculumId: number;
  name: string;
  totalMotions: number;
  exerciseTime: number;
  moveTime: number;
  description: string;
  floor: string;
  comment: string;
}

interface ProgramDetailProps {
  readOnly?: boolean;
  programId?: string;
}

export default function ProgramDetail({
  readOnly = false,
  programId,
}: ProgramDetailProps) {
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [totalExerciseTime, setTotalExerciseTime] = useState("");
  const [totalSeconds, setTotalSeconds] = useState(0);
  const [zone1Data, setZone1Data] = useState<ContentsDtoWithId[]>([]);
  const [zone2Data, setZone2Data] = useState<ContentsDtoWithId[]>([]);
  const [zone3Data, setZone3Data] = useState<ContentsDtoWithId[]>([]);
  const [zone1Tools, setZone1Tools] = useState<string[]>([]);
  const [zone2Tools, setZone2Tools] = useState<string[]>([]);
  const [zone3Tools, setZone3Tools] = useState<string[]>([]);
  const [getLoading, setGetLoading] = useState(false);
  const [originalZoneId, setOriginalZoneId] = useState<string>("");
  const [tutorialId, setTutorialId] = useState<number[]>([]);
  const [setsMap, setSetsMap] = useState(new Map());
  const [cadioTimesMap, setCadioTimesMap] = useState(new Map());
  const [cadioPatternsMap, setCadioPatternsMap] = useState(new Map());
  const [cadioStrangthMap, setCadioStrangthMap] = useState(new Map());
  const [badgeUrl, setBadgeUrl] = useState("");
  const [badgeFile, setBadgeFile] = useState<FileList>();

  const [programCategoryList, setProgramCategoryList] = useState<ProgramCategoryDto[]>();
  const [programCategory, setProgramCategory] = useState<ProgramCategoryDto[]>();
  
  const [curriculumList, setCurriculumList] = useState<CurriculumDto[]>();
  const [curriculum, setCurriculum] = useState<CurriculumDto[]>();

  const [brandList, setBrandList] = useState<BrandDto[]>();
  const [floorCount, setFloorCount] = useState(0);

  const mapStateAdd = (setFunc:any, key:any, value:any) => {
    setFunc((prev:any) => new Map([...prev, [key, value]]));
  };
  
  const zone = [
    {
      id: "zone1",
      title: "1구역",
      zoneData: zone1Data,
      setZoneData: setZone1Data,
      zoneTools: zone1Tools,
    },
    {
      id: "zone2",
      title: "2구역",
      zoneData: zone2Data,
      setZoneData: setZone2Data,
      zoneTools: zone2Tools,
    },
    {
      id: "zone3",
      title: "3구역",
      zoneData: zone3Data,
      setZoneData: setZone3Data,
      zoneTools: zone3Tools,
    },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<UserFormData>({
     defaultValues: {
       programCategory: ''
     }});

  const uploadImage = async () => {
    try {
      let newImageUrl = badgeUrl ? badgeUrl : '';
      
      if (badgeFile && badgeFile[0]) {
        try {
          newImageUrl = await uploadImageToServer(badgeFile[0]);
        } catch (err) {
          alert("이미지 업로드 중 문제가 발생했습니다.");
          return;
        }
      }

      return newImageUrl
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }
  };
  
  const onValid = async (formData: UserFormData) => {
    if (watch("programType") === "본운동" && watch("totalMotions") % 3 !== 0) {
      alert("전체 동작수를 3의 배수로 입력해주세요.");
      return;
    }
    if (
      watch("programType") === "본운동" &&
      Number(zone1Data.length) !== Number(watch("totalMotions")) / 3
    ) {
      alert("1구역 동작이 부족하거나 초과했어요.");
      return;
    }
    if (
      watch("programType") === "본운동" &&
      Number(zone2Data.length) !== Number(watch("totalMotions")) / 3
    ) {
      alert("2구역 동작이 부족하거나 초과했어요.");
      return;
    }
    if (
      watch("programType") === "본운동" &&
      Number(zone3Data.length) !== Number(watch("totalMotions")) / 3
    ) {
      alert("3구역 동작이 부족하거나 초과했어요.");
      return;
    }
    if (
      watch("programType") !== "본운동" &&
      Number(zone1Data.length) !== Number(watch("totalMotions"))
    ) {
      alert("동작이 부족하거나 초과했어요.");
      return;
    }
    if (watch("programType") == "본운동") {
      const _totalMotions = Number(watch("totalMotions"))

      for (let i = 0; i < (_totalMotions / 3); i++) {
        if(!isCadioCheck(0, i))
        {
          alert(`1구역 ${i+1}번째 운동 정보를 잘못 입력하였습니다.`);
          return;
        }
        if(!isCadioCheck(1, i))
        {
          alert(`2구역 ${i+1}번째 운동 정보를 잘못 입력하였습니다.`);
          return;
        }
        if(!isCadioCheck(2, i))
        {
          alert(`3구역 ${i+1}번째 운동 정보를 잘못 입력하였습니다.`);
          return;
        }
      }
    }
    // if (
    //   !(badgeFile && badgeFile[0]) && !badgeUrl
    // ) {
    //   alert("뱃지를 등록하세요.");
    //   return;
    // }
    setLoading(true);
    try {
      const zone1Ids = zone1Data.map(
        (item: ContentsDtoWithId) => item.content.id
      );
      const zone2Ids = zone2Data.map(
        (item: ContentsDtoWithId) => item.content.id
      );
      const zone3Ids = zone3Data.map(
        (item: ContentsDtoWithId) => item.content.id
      );

      const sets = [
        zone1Data.map((item: ContentsDtoWithId) => item.sets), zone2Data.map((item: ContentsDtoWithId) => item.sets), zone3Data.map((item: ContentsDtoWithId) => item.sets)
      ]
      const cadioTimes = [
        zone1Data.map((item: ContentsDtoWithId) => item.cadioTimes), zone2Data.map((item: ContentsDtoWithId) => item.cadioTimes), zone3Data.map((item: ContentsDtoWithId) => item.cadioTimes)
      ]
      const cadioPatterns = [
        zone1Data.map((item: ContentsDtoWithId) => item.cadioPatterns), zone2Data.map((item: ContentsDtoWithId) => item.cadioPatterns), zone3Data.map((item: ContentsDtoWithId) => item.cadioPatterns)
      ]
      const cadioStrength = [
        zone1Data.map((item: ContentsDtoWithId) => item.cadioStrength), zone2Data.map((item: ContentsDtoWithId) => item.cadioStrength), zone3Data.map((item: ContentsDtoWithId) => item.cadioStrength)
      ]

      const _brandName = brandList?.filter((brand:BrandDto)=>{
        if(brand.id == formData.brandId)
        {
          return true
        }

        return false;
      })
      const _curriculumName = curriculum?.filter((_curriculum:CurriculumDto)=>{
        if(_curriculum.id == formData.curriculumId)
        {
          return true
        }

        return false;
      })
      let exerciseNames = '';
      if(formData.programType != '본운동') {
        
        const zoneNames = zone1Data.map(
          (item: ContentsDtoWithId) => " " + item.content.name
        );

        exerciseNames = zoneNames.toString();
      }
      let _tutorialId = [...tutorialId]
      _tutorialId = _tutorialId.filter((id) => id !== 0)
      console.log('tutorialId : ', tutorialId)

      if (programId) {
        
        let _badgeUrl = await uploadImage();
        await axios.patch(`${process.env.REACT_APP_SERVER_URL}api/admin/program/${programId}`, {
          programType: formData.programType,
          programCategory: formData.programCategory,
          
          brandId: watch("programType") === "본운동"?formData.brandId:0,
          brandName:(_brandName && watch("programType") === "본운동")?_brandName[0].name:'',
          curriculumId: watch("programType") === "본운동"?formData.curriculumId:0,
          curriculumName:(_curriculumName && watch("programType") === "본운동")?_curriculumName[0].name:'',

          name: formData.name,
          totalMotions: formData.totalMotions,
          totalSet:
            watch("programType") === "본운동"
              ? Number(formData.totalMotions) * 2
              : Number(formData.totalMotions),
          zone1Ids: zone1Ids.toString(),
          zone2Ids: zone2Ids.toString(),
          zone3Ids: zone3Ids.toString(),
          exerciseTime: Number(formData.exerciseTime),
          moveTime: Number(formData.moveTime),
          totalExerciseTime: Number(totalSeconds),
          description: formData.description,
          comment: formData.comment,
          tutorialIds: _tutorialId.join(","),
          sets: JSON.stringify(sets),
          cadioTimes: JSON.stringify(cadioTimes),
          cadioPatterns: JSON.stringify(cadioPatterns),
          cadioStrength: JSON.stringify(cadioStrength),
          floor:formData.floor,
          badgeUrl:_badgeUrl,
          exerciseNames:exerciseNames,
        }, { withCredentials: true });
      } else {
        let _badgeUrl = await uploadImage();
        // 2. DB 저장
        await axios.post(`${process.env.REACT_APP_SERVER_URL}api/admin/program`, {
          programType: formData.programType,
          programCategory: formData.programCategory,
          
          brandId: watch("programType") === "본운동"?formData.brandId:0,
          brandName:(_brandName && watch("programType") === "본운동")?_brandName[0].name:'',
          curriculumId: watch("programType") === "본운동"?formData.curriculumId:0,
          curriculumName:(_curriculumName && watch("programType") === "본운동")?_curriculumName[0].name:'',

          name: formData.name,
          totalMotions: formData.totalMotions,
          totalSet:
            watch("programType") === "본운동"
              ? Number(formData.totalMotions) * 2
              : Number(formData.totalMotions),
          zone1Ids: zone1Ids.toString(),
          zone2Ids: zone2Ids.toString(),
          zone3Ids: zone3Ids.toString(),
          exerciseTime: Number(formData.exerciseTime),
          moveTime: Number(formData.moveTime),
          totalExerciseTime: Number(totalSeconds),
          description: formData.description,
          comment: formData.comment,
          tutorialIds: _tutorialId.join(","),
          sets: JSON.stringify(sets),
          cadioTimes: JSON.stringify(cadioTimes),
          cadioPatterns: JSON.stringify(cadioPatterns),
          cadioStrength: JSON.stringify(cadioStrength),
          floor:formData.floor,
          badgeUrl:_badgeUrl,
          exerciseNames:exerciseNames,
        }, { withCredentials: true });
      }
      navigate(`/admin/program`);
      setLoading(false);
    } catch (error: any) {
      if (error && error.response && error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
      setLoading(false);
    }
  };

  function formatTime(seconds: number): string {
    let minutes = Math.floor(seconds / 60);
    let remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  useEffect(() => {
    const exerciseTime = watch("exerciseTime") as number;
    const moveTime = watch("moveTime") as number;
    const totalMotions = watch("totalMotions") as number;

    const totalSecond =
      (Number(exerciseTime) + Number(moveTime)) *
      (watch("programType") === "본운동" ? totalMotions * 2 : totalMotions);
    setTotalSeconds(totalSecond);
    const formattedTime = formatTime(totalSecond);

    setTotalExerciseTime(formattedTime);
  }, [
    watch("exerciseTime"),
    watch("moveTime"),
    watch("totalMotions"),
    watch("programType"),
  ]);

  //모달 열기
  const showModal = (zoneId: string) => {
    if(!watch("exerciseTime") || !watch("moveTime"))
    {
      alert('라운드당 시간을 입력하세요.')
      return
    }
    if(!watch("totalMotions"))
    {
      alert('전체 동작수를 입력하세요.')
      return
    }
    //@ts-ignore
    window[zoneId]?.showModal();
  };
  
  const openProgramLoadModal = () => {
    //setRandomCategoryMutate(true);

    if(!watch("brandId")  ||  watch("brandId") == 0) {
      alert('브랜드를 먼저 선택해 주세요.')
      return;
    }

    //@ts-ignore
    window.programLoad.showModal();
  };

  const onDelete = (id: string, zoneId: string) => {
    const zoneObject = zone.find((item) => item.id === zoneId);
    const zoneData = findZoneData(zoneId);
    const zoneSetZoneData = zoneObject ? zoneObject.setZoneData : undefined;

    if (zoneSetZoneData && zoneData) {
      const updatedZoneData = zoneData.filter(
        (item: ContentsDtoWithId) => item.id !== id
      );
      zoneSetZoneData(updatedZoneData);
    }
  };

  const extractTools = (arr: ContentsDtoWithId[]) => {
    const toolNames = Array.from(
      new Set(
        arr
          .map((item: ContentsDtoWithId) =>
            item.tools.map((tool: Tool) => tool.name)
          )
          .flat()
      )
    );

    return toolNames;
  };

  //도구 추출
  useEffect(() => {
    const toolsName: string[] = extractTools(zone1Data);
    setZone1Tools(toolsName);
  }, [zone1Data]);

  //도구 추출
  useEffect(() => {
    const toolsName = extractTools(zone2Data);
    setZone2Tools(toolsName);
  }, [zone2Data]);

  //도구 추출
  useEffect(() => {
    const toolsName = extractTools(zone3Data);
    setZone3Tools(toolsName);
  }, [zone3Data]);

  const findZoneData = (id: string) => {
    const zoneItem = zone.find((item) => item.id === id);
    return zoneItem ? zoneItem.zoneData : null;
  };

  const onDuplicate = (item: ContentsDtoWithId, zoneId: string) => {
    const zoneData = findZoneData(zoneId);
    if (zoneData) {
      const zoneObject = zone.find((i) => i.id === zoneId);
      const zoneSetZoneData = zoneObject ? zoneObject.setZoneData : undefined;

      const newItem = { ...item, id: uuidv4() }; // 새로운 id로 item 복사
      const newData = [...zoneData, newItem];

      if (zoneSetZoneData) {
        zoneSetZoneData(newData);
      }
    }
  };

  //Drop시 실행되는 함수
  const moveContent = (
    draggedId: string,
    index: number,
    originalZoneId: string,
    newZoneId: string
  ) => {
    // 드래그를 시작한 구역의 데이터와 드래그 하는 아이템의 데이터를 찾습니다.
    const originalZoneData = findZoneData(originalZoneId);

    if (!originalZoneData) {
      return;
    }
    
    const draggedItemIndex = originalZoneData.findIndex(
      (item: ContentsDtoWithId) => item.id === draggedId
    );
    const draggedItem = originalZoneData[draggedItemIndex];

    // 드래그를 시작한 구역에서 데이터를 삭제합니다.
    const newOriginalData = [...originalZoneData];
    newOriginalData.splice(draggedItemIndex, 1);

    if (originalZoneId === newZoneId) {
      // 구역이 같은 경우

      // 같은 구역의 해당 Index에 데이터를 넣습니다.
      newOriginalData.splice(index, 0, draggedItem);

      // 각각의 zone에 대한 setZoneData를 찾습니다.
      const zoneObject = zone.find((item) => item.id === originalZoneId);

      // state에 최종적용합니다.
      if (zoneObject && zoneObject.setZoneData) {
        zoneObject.setZoneData(newOriginalData);
        
        for (let i = 0; i < newOriginalData.length; i++) {
          for (let j = 0; j < newOriginalData[i].sets.length; j++) {
            mapStateAdd(setSetsMap, `${originalZoneId == 'zone1'?'0':(originalZoneId == 'zone2'?'1':'2')}_${i}_${j}`, newOriginalData[i].sets[j])
          }
          for (let j = 0; j < newOriginalData[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioTimesMap, `${originalZoneId == 'zone1'?'0':(originalZoneId == 'zone2'?'1':'2')}_${i}_${j}`, newOriginalData[i].cadioTimes[j])
          }
          for (let j = 0; j < newOriginalData[i].cadioPatterns.length; j++) {
            mapStateAdd(setCadioPatternsMap, `${originalZoneId == 'zone1'?'0':(originalZoneId == 'zone2'?'1':'2')}_${i}_${j}`, newOriginalData[i].cadioPatterns[j])
          }
          for (let j = 0; j < newOriginalData[i].cadioStrength.length; j++) {
            mapStateAdd(setCadioStrangthMap, `${originalZoneId == 'zone1'?'0':(originalZoneId == 'zone2'?'1':'2')}_${i}_${j}`, newOriginalData[i].cadioStrength[j])
          }
        }
      }


    } else {
      // 구역이 변경된 경우

      // 드랍한곳 구역의 데이터를 얻습니다.
      const newZoneData = findZoneData(newZoneId);

      if (!newZoneData) {
        return;
      }

      // 해당 구역의 해당 index에 데이터를 넣습니다.
      const newNewData = [...newZoneData];
      newNewData.splice(index, 0, draggedItem);

      // 각각의 zone에 대한 setZoneData를 찾습니다.
      const originalZoneObject = zone.find(
        (item) => item.id === originalZoneId
      );
      const newZoneObject = zone.find((item) => item.id === newZoneId);

      // state에 최종적용합니다.
      if (originalZoneObject && originalZoneObject.setZoneData) {
        originalZoneObject.setZoneData(newOriginalData);

        console.log('newOriginalData : ', newOriginalData)
        for (let i = 0; i < newOriginalData.length; i++) {
          for (let j = 0; j < newOriginalData[i].sets.length; j++) {
            mapStateAdd(setSetsMap, `${originalZoneId == 'zone1'?'0':(originalZoneId == 'zone2'?'1':'2')}_${i}_${j}`, newOriginalData[i].sets[j])
          }
          for (let j = 0; j < newOriginalData[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioTimesMap, `${originalZoneId == 'zone1'?'0':(originalZoneId == 'zone2'?'1':'2')}_${i}_${j}`, newOriginalData[i].cadioTimes[j])
          }
          for (let j = 0; j < newOriginalData[i].cadioPatterns.length; j++) {
            mapStateAdd(setCadioPatternsMap, `${originalZoneId == 'zone1'?'0':(originalZoneId == 'zone2'?'1':'2')}_${i}_${j}`, newOriginalData[i].cadioPatterns[j])
          }
          for (let j = 0; j < newOriginalData[i].cadioStrength.length; j++) {
            mapStateAdd(setCadioStrangthMap, `${originalZoneId == 'zone1'?'0':(originalZoneId == 'zone2'?'1':'2')}_${i}_${j}`, newOriginalData[i].cadioStrength[j])
          }
        }
      }
      if (newZoneObject && newZoneObject.setZoneData) {
        newZoneObject.setZoneData(newNewData);
        console.log('newNewData : ', newNewData)
        for (let i = 0; i < newNewData.length; i++) {
          for (let j = 0; j < newNewData[i].sets.length; j++) {
            mapStateAdd(setSetsMap, `${newZoneId == 'zone1'?'0':(newZoneId == 'zone2'?'1':'2')}_${i}_${j}`, newNewData[i].sets[j])
          }
          for (let j = 0; j < newNewData[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioTimesMap, `${newZoneId == 'zone1'?'0':(newZoneId == 'zone2'?'1':'2')}_${i}_${j}`, newNewData[i].cadioTimes[j])
          }
          for (let j = 0; j < newNewData[i].cadioPatterns.length; j++) {
            mapStateAdd(setCadioPatternsMap, `${newZoneId == 'zone1'?'0':(newZoneId == 'zone2'?'1':'2')}_${i}_${j}`, newNewData[i].cadioPatterns[j])
          }
          for (let j = 0; j < newNewData[i].cadioStrength.length; j++) {
            mapStateAdd(setCadioStrangthMap, `${newZoneId == 'zone1'?'0':(newZoneId == 'zone2'?'1':'2')}_${i}_${j}`, newNewData[i].cadioStrength[j])
          }
        }
      }


    }
  };

  //수정의 경우, 기존 데이터 불러오기
  useEffect(() => {
    if (programId) {
      getOriginalData();
      console.log('수정의 경우, 기존 데이터 불러오기 : ', programId)
    }
    else if (programId == undefined) {
      InitListData();
      console.log('새로 생성하기 : ', programId)
    }
  }, [programId]);

  const InitListData = async ()=>{
    try {
      //--> 브랜드 리스트 셋팅
      const {data:_brandlist} = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`, {
        withCredentials: true,
      });

      let brandAuth = []
      if(user) {
        brandAuth = JSON.parse(user.brandAuth);
      }

      let brandlist = []
      for (let i = 0; i < _brandlist.length; i++) {
        if(_brandlist[i].type == 1)
        {
          if(brandAuth) {
            
            if(!getUseEditFromAuth('2', _brandlist[i].id, brandAuth)) {
              continue
            }
          }
          brandlist.push(_brandlist[i])
        }
      }
      setBrandList(brandlist);

      const {data:_programcategoryList} = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/programtype/all`, { withCredentials: true });
      setProgramCategoryList(_programcategoryList)

      const {data:_curriculumList} = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/admin/curriculum/all`, { withCredentials: true });
      setCurriculumList(_curriculumList)
      if(!programId && brandlist.length > 0) {
        setValue('brandId', brandlist[0].id);
      }
      setTimeout(()=>{
        setValue('brandId', watch('brandId'))
      }, 100)
    } catch (error) {
      console.log(error);
    }
    setGetLoading(false);
  }

  // useEffect(()=>{
  //   const _brandId = watch("brandId")
  //   if(_brandId && _brandId != 0 && programCategoryList && programCategoryList.length > 0) {
  //     let programcategory = []
  //     for (let i = 0; i < programCategoryList.length; i++) {
  //       if((programCategoryList[i].brandId == _brandId) && (programCategoryList[i].active == 1)) {
  //         programcategory.push(programCategoryList[i]);
  //       }
  //     }
  //     setProgramCategory(programcategory)
  //   }
  //   if(_brandId && _brandId != 0 && curriculumList && curriculumList.length > 0) {
  //     let _curriculum = []
  //     for (let i = 0; i < curriculumList.length; i++) {
  //       if(curriculumList[i].brandId == _brandId) {
  //         _curriculum.push(curriculumList[i]);
  //       }
  //     }
  //     setCurriculum(_curriculum)
  //   }
  //   setTimeout(()=>{
  //     setValue('programCategory', watch('programCategory'))
  //     setValue('curriculumId', watch('curriculumId'))
  //   }, 100)
    
  // }, [watch("brandId"), programCategoryList, curriculumList])
  
  useEffect(()=>{
    const _brandId = watch("brandId")

    if(_brandId && _brandId != 0 && programCategoryList && programCategoryList.length > 0) {
      let programcategory = []
      for (let i = 0; i < programCategoryList.length; i++) {
        if((programCategoryList[i].brandId == _brandId) && (programCategoryList[i].active == 1)) {
          programcategory.push(programCategoryList[i]);
        }
      }
      setProgramCategory(programcategory)
    }
    if(_brandId && _brandId != 0 && curriculumList && curriculumList.length > 0) {
      let _curriculum = []
      for (let i = 0; i < curriculumList.length; i++) {
        if(curriculumList[i].brandId == _brandId) {
          _curriculum.push(curriculumList[i]);
        }
      }
      setCurriculum(_curriculum)
    }
    setTimeout(()=>{
      setValue('programCategory', watch('programCategory'))
      setValue('curriculumId', watch('curriculumId'))
    }, 100)
    
  }, [watch("brandId")])
  
  //--> 프로그램 카데고리 선택 시 커리큘럼 셋팅
  useEffect(()=>{
    const _brandId = watch("brandId")
    const _programCategory = watch("programCategory")

    if(_brandId && _brandId != 0 && curriculumList && curriculumList.length > 0) {
      let _curriculum = []
      for (let i = 0; i < curriculumList.length; i++) {
        if(curriculumList[i].brandId == _brandId && curriculumList[i].programTypeName == _programCategory) {
          _curriculum.push(curriculumList[i]);
        }
      }
      console.log('_curriculum : ', _curriculum, watch('curriculumId'))
      setCurriculum(_curriculum)
    }
    setTimeout(()=>{
      setValue('curriculumId', watch('curriculumId'))
    }, 100)
    
  }, [programCategory, watch('programCategory'), watch('programType')])
  ///

  const getOriginalData = async () => {
    setGetLoading(true);
    try {
      await InitListData();

      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/program/${programId}`, { withCredentials: true });

      const responseData: ProgramDto = response.data;

      const programData = responseData.program;
      const zone1Data = responseData.zone1Data;
      const zone2Data = responseData.zone2Data;
      const zone3Data = responseData.zone3Data;

      setZone1Data(zone1Data);
      setZone2Data(zone2Data);
      setZone3Data(zone3Data);

      setValue("programType", programData.programType);
      setValue("brandId", programData.brandId);
      setValue("curriculumId", programData.curriculumId);
      setValue("programCategory", programData.programCategory);
      setValue("name", programData.name);
      setValue("totalMotions", programData.totalMotions);
      setValue("exerciseTime", programData.exerciseTime);
      setValue("floor", programData.floor || "");
      setValue("moveTime", programData.moveTime);
      setValue("description", programData.description || "");
      setValue("comment", programData.comment || "");

      let _floor = programData.floor?programData.floor:''
      setFloorCount(_floor.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length)

      setBadgeUrl(programData.badgeUrl || "")
      
      if (responseData.program.tutorialIds) {
        const tutorialIdsArray = responseData.program.tutorialIds
          .split(",")
          .map(Number);

        setTutorialId(tutorialIdsArray);
      }
      
      if(zone1Data && zone1Data.length > 0)
      {
        for (let i = 0; i < zone1Data.length; i++) {
          for (let j = 0; j < zone1Data[i].sets.length; j++) {
            mapStateAdd(setSetsMap, `0_${i}_${j}`,zone1Data[i].sets[j])
          }
          for (let j = 0; j < zone1Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioTimesMap, `0_${i}_${j}`,zone1Data[i].cadioTimes[j])
          }
          for (let j = 0; j < zone1Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioPatternsMap, `0_${i}_${j}`,zone1Data[i].cadioPatterns[j])
          }
          for (let j = 0; j < zone1Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioStrangthMap, `0_${i}_${j}`,zone1Data[i].cadioStrength[j])
          }
        }
      }

      if(zone2Data && zone2Data.length > 0)
      {
        for (let i = 0; i < zone2Data.length; i++) {
          for (let j = 0; j < zone2Data[i].sets.length; j++) {
            mapStateAdd(setSetsMap, `1_${i}_${j}`,zone2Data[i].sets[j])
          }
          for (let j = 0; j < zone2Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioTimesMap, `1_${i}_${j}`,zone2Data[i].cadioTimes[j])
          }
          for (let j = 0; j < zone2Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioPatternsMap, `1_${i}_${j}`,zone2Data[i].cadioPatterns[j])
          }
          for (let j = 0; j < zone2Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioStrangthMap, `1_${i}_${j}`,zone2Data[i].cadioStrength[j])
          }
        }
      }

      if(zone3Data && zone3Data.length > 0)
      {
        for (let i = 0; i < zone3Data.length; i++) {
          for (let j = 0; j < zone3Data[i].sets.length; j++) {
            mapStateAdd(setSetsMap, `2_${i}_${j}`,zone3Data[i].sets[j])
          }
          for (let j = 0; j < zone2Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioTimesMap, `2_${i}_${j}`,zone3Data[i].cadioTimes[j])
          }
          for (let j = 0; j < zone2Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioPatternsMap, `2_${i}_${j}`,zone3Data[i].cadioPatterns[j])
          }
          for (let j = 0; j < zone2Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioStrangthMap, `2_${i}_${j}`,zone3Data[i].cadioStrength[j])
          }
        }
      }
      
    } catch (error) {
      console.log(error);
    }
    setGetLoading(false);
  };

  const onAddContents = (zoneData:ContentsDtoWithId[], setZoneData:any, index:number)=>{
    for (let i = 0; i < zoneData.length; i++) {
      for (let j = 0; j < zoneData[i].sets.length; j++) {
        mapStateAdd(setSetsMap, `${index}_${i}_${j}`, zoneData[i].sets[j])
      }
      for (let j = 0; j < zoneData[i].cadioTimes.length; j++) {
        mapStateAdd(setCadioTimesMap, `${index}_${i}_${j}`, zoneData[i].cadioTimes[j])
      }
      for (let j = 0; j < zoneData[i].cadioPatterns.length; j++) {
        mapStateAdd(setCadioPatternsMap, `${index}_${i}_${j}`, zoneData[i].cadioPatterns[j])
      }
      for (let j = 0; j < zoneData[i].cadioStrength.length; j++) {
        mapStateAdd(setCadioStrangthMap, `${index}_${i}_${j}`, zoneData[i].cadioStrength[j])
      }
    }
    
    setZoneData(zoneData)
  }

  const onClickAddSet = (zoneindex:number, index:number)=>{
    console.log('onClickAddSet : ', setsMap)
    
    let temp = zone[zoneindex].zoneData[index].sets;

    let lastStr = ""
    let targetIndex = -1
    for (let i = 0; i < temp.length; i++) {
      if(temp[i] != '')
      {
        lastStr = temp[i];
      }
      if(temp[i] == '')
      {
        targetIndex = i;
        break;
      }
    }

    if(targetIndex == 0 || targetIndex == -1)
    {
      return
    }

    temp[targetIndex] = lastStr;
    zone[zoneindex].zoneData[index].sets = temp;
    zone[zoneindex].setZoneData(zone[zoneindex].zoneData)
    mapStateAdd(setSetsMap, `${zoneindex}_${index}_${targetIndex}`, lastStr)
  }
  const onClickDeleteSet = (zoneindex:number, index:number)=>{
    
    let temp = zone[zoneindex].zoneData[index];

    let targetIndex = -1
    for (let i = 0; i < temp.sets.length; i++) {
      if(temp.sets[i] != '')
      {
        targetIndex = i;
      }
      if(temp.sets[i] == '')
      {
        break;
      }
    }

    if(targetIndex == -1)
    {
      return
    }

    temp.sets[targetIndex] = "";
    zone[zoneindex].zoneData[index] = temp;

    mapStateAdd(setSetsMap, `${zoneindex}_${index}_${targetIndex}`, "")
    zone[zoneindex].setZoneData(zone[zoneindex].zoneData)

  }
  const onClickAddAero = (zoneindex:number, index:number)=>{

    let zoneData = zone[zoneindex].zoneData[index];

    let lastTime = ''
    let lastPerttern = ''
    let lastStrength = ''
    let targetIndex = -1
    for (let i = 0; i < zoneData.cadioTimes.length; i++) {
      if(zoneData.cadioTimes[i] != '' || zoneData.cadioPatterns && zoneData.cadioPatterns[i] != '' || zoneData.cadioStrength && zoneData.cadioStrength[i] != '')
      {
        lastTime = zoneData.cadioTimes[i];
        if(zoneData.cadioPatterns && zoneData.cadioPatterns[i] != '')
        {
          lastPerttern = zoneData.cadioPatterns[i];
        }
        else
        {
          lastPerttern = ""
        }
        
        if(zoneData.cadioStrength && zoneData.cadioStrength[i] != '')
        {
          lastStrength = zoneData.cadioStrength[i];
        }
        else
        {
          lastStrength = ""
        }
      }
      
      if(zoneData.cadioTimes[i] == '' && (!zoneData.cadioPatterns || zoneData.cadioPatterns[i] == '') && (!zoneData.cadioStrength || zoneData.cadioStrength[i] == ''))
      {
        targetIndex = i;
        break;
      }
    }

    if(targetIndex == 0 || targetIndex == -1)
    {
      return
    }


    if(!zoneData.cadioPatterns)
    {
      zoneData.cadioPatterns = ["", "", ""];
    }
    if(!zoneData.cadioStrength)
    {
      zoneData.cadioStrength = ["", "", ""];
    }

    zoneData.cadioTimes[targetIndex] = lastTime;
    zoneData.cadioPatterns[targetIndex] = lastPerttern;
    zoneData.cadioStrength[targetIndex] = lastStrength;
    zone[zoneindex].zoneData[index] = zoneData;
    zone[zoneindex].setZoneData(zone[zoneindex].zoneData)
    mapStateAdd(setCadioTimesMap, `${zoneindex}_${index}_${targetIndex}`, lastTime)
    mapStateAdd(setCadioPatternsMap, `${zoneindex}_${index}_${targetIndex}`, lastPerttern)
    mapStateAdd(setCadioStrangthMap, `${zoneindex}_${index}_${targetIndex}`, lastStrength)
  }

  const onClickDeleteAero = (zoneindex:number, index:number)=>{
    let zoneData = zone[zoneindex].zoneData[index];
    let targetIndex = -1
    for (let i = 0; i < zoneData.cadioTimes.length; i++) {
      
      if(zoneData.cadioTimes[i] != '' || (zoneData.cadioPatterns && zoneData.cadioPatterns[i] != '') || (zoneData.cadioStrength && zoneData.cadioStrength[i] != ''))
      {
        targetIndex = i;
      }
      if(zoneData.cadioTimes[i] == '' && (!zoneData.cadioPatterns || zoneData.cadioPatterns[i] == '') && (!zoneData.cadioStrength && zoneData.cadioStrength[i] == ''))
      {
        break;
      }
    }

    if(targetIndex == -1)
    {
      return
    }

    if(!zoneData.cadioPatterns)
    {
      zoneData.cadioPatterns = ["", "", ""];
    }
    if(!zoneData.cadioStrength)
    {
      zoneData.cadioStrength = ["", "", ""];
    }
    zoneData.cadioTimes[targetIndex] = "";
    zoneData.cadioPatterns[targetIndex] = "";
    zoneData.cadioStrength[targetIndex] = "";
    zone[zoneindex].zoneData[index] = zoneData;

    mapStateAdd(setCadioTimesMap, `${zoneindex}_${index}_${targetIndex}`, "")
    mapStateAdd(setCadioPatternsMap, `${zoneindex}_${index}_${targetIndex}`, "")
    mapStateAdd(setCadioStrangthMap, `${zoneindex}_${index}_${targetIndex}`, "")
    zone[zoneindex].setZoneData(zone[zoneindex].zoneData)

  }

  const onClickCopyRound = (zoneindex:number, index:number)=>{
    let targetZoneIndex = zoneindex+1
    let targetIndex = index
    if(zone.length == targetZoneIndex)
    {
      targetZoneIndex = 0
      targetIndex = targetIndex + 1
    }

    console.log(targetZoneIndex, targetIndex)

    if(zone[targetZoneIndex].zoneData.length < (targetIndex + 1))
    {
      return
    }

    let zoneData = zone[zoneindex].zoneData[index];
    let targetZoneData = zone[targetZoneIndex].zoneData[targetIndex];

    targetZoneData.sets = [...zoneData.sets]
    targetZoneData.cadioTimes = [...zoneData.cadioTimes]
    targetZoneData.cadioPatterns = [...zoneData.cadioPatterns]
    targetZoneData.cadioStrength = [...zoneData.cadioStrength]

    zone[targetZoneIndex].zoneData[targetIndex] = targetZoneData;
    zone[targetZoneIndex].setZoneData(zone[targetZoneIndex].zoneData)
    
    for (let i = 0; i < 3; i++) {
      mapStateAdd(setSetsMap, `${targetZoneIndex}_${targetIndex}_${i}`, setsMap.get(`${zoneindex}_${index}_${i}`))
      mapStateAdd(setCadioTimesMap, `${targetZoneIndex}_${targetIndex}_${i}`, cadioTimesMap.get(`${zoneindex}_${index}_${i}`))
      mapStateAdd(setCadioPatternsMap, `${targetZoneIndex}_${targetIndex}_${i}`, cadioPatternsMap.get(`${zoneindex}_${index}_${i}`))
      mapStateAdd(setCadioStrangthMap, `${targetZoneIndex}_${targetIndex}_${i}`, cadioStrangthMap.get(`${zoneindex}_${index}_${i}`))
    }
  }

  const isCadioCheck = (zoneindex:number, index:number)=>{
    let timevalue1 = parseInt(cadioTimesMap.get(`${zoneindex}_${index}_0`))
    let timevalue2 = parseInt(cadioTimesMap.get(`${zoneindex}_${index}_1`))
    let timevalue3 = parseInt(cadioTimesMap.get(`${zoneindex}_${index}_2`))

    if(!timevalue1) timevalue1 = 0
    if(!timevalue2) timevalue2 = 0
    if(!timevalue3) timevalue3 = 0

    if(watch('exerciseTime') != (timevalue1+timevalue2+timevalue3))
    {
      return false
    }

    let patternvalue1 = cadioPatternsMap.get(`${zoneindex}_${index}_0`)
    let patternvalue2 = cadioPatternsMap.get(`${zoneindex}_${index}_1`)
    let patternvalue3 = cadioPatternsMap.get(`${zoneindex}_${index}_2`)

    let strangthvalue1 = cadioStrangthMap.get(`${zoneindex}_${index}_0`)
    let strangthvalue2 = cadioStrangthMap.get(`${zoneindex}_${index}_1`)
    let strangthvalue3 = cadioStrangthMap.get(`${zoneindex}_${index}_2`)
    
    if((timevalue1 == 0 && (patternvalue1 || strangthvalue1)) ||
    (timevalue2 == 0 && (patternvalue2 || strangthvalue2)) ||
    (timevalue3 == 0 && (patternvalue3 || strangthvalue3)))
    {
      return false
    }
    if((timevalue1 != 0 && (!patternvalue1 || !strangthvalue1)) ||
    (timevalue2 != 0 && (!patternvalue2 || !strangthvalue2)) ||
    (timevalue3 != 0 && (!patternvalue3 || !strangthvalue3)))
    {
      return false
    }

    if((timevalue3 != 0 && (timevalue1 == 0 || timevalue2 == 0)) ||
    (patternvalue3 && (!patternvalue1 || !strangthvalue2)) ||
    (strangthvalue3 && (!strangthvalue1 || !strangthvalue2)))
    {
      return false
    }
    
    if((timevalue2 != 0 && timevalue1 == 0) ||
    (patternvalue2 && !patternvalue1) ||
    (strangthvalue2 && !strangthvalue1))
    {
      return false
    }

    return true
  }

  const isCadioTimeCheck = (zoneindex:number, index:number)=>{
    let value1 = parseInt(cadioTimesMap.get(`${zoneindex}_${index}_0`))
    let value2 = parseInt(cadioTimesMap.get(`${zoneindex}_${index}_1`))
    let value3 = parseInt(cadioTimesMap.get(`${zoneindex}_${index}_2`))

    if(!value1) value1 = 0
    if(!value2) value2 = 0
    if(!value3) value3 = 0

    return watch('exerciseTime') == (value1+value2+value3)
  }
  
  useEffect(() => {
    if (badgeFile && badgeFile[0]) {
      setBadgeUrl(URL.createObjectURL(badgeFile[0]));
    }
  }, [badgeFile]);


  const totalMotions = watch('totalMotions')
  
  const cadioPrevList = []
  for (let i = 0; i < totalMotions; i++) {
    let _zoneindex = (i % 3)
    let _index = Math.floor(i / 3)
    let value1 = cadioStrangthMap.get(`${_zoneindex}_${_index}_0`)
    let value2 = cadioStrangthMap.get(`${_zoneindex}_${_index}_1`)
    let value3 = cadioStrangthMap.get(`${_zoneindex}_${_index}_2`)

    let resultColor = 'NONE'
    if(value1 == 'FREE' || value2 == 'FREE' || value3 == 'FREE')
    {
      resultColor = 'FREE'
    }
    if(value1 == 'ROLL-UP' || value2 == 'ROLL-UP' || value3 == 'ROLL-UP')
    {
      resultColor = 'ROLL-UP'
    }
    
    if(value1 == 'ALL-OUT' || value2 == 'ALL-OUT' || value3 == 'ALL-OUT')
    {
      resultColor = 'ALL-OUT'
    }

    cadioPrevList.push(resultColor);
    cadioPrevList.push(resultColor);
  }

  const onSelectProgram = async (item:any)=> {
    
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/program/${item.id}`, { withCredentials: true });

      const responseData: ProgramDto = response.data;

      const programData = responseData.program;
      const zone1Data = responseData.zone1Data;
      const zone2Data = responseData.zone2Data;
      const zone3Data = responseData.zone3Data;

      setZone1Data(zone1Data);
      setZone2Data(zone2Data);
      setZone3Data(zone3Data);

      setValue("programType", programData.programType);
      setValue("programCategory", programData.programCategory);
      setValue("totalMotions", programData.totalMotions);
      setValue("exerciseTime", programData.exerciseTime);
      setValue("floor", programData.floor || "");
      setValue("moveTime", programData.moveTime);
      setValue("description", programData.description || "");
      setValue("comment", programData.comment || "");
      let _floor = programData.floor?programData.floor:''
      setFloorCount(_floor.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length)

      setBadgeUrl(programData.badgeUrl || "")
      
      if (responseData.program.tutorialIds) {
        const tutorialIdsArray = responseData.program.tutorialIds
          .split(",")
          .map(Number);

        setTutorialId(tutorialIdsArray);
      }

      if(zone1Data && zone1Data.length > 0)
      {
        for (let i = 0; i < zone1Data.length; i++) {
          for (let j = 0; j < zone1Data[i].sets.length; j++) {
            mapStateAdd(setSetsMap, `0_${i}_${j}`,zone1Data[i].sets[j])
          }
          for (let j = 0; j < zone1Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioTimesMap, `0_${i}_${j}`,zone1Data[i].cadioTimes[j])
          }
          for (let j = 0; j < zone1Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioPatternsMap, `0_${i}_${j}`,zone1Data[i].cadioPatterns[j])
          }
          for (let j = 0; j < zone1Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioStrangthMap, `0_${i}_${j}`,zone1Data[i].cadioStrength[j])
          }
        }
      }

      if(zone2Data && zone2Data.length > 0)
      {
        for (let i = 0; i < zone2Data.length; i++) {
          for (let j = 0; j < zone2Data[i].sets.length; j++) {
            mapStateAdd(setSetsMap, `1_${i}_${j}`,zone2Data[i].sets[j])
          }
          for (let j = 0; j < zone2Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioTimesMap, `1_${i}_${j}`,zone2Data[i].cadioTimes[j])
          }
          for (let j = 0; j < zone2Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioPatternsMap, `1_${i}_${j}`,zone2Data[i].cadioPatterns[j])
          }
          for (let j = 0; j < zone2Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioStrangthMap, `1_${i}_${j}`,zone2Data[i].cadioStrength[j])
          }
        }
      }

      if(zone3Data && zone3Data.length > 0)
      {
        for (let i = 0; i < zone3Data.length; i++) {
          for (let j = 0; j < zone3Data[i].sets.length; j++) {
            mapStateAdd(setSetsMap, `2_${i}_${j}`,zone3Data[i].sets[j])
          }
          for (let j = 0; j < zone2Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioTimesMap, `2_${i}_${j}`,zone3Data[i].cadioTimes[j])
          }
          for (let j = 0; j < zone2Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioPatternsMap, `2_${i}_${j}`,zone3Data[i].cadioPatterns[j])
          }
          for (let j = 0; j < zone2Data[i].cadioTimes.length; j++) {
            mapStateAdd(setCadioStrangthMap, `2_${i}_${j}`,zone3Data[i].cadioStrength[j])
          }
        }
      }
      
    } catch (error) {
      console.log(error);
    }
    setGetLoading(false);
  }
  
  let _brandName = "";
  if(readOnly) {
    const _brand = brandList?.filter((brand:BrandDto)=>{
      if(brand.id == watch("brandId"))
      {
        return true
      }

      return false;
    })
    if(_brand && _brand.length > 0) {
      
      _brandName = _brand[0].name;
    }
  }
  let _curriculumName = ""
  if(readOnly) {
    const _curriculum = curriculum?.filter((_curriculum:CurriculumDto)=>{
      if(_curriculum.id == watch("curriculumId"))
      {
        return true
      }
  
      return false;
    })
    if(_curriculum && _curriculum.length > 0) {
      
      _curriculumName = _curriculum[0].name;
    }
  }

  return (
    <>
    {!readOnly &&
      <Modal id='programLoad' title={`프로그램 불러오기`} size="xlarge" overflow="hidden">
        <div>
          <ProgramsSelectModal
            selectedData={onSelectProgram}
            brandId={watch('brandId')}
          />
        </div>
      </Modal>
    }
      {!readOnly &&
        zone.map((z, index) => (
          <Modal id={z.id} title={`동작 추가`} size="xlarge" overflow="hidden">
            <div>
              <ContentsSearchModal
                zoneData={z.zoneData}
                exerciseTime={watch("exerciseTime")}
                setZoneData={(zoneData)=>{
                  onAddContents(zoneData, z.setZoneData, index)
                  
                }}
              />
            </div>
          </Modal>
        ))}
      <div className="">
        <div className="bg-white drop-shadow-md p-10 ">
          {getLoading && <FullPageLoading />}
          {
            !readOnly &&
            <div className="flex justify-end">
              <button
                className=" btn-primary btn   text-white  "
                onClick={()=>{openProgramLoadModal()}}
              >
                <FontAwesomeIcon icon={faFolder} />
                프로그램 불러오기
              </button>
            </div>
          }
          {
            watch("programType") == '본운동'?
            <FormBox title="브랜드" icon={<FontAwesomeIcon icon={faSign} />}>
              {
                readOnly?
                <div className="">
                  {_brandName}
                </div>
                :
                <select
                  {...register("brandId")}
                  className="select select-bordered w-full max-w-xs"
                >
                  {brandList &&
                    brandList.map(
                      (brand: BrandDto) => (
                          <option value={brand.id}>
                            {brand.name}
                          </option>
                        )
                    )}
                </select>
              }
            </FormBox>
            :null
          }
          <FormBox
            title="프로그램 종류"
            icon={<FontAwesomeIcon icon={faLocation} />}
          >
            {
              readOnly || programId?
              <div className="">
                {watch("programType")}
              </div>
              :
              <div className="flex space-x-5">
                {ProgramType.map((program) => (
                  <Radio
                    title={program.name}
                    value={program.name}
                    register={register("programType", {
                      required: "프로그램 종류를 선택해주세요",
                    })}
                    disabled={readOnly}
                  />
                ))}
              </div>
            }
          </FormBox>
          {
            watch("programType") == '본운동'?
            <div className="flex w-full space-x-2 ">
              <div className="w-1/2">
                <FormBox title="운동타입" icon={<FontAwesomeIcon icon={faSign} />}>
                  {
                    readOnly?
                    <div className="">
                      {watch("programCategory")}
                    </div>
                    :
                    <select
                      {...register("programCategory")}
                      className="select select-bordered w-full max-w-xs"
                    >
                      {programCategory &&
                        programCategory.map(
                          (programType: ProgramCategoryDto) => {
                            return (
                              <option value={programType.name}>
                                {programType.name}
                              </option>
                            )
                          }
                        )}
                    </select>
                  }
                </FormBox>
              </div>
              <div className="w-1/2">
                <FormBox title="커리큘럼" icon={<FontAwesomeIcon icon={faSign} />}>
                  {
                    readOnly?
                    <div className="">
                      {_curriculumName}
                    </div>
                    :
                    <select
                      {...register("curriculumId")}
                      className="select select-bordered w-full max-w-xs"
                    >
                      {curriculum &&
                        curriculum.map(
                          (__curriculum: CurriculumDto) => {
                            return (
                              <option value={__curriculum.id}>
                                {__curriculum.name}
                              </option>
                            )
                          }
                        )}
                    </select>
                  }
                </FormBox>
              </div>
            </div>
            :null
          }
          {/* <FormBox
            title="프로그램 구분"
            icon={<FontAwesomeIcon icon={faLocation} />}
          >
            <div className="flex space-x-5">
              {
                programCategory?.map((item:ProgramCategoryDto, index:number)=>{
                  return (
                    <Radio
                      title={item.name}
                      value={item.name}
                      register={register("programCategory", {
                        required: "프로그램 구분을 선택해주세요",
                      })}
                      disabled={readOnly}
                    />
                  )
                })
              }
              <Radio
                title="상체"
                value="상체"
                register={register("programCategory", {
                  required: "프로그램 구분을 선택해주세요",
                })}
                disabled={readOnly}
              />
              <Radio
                title="하체"
                value="하체"
                register={register("programCategory", {
                  required: "프로그램 구분을 선택해주세요",
                })}
                disabled={readOnly}
              />

              <Radio
                title="전신"
                value="전신"
                register={register("programCategory", {
                  required: "프로그램 구분을 선택해주세요",
                })}
                disabled={readOnly}
              />

              <Radio
                title="코어/유산소"
                value="코어/유산소"
                register={register("programCategory", {
                  required: "프로그램 구분을 선택해주세요",
                })}
                disabled={readOnly}
              />

              <Radio
                title="오리지널 "
                value="오리지널 "
                register={register("programCategory", {
                  required: "프로그램 구분을 선택해주세요",
                })}
                disabled={readOnly}
              />
            </div>
          </FormBox> */}

          <div className="flex w-full space-x-2 ">
              <FormBox title="프로그램명" icon={<FontAwesomeIcon icon={faSign} />}>
                {readOnly ? (
                  watch("name")
                ) : (
                  <div className="w-2/5">
                    <FormInput
                      placeholder="프로그램명"
                      register={register("name", {
                        required: "프로그램명을 입력해주세요",
                      })}
                    />
                  </div>
                )}
              </FormBox>
              {/* <div className="w-1/2">
                <FormBox title="프로그램명" icon={<FontAwesomeIcon icon={faSign} />}>
                  {readOnly ? (
                    watch("name")
                  ) : (
                    <FormInput
                      placeholder="프로그램명"
                      register={register("name", {
                        required: "프로그램명을 입력해주세요",
                      })}
                    />
                  )}
                </FormBox>
              </div> */}
              {/* <div className="w-1/2">
                <FormBox title="프로그램 뱃지" required={false} icon={<FontAwesomeIcon icon={faImage}/>}>
                  {readOnly ? 
                  null: (
                    <div className="flex">
                      <label htmlFor="file-upload-platformA" className="btn">
                        <span className="cursor-pointer">파일 선택</span>
                      </label>
                      <input
                        id="file-upload-platformA"
                        type="file"
                        accept="image/png, image/jpeg, image/gif"
                        className="hidden"
                        onChange={e =>{setBadgeFile(e.target.files?e.target.files:undefined)}} 
                      />
                      {
                        badgeUrl?
                        <img src={badgeUrl} className="border ml-3 w-14 " alt="preview" />
                        :null
                      }
                      {badgeFile && (
                        <p className="mt-2 ml-3">{badgeFile[0]?.name}</p>
                      )}
                    </div>
                  )}
                </FormBox>
              </div> */}
          </div>
          
          <div className="flex w-full space-x-2 ">
            <div className="w-1/2">
              <FormBox
                title="라운드당 시간"
                icon={<FontAwesomeIcon icon={faClock} />}
              >
                <div className="flex items-center space-x-5">
                  <div className="flex items-center whitespace-nowrap space-x-2">
                    <p>운동</p>
                    <div className=" w-20 ">
                      {readOnly ? (
                        watch("exerciseTime")
                      ) : (
                        <FormInput
                          placeholder=""
                          type="number"
                          register={register("exerciseTime", {
                            required: "라운드당 시간을 입력해주세요",
                          })}
                        />
                      )}
                    </div>
                    <p>초</p>
                  </div>
                  <div className="flex items-center whitespace-nowrap space-x-2">
                    <p>휴식</p>
                    <div className=" w-20 ">
                      {readOnly ? (
                        watch("moveTime")
                      ) : (
                        <FormInput
                          placeholder=""
                          type="number"
                          register={register("moveTime", {
                            required: "라운드당 시간을 입력해주세요",
                          })}
                        />
                      )}
                    </div>
                    <p>초</p>
                  </div>
                </div>
              </FormBox>
            </div>
            <div className="w-1/2">
              <FormBox
                title="총 운동시간"
                required={false}
                icon={<FontAwesomeIcon icon={faClock} />}
              >
                <p className="font-bold">{totalExerciseTime}</p>
              </FormBox>
            </div>
          </div>

          <div className="flex w-full space-x-2 ">
            <div className="w-1/2">
              <FormBox
                title="전체 동작수"
                icon={<FontAwesomeIcon icon={faStopwatch} />}
              >
                <div className="flex items-center space-x-5">
                  <div
                    className={cls(
                      "w-20",
                      watch("programType") === "본운동"
                        ? watch("totalMotions") % 3 === 0
                          ? "text-black"
                          : "text-red-500"
                        : "text-black"
                    )}
                  >
                    {readOnly ? (
                      watch("totalMotions")
                    ) : (
                      <FormInput
                        placeholder="전체 동작수"
                        type="number"
                        step={watch("programType") === "본운동" ? 3 : 1}
                        register={register("totalMotions", {
                          required: "전체 동작수를 입력해주세요",
                        })}
                      />
                    )}
                  </div>
                  {!readOnly && watch("programType") === "본운동" && (
                    <p
                      className={cls(
                        "",
                        watch("totalMotions") % 3 === 0
                          ? "text-black"
                          : "text-red-500"
                      )}
                    >
                      * 3의 배수로 입력해주세요
                    </p>
                  )}
                </div>
              </FormBox>
            </div>
            <div className="w-1/2">
              <FormBox
                title="전체 라운드수"
                required={false}
                icon={<FontAwesomeIcon icon={faStopwatch} />}
              >
                <p className="font-bold">
                  {" "}
                  {watch("programType") === "본운동"
                    ? watch("totalMotions") * 2
                    : watch("totalMotions")}
                </p>
              </FormBox>
            </div>
          </div>
          {/* <FormBox title="동작" icon={<FontAwesomeIcon icon={faPerson} />}>
            <div className="overflow-x-auto">
              <table className="table">
                <tbody>
                  {(watch("programType") !== "본운동"
                    ? zone.slice(0, 1)
                    : zone
                  ).map((z, index) => (
                    <>
                      <tr>
                        <th
                          className={cls(
                            "text-center w-28 ",
                            watch("programType") === "본운동"
                              ? Number(z.zoneData.length) ===
                                Number(watch("totalMotions")) / 3
                                ? "text-green-600"
                                : "text-red-400"
                              : Number(z.zoneData.length) ===
                                Number(watch("totalMotions"))
                              ? "text-green-600"
                              : "text-red-400"
                          )}
                        >
                          {z.title}
                          <br />({z.zoneData.length} /{" "}
                          {watch("programType") === "본운동"
                            ? Math.floor(watch("totalMotions") / 3)
                            : watch("totalMotions")}
                          )
                        </th>
                        <td className="flex flex-wrap min-h-[176px]  ">
                          {z.zoneData.map(
                            (item: ContentsDtoWithId, index: number) => {
                              return (
                                <div className=" flex mb-2">
                                  <ContentsGapDrag
                                    item={item}
                                    index={index}
                                    zoneId={z.id}
                                    moveContent={moveContent}
                                    originalZoneId={originalZoneId}
                                  />
                                  <ContentsBoxWrap
                                    item={item}
                                    zoneId={z.id}
                                    onDelete={onDelete}
                                    onDuplicate={onDuplicate}
                                    readOnly={readOnly}
                                    setOriginalZoneId={setOriginalZoneId}
                                    tutorialCheckbox={
                                      watch("programType") === "본운동"
                                    }
                                    tutorialId={tutorialId}
                                    setTutorialId={setTutorialId}
                                  />
                                  {!readOnly &&
                                    z.zoneData.length - 1 === index && (
                                      <>
                                        <ContentsGapDrag
                                          item={item}
                                          index={index}
                                          zoneId={z.id}
                                          moveContent={moveContent}
                                          originalZoneId={originalZoneId}
                                        />
                                        <div
                                          onClick={() => showModal(z.id)}
                                          className="flex cursor-pointer  rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
                                        >
                                          <FontAwesomeIcon icon={faPlus} />
                                        </div>
                                      </>
                                    )}
                                </div>
                              );
                            }
                          )}
                          {z.zoneData.length === 0 && (
                            <div
                              onClick={() => showModal(z.id)}
                              className="flex ml-2 cursor-pointer rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
                            >
                              <FontAwesomeIcon icon={faPlus} />
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th className="text-center w-28 ">{z.title} 도구</th>
                        <td colSpan={2} className="">
                          {z.zoneTools.map((tool: string) => (
                            <div className="btn btn-xs m-1 bg-orange-200">
                              {tool}
                            </div>
                          ))}
                        </td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </FormBox> */}
          <FormBox title="동작" icon={<FontAwesomeIcon icon={faPerson} />}>
            <div className="overflow-x-auto">
              <table className="table">
                <tbody>
                  {
                    watch("programType") == "본운동"?
                    <>
                      <tr className=" border-none">
                        {
                          zone.map((z, index) => (
                            <th
                              className={cls(
                                "w-28",
                                watch("programType") === "본운동"
                                  ? Number(z.zoneData.length) ===
                                    Number(watch("totalMotions")) / 3
                                    ? "text-green-600"
                                    : "text-red-400"
                                  : Number(z.zoneData.length) ===
                                    Number(watch("totalMotions"))
                                  ? "text-green-600"
                                  : "text-red-400"
                                )}
                                scope="col"
                              >
                                <div className="mx-1">
                                  {z.title}({z.zoneData.length} /{" "} {Math.floor(watch("totalMotions") / 3)})
                                </div>
                            </th>
                          ))
                        }
                      </tr>
                      <tr className=" border-none ">
                        {
                          zone.map((z, index) => (
                            <td className="m-0 p-0">
                              {z.zoneTools.map((tool: string) => (
                                <div className="btn btn-xs mx-1 mb-4 bg-orange-200">
                                  {tool}
                                </div>
                              ))}
                            </td>
                          ))
                        }
                      </tr>
                      <tr className=" border-none">
                        {
                          zone.map((z, index) => (
                            <th className="p-0 m-0">
                              <div className="flex">
                                <div className="w-40 h-8 relative ml- border-b-2 text-center ml-1 mr-1 mb-0">
                                  동작
                                </div>
                                <div className=" w-16 h-8 relative ml- border-b-2 text-center ml-1 mr-1 mb-0">
                                  세트수
                                </div>
                                <div className="w-48 h-8 relative ml- border-b-2 text-center ml-1 mr-1 mb-0">
                                  유산소
                                </div>
                              </div>
                            </th>
                          ))
                        }
                      </tr>
                      <tr className="">
                        {
                          zone.map((z, zoneindex) => (
                            <td className=" justify-start align-top min-h-[176px] p-0 m-0">
                              {z.zoneData.map(
                                (item: ContentsDtoWithId, index: number) => {
                                  const cadioTime = isCadioTimeCheck(zoneindex, index)
                                  return (
                                    <div className=" mb-3">
                                      {/* 라운드 표시 */}
                                      <div className=" w-full text-right pr-24 h-5">
                                        {((index * 6) + (zoneindex *2))+1}~{((index * 6) + (zoneindex *2))+2}R
                                      </div>
                                      <div className="flex">
                                        <ContentsGapDrag
                                          item={item}
                                          index={index}
                                          zoneId={z.id}
                                          moveContent={moveContent}
                                          originalZoneId={originalZoneId}
                                        />
                                        <ContentsBoxWrap
                                          item={item}
                                          zoneId={z.id}
                                          onDelete={onDelete}
                                          onDuplicate={onDuplicate}
                                          readOnly={readOnly}
                                          setOriginalZoneId={setOriginalZoneId}
                                          tutorialCheckbox={
                                            watch("programType") === "본운동"
                                          }
                                          tutorialId={tutorialId}
                                          setTutorialId={setTutorialId}
                                        />
                                        {/* set 표시 */}
                                        <div className=" w-full h-full">
                                          <div className="flex">

                                            <div className=" w-16 h-full relative ml- text-center ml-1 mr-1 mb-0">
                                              <div className=" w-full m-1">
                                                <input
                                                  placeholder={''}
                                                  value={setsMap.get(`${zoneindex}_${index}_0`)}
                                                  className="input w-full input-xs border-[#E9E9E9]"
                                                  onChange={(e)=>{
                                                    let temp = item.sets;
                                                    if(!temp || temp.length == 0)
                                                    {
                                                      temp = ['', '', '']  
                                                    }
                                                    temp[0] = e.target.value
                                                    z.zoneData[index].sets = temp
                                                    console.log('z.zoneData[index].sets : ', z.zoneData[index].sets)
                                                    z.setZoneData(z.zoneData)
                                                    mapStateAdd(setSetsMap, `${zoneindex}_${index}_0`, e.target.value)
                                                  }}
                                                  disabled={readOnly}
                                                />
                                              </div>
                                              <div className=" w-full m-1">
                                                <input
                                                  placeholder={''}
                                                  value={setsMap.get(`${zoneindex}_${index}_1`)}
                                                  className="input w-full input-xs border-[#E9E9E9]"
                                                  onChange={(e)=>{
                                                    let temp = item.sets;
                                                    if(!temp || temp.length == 0)
                                                    {
                                                      temp = ['', '', '']  
                                                    }
                                                    temp[1] = e.target.value
                                                    z.zoneData[index].sets = temp
                                                    console.log('z.zoneData[index].sets : ', z.zoneData[index].sets)
                                                    z.setZoneData(z.zoneData)
                                                    mapStateAdd(setSetsMap, `${zoneindex}_${index}_1`, e.target.value)
                                                  }}
                                                  disabled={readOnly}
                                                />
                                              </div>
                                              <div className=" w-full m-1">
                                                <input
                                                  placeholder={''}
                                                  value={setsMap.get(`${zoneindex}_${index}_2`)}
                                                  className="input w-full input-xs border-[#E9E9E9]"
                                                  onChange={(e)=>{
                                                    let temp = item.sets;
                                                    if(!temp || temp.length == 0)
                                                    {
                                                      temp = ['', '', '']  
                                                    }
                                                    temp[2] = e.target.value
                                                    z.zoneData[index].sets = temp
                                                    console.log('z.zoneData[index].sets : ', z.zoneData[index].sets)
                                                    z.setZoneData(z.zoneData)
                                                    mapStateAdd(setSetsMap, `${zoneindex}_${index}_2`, e.target.value)
                                                  }}
                                                  disabled={readOnly}
                                                />
                                              </div>
                                            </div>
                                            {/* 유산소 표시 */}
                                            <div className="w-50 h-full relative text-center mr-1 ml-1 mb-0 flex">
                                              {/* 시간 표시 */}
                                              <div className=" w-10 h-full mr-1">
                                                <div className=" w-full m-1">
                                                  <input
                                                    placeholder={''}
                                                    value={cadioTimesMap.get(`${zoneindex}_${index}_0`)}
                                                    className={cadioTime?`input w-full input-xs border border-[#E9E9E9]`:`input w-full input-xs border border-[#E9E9E9] text-red-600`}
                                                    onChange={(e)=>{
                                                      var regex = /[^0-9]/g;
                                                      var result = e.target.value.replace(regex, "");
                                                      let temp = item.cadioTimes;
                                                      if(!temp || temp.length == 0)
                                                      {
                                                        temp = ['', '', '']  
                                                      }
                                                      temp[0] = result
                                                      z.zoneData[index].cadioTimes = temp
                                                      z.setZoneData(z.zoneData)
                                                      mapStateAdd(setCadioTimesMap, `${zoneindex}_${index}_0`, result)
                                                    }}
                                                    disabled={readOnly}
                                                  />
                                                </div>
                                                <div className=" w-full m-1">
                                                  <input
                                                    placeholder={''}
                                                    value={cadioTimesMap.get(`${zoneindex}_${index}_1`)}
                                                    className={cadioTime?`input w-full input-xs border border-[#E9E9E9]`:`input w-full input-xs border border-[#E9E9E9] text-red-600`}
                                                    onChange={(e)=>{
                                                      var regex = /[^0-9]/g;
                                                      var result = e.target.value.replace(regex, "");
                                                      let temp = item.cadioTimes;
                                                      if(!temp || temp.length == 0)
                                                      {
                                                        temp = ['', '', '']  
                                                      }
                                                      temp[1] = result
                                                      z.zoneData[index].cadioTimes = temp
                                                      z.setZoneData(z.zoneData)
                                                      mapStateAdd(setCadioTimesMap, `${zoneindex}_${index}_1`, result)
                                                    }}
                                                    disabled={readOnly}
                                                  />
                                                </div>
                                                <div className=" w-full m-1">
                                                  <input
                                                    placeholder={''}
                                                    value={cadioTimesMap.get(`${zoneindex}_${index}_2`)}
                                                    className={cadioTime?`input w-full input-xs border border-[#E9E9E9]`:`input w-full input-xs border border-[#E9E9E9] text-red-600`}
                                                    onChange={(e)=>{
                                                      var regex = /[^0-9]/g;
                                                      var result = e.target.value.replace(regex, "");
                                                      let temp = item.cadioTimes;
                                                      if(!temp || temp.length == 0)
                                                      {
                                                        temp = ['', '', '']  
                                                      }
                                                      temp[2] = result
                                                      z.zoneData[index].cadioTimes = temp
                                                      z.setZoneData(z.zoneData)
                                                      mapStateAdd(setCadioTimesMap, `${zoneindex}_${index}_2`, result)
                                                    }}
                                                    disabled={readOnly}
                                                  />
                                                </div>
                                              </div>
                                              {/* 패턴 표시 */}
                                              <div className=" w-17 h-full">
                                                <div className=" w-full m-1">
                                                  <select
                                                    className=" w-17 select-xs text-xs appearance-none border select border-[#E9E9E9]"
                                                    value={cadioPatternsMap.get(`${zoneindex}_${index}_0`)}
                                                    onChange={(e)=>{
                                                      let temp = item.cadioPatterns;
                                                      if(!temp || temp.length == 0)
                                                      {
                                                        temp = ['', '', '']  
                                                      }
                                                      temp[0] = e.target.value
                                                      z.zoneData[index].cadioPatterns = temp
                                                      z.setZoneData(z.zoneData)
                                                      mapStateAdd(setCadioPatternsMap, `${zoneindex}_${index}_0`, e.target.value)
                                                    }}
                                                    disabled={readOnly}
                                                  >
                                                    <option value={''}>
                                                    </option>
                                                    <option value={'PUSH'}>
                                                      {'PUSH'}
                                                    </option>
                                                    <option value={'PULL'}>
                                                      {'PULL'}
                                                    </option>
                                                    <option value={'LEG'}>
                                                      {'LEG'}
                                                    </option>
                                                  </select>
                                                </div>
                                                <div className=" w-full m-1">
                                                  <select
                                                    className=" w-17 select-xs text-xs appearance-none border select border-[#E9E9E9]"
                                                    value={cadioPatternsMap.get(`${zoneindex}_${index}_1`)}
                                                    onChange={(e)=>{
                                                      let temp = item.cadioPatterns;
                                                      if(!temp || temp.length == 0)
                                                      {
                                                        temp = ['', '', '']  
                                                      }
                                                      temp[1] = e.target.value
                                                      z.zoneData[index].cadioPatterns = temp
                                                      z.setZoneData(z.zoneData)
                                                      mapStateAdd(setCadioPatternsMap, `${zoneindex}_${index}_1`, e.target.value)
                                                    }}
                                                    disabled={readOnly}
                                                  >
                                                    <option value={''}>
                                                    </option>
                                                    <option value={'PUSH'}>
                                                      {'PUSH'}
                                                    </option>
                                                    <option value={'PULL'}>
                                                      {'PULL'}
                                                    </option>
                                                    <option value={'LEG'}>
                                                      {'LEG'}
                                                    </option>
                                                  </select>
                                                </div>
                                                <div className=" w-full m-1">
                                                  <select
                                                    className=" w-17 select-xs text-xs appearance-none border select border-[#E9E9E9]"
                                                    value={cadioPatternsMap.get(`${zoneindex}_${index}_2`)}
                                                    onChange={(e)=>{
                                                      let temp = item.cadioPatterns;
                                                      if(!temp || temp.length == 0)
                                                      {
                                                        temp = ['', '', '']  
                                                      }
                                                      temp[2] = e.target.value
                                                      z.zoneData[index].cadioPatterns = temp
                                                      z.setZoneData(z.zoneData)
                                                      mapStateAdd(setCadioPatternsMap, `${zoneindex}_${index}_2`, e.target.value)
                                                    }}
                                                    disabled={readOnly}
                                                  >
                                                    <option value={''}>
                                                    </option>
                                                    <option value={'PUSH'}>
                                                      {'PUSH'}
                                                    </option>
                                                    <option value={'PULL'}>
                                                      {'PULL'}
                                                    </option>
                                                    <option value={'LEG'}>
                                                      {'LEG'}
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                              {/* 강도 표시 */}
                                              <div className=" w-16 h-full">
                                                <div className=" w-full m-1">
                                                  <select
                                                    className=" w-full select-xs text-xs appearance-none border select border-[#E9E9E9]"
                                                    value={cadioStrangthMap.get(`${zoneindex}_${index}_0`)}
                                                    onChange={(e)=>{
                                                      let temp = item.cadioStrength;
                                                      if(!temp || temp.length == 0)
                                                      {
                                                        temp = ['', '', '']  
                                                      }
                                                      temp[0] = e.target.value
                                                      z.zoneData[index].cadioStrength = temp
                                                      z.setZoneData(z.zoneData)
                                                      mapStateAdd(setCadioStrangthMap, `${zoneindex}_${index}_0`, e.target.value)
                                                    }}
                                                    disabled={readOnly}
                                                  >
                                                    <option value={''}>
                                                    </option>
                                                    <option value={'FREE'}>
                                                      {'FREE'}
                                                    </option>
                                                    <option value={'ROLL-UP'}>
                                                      {'ROLL-UP'}
                                                    </option>
                                                    <option value={'ALL-OUT'}>
                                                      {'ALL-OUT'}
                                                    </option>
                                                  </select>
                                                </div>
                                                <div className=" w-full m-1">
                                                  <select
                                                    className=" w-full select-xs text-xs appearance-none border select border-[#E9E9E9]"
                                                    value={cadioStrangthMap.get(`${zoneindex}_${index}_1`)}
                                                    onChange={(e)=>{
                                                      let temp = item.cadioStrength;
                                                      if(!temp || temp.length == 0)
                                                      {
                                                        temp = ['', '', '']  
                                                      }
                                                      temp[1] = e.target.value
                                                      z.zoneData[index].cadioStrength = temp
                                                      z.setZoneData(z.zoneData)
                                                      mapStateAdd(setCadioStrangthMap, `${zoneindex}_${index}_1`, e.target.value)
                                                    }}
                                                    disabled={readOnly}
                                                  >
                                                    <option value={''}>
                                                    </option>
                                                    <option value={'FREE'}>
                                                      {'FREE'}
                                                    </option>
                                                    <option value={'ROLL-UP'}>
                                                      {'ROLL-UP'}
                                                    </option>
                                                    <option value={'ALL-OUT'}>
                                                      {'ALL-OUT'}
                                                    </option>
                                                  </select>
                                                </div>
                                                <div className=" w-full m-1">
                                                  <select
                                                    className=" w-full select-xs text-xs appearance-none border select border-[#E9E9E9]"
                                                    value={cadioStrangthMap.get(`${zoneindex}_${index}_2`)}
                                                    onChange={(e)=>{
                                                      let temp = item.cadioStrength;
                                                      if(!temp || temp.length == 0)
                                                      {
                                                        temp = ['', '', '']  
                                                      }
                                                      temp[2] = e.target.value
                                                      z.zoneData[index].cadioStrength = temp
                                                      z.setZoneData(z.zoneData)
                                                      mapStateAdd(setCadioStrangthMap, `${zoneindex}_${index}_2`, e.target.value)
                                                    }}
                                                    disabled={readOnly}
                                                  >
                                                    <option value={''}>
                                                    </option>
                                                    <option value={'FREE'}>
                                                      {'FREE'}
                                                    </option>
                                                    <option value={'ROLL-UP'}>
                                                      {'ROLL-UP'}
                                                    </option>
                                                    <option value={'ALL-OUT'}>
                                                      {'ALL-OUT'}
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          {/* 버튼 */}
                                          {
                                            !readOnly?
                                            <div className="flex m-1 mt-4 w-full">
                                              <div className=" flex w-20">
                                                <div
                                                  onClick={()=>{onClickAddSet(zoneindex, index)}}
                                                  className="btn btn-xs m-1 mr-0 bg-[#F3F4F6] font-medium p-1"
                                                >
                                                  추가
                                                </div>
                                                <div
                                                  onClick={()=>{onClickDeleteSet(zoneindex, index)}}
                                                  className="btn btn-xs m-1 bg-[#F3F4F6] font-medium p-1"
                                                >
                                                  삭제
                                                </div>
                                              </div>
                                              <div className=" flex w-24 ml-2">
                                                <div
                                                  onClick={()=>{onClickAddAero(zoneindex, index)}}
                                                  className="btn btn-xs m-1 mr-0 bg-[#F3F4F6] font-medium"
                                                >
                                                  추가
                                                </div>
                                                <div
                                                  onClick={()=>{onClickDeleteAero(zoneindex, index)}}
                                                  className="btn btn-xs m-1 bg-[#F3F4F6] font-medium"
                                                >
                                                  삭제
                                                </div>
                                              </div>
                                              <div className=" flex w-24">
                                                <div
                                                  onClick={()=>{onClickCopyRound(zoneindex, index)}}
                                                  className="btn btn-xs m-1 mr-0 bg-[#E2E9E9] font-medium"
                                                >
                                                  라운드 복사
                                                </div>
                                              </div>
                                            </div>
                                            :null
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              )}
                              {
                                z.zoneData.length === 0?
                                <div
                                  onClick={() => showModal(z.id)}
                                  className=" m-2 flex ml-2 cursor-pointer rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </div>
                                :
                                !readOnly?<>
                                <ContentsGapDrag
                                  item={z.zoneData[z.zoneData.length - 1]}
                                  index={z.zoneData.length - 1}
                                  zoneId={z.id}
                                  moveContent={moveContent}
                                  originalZoneId={originalZoneId}
                                />
                                <div
                                  onClick={() => showModal(z.id)}
                                  className=" m-2 flex cursor-pointer  rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </div>
                              </>
                                :null
                              }
                            </td>
                          ))
                        }
                      </tr>
                    </>
                    :
                    <>
                    {
                      zone.slice(0, 1).map((z, index) => (
                        <>
                          <tr>
                            <th
                              className={cls(
                                "text-center w-28 ",
                                watch("programType") === "본운동"
                                  ? Number(z.zoneData.length) ===
                                    Number(watch("totalMotions")) / 3
                                    ? "text-green-600"
                                    : "text-red-400"
                                  : Number(z.zoneData.length) ===
                                    Number(watch("totalMotions"))
                                  ? "text-green-600"
                                  : "text-red-400"
                              )}
                            >
                              {z.title}
                              <br />({z.zoneData.length} /{" "}
                              {watch("programType") === "본운동"
                                ? Math.floor(watch("totalMotions") / 3)
                                : watch("totalMotions")}
                              )
                            </th>
                            <td className="flex flex-wrap min-h-[176px]  ">
                              {z.zoneData.map(
                                (item: ContentsDtoWithId, index: number) => {
                                  return (
                                    <div className=" flex mb-2">
                                      <ContentsGapDrag
                                        item={item}
                                        index={index}
                                        zoneId={z.id}
                                        moveContent={moveContent}
                                        originalZoneId={originalZoneId}
                                      />
                                      <ContentsBoxWrap
                                        item={item}
                                        zoneId={z.id}
                                        onDelete={onDelete}
                                        onDuplicate={onDuplicate}
                                        readOnly={readOnly}
                                        setOriginalZoneId={setOriginalZoneId}
                                        tutorialCheckbox={
                                          watch("programType") === "본운동"
                                        }
                                        tutorialId={tutorialId}
                                        setTutorialId={setTutorialId}
                                      />
                                      {!readOnly &&
                                        z.zoneData.length - 1 === index && (
                                          <>
                                            <ContentsGapDrag
                                              item={item}
                                              index={index}
                                              zoneId={z.id}
                                              moveContent={moveContent}
                                              originalZoneId={originalZoneId}
                                            />
                                            <div
                                              onClick={() => showModal(z.id)}
                                              className="flex cursor-pointer  rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
                                            >
                                              <FontAwesomeIcon icon={faPlus} />
                                            </div>
                                          </>
                                        )}
                                    </div>
                                  );
                                }
                              )}
                              {z.zoneData.length === 0 && (
                                <div
                                  onClick={() => showModal(z.id)}
                                  className="flex ml-2 cursor-pointer rounded-md  w-40 h-36 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
                                >
                                  <FontAwesomeIcon icon={faPlus} />
                                </div>
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th className="text-center w-28 ">{z.title} 도구</th>
                            <td colSpan={2} className="">
                              {z.zoneTools.map((tool: string) => (
                                <div className="btn btn-xs m-1 bg-orange-200">
                                  {tool}
                                </div>
                              ))}
                            </td>
                          </tr>
                        </>
                      ))}
                    </>
                  }
                </tbody>
              </table>
            </div>
          </FormBox>
          {
            watch("programType") === "본운동"?
            <FormBox
              title="유산소 미리보기"
              required={false}
              icon={<FontAwesomeIcon icon={faImage} />}
            >
              {/* <div className="btn btn-sm m-1 bg-[#F3F4F6]">
                {'유산소 라운드 설정 미리보기'}
              </div> */}
              <div className="flex">
                {
                  cadioPrevList.map((cadioItem, cadioIndex)=>{

                    return (
                      <div className="ml-2 items-center">
                        <div className=" text-center text-xs">{cadioIndex +1}</div>
                        {
                          cadioItem == 'FREE'?
                          <div className={` w-4 h-7 bg-[#00C7F2]`}/>
                          :
                          cadioItem == 'ROLL-UP'?
                          <div className={` w-4 h-7 bg-[#FE06F2]`}/>
                          :
                          cadioItem == 'ALL-OUT'?
                          <div className={` w-4 h-7 bg-[#FF0000]`}/>
                          :
                          <div className={` w-4 h-7 bg-[#D2D2D2]`}/>
                        }
                      </div>
                    )
                  })
                }
              </div>
            </FormBox>
            :null
          }
          <FormBox
            title="FLOOR"
            required={false}
            icon={<FontAwesomeIcon icon={faInfo} />}
          >
            {readOnly ? (
              watch("floor")
            ) : (
              <>
                <textarea
                  //{...register("floor")}
                  value={watch("floor")}
                  onChange={(e)=>{
                    let _floor = e.currentTarget.value
                    const _floorCount = _floor.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
                    if(_floorCount <= 140) {
                      setValue("floor", e.currentTarget.value)
                      setFloorCount(_floorCount)
                    }
                  }}
                  placeholder={"FLOOR"}
                  className="textarea textarea-bordered textarea-md w-full "
                  maxLength={140}
                >
      
                </textarea>
                <div className=" flex">
                  <div className=" flex-1"/>
                  <div>{floorCount}/140 byte</div>
                </div>
              </>
              // <FormInput
              //   placeholder="FLOOR"
              //   type="textarea"
              //   register={register("floor")}
              // />
            )}
          </FormBox>
          <FormBox
            title="프로그램 설명"
            required={false}
            icon={<FontAwesomeIcon icon={faBook} />}
          >
            {readOnly ? (
              watch("description")
            ) : (
              <FormInput
                placeholder="설명"
                type="textarea"
                register={register("description")}
              />
            )}
          </FormBox>

          <FormBox
            title="수업 코멘트"
            required={false}
            icon={<FontAwesomeIcon icon={faBook} />}
          >
            {readOnly ? (
              watch("comment")
            ) : (
              <FormInput
                placeholder="수업 코멘트"
                type="textarea"
                register={register("comment")}
              />
            )}
          </FormBox>

          {!readOnly && (
            <div className="w-full flex flex-col justify-center mt-5">
              <Button
                loading={loading}
                title={`프로그램  ${programId ? "수정" : "등록"}`}
                onClick={handleSubmit(onValid)}
                iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

import AdminLayout from "../../../components/Layout/AdminLayout";
import { useEffect, useState } from "react";
import axios from "axios";
import { SecondsToMinutes, StringToDate, getUseEditFromAuth } from "../../../libs/utils";
import Table from "../../../components/interface/Table";
import { BrandDto, ProgramDto } from "../../../types/dto";
import Modal from "../../../components/interface/Modal";
import { NoticeDto } from "../../../types/models";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "../../../store";

export default function Notice() {
  const user = useUserStore((state) => state.user);
  const [mutate, setMutate] = useState(false);
  const [noticeId, setNoticeId] = useState(0);
  const [brandData, setBrandData] = useState<BrandDto[]>();
  const [brandAuth, setBrandAuth] = useState<any[]>();
  const navigate = useNavigate();

  const showNoticeDetailModal = (id: number) => {
    setNoticeId(id);
    //@ts-ignore
    window.noticeDetail.showModal();
  };

  useEffect(() => {
    const getBrands = async () => {
      try {
        const getbrand = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`, {
          withCredentials: true,
        });
        setBrandData(getbrand.data)
      } catch (error) {
      }
    };

    getBrands()
  }, []);
  useEffect(() => {
    if(user) {
      const _brandAuth = JSON.parse(user.brandAuth);
      if(_brandAuth)
      {
        setBrandAuth(_brandAuth)
      }
    }
  }, [user]);

  
  const searchData = [
    { title: "브랜드", value: brandData, searchKey: "id", searchFiled: "brandIds" }
  ];

  const onAddBtn = () => {
    navigate(`/admin/notice/add`);
  };

  const onEditNotice = (id: number) => {
    navigate(`/admin/notice/edit/${id}`);
  };

  const onDelete = async (item: any) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}api/admin/notice/${item.id}`, { withCredentials: true });
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  return (
    <>
      <Modal
        id="noticeDetail"
        title={`운동 프로그램 안내 상세보기`}
        size="2xlarge"
        overflow="hidden"
      >
        <div className="mt-5">
          {/* <ProgramDetail programId={programId} readOnly={true} /> */}
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">{'운동 프로그램 안내 관리'}</h1>
          
          <Table
            dataApiUrl={`${process.env.REACT_APP_SERVER_URL}api/public/notice`}
            buttonComponent={
              <button
                className=" btn-primary btn   text-white  "
                onClick={onAddBtn}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                운동 프로그램 안내 등록
              </button>
            }
            headerArray={[
              { title: "No." },
              { title: "등록일" },
              { title: "브랜드" },
              { title: "운영기간" },
              { title: "게시물 명" },
              { title: "관리"},
            ]}
            mutate={mutate}
            initSort="createdAt"
            initDirection="desc"
            searchData={searchData}
            searchApiUrl={`${process.env.REACT_APP_SERVER_URL}api/public/notice/search`}
            searchTime={true}
          >
            {(data: NoticeDto[], startIndex: number) =>
              data.map((item: any, index: number) => {
                // console.log(item);
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>
                    <td>
                      <StringToDate dateString={item?.createdAt} />
                    </td>
                    <td>{item?.brandName}</td>
                    <td>{item?.startTime}{` ~ `}{item?.endTime}</td>
                    <td
                    >
                      {item?.name}
                    </td>
                    <td className="flex space-x-2">
                      <>
                        <button
                          onClick={() => onEditNotice(item.id)}
                          className="btn btn-xs"
                          disabled={!getUseEditFromAuth('9', item?.brandId, brandAuth || [])}
                        >
                          수정
                        </button>
                        <button
                          onClick={() => onDelete(item)}
                          className="btn btn-xs btn-error"
                          disabled={!getUseEditFromAuth('9', item?.brandId, brandAuth || [])}
                        >
                          삭제
                        </button>
                      </>
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}

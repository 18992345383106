import { useForm } from "react-hook-form";
import axios from "axios";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faCirclePlus,
  faHouse,
  faIdCard,
  faKey,
  faTriangleExclamation,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../../components/Layout/AdminLayout";
import { Pagination } from "../../components/interface/Pagination";
import { useEffect, useState } from "react";
import Modal from "../../components/interface/Modal";
import Radio from "../../components/interface/Radio";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { StringToDate, cls, getUseEditFromAuth } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { useUserStore } from "../../store";
import { Part, Programtype, Tool } from "../../types/models";
import { checkRanderAdminMenu } from "../../constants/AdminMenu";
import BrandLayout from "../../components/Layout/BrandLayout";
import { BrandDto } from "../../types/dto";

interface ProgramTypeFormData {
  id: number;
  name: string;
  active: string;
  brandId: number;
}

export default function ProgramType() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const [mutate, setMutate] = useState(false);
  const [searchData, setSearchData] = useState<any[]>();
  const [brandList, setBrandList] = useState<any[]>();
  const [brandAuth, setBrandAuth] = useState<any[]>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<ProgramTypeFormData>({
    defaultValues: {
      active: "true",
    },
  });

  const tableHeader = [
    { title: "No." },
    { title: "브랜드" },
    { title: "운동 타입" },
    { title: "사용여부" },
  ];

  useEffect(() => {
    if(user)
    {
      if(!checkRanderAdminMenu(user, '/admin/programtype'))
      {
        navigate(`/admin/contents`);
        return;
      }
    }
    const getBrands = async () => {
      try {
        const getbrand = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`, {
          withCredentials: true,
        });

        let searchList = []
        for (let i = 0; i < getbrand.data.length; i++) {
          searchList.push({
            name: getbrand.data[i].name,
            value: getbrand.data[i].id
          })
        }

        setBrandList(getbrand.data)

        let SearchDatalayout = [
          {
            title: "브랜드 종류",
            value: searchList,
            searchKey: "value",
            searchFiled: "brandId",
          }
        ];

        console.log('SearchDatalayout : ', SearchDatalayout)
        setSearchData(SearchDatalayout)
      } catch (error) {
      }
    };

    getBrands()
  }, []);
  useEffect(() => {
    if(user) {
      const _brandAuth = JSON.parse(user.brandAuth);
      if(_brandAuth)
      {
        setBrandAuth(_brandAuth)
      }
    }
  }, [user]);
  
  const showModal = () => {
    reset();
    window.my_modal_2.showModal();
  };

  const closeModal = () => {
    reset();
    window.my_modal_2.close();
  };

  const onValid = async (formData: ProgramTypeFormData) => {
    try {
      const _brandName = brandList?.filter((brand:BrandDto)=>{
        if(brand.id == formData.brandId)
        {
          return true
        }

        return false;
      })
      const programType = {
        name: formData.name,
        active: formData.active,
        brandId: formData.brandId,
        brandName:_brandName?_brandName[0].name:''
      };

      const url = ``;

      if (formData.id) {
        await axios.patch(`${process.env.REACT_APP_SERVER_URL}api/admin/programtype/${formData.id}`, programType, { withCredentials: true });
      } else {
        await axios.post(`${process.env.REACT_APP_SERVER_URL}api/admin/programtype`, programType, { withCredentials: true });
      }
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }

    closeModal();
    setMutate(!mutate);
  };

  const openEditModal = (item: Programtype) => {
    showModal();
    setValue("id", item.id);
    setValue("name", item.name);
    setValue("active", item.active.toString());
    setValue("brandId", item.brandId);
  };

  const onDelete = async (item: ProgramTypeFormData) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}api/admin/programtype/${item.id}`, { withCredentials: true });
      closeModal();
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  return (
    <>
      <Modal
        id="my_modal_2"
        title={`운동타입 ${
          watch("id") ? "수정" : "등록"
        }`}
      >
        <FormBox
          title={"브랜드"}
          required={false}
          icon={<FontAwesomeIcon icon={faUser} />}
        >
            <select
              {...register("brandId")}
              className="select select-bordered w-full max-w-xs"
            >
              {brandList &&
                brandList.map(
                  (brand: BrandDto) => {
                    if(!getUseEditFromAuth('7', brand.id, brandAuth || [])) {
                      return null
                    }
                    return (
                      <option value={brand.id}>
                        {brand.name}
                      </option>
                    )
                  }
                )}
            </select>
        </FormBox>
              
        <FormBox
          title={"운동타입명"}
          icon={<FontAwesomeIcon icon={faUser} />}
        >
          <FormInput
            placeholder={"운동타입명"}
            register={register("name", {
              required: `운동타입명을 입력해주세요`,
            })}
          />
        </FormBox>
        <FormBox title="사용여부" icon={<FontAwesomeIcon icon={faBuilding} />}>
          <div className="flex space-x-5">
            <Radio
              title="사용"
              value={true}
              register={register("active", {
                required: "사용여부를 선택해주세요",
              })}
            />
            <Radio
              title="미사용"
              value={false}
              register={register("active", {
                required: "사용여부를 선택해주세요",
              })}
            />
          </div>
        </FormBox>

        <div className="w-full flex flex-col justify-center mt-5">
          {watch("id") && (
            <button
              className=" btn w-full bg-black text-white  "
              onClick={() => onDelete(watch())}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} />
              운동타입 삭제
            </button>
          )}
          <button
            className=" btn-primary btn w-full mt-2   text-white  "
            onClick={handleSubmit(onValid)}
          >
            <FontAwesomeIcon icon={faCirclePlus} />
            {`운동타입 ${watch("id") ? "수정" : "등록"}`}
          </button>
        </div>
      </Modal>
      <AdminLayout>
        <div>
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">{'운동 타입 관리'}</h1>
          <Table
            initSort="id"
            initDirection="desc"
            dataApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/programtype?brandId`}
            buttonComponent={
              <button
                className=" btn-primary btn   text-white  "
                onClick={() => showModal()}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                운동타입 등록
              </button>
            }
            headerArray={tableHeader}
            mutate={mutate}
            searchData={searchData}
            searchApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/programtype/search`}
          >
            {(data: Programtype[], startIndex: number) =>
              data.map((item: Programtype, index: number) => {
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>
                    <td>{item.brandName}</td>
                    <td>{item.name}</td>
                    <td
                      className={cls(
                        "",
                        item.active.toString() === "true"
                          ? "text-blue-500"
                          : "text-red-500"
                      )}
                    >
                      {item.active.toString() === "true"
                        ? "사용"
                        : "미사용"}
                    </td>
                    <td>
                      <button
                        onClick={() => openEditModal(item)}
                        className="btn btn-xs"
                        disabled={!getUseEditFromAuth('7', item?.brandId, brandAuth || [])}
                      >
                        수정
                      </button>
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}
import { ReactElement, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useUserStore } from "../../store";

interface PrivateRouteProps {
  children?: ReactElement; // Router.tsx에서 PrivateRoute가 감싸고 있는 Componet Element\
}

export function BranchRoute({ children }: PrivateRouteProps) {
  const { branchCode } = useParams();
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  console.log(user);
  useEffect(() => {
    if (user?.branchCode !== branchCode) {
      if (user?.branchCode !== "main") {
        navigate(`/`);
      }
    }
  }, [user, branchCode]);

  return children || null;
}

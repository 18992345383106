import { UseFormRegisterReturn } from "react-hook-form";

interface ModalProps {
  register: UseFormRegisterReturn;
  placeholder?: string;
  type?: string;
  step?: number;
  maxLength?: number;
  disabled?: boolean;
}

export default function FormInput({
  register,
  placeholder,
  type = "text",
  step,
  maxLength,
  disabled,
}: ModalProps) {
  return type === "textarea" ? (
    <textarea
      {...register}
      placeholder={placeholder}
      className="textarea textarea-bordered textarea-md w-full "
    ></textarea>
  ) : (
    <input
      type={type}
      step={step}
      maxLength={maxLength}
      placeholder={placeholder}
      className="input w-full input-bordered"
      disabled={disabled}
      {...register}
    />
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BranchGroup } from "../types/models";
import {
  faDeleteLeft,
  faGrip,
  faGripVertical,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import axios from "axios";
import { useDrag } from "react-dnd";
import { DragItem } from "../types/dnd";
import AdsBoxDrag from "./AdsBoxDrag";
import BranchGroupNameDrag from "./BranchGroupNameDrag";
import BranchGroupNameDrop from "./BranchGroupNameDrop";
import { useQueryClient } from "@tanstack/react-query";
import { cls } from "../libs/utils";
import UserGroupBox from "./UserGroupBox";

interface BranchGroupGridProps {
  data?: BranchGroup[];
  headcount: number;
  readonly?: boolean;
  refetch?: any;
  scheduleId?: string;
  size?: string;
}

export default function BranchGroupGrid({
  data,
  headcount,
  readonly = false,
  refetch,
  scheduleId,
  size,
}: BranchGroupGridProps) {
  const groupArr = ["A", "B", "C"];
  const [scheduleLoading, setSheduleLoading] = useState<number[]>([]);

  const queryClient = useQueryClient();
  const onDelete = async (groupId: number) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    setSheduleLoading([...scheduleLoading, groupId]);

    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}api/branch/group/${groupId}`, {
        withCredentials: true,
      });

      refetch();
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }

    const newLoadingArray = scheduleLoading.filter(
      (item: number) => item !== groupId
    );

    setSheduleLoading(newLoadingArray);
  };

  //Drop시 실행되는 함수
  const moveContent = async (originalIndex: number, targetIndex: number) => {
    console.log("targetindex", targetIndex);
    console.log("originalindex", originalIndex);

    if (!data) {
      return;
    }

    // Step 1 and 2
    const A = data.find((item) => item.indexNum === targetIndex);
    const B = data.find((item) => item.indexNum === originalIndex);

    // if (A) {
    //   alert("이미 배정된 자리로 이동할 수 없어요");
    //   return;
    // }

    if (!B) {
      return; // or throw an error, or whatever you want to do
    }

    const newData = data.map((item) => {
      if (item.indexNum === B.indexNum) {
        return { ...item, indexNum: targetIndex };
      }
      return item;
    });

    // // Create a new array instead of mutating the original one
    // const newData = data.map((item) => {
    //   if (A && item.indexNum === A.indexNum) {
    //     return { ...item, indexNum: B.indexNum };
    //   } else if (item.indexNum === B.indexNum) {
    //     return { ...item, indexNum: A ? A.indexNum : targetIndex };
    //   }
    //   return item;
    // });

    // Sort by indexNum
    newData.sort((a, b) => a.indexNum - b.indexNum);

    // Update the state
    queryClient.setQueryData(["getGroupData", scheduleId], newData);

    await axios.patch(`${process.env.REACT_APP_SERVER_URL}api/branch/group/move/${B.id}`, {
      targetIndex: targetIndex,
    }, { withCredentials: true });
  };

  return (
    <div
      className={cls(
        "grid    w-full",
        size === "tv" ? "grid-cols-5 gap-5" : "grid-cols-5 gap-2"
      )}
    >
      {Array.from({ length: Number(headcount) }).map((_, i) => {
        const item = data?.find((d) => d.indexNum === i);
        return (
          <div key={i} className="  ">
            <UserGroupBox
              item={item}
              i={i}
              readonly={readonly}
              moveContent={moveContent}
              onDelete={onDelete}
              size={size}
            />
          </div>
        );
      })}
    </div>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faStop,
  faForward,
  faBackward,
} from "@fortawesome/free-solid-svg-icons";

interface StreamingButtonProps {
  isLoading: boolean;
  onClick: () => void;
  size?: "small" | "large";
  flag: string;
}

const StreamingButton = ({
  isLoading,
  onClick,
  size = "large",
  flag,
}: StreamingButtonProps) => {
  const buttonSize = size === "small" ? "w-10 h-10" : "w-20 h-20";
  const iconSize = size === "small" ? "lg" : "2xl";

  let icon;
  let buttonColor;
  switch (flag) {
    case "playing":
      icon = faPlay;
      buttonColor = "bg-gymmate from-red-400 via-red-500 to-gymmate";
      break;
    case "pause":
      icon = faPause;
      buttonColor = "bg-gymmate from-red-400 via-red-500 to-gymmate";
      break;
    case "stop":
      icon = faStop;
      buttonColor = "bg-gray-400";
      break;
    case "forward":
      icon = faForward;
      buttonColor = "bg-blue-500";
      break;
    case "backward":
      icon = faBackward;
      buttonColor = "bg-blue-500";
      break;
    default:
      icon = faPlay;
      buttonColor = "bg-gymmate from-red-400 via-red-500 to-gymmate";
  }

  return (
    <button
      onClick={onClick}
      disabled={isLoading}
      className={`${buttonSize} ${buttonColor} rounded-full flex justify-center items-center shadow-lg transition-all transform active:scale-90`}
    >
      {isLoading ? (
        <span className="loading loading-spinner loading-sm bg-white"></span>
      ) : (
        <FontAwesomeIcon icon={icon} color="white" size={iconSize} />
      )}
    </button>
  );
};

export default StreamingButton;

import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCirclePlus,
  faClock,
  faLock,
  faPlus,
  faSave,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Modal from "../../components/interface/Modal";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { useForm } from "react-hook-form";
import Button from "../interface/Button";
import { BranchSchedule } from "../../types/models";
import WeekButton from "../WeekButton";
import { cls, getBoxBackgroundClass, getDayClassNames } from "../../libs/utils";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getBranchSchedules,
  getBranchSchedulesSortTime,
} from "../../services/branchSchedule";
import LoadingMsg from "../LoadingMsg";
import { getProgramCategoryByDateRange } from "../../services/schedule";

interface BranchAddTimeModalProps {
  branchCode: string;
  initStartDate: string;
  initEndDate: string;
  isOpen: boolean;
  refetch: any;
}

interface ScheduleDetails {
  duration: number;
  headcount: number;
  assignCount: number;
}

interface ScheduleByDay {
  [time: string]: {
    [dayFormat: string]: ScheduleDetails[];
  };
}

export default function ScheduleSettingsModal({
  branchCode,
  initStartDate,
  initEndDate,
  refetch,
}: BranchAddTimeModalProps) {
  const [daysOfWeek, setDaysOfWeek] = useState<any>();
  // const [programByDay, setProgramByDay] = useState<String[]>([]);
  const [startDate, setStartDate] = useState(initStartDate);
  const [endDate, setEndDate] = useState(initEndDate);
  const [saveLoading, setSaveLoading] = useState(false);
  const [scheduleByDay, setScheduleByDay] = useState<ScheduleByDay>({});

  const {
    data: origianlScheduleByDay,
    isLoading,
    isError,
    refetch: origianlScheduleByDayRefetch,
  } = useQuery<ScheduleByDay, Error>(
    ["getBranchSchedulesSortTime", startDate, endDate, branchCode],
    () => getBranchSchedulesSortTime(startDate!, endDate!, branchCode!),
    {
      enabled: !!startDate && !!endDate && !!branchCode,
    }
  );

  const { data: programByDay, isLoading: programByDayLoading } = useQuery<
    any[],
    Error
  >(
    ["getProgramCategoryByDateRanges", startDate, endDate, branchCode],
    () => getProgramCategoryByDateRange(startDate!, endDate!, branchCode!),
    {
      enabled: !!startDate && !!endDate,
    }
  );

  useEffect(() => {
    if (origianlScheduleByDay) {
      setScheduleByDay(origianlScheduleByDay);
    }
  }, [origianlScheduleByDay]);

  useEffect(() => {
    if (daysOfWeek) {
      setStartDate(daysOfWeek[0].format("YYYY-MM-DD"));
      setEndDate(daysOfWeek[daysOfWeek.length - 1].format("YYYY-MM-DD"));
    }
  }, [daysOfWeek]);

  console.log(scheduleByDay);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    time: string,
    dayFormat: string,
    fieldName: keyof BranchSchedule
  ) => {
    // 새로운 값을 수치로 변환
    const newValue = Number(e.target.value);

    // 기존 데이터를 가져옴
    let updatedSchedule: ScheduleByDay = { ...scheduleByDay };

    // 해당하는 데이터가 없으면 새 데이터를 생성
    if (!updatedSchedule[time]) {
      updatedSchedule[time] = {
        [dayFormat]: [{ duration: 0, headcount: 0, assignCount: 0 }],
      };
    } else if (!updatedSchedule[time][dayFormat]) {
      updatedSchedule[time][dayFormat] = [
        { duration: 0, headcount: 0, assignCount: 0 },
      ];
    } else if (!updatedSchedule[time][dayFormat][0]) {
      updatedSchedule[time][dayFormat].push({
        duration: 0,
        headcount: 0,
        assignCount: 0,
      });
    }

    // 그렇지 않으면 duration 또는 headcount 값을 업데이트
    updatedSchedule[time][dayFormat][0][fieldName as keyof ScheduleDetails] =
      newValue;

    setScheduleByDay(updatedSchedule);
  };

  const handleTimeChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    time: string,
    isHour: boolean
  ) => {
    if (!scheduleByDay) {
      return;
    }
    // 새로운 시간을 계산합니다.
    const newTime = isHour
      ? `${e.target.value.padStart(2, "0")}:${time.split(":")[1]}`
      : `${time.split(":")[0]}:${e.target.value.padStart(2, "0")}`;

    // 새로운 시간이 이미 존재하는지 확인합니다.
    if (scheduleByDay[newTime]) {
      alert("이미 해당 시간이 존재합니다!");
      return;
    }

    // 기존 데이터를 가져옵니다.
    let updatedSchedule: ScheduleByDay = { ...scheduleByDay };

    // 새 키로 값을 복사하고, 원래 키를 삭제합니다.
    updatedSchedule[newTime] = updatedSchedule[time];
    delete updatedSchedule[time];

    // 키를 정렬합니다.
    const keys = Object.keys(updatedSchedule);
    keys.sort((a, b) => {
      const aHour = parseInt(a.split(":")[0]);
      const bHour = parseInt(b.split(":")[0]);
      const aMinute = parseInt(a.split(":")[1]);
      const bMinute = parseInt(b.split(":")[1]);

      if (aHour === bHour) {
        return aMinute - bMinute;
      }
      return aHour - bHour;
    });

    // 정렬된 키를 기반으로 새로운 객체를 생성합니다.
    const sortedSchedule: ScheduleByDay = {};
    keys.forEach((key) => {
      sortedSchedule[key] = updatedSchedule[key];
    });

    setScheduleByDay(sortedSchedule);
  };

  const addNewHour = () => {
    // 기존 데이터를 가져옵니다.
    let updatedSchedule: ScheduleByDay = { ...scheduleByDay };

    // 만약 scheduleByDay가 비어있다면 "00:00"을 추가합니다.
    if (Object.keys(updatedSchedule).length === 0) {
      //@ts-ignore
      updatedSchedule["00:00"] = {};
    } else {
      // 마지막 시간을 가져옵니다.
      const lastTime =
        Object.keys(updatedSchedule).sort()[
          Object.keys(updatedSchedule).length - 1
        ];

      // 마지막 시간의 시와 분을 분리합니다.
      const [lastHour, lastMinute] = lastTime.split(":").map(Number);

      // 새로운 시간과 분을 계산합니다.
      let newHour = lastHour;
      let newMinute = lastMinute;

      // 만약 마지막 시간이 23 미만이라면 시간을 1 증가시킵니다.
      if (lastHour < 23) {
        newHour += 1;
        newMinute = 0;
      } else if (newMinute < 59) {
        // 그렇지 않고 마지막 분이 59 미만이라면 분을 1 증가시킵니다.
        newMinute += 1;
      } else {
        // 그렇지 않으면 alert를 띄우고 함수를 종료합니다.
        alert("더 이상 추가할 수 없습니다.");
        return;
      }

      // 새로운 시간을 문자열로 변환합니다.
      const newTime = `${newHour.toString().padStart(2, "0")}:${newMinute
        .toString()
        .padStart(2, "0")}`;

      // 새로운 시간을 scheduleByDay에 추가합니다.
      //@ts-ignore
      updatedSchedule[newTime] = {};
    }

    setScheduleByDay(updatedSchedule);
  };

  const onSave = async () => {
    if (!scheduleByDay) {
      return;
    }

    setSaveLoading(true);

    const startDate = daysOfWeek[0].format("YYYY-MM-DD");
    const endDate = daysOfWeek[daysOfWeek.length - 1].format("YYYY-MM-DD");

    Object.entries(scheduleByDay).forEach(([time, dateSchedules]: any) => {
      console.log('time dateSchedules', time, dateSchedules, scheduleByDay)
      Object.entries(dateSchedules).forEach(([date, schedules]: any) => {
        schedules.forEach((schedule: any) => {
          if (schedule.duration === 0 || schedule.headcount === 0) {
            var result = window.confirm(
              `${date}의 ${time}에 시간 또는 인원수가 0입니다.\n계속하시면 해당 스케줄이 삭제 됩니다.\n계속 하시겠어요?`
            );

            if (!result) {
              return;
            }
          }
        });
      });
    });

    const data = {
      scheduleByDay,
      startDate,
      endDate,
      branchCode: branchCode,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}api/branch/schedule/bulkupdate`,
        data,
        {
          withCredentials: true,
        }
      );
      console.log(response.data);
      alert('시간표를 설정하였습니다.');
      refetch();
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      } else {
        alert(`서버 오류 발생 : ${error.message} `);
      }
    }

    setSaveLoading(false);
  };

  return (
    <Modal id="scheduleSettingsModal" title="수업 시간표 설정" size="xlarge">
      <div className="flex flex-col justify-center">
        <div className="grid grid-cols-3 mb-5">
          {isLoading ? <LoadingMsg /> : <div />}
          <WeekButton daysOfWeek={daysOfWeek} setDaysOfWeek={setDaysOfWeek} />
          <div className="place-self-end ">
            <Button
              loading={saveLoading}
              title={`저장`}
              onClick={onSave}
              iconComponent={<FontAwesomeIcon icon={faSave} />}
            />
          </div>
        </div>

        <div className="overflow-x-auto w-full">
          <table className="text-xs">
            <thead>
              <tr>
                <th className="w-[200px] bg-gray-400 text-white text-center">
                  시작시간
                </th>
                {daysOfWeek?.map((day: any, index: number) => (
                  <th
                    key={index}
                    className={cls(
                      "w-[200px] py-3  overflow-hidden ",
                      getBoxBackgroundClass(day)
                    )}
                  >
                    <div className="font-bold text-center  ">
                      <p className={getDayClassNames(day)}>
                        {day.format("MM")}.{day.format("DD")} (
                        {day.format("ddd")})
                      </p>

                      <div className="h-5 flex items-center mt-2 justify-center">
                        <div className="badge text-sm bg-gymmate text-white">
                          {programByDay ? (
                            programByDay[day.format("YYYY-MM-DD")]
                          ) : (
                            <span className="loading loading-bars loading-xs"></span>
                          )}
                        </div>
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.keys(scheduleByDay).map((time, index) => (
                <tr key={index} className="h-20 border-b relative">
                  <th className=" min-w-[170px] px-5    ">
                    <div className="flex  space-x-2">
                      <select
                        className="select select-bordered select-xs w-full max-w-xs"
                        value={parseInt(time.split(":")[0])}
                        onChange={(e) => handleTimeChange(e, time, true)}
                      >
                        {Array.from({ length: 24 }, (_, i) => (
                          <option key={i} value={i}>
                            {i.toString().padStart(2, "0")}
                          </option>
                        ))}
                      </select>
                      <p>:</p>
                      <select
                        className="select select-bordered select-xs w-full max-w-xs"
                        value={parseInt(time.split(":")[1])}
                        onChange={(e) => handleTimeChange(e, time, false)}
                      >
                        {Array.from({ length: 60 }, (_, i) => (
                          <option key={i} value={i}>
                            {i.toString().padStart(2, "0")}
                          </option>
                        ))}
                      </select>
                    </div>
                  </th>

                  {daysOfWeek?.map((day: any) => {
                    const dayFormat = day.format("YYYY-MM-DD");
                    const duration =
                      scheduleByDay[time] && scheduleByDay[time][dayFormat]
                        ? scheduleByDay[time][dayFormat][0]?.duration
                        : "";

                    const headcount =
                      scheduleByDay[time] && scheduleByDay[time][dayFormat]
                        ? scheduleByDay[time][dayFormat][0]?.headcount
                        : "";

                    const assignCount =
                      scheduleByDay[time] && scheduleByDay[time][dayFormat]
                        ? scheduleByDay[time][dayFormat][0]?.assignCount
                        : "";

                    return (
                      <td key={dayFormat} className="min-w-[120px] relative">
                        {Number(assignCount) > 0 && (
                          <div className="bg-gray-300 absolute w-full h-20 bottom-0 flex items-center justify-center  z-50 bg-opacity-40 ">
                            <div
                              className="tooltip"
                              data-tip="이미 배정된 인원이 있어요"
                            >
                              <div className=" cursor-pointer ">
                                <FontAwesomeIcon icon={faLock} />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="px-2">
                          <div className="flex items-center space-x-2 mb-2 ">
                            <FontAwesomeIcon
                              icon={faClock}
                              width={8}
                              className="mr-1"
                            />
                            <input
                              type="number"
                              placeholder="시간"
                              className="input input-bordered input-xs w-full max-w-xs"
                              value={duration}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  time,
                                  dayFormat,
                                  "duration"
                                )
                              }
                            />
                            <span className="text-xs">분</span>
                          </div>
                          <div className="flex items-center space-x-2 ">
                            <FontAwesomeIcon
                              icon={faUser}
                              width={8}
                              className="mr-1"
                            />
                            <input
                              type="number"
                              placeholder="인원"
                              className="input input-bordered input-xs w-full max-w-xs"
                              value={headcount}
                              onChange={(e) =>
                                handleInputChange(
                                  e,
                                  time,
                                  dayFormat,
                                  "headcount"
                                )
                              }
                            />
                            <span className="text-xs">명</span>
                          </div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
              <tr
                onClick={addNewHour}
                className="flex opacity-30 cursor-pointer mt-2  rounded-md  w-full h-5 bg-gray-100 transition-all hover:bg-gray-300 justify-center items-center shadow-md"
              >
                <FontAwesomeIcon icon={faPlus} />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
}

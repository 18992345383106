import AdminLayout from "../../components/Layout/AdminLayout";

import "moment/locale/ko";

import ScheduleCalender from "../../components/ScheduleCalender";
import BrandLayout from "../../components/Layout/BrandLayout";
import { useState } from "react";
import { BrandDto } from "../../types/dto";

export default function Schedule() {
  const [selectBrand, setSelectBrand] = useState<BrandDto>();

  const onSelectBrand = (brand:BrandDto)=>{
    setSelectBrand(brand)
  }
  return (
    <AdminLayout>
      <div className="">
        <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">{'일별 프로그램 관리'}</h1>
        <BrandLayout
          selectBrand={selectBrand}
          setSelectBrand={onSelectBrand}
          target={'3'}
        />
        <ScheduleCalender selectBrand={selectBrand}/>
      </div>
    </AdminLayout>
  );
}

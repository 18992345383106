export const TableHeaderForProgram = [
  { title: "No." },
  { title: "등록일" },
  { title: "브랜드" },
  { title: "프로그램 종류" },
  { title: "운동타입" },
  { title: "커리큘럼명" },
  { title: "레이아웃명" },
  { title: "프로그램명" },
  { title: "동작수" },
  { title: "총세트수" },
  // { title: "동작수 (SET당시간)" },
  // { title: "총세트수 (총운동시간)" },
  // { title: "최종수업일", value: "lastLectureDate" },
  { title: "관리" },
];

export const TableHeaderForStatistics = [
  { title: "No." },
  { title: "등록일", value: "createdAt" },
  { title: "브랜드" },
  { title: "프로그램 종류" },
  { title: "운동타입" },
  { title: "프로그램명" },
  { title: "동작수" },
  { title: "총세트수" },
  // { title: "동작수 (SET당시간)" },
  // { title: "총세트수 (총운동시간)" },
  { title: "수업일수", value: "registrationCount" },
  { title: "예약자수", value: "reservationCount" },
  { title: "평균예약자수", value: "averageCount" },
];

export const TableHeaderForContents = [
  { title: "No." },
  { title: "등록일", value: "createdAt" },
  { title: "부위" },
  { title: "도구" },
  { title: "영상" },
  { title: "운동명" },
  { title: "운동종류" },
  { title: "등록 수", value: "registrationCount" },
  { title: "관리" },
];

export const TableHeaderForLayout = [
  { title: "No." },
  { title: "브랜드명" },
  { title: "레이아웃명" },
  { title: "옵션" },
  { title: "레이아웃" },
  { title: "프로그램 수" },
  { title: "관리" },
];



export const TableHeaderForCurriculum = [
  { title: "No." },
  { title: "등록일" },
  { title: "브랜드" },
  { title: "운동타입" },
  { title: "커리큘럼명" },
  { title: "뱃지" },
  { title: "프로그램수" },
  { title: "관리" },
];

export const TableHeaderForNotice = [
  { title: "No." },
  { title: "등록일" },
  { title: "브랜드" },
  { title: "게시물 이름" },
  { title: "커리큘럼명" },
  { title: "뱃지" },
  { title: "프로그램수" },
  { title: "관리" },
];

import axios from "axios";
import { ProgramSchedule } from "../types/dto";

export const getSchedules = async (startDate: string, endDate: string, brandId:number) => {
  let result:Record<string, ProgramSchedule> = {}
  try {
    if(brandId != 0) {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/schedule?brandId=${brandId}`, {
        params: {
          startDate: startDate,
          endDate: endDate,
        },
        withCredentials: true 
      });
      
    
      for (let i = 0; i < response.data.data.length; i++) {
        result[response.data.data[i].date] = response.data.data[i].list
      }
    }
    
  } catch (error) {
    window.location.reload();
  }

  return result
};

export const getProgramCategoryByDateRange = async (
  startDate: string,
  endDate: string,
  branchCode: string,
) => {
  const response = await axios.get(
    `${process.env.REACT_APP_SERVER_URL}api/public/schedule/programCategory?startDate=${startDate}&endDate=${endDate}&branchCode=${branchCode}`, { withCredentials: true });

  
    let result:any[] = []

    for (let i = 0; i < response.data.data.length; i++) {
      result[response.data.data[i].date] = response.data.data[i].programCategory
    }
    
    return result
  return response.data;
};

export const getSchedulesToday = async () => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/schedule/today`, { withCredentials: true });

  
  let result:any[] = []

  for (let i = 0; i < response.data.data.length; i++) {
    result[response.data.data[i].date] = response.data.data[i].programCategory
  }
  
  return result
  return response.data;
};

export const getSchedulesDescriptionToday = async () => {
  const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/program/today`, { withCredentials: true });

  return response.data;
};

import { ReactElement, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useBranchStore, useUserStore } from "../../store";
import { useQuery } from "@tanstack/react-query";
import { fetchBranchNameByCode } from "../../services/branch";

interface PrivateRouteProps {
  children?: ReactElement; // Router.tsx에서 PrivateRoute가 감싸고 있는 Componet Element\
}

export function ScreenRoute({ children }: PrivateRouteProps) {
  const { branchCode } = useParams();
  const navigate = useNavigate();

  const setBranchName = useBranchStore((state) => state.setBranchName);

  const {
    data: branchName,
    isLoading,
    isError,
  } = useQuery<string, Error>(
    ["getBranchNameByCode", branchCode],
    () => fetchBranchNameByCode(branchCode!),
    {
      enabled: !!branchCode,
      retry: false,
    }
  );

  useEffect(() => {
    if (branchName) {
      setBranchName(branchName);
    }
  }, [branchName]);

  useEffect(() => {
    if (!isLoading && !branchName) {
      navigate("/screen");
      alert("지점코드가 올바르지 않아요");
    }
  }, [isLoading]);

  if (isLoading) {
    return (
      <div className=" min-h-screen w-full flex justify-center items-center ">
        <span className="loading loading-spinner bg-gymmate "></span>
      </div>
    );
  }

  return children || null;
}

import React, { useEffect, useState } from "react";
import { AdsDtoWithVideo } from "../../types/dto";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getProgramCategoryByDateRange } from "../../services/schedule";
import moment from "moment";
import { getBranchSchedules } from "../../services/branchSchedule";
import BranchScheduleBox from "../BranchScheduleBox";
import { useBranchStore } from "../../store";
import BranchScheduleBoxForAds from "../BranchScheduleBoxForAds";

interface AdsSlideProps {
  data: AdsDtoWithVideo[];
  schedule?: boolean;
}

function AdsSlide({ data, schedule = false }: AdsSlideProps) {
  const { branchCode } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [daysOfWeek, setDaysOfWeek] = useState<any>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [adsData, setAdsData] = useState<any>(data);
  const storeBranchName = useBranchStore((state) => state.branchName);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === adsData.length - 1 ? 0 : prevIndex + 1
      );
    }, adsData[currentIndex].time * 1000);
    return () => clearTimeout(timer); // cleanup function to clear the timer
  }, [currentIndex, adsData]);

  useEffect(() => {
    if (schedule === true) {
      const currentWeek = moment().startOf("week");
      const getDay = Array(7)
        .fill(0)
        .map((_, i) => currentWeek.clone().add(i, "days"));

      setDaysOfWeek(getDay);
      setStartDate(getDay[0].format("YYYY-MM-DD"));
      setEndDate(getDay[getDay.length - 1].format("YYYY-MM-DD"));

      // 각 두 번째 항목 후에 스케줄 박스를 추가합니다.
      const newData = [];
      for (let i = 0; i < data.length; i++) {
        newData.push(data[i]);
        if ((i + 1) % 2 === 0) {
          newData.push({ type: "scheduleBox", time: 14 });
        }
      }
      setAdsData(newData); // setData는 상태 설정 함수입니다. 여기서는 useState를 사용하여 정의해야 합니다.
    }
  }, [schedule, data]);

  const {
    data: scheduleByDay,
    isLoading,
    isError,
    refetch,
  } = useQuery<any[], Error>(
    ["getBranchSchedules", startDate, endDate, branchCode],
    () => getBranchSchedules(startDate!, endDate!, branchCode!),
    {
      enabled: !!startDate && !!endDate && !!branchCode,
    }
  );

  const { data: programByDay, isLoading: programByDayLoading } = useQuery<
    any[],
    Error
  >(
    ["getProgramCategoryByDateRanges", startDate, endDate, branchCode],
    () => getProgramCategoryByDateRange(startDate!, endDate!, branchCode!),
    {
      enabled: !!startDate && !!endDate,
    }
  );

  return (
    <div className="w-full">
      {adsData && adsData[currentIndex].type === "video" ? (
        <video
          src={adsData[currentIndex].video!.fileLink!}
          className="w-full h-full"
          autoPlay
          loop
          muted
        />
      ) : adsData[currentIndex].type === "image" ? (
        <img
          src={adsData[currentIndex].imgUrl!}
          alt=""
          className="w-full h-full"
        />
      ) : adsData[currentIndex].type === "youtube" ? (
        <iframe
          className="w-full"
          width="3840"
          height="2160"
          src={`https://www.youtube.com/embed/${adsData[currentIndex].youtubeId}?autoplay=1&mute=1`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        ></iframe>
      ) : (
        <div>
          <BranchScheduleBoxForAds
            daysOfWeek={daysOfWeek}
            scheduleByDay={scheduleByDay}
            programByDay={programByDay}
            size="big"
            storeBranchName={storeBranchName}
          />
        </div>
      )}
    </div>
  );
}

export default AdsSlide;

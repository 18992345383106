import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faDice,
  faGear,
  faPlus,
  faTrash,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons";

import axios from "axios";

import { useForm } from "react-hook-form";
import Button from "../interface/Button";

interface RandomSelectForm {
  startDate: string;
  endDate: string;
  noDuplicates: boolean;
  isNullable: boolean;
  isOrderByRegistrationCountAsc: boolean;
}

export default function RunRandomModal({ refetch, startDate, endDate, brandId }: any) {
  const [randomLoading, setRandomLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<RandomSelectForm>({
    defaultValues: {
      isOrderByRegistrationCountAsc: true,
    },
  });

  useEffect(() => {
    setValue("startDate", startDate);
    setValue("endDate", endDate);
  }, [startDate, endDate]);

  const onRunRadnomSelect = async (formData: RandomSelectForm) => {
    setRandomLoading(true);

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}api/admin/schedule/random`, {
        startDate: formData.startDate,
        endDate: formData.endDate,
        brandId: brandId,
        noDuplicates: formData.noDuplicates,
        isNullable: !formData.isNullable,
        isOrderByRegistrationCountAsc: formData.isOrderByRegistrationCountAsc,
      }, { withCredentials: true });

      refetch();
      //@ts-ignore
      window.randomSelect.close();
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }

    setRandomLoading(false);
  };

  console.log(watch());

  return (
    <div>
      <label className="label mt-2">
        <span className="label-text font-bold">랜덤 선택 기간</span>
      </label>

      <div className="space-x-2 flex items-center ">
        <input
          type="date"
          className="text-sm w-[143px] input input-bordered "
          {...register("startDate", { required: "true" })}
        />
        <p>-</p>
        <input
          type="date"
          className="text-sm w-[143px] input input-bordered"
          {...register("endDate", { required: "true" })}
        />
      </div>
      <label className="label mt-2">
        <span className="label-text font-bold">옵션</span>
      </label>

      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">
            등록 수 적은 운동 프로그램 우선 선택
          </span>
          <input
            type="checkbox"
            {...register("isOrderByRegistrationCountAsc")}
            className="checkbox"
          />
        </label>
      </div>

      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">
            랜덤 선택 기간 중 운동 프로그램 중복 방지
          </span>
          <input
            type="checkbox"
            {...register("noDuplicates")}
            className="checkbox"
          />
        </label>
      </div>

      <div className="form-control">
        <label className="label cursor-pointer">
          <span className="label-text">
            조건에 맞는 운동 프로그램이 없을 경우 랜덤 선택 중단
          </span>
          <input
            type="checkbox"
            {...register("isNullable")}
            className="checkbox"
          />
        </label>
      </div>

      <Button
        loading={randomLoading}
        title={`랜덤 선택`}
        onClick={handleSubmit(onRunRadnomSelect)}
        iconComponent={<FontAwesomeIcon icon={faDice} />}
      />
    </div>
  );
}

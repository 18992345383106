import { LayoutDto } from "../../../types/dto";

export default function LayoutTableItem({
    item,
    small
}: {
  item?: LayoutDto;
  small?: Boolean;
}) {
  if(!item) {
    return null
  }
  const tv1Teams = (item?.tv1Teams)?(item?.tv1Teams).split(','):['', '', '', '', '', '', '', ''];
  const tv1Ids = (item?.tv1Ids)?(item?.tv1Ids).split(','):['0','0','0','0','0','0','0','0'];
  const tv2Teams = (item?.tv2Teams)?(item?.tv2Teams).split(','):['', '', '', '', '', '', '', ''];
  const tv2Ids = (item?.tv2Ids)?(item?.tv2Ids).split(','):['0','0','0','0','0','0','0','0'];
  const tv3Teams = (item?.tv3Teams)?(item?.tv3Teams).split(','):['', '', '', '', '', '', '', ''];
  const tv3Ids = (item?.tv3Ids)?(item?.tv3Ids).split(','):['0','0','0','0','0','0','0','0'];

  let render1list = []
  for (let i = 0; i < item.tv1Count; i++) {
    render1list.push('')
  }
  let render2list = []
  for (let i = 0; i < item.tv2Count; i++) {
    render2list.push('')
  }
  let render3list = []
  for (let i = 0; i < item.tv3Count; i++) {
    render3list.push('')
  }

  const itemRender = (teams:string[], ids:string[], count:number, renderlist:string[], tvindex:number)=>{
    
    if(count == 0) {
      return (
        <div className={
          small?"border-l border-t border-[#666577] w-16 h-28 bg-[#D5D6D9] mr-1 justify-center items-center text-center text-xs font-medium flex flex-col"
          :"border-l border-t border-[#666577] w-20 h-28 bg-[#D5D6D9] mr-1 justify-center items-center text-center text-xs font-medium flex flex-col"
        }>
          {
            ((tvindex == 3) && (item?.tv3State == 1))?'TV1과 동일':
            ((tvindex - 1) == 1)?`TV1과 동일`:
            'TV2와 동일'}
        </div>
      )
    }
    return (
      <div className={
        small?
        (count >= 1 && count <= 4?"border-l border-t border-[#666577] w-16 h-28 bg-[#F3F4F6] mr-1 grid grid-cols-1":
        count >= 6?"border-l border-t border-[#666577] w-16 h-28 bg-[#F3F4F6] mr-1 grid grid-cols-2":
        "border-l border-t border-[#666577] w-16 h-28 bg-[#F3F4F6] mr-1 justify-center items-center")
        :
        (count >= 1 && count <= 4?"border-l border-t border-[#666577] w-20 h-28 bg-[#F3F4F6] mr-1 grid grid-cols-1":
        count >= 6?"border-l border-t border-[#666577] w-20 h-28 bg-[#F3F4F6] mr-1 grid grid-cols-2":
        "border-l border-t border-[#666577] w-20 h-28 bg-[#F3F4F6] mr-1 justify-center items-center")
      }>
        {
          renderlist.map((renderItem, index)=>{
            return (
              <div
                className={ids[index] == '-1'?" w-full h-full border-[#666577] border-b border-r bg-[#A3A3A3] text-[#A3A3A3] flex flex-col justify-center items-center text-center text-xs font-medium":" w-full h-full border-[#666577] border-b border-r bg-[#F3F4F6] flex flex-col justify-center items-center text-center text-xs font-medium"}
                key={`LayoutTableItem_itemRender_${tvindex}_${index}`}
              >
                {ids[index] == '-1'?'-':ids[index]}{ids[index] == '-1'?'-':teams[index]}
              </div>
            )
          })
        }
        {
          renderlist.length == 0?
          <div
            className='w-full h-full border-[#666577] border-b border-r bg-[#A3A3A3]'
          />
          :null
        }
      </div>
    )
  }

  return (
    <div className="flex">
      {itemRender(tv1Teams, tv1Ids, item.tv1Count, render1list, 1)}
      {itemRender(tv2Teams, tv2Ids, item.tv2Count, render2list, 2)}
      {itemRender(tv3Teams, tv3Ids, item.tv3Count, render3list, 3)}
    </ div>
  );
}

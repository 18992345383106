import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faCirclePlus,
  faClock,
  faIdBadge,
  faPhone,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import Modal from "../../components/interface/Modal";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { useForm } from "react-hook-form";
import Button from "../interface/Button";
import { BranchSchedule } from "../../types/models";

interface ModalForm {
  name: string;
  phone: string;
}

interface BranchAddTimeModalProps {
  isOpen: boolean;

  scheduleId: string;
  branchCode: string;
  refetch: any;
}

export default function AssignUserModal({
  isOpen,
  scheduleId,
  branchCode,
  refetch,
}: BranchAddTimeModalProps) {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<number>();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors, isValid },
  } = useForm<ModalForm>();

  // 모달이 열리거나 닫힐 때마다 폼을 초기화
  useEffect(() => {
    reset();
  }, [isOpen]);

  const onValid = async (formData: ModalForm) => {
    setLoading(true);
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}api/public/group`, {
        branchCode: branchCode,
        scheduleId: scheduleId,
        name: formData.name,
        phone: formData.phone,
      }, { withCredentials: true });
    } catch (error: any) {
      if (error.response && error.response.data) {
        alert(error.response.data);
      } else {
        alert(`서버 오류 발생 : ${error.message} `);
      }
      console.error(error);
    }

    setLoading(false);
    //@ts-ignore
    window.assignUserModal.close();
    refetch();
  };

  return (
    <Modal id="assignUserModal" title={`그룹 배정`}>
      <div className="mt-5">
        <FormBox title="이름" icon={<FontAwesomeIcon icon={faIdBadge} />}>
          <div className="flex items-center space-x-5">
            <FormInput
              placeholder="이름"
              register={register("name", {
                required: "이름을 입력해주세요",
              })}
            />
          </div>
        </FormBox>
        <FormBox
          title="전화번호 뒤 4자리"
          icon={<FontAwesomeIcon icon={faPhone} />}
        >
          <div className="flex items-center space-x-5">
            <FormInput
              placeholder="전화번호 뒤 4자리"
              maxLength={4}
              register={register("phone", {
                pattern: {
                  value: /^[0-9]*$/,
                  message: "숫자만 입력해주세요",
                },
                minLength: 4,
                required: "전화번호을 입력해주세요",
              })}
            />
          </div>
        </FormBox>
        <Button
          loading={loading}
          title={"등록"}
          onClick={handleSubmit(onValid)}
          iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
          disabled={isValid ? false : true}
        />
      </div>
    </Modal>
  );
}

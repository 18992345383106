import { useEffect, useState, useRef } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { v4 as uuidv4 } from "uuid";

export const useWebSocket = (url: string, isJson: boolean = true) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<string>();
  const [client, setClient] = useState<W3CWebSocket | null>(null);

  const shouldReconnect = useRef(true); // 연결을 유지하려는지의 여부를 판단하는 ref

  useEffect(() => {
    const connect = () => {
      const client = new W3CWebSocket(url);

      setClient(client);

      client.onopen = () => {
        console.log(`WebSocket Client Connected : ${url}`);
        setError(undefined); // Reset the error state when successfully connected
      };

      client.onmessage = (message: any) => {
        console.log('onmessage:', message)
        return
        if (isJson) {
          const newData = JSON.parse(message.data);
          setData(newData);
        } else {
          setData(message.data + uuidv4());
        }
      };

      client.onerror = (error: any) => {
        console.error("WebSocket error:", error);
        setError("Failed to connect to the server. Please try again later.");
      };

      client.onclose = (event) => {
        // 연결이 끊어진 경우 재연결 시도
        if (shouldReconnect.current) {
          console.log("WebSocket closed, retrying...");
          setTimeout(connect, 5000); // 5초 후 재연결 시도
        }
      };
    };

    connect();

    return () => {
      shouldReconnect.current = false; // 컴포넌트가 unmount될 때 재연결 시도를 멈춤
      if (client) {
        client.close();
      }
    };
  }, [url]);

  return [data, error, client]; // Return both data and error
};

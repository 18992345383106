import { useForm } from "react-hook-form";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import ErrorMsg from "../../components/ErrorMsg";

type SigninForm = {
  userId: string;
};

export default function ScreenLogin() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<SigninForm>();

  const onValid = (formdata: SigninForm) => {
    navigate(`/screen/${formdata.userId}`);
  };

  return (
    <div className=" justify-center items-center min-h-screen flex p-20 flex-col">
      <img
        src="/main-logo.png"
        className=" w-56 tv:w-[1000px]  mb-5 "
        alt="logo"
      />

      <form className="space-y-5 tv:mt-20" onSubmit={handleSubmit(onValid)}>
        <div>
          <label className="block text-gray-700  mb-2 text-sm tv:text-5xl tv:mb-5 ">
            지점코드
          </label>
          <input
            {...register("userId", { required: "지점코드를 입력하세요" })}
            placeholder="지점코드를 입력하세요"
            className="border rounded-lg py-2 px-3 text-gray-700 w-full tv:text-6xl border-black tv"
          />

          <div className="mt-2">
            <ErrorMessage
              errors={errors}
              name="userId"
              render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
            />
          </div>
        </div>

        <button className="bg-[#2ea7e0] rounded-lg text-white w-full py-3 mt-10 tv:text-8xl tv:p-10 ">
          로그인
        </button>
      </form>
    </div>
  );
}

import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Button from "../interface/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBullhorn,
  faCamera,
  faDumbbell,
  faQrcode,
  faSignIn,
  faTv,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useBranchStore } from "../../store";

type SigninForm = {
  userId: string;
};

export default function ScreenMenu() {
  const { branchCode } = useParams();
  const storeBranchName = useBranchStore((state) => state.branchName);
  const navigate = useNavigate();

  const onWorkoutPage = () => {
    navigate(`/screen/${branchCode}/workout`);
  };
  const onQrPage = () => {
    navigate(`/screen/${branchCode}/qr`);
  };
  const onGroupPage = () => {
    navigate(`/screen/${branchCode}/group`);
  };

  const onAdsPage = () => {
    navigate(`/screen/${branchCode}/ads`);
  };

  return (
    <div className="mt-5  w-full max-w-lg tv:max-w-max flex flex-col justify-center items-cente tv:mt-20">
      <Button
        title={`운동 출력 화면`}
        onClick={onWorkoutPage}
        iconComponent={<FontAwesomeIcon icon={faTv} />}
      />
      <Button
        title={`예약자 그룹 배정 현황 화면`}
        onClick={onGroupPage}
        iconComponent={<FontAwesomeIcon icon={faUserGroup} />}
      />
      <Button
        title={`광고 화면`}
        onClick={onAdsPage}
        iconComponent={<FontAwesomeIcon icon={faBullhorn} />}
      />
      <Button
        title={`고객 표시용 QR 코드 화면`}
        onClick={onQrPage}
        iconComponent={<FontAwesomeIcon icon={faQrcode} />}
      />
    </div>
  );
}

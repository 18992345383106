import useMutation from "./useMutation";

// 타입 정의
type HandlePlayArgs = {
  id: number; // 추가
  branchCode: string;
  flag: string;
};

const useUpdateStreamingFlag = () => {
  const [updateStreamingPlay, { loading, data }] = useMutation(
    "/api/branch/streaming",
    "put"
  );

  const updateFlag = async ({ id, branchCode, flag }: HandlePlayArgs) => {
    await updateStreamingPlay({ flag, branchCode }, id); // id를 두 번째 인자로 넘깁니다.
  };

  return { updateFlag, loading, data };
};

export default useUpdateStreamingFlag;

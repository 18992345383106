import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
interface PaginationProps {
  totalCount: number;
  page: number;
  setPage: any;
  pageSize: number;
}

export const Pagination = ({
  totalCount,
  page,
  setPage,
  pageSize,
}: PaginationProps) => {
  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handlePreviousPage = () => {
    setPage(page - 1);
  };

  const handlePageClick = (pageNumber: any) => {
    setPage(pageNumber - 1);
  };

  const totalPages = Math.ceil(totalCount / pageSize); // 전체 페이지 수

  // 현재 페이지 기준으로 이전/다음 페이지, 현재 페이지를 기준으로 앞뒤 2페이지씩 총 5개의 페이지 번호 출력
  const pages = [];
  const prevPages = page > 3 ? [1, "..."] : [];
  const nextPages = page < totalPages - 2 ? ["...", totalPages] : [];
  for (
    let i = Math.max(1, page - 2);
    i <= Math.min(page + 2, totalPages);
    i++
  ) {
    pages.push(i);
  }

  return (
    <div className="flex items-center">
      <button onClick={handlePreviousPage} disabled={page === 1} className="">
        <FontAwesomeIcon icon={faChevronLeft} className=" h-4 " />
      </button>
      {prevPages.map((pageNumber, index) => (
        <span
          key={index}
          className="text-sm font-semibold mx-2"
          onClick={() => handlePageClick(pageNumber)}
          style={{ cursor: "pointer" }}
        >
          {pageNumber}
        </span>
      ))}
      {pages.map((pageNumber) => (
        <span
          key={pageNumber}
          className={`text-sm font-semibold mx-2 w-7 h-7 items-center flex justify-center text-center rounded-full ${
            pageNumber === page ? "bg-gymmate text-white" : ""
          }`}
          onClick={() => handlePageClick(pageNumber)}
          style={{ cursor: "pointer" }}
        >
          {pageNumber}
        </span>
      ))}
      {nextPages.map((pageNumber, index) => (
        <span
          key={index}
          className="text-sm  font-semibold mx-2"
          onClick={() => handlePageClick(pageNumber)}
          style={{ cursor: "pointer" }}
        >
          {pageNumber}
        </span>
      ))}
      <button onClick={handleNextPage} disabled={page === totalPages}>
        <FontAwesomeIcon icon={faChevronRight} className=" h-4 " />
      </button>
    </div>
  );
};

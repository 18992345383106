import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faBook,
  faCirclePlus,
  faCircleXmark,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faLocation,
  faSign,
  faTriangleExclamation,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import { useEffect, useState } from "react";

import { SecondsToMinutes, StringToDate } from "../../libs/utils";
import Table from "../interface/Table";
import { useUserStore } from "../../store";

import { useNavigate } from "react-router-dom";
import FullPageLoading from "../interface/FullPageLoading";
import Button from "../interface/Button";
import { TableHeaderForProgram } from "../../constants/TableHeader";
import { SearchDataForProgram } from "../../constants/SearchData";
import { Program, Programtype } from "../../types/models";
import Modal from "../interface/Modal";
import ProgramDetail from "../ProgramDetail";
import DynamicProgramDetail from "../DynamicProgramDetail";

interface ProgramsSelectModalProps {
  selectedData?: any;
  brandId: number;
}

export default function ProgramsSelectModal({
  selectedData,
  brandId,
}: ProgramsSelectModalProps) {
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();
  const [mutate, setMutate] = useState(false);

  const tableHeader = [
    { title: "No." },
    { title: "등록일" },
    { title: "프로그램 종류" },
    { title: "운동타입" },
    { title: "프로그램명" },
    { title: "동작수" },
    { title: "총세트수" },
    { title: "관리" },
  ];;
  //const searchData = SearchDataForProgram;

  const [loading, setLoading] = useState(false);
  const [actionType, setActionType] = useState(0);
  const [programTypeAllData, setProgramTypeAllData] = useState<Programtype[]>();

  const [modalProgramId, setModalProgramId] = useState("");

  useEffect(() => {
    const getProgramTypes = async () => {
      try {
        const getProgramType = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/programtype/all`, {
          withCredentials: true,
        });
        let result = []
        if(getProgramType.data) {
          for (let i = 0; i < getProgramType.data.length; i++) {
            console.log(getProgramType.data[i])
            result.push({...getProgramType.data[i], sname:getProgramType.data[i].name, name:`[${getProgramType.data[i].brandName}] - ${getProgramType.data[i].name}`})
            
          }
          result.sort((a:Programtype, b:Programtype)=>{
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          })
        }
        setProgramTypeAllData(result)
      } catch (error) {
      }
    };

    getProgramTypes()
  }, []);

  const onAddBtn = async (item: Program) => {
    try {
      selectedData(item);
      //@ts-ignore
      window.searchProgram.close();
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
    setLoading(false);
  };

  const handleOpenDetail = (id: string, _actionType:number) => {
    console.log('asd : ', actionType)
    setActionType(_actionType)
    setModalProgramId(id);
    //@ts-ignore
    window.programDetailModal.showModal();
  };
  
  const searchData = [
    {
      title: "프로그램 종류",
      value: [
        { name: "본운동" },
        { name: "준비운동" },
        { name: "마무리운동" },
        { name: "스트레칭" },
        { name: "추가운동" },
      ],
      searchKey: "name",
      searchFiled: "programType",
    },
    { title: "운동타입", value: programTypeAllData, searchKey: "sname", searchFiled: "programCategory" }
  ];

  return (
    <>
      <Modal
        id="programDetailModal"
        title={`프로그램 상세보기`}
        size="2xlarge"
        overflow="hidden"
      >
        <div className="mt-5">
          {
            actionType == 1?
            <ProgramDetail programId={modalProgramId} readOnly={true} />
            :
            <DynamicProgramDetail programId={modalProgramId} readOnly={true} />
          }
        </div>
      </Modal>
      {loading && <FullPageLoading />}
      <div className="mt-5">
        <Table
          dataApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/dynamicprogram?brandId=${brandId}`}
          headerArray={tableHeader}
          mutate={mutate}
          initSort="createdAt"
          initDirection="desc"
          searchData={searchData}
          searchApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/dynamicprogram/search?brandId=${brandId}`}
        >
          {(data: Program[], startIndex: number) =>
            data.map((item: any, index: number) => {
              // console.log(item);
              return (
                <tr key={item.id}>
                  <th>{startIndex - index}</th>
                  <td>
                    <StringToDate dateString={item?.createdAt.toString()} />
                  </td>
                  <td>{item?.programType}</td>
                  <td>{item?.programCategory}</td>
                  <td>
                    {item?.name}
                    <FontAwesomeIcon
                      className=" cursor-pointer ml-2 "
                      onClick={() => handleOpenDetail(item.id.toString(), item.actionType)}
                      icon={faArrowUpRightFromSquare}
                    />
                  </td>
                  <td className="">
                    <p> {item?.totalMotions}</p>

                    <p className="text-xs">{`(${item.exerciseTime}/${item.moveTime})`}</p>
                  </td>
                  <td className="">
                    <p>{item.totalSet}</p>

                    <p className="text-xs">
                      {" "}
                      <SecondsToMinutes seconds={item?.totalExerciseTime} />
                    </p>
                  </td>
                  {/* <td className="">
                    <p>
                      {item?.lastLectureDate
                        ? item?.lastLectureDate.toString()
                        : null}
                    </p>
                  </td> */}
                  <td>
                    <Button
                      loading={loading}
                      title="추가"
                      onClick={() => onAddBtn(item)}
                      iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
                    />
                  </td>
                </tr>
              );
            })
          }
        </Table>
      </div>
    </>
  );
}

import AdminLayout from "./Layout/AdminLayout";
import { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/ko";
import {
  SecondsToMinutes,
  cls,
  getBoxBackgroundClass,
  getDayClassNames,
} from "../libs/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faDice,
  faGear,
  faPlus,
  faPrint,
  faTrash,
  faUpRightAndDownLeftFromCenter,
} from "@fortawesome/free-solid-svg-icons";
import Modal from "../components/interface/Modal";
import ProgramsSearchModal from "../components/Modal/ProgramsSearchModal";
import axios from "axios";
import ProgramDetail from "../components/ProgramDetail";
import RandomSettingsModal from "../components/Modal/RandomSettingsModal";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../components/interface/Button";
import { ProgramType } from "../constants/ProgramCategory";
import { BrandDto, ProgramDtoWithScheduleId, ProgramSchedule } from "../types/dto";
import WeekButton from "../components/WeekButton";
import { useQuery } from "@tanstack/react-query";
import { getSchedules } from "../services/schedule";
import RunRandomModal from "./Modal/RunRandomModal";
import LoadingMsg from "./LoadingMsg";

interface RandomSelectForm {
  startDate: string;
  endDate: string;
  noDuplicates: boolean;
  isReset: boolean;
  isNullable: boolean;
  isOrderByRegistrationCountAsc: boolean;
}

export default function ScheduleCalender({
  readonly = false,
  selectBrand
}: {
  readonly?: boolean;
  selectBrand?:BrandDto;
}) {
  const [daysOfWeek, setDaysOfWeek] = useState<any>();
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();
  const [programByDayLoading, setProgramByDayLoading] = useState<number[]>([]);
  const [modalId, setModalId] = useState<string>("");
  const [dropdown, setDropdown] = useState({
    show: false,
    x: 0,
    y: 0,
  });
  const [programId, setProgramId] = useState("");
  const [scheduleId, setScheduleId] = useState<number>();
  const [brandId, setBrandId] = useState(0);
  const [brandType, setBrandType] = useState(0);
  
  const navigate = useNavigate();
  const { branchCode } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<RandomSelectForm>();

  useEffect(() => {
    if (daysOfWeek) {
      setStartDate(daysOfWeek[0].format("YYYY-MM-DD"));
      setEndDate(daysOfWeek[daysOfWeek.length - 1].format("YYYY-MM-DD"));
    }
  }, [daysOfWeek]);

  useEffect(() => {
    if (selectBrand) {
      console.log('selectBrand : ', selectBrand)
      setBrandId(selectBrand.id)
      setBrandType(selectBrand.type)
    }
  }, [selectBrand]);

  useEffect(() => {
    if (branchCode) {
      resetBrandID()
    }
  }, [branchCode]);

  const resetBrandID = async ()=>{
    if(branchCode)
    {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/branches/brandidnew/${branchCode}`);
      setBrandId(data.id)
      setBrandType(data.type)
    }
  }

  const {
    data: programByDay,
    isLoading,
    isError,
    refetch,
  } = useQuery<Record<string, ProgramSchedule>, Error>(
    ["getSchedules", startDate, endDate, brandId],
    () => getSchedules(startDate!, endDate!, brandId),
    {
      enabled: !!startDate && !!endDate,
    }
  );

  const ProgramTypeArray = ProgramType;

  const getBadgeClass = (programType: string) => {
    switch (programType) {
      case ProgramTypeArray[0].name:
        return "bg-gymmate";
      case ProgramTypeArray[1].name:
        return "bg-orange-400";
      case ProgramTypeArray[2].name:
        return "bg-blue-400";
      case ProgramTypeArray[3].name:
        return "bg-purple-400";
      default:
        return "bg-gymmate";
    }
  };

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        dropdown.show &&
        !e.target.closest(".dropdown-content") &&
        !e.target.closest(".menu")
      ) {
        setDropdown({
          show: false,
          x: 0,
          y: 0,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const handleProgramClick = (e: any, pId: string, sId: number) => {
    if(readonly)
    {
      return
    }
    e.preventDefault();
    setDropdown({
      show: true,
      x: e.clientX,
      y: e.clientY,
    });
    setProgramId(pId);
    setScheduleId(sId);
  };

  //-->삭제
  const onDelete = async () => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result || !scheduleId) {
      return;
    }

    setProgramByDayLoading([...programByDayLoading, scheduleId]);

    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}api/admin/schedule/${scheduleId}`, { withCredentials: true });
      refetch();
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }

    const newLoadingArray = programByDayLoading.filter(
      (item: number) => item !== scheduleId
    );

    setProgramByDayLoading(newLoadingArray);

    setDropdown({
      show: false,
      x: 0,
      y: 0,
    });
  };

  //모달 열기
  const showModal = (dayFormat: string) => {
    setModalId(dayFormat);
    //@ts-ignore
    window.searchProgram.showModal();
  };

  const showProgramDetailModal = () => {
    //@ts-ignore
    window.programDetail.showModal();
  };

  const [randomCategoryMutate, setRandomCategoryMutate] = useState(false);

  const openRandomSettingsModal = () => {
    setRandomCategoryMutate(true);
    //@ts-ignore
    window.randomSettings.showModal();
  };

  const onRandomSelect = () => {
    //@ts-ignore
    window.randomSelect.showModal();
  };

  console.log('brandId : ', brandId)
  return (
    <>
      {!readonly && (
        <>
          <Modal
            id="searchProgram"
            title={`프로그램 등록 (${modalId})`}
            size="xlarge"
            overflow="hidden"
          >
            <div>
              <ProgramsSearchModal
                modalId={modalId}
                refetch={refetch}
                brandId={brandId}
                brandType={brandType}
                selectedData={
                  programByDay && programByDay[modalId]
                    ? programByDay[modalId]
                    : undefined
                }
              />
            </div>
          </Modal>
          <Modal id="randomSettings" title={`랜덤 환경설정`} size="fit">
            <div className="mt-5">
              <RandomSettingsModal
                randomCategoryMutate={randomCategoryMutate}
                setRandomCategoryMutate={setRandomCategoryMutate}
                brandId={brandId}
              />
            </div>
          </Modal>
          <Modal id="randomSelect" title={`랜덤 선택`}>
            <RunRandomModal
              refetch={refetch}
              brandId={brandId}
              startDate={startDate}
              endDate={endDate}
            />
          </Modal>
        </>
      )}
      <Modal
        id="programDetail"
        title={`프로그램 상세보기`}
        size="2xlarge"
        overflow="hidden"
      >
        <div className="mt-5">
          <ProgramDetail programId={programId} readOnly={true} />
        </div>
      </Modal>

      <div className='block md:hidden lg:hidden xl:hidden 2xl:hidden'>
        <div className="mb-5">
          {isLoading ? <LoadingMsg /> : <div />}
          <WeekButton daysOfWeek={daysOfWeek} setDaysOfWeek={setDaysOfWeek} size='text-xs'/>
          <div className="flex items-end btn-group">
            <div className="flex-1"/>
            {!readonly && (
              <div className="flex items-end btn-group">
                <button
                  className=" btn-primary btn w-fit text-sm text-white "
                  onClick={onRandomSelect}
                >
                  <FontAwesomeIcon icon={faDice} />
                  랜덤 선택
                </button>
                <button className="btn" onClick={openRandomSettingsModal}>
                  <FontAwesomeIcon icon={faGear} />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col space-y-2">
          {daysOfWeek?.map((day: any, index: number) => (
            <div
              key={`schedulecalendar_daysOfWeek_${index}`}
              className={cls(
                "w-full  flex justify-between rounded-lg shadow-md overflow-hidden",
                getBoxBackgroundClass(day)
              )}
            >
              <div className="flex p-2 flex-col space-y-2 w-1/6">
                <p className={getDayClassNames(day)}>{day.format("MM")}</p>
                <p className={cls("text-xl font-bold", getDayClassNames(day))}>
                  {day.format("DD")}
                </p>
                <p className={cls("font-bold mt-2", getDayClassNames(day))}>
                  {day.format("ddd")}
                </p>
              </div>
              <div className="w-1/2 flex flex-col px-1 py-3 space-y-2">
                {isLoading &&
                  Array.from({ length: 4 }).map((_, cellIndex) => {
                    const widths = [
                      "w-1/2",
                      "w-1/3",
                      "w-1/4",
                      "w-2/3",
                      "w-3/4",
                    ]; // add more classes if needed
                    const randomWidth =
                      widths[Math.floor(Math.random() * widths.length)];
                    return (
                      <div
                        key={`schedule_calendar_${cellIndex}`}
                        className={`${randomWidth} h-[20px] skeleton-box rounded-md `}
                      ></div>
                    );
                  })}

                {programByDay &&
                  programByDay[day.format("YYYY-MM-DD")] &&
                  //@ts-ignore
                  programByDay[day.format("YYYY-MM-DD")].map(
                    (program: ProgramDtoWithScheduleId) => {
                      const combinedText = `${program.name} ${
                        program.description && `(${program.description})`
                      }`;

                      const badgeClass = getBadgeClass(program.programType);
                      return (
                        <div className="flex items-center space-x-1 w-full ">
                          <div
                            onClick={(e) =>
                              handleProgramClick(
                                e,
                                program.id.toString(),
                                program.scheduleId
                              )
                            }
                            className={` rounded-md p-2 border-2 border-white no-animation text-xs ${badgeClass} justify-start text-white w-full`}
                          >
                            <p>
                              {`[${program.programType} = ${program.programCategory}] | `}<br/><p className="line-clamp-1">{combinedText}</p>{` | ${program.totalSet} set ${`(${program.exerciseTime}/${program.moveTime})`} | `}<SecondsToMinutes seconds={program?.totalExerciseTime}/>
                            </p>
                            {/* <p>|</p>
                            <p className=" truncate max-w-lg  ">
                              {combinedText}
                            </p>
                            <p>|</p>
                            <p>
                              {program.totalSet} set{" "}{`(${program.exerciseTime}/${program.moveTime})`}
                            </p>
                            <p>|</p>
                            <p>
                              <SecondsToMinutes
                                seconds={program?.totalExerciseTime}
                              />
                            </p> */}
                          </div>
                          {programByDayLoading &&
                            programByDayLoading.includes(
                              program.scheduleId
                            ) && (
                              <span className="loading loading-spinner loading-xs"></span>
                            )}
                        </div>
                      );
                    }
                  )}
              </div>
              <div className="w-1/4 flex">
                <div
                  onClick={() =>{
                    if(readonly)
                    {
                      navigate(`/branch/${branchCode}/program/${day.format("YYYY-MM-DD")}?brandId=${brandId}`);
                    }
                    else
                    {
                      navigate(`/admin/schedule/${day.format("YYYY-MM-DD")}?brandId=${brandId}`);
                    }
                  }}
                  className="border-l w-28 flex justify-center hover:bg-gray-400 cursor-pointer transition-all items-center border-white"
                >
                  <FontAwesomeIcon icon={faPrint} />
                </div>
                {!readonly && (
                  <div
                    onClick={() => showModal(day.format("YYYY-MM-DD"))}
                    className="border-l w-28 flex justify-center hover:bg-gray-400 cursor-pointer transition-all items-center border-white"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='hidden md:block lg:block xl:block 2xl:block'>
        <div className="">
          <div className=" text-4xl text-gray-700 text-center font-bold my-4">{selectBrand?selectBrand.name:''}</div>
          <div className="grid grid-cols-3 mb-5">
            {isLoading ? <LoadingMsg /> : <div />}
            <WeekButton daysOfWeek={daysOfWeek} setDaysOfWeek={setDaysOfWeek} />
            {!readonly && (
              <div className="btn-group  place-self-end ">
                <button
                  className=" btn-primary btn w-fit  text-white  "
                  onClick={onRandomSelect}
                >
                  <FontAwesomeIcon icon={faDice} />
                  랜덤 선택
                </button>
                <button className="btn" onClick={openRandomSettingsModal}>
                  <FontAwesomeIcon icon={faGear} />
                </button>
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-2">
            {daysOfWeek?.map((day: any, index: number) => (
              <div
                key={`schedulecalendar_daysOfWeek_${index}`}
                className={cls(
                  "w-full  flex justify-between rounded-lg shadow-md overflow-hidden",
                  getBoxBackgroundClass(day)
                )}
              >
                <div className="flex p-5 flex-col space-y-2">
                  <p className={getDayClassNames(day)}>{day.format("MM")}</p>
                  <p className={cls("text-xl font-bold", getDayClassNames(day))}>
                    {day.format("DD")}
                  </p>
                  <p className={cls("font-bold mt-2", getDayClassNames(day))}>
                    {day.format("ddd")}
                  </p>
                </div>
                <div className="w-full flex flex-col p-5 space-y-2">
                  {isLoading &&
                    Array.from({ length: 4 }).map((_, cellIndex) => {
                      const widths = [
                        "w-1/2",
                        "w-1/3",
                        "w-1/4",
                        "w-2/3",
                        "w-3/4",
                      ]; // add more classes if needed
                      const randomWidth =
                        widths[Math.floor(Math.random() * widths.length)];
                      return (
                        <div
                          key={`schedule_calendar_${cellIndex}`}
                          className={`${randomWidth} h-[20px] skeleton-box rounded-md `}
                        ></div>
                      );
                    })}

                  {programByDay &&
                    programByDay[day.format("YYYY-MM-DD")] &&
                    //@ts-ignore
                    programByDay[day.format("YYYY-MM-DD")].map(
                      (program: ProgramDtoWithScheduleId) => {
                        const combinedText = `${program.name} ${
                          program.description?`(${program.description})`:''
                        }`;

                        const badgeClass = getBadgeClass(program.programType);
                        return (
                          <div className="flex items-center space-x-1 ">
                            <div
                              onClick={(e) =>
                                handleProgramClick(
                                  e,
                                  program.id.toString(),
                                  program.scheduleId
                                )
                              }
                              className={`btn no-animation whitespace-nowrap flex-nowrap btn-xs ${badgeClass} justify-start w-fit text-white `}
                            >
                              <p>
                                [{program.programType} - {program.programCategory}
                                ]
                              </p>
                              <p>|</p>
                              <p className=" truncate max-w-lg  ">
                                {combinedText}
                              </p>
                              <p>|</p>
                              <p>
                                {program.totalSet} set{" "}
                                {`(${program.exerciseTime}/${program.moveTime})`}
                              </p>
                              <p>|</p>
                              <p>
                                <SecondsToMinutes
                                  seconds={program?.totalExerciseTime}
                                />
                              </p>
                            </div>
                            {programByDayLoading &&
                              programByDayLoading.includes(
                                program.scheduleId
                              ) && (
                                <span className="loading loading-spinner loading-xs"></span>
                              )}
                          </div>
                        );
                      }
                    )}
                </div>
                <div
                  onClick={() =>{
                    if(readonly)
                    {
                      navigate(`/branch/${branchCode}/program/${day.format("YYYY-MM-DD")}?brandId=${brandId}`);
                    }
                    else
                    {
                      navigate(`/admin/schedule/${day.format("YYYY-MM-DD")}?brandId=${brandId}`);
                    }
                  }}
                  className="border-l w-28 flex justify-center hover:bg-gray-400 cursor-pointer transition-all items-center min-h-full border-white"
                >
                  <FontAwesomeIcon icon={faPrint} />
                </div>
                {!readonly && (
                  <div
                    onClick={() => showModal(day.format("YYYY-MM-DD"))}
                    className="border-l w-28 flex justify-center hover:bg-gray-400 cursor-pointer transition-all items-center min-h-full border-white"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      

      {dropdown.show && (
        <div style={{ position: "fixed", top: dropdown.y, left: dropdown.x }}>
          <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box text-xs ">
            {/* <li onClick={showProgramDetailModal}>
              <a>
                <FontAwesomeIcon icon={faUpRightAndDownLeftFromCenter} />
                상세보기
              </a>
            </li> */}
            {!readonly && (
              <li onClick={onDelete}>
                <a>
                  <FontAwesomeIcon icon={faTrash} /> 삭제
                </a>
              </li>
            )}
          </ul>
        </div>
      )}
    </>
  );
}

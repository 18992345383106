import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCirclePlus
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../../../components/Layout/AdminLayout";
import { useEffect, useState } from "react";
import { getUseEditFromAuth } from "../../../libs/utils";
import Table from "../../../components/interface/Table";
import { useUserStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import { TableHeaderForLayout } from "../../../constants/TableHeader";
import { LayoutDto } from "../../../types/dto";
import LayoutTableItem from "./LayoutTableItem";

export default function Layout() {
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();
  const [mutate, setMutate] = useState(false);
  const [searchData, setSearchData] = useState<any[]>();
  const [brandAuth, setBrandAuth] = useState<any[]>();

  const tableHeader = TableHeaderForLayout;

  useEffect(() => {
    const getBrands = async () => {
      try {
        const getbrand = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`, {
          withCredentials: true,
        });

        let searchList = []
        for (let i = 0; i < getbrand.data.length; i++) {
          searchList.push({
            name: getbrand.data[i].name,
            value: getbrand.data[i].id
          })
        }
        let SearchDatalayout = [
          {
            title: "브랜드 종류",
            value: searchList,
            searchKey: "value",
            searchFiled: "brandId",
          }
        ];

        console.log('SearchDatalayout : ', SearchDatalayout)
        setSearchData(SearchDatalayout)
      } catch (error) {
      }
    };

    getBrands()
  }, []);

  
  useEffect(() => {
    if(user) {
      const _brandAuth = JSON.parse(user.brandAuth);
      if(_brandAuth)
      {
        setBrandAuth(_brandAuth)
      }
    }
  }, [user]);

  const onAddBtn = () => {
    navigate(`/admin/layout/add`);
  };

  const onEditLayout = (id: number) => {
    navigate(`/admin/layout/edit/${id}`);
  };

  const onDelete = async (item: any) => {
    if(item.programCount > 0) {
      alert('사용 중인 프로그램은 삭제 할 수 없습니다.')
      return;
    }
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}api/admin/layout/${item.id}`, { withCredentials: true });
      console.log(response);
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  const getOption = (item:LayoutDto)=>{
    let result = item.optionType == 'team'?'팀로테이션':'전체로테이션'
    if(item.optionType == 'team'){
      result += `/${item.optionTeamCount}팀`

      if(item.optionTeamRotate == 1) {
        result += `/팀교대`
      }
    }
    if(item.optionMission == 1) {
      result += `/미션`
    }
    if(item.optionOver == 1) {
      result += `/OVER`
    }
    if(item.optionAdd == 1) {
      result += `/ADD`
    }
    if(item.optionWod == 1) {
      result += `/WOD`
    }

    return result;
  }

  return (
    <>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">{'레이아웃 관리'}</h1>
          <Table
            dataApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/layout`}
            buttonComponent={
              <button
                className=" btn-primary btn   text-white  "
                onClick={onAddBtn}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                레이아웃 등록
              </button>
            }
            headerArray={tableHeader}
            mutate={mutate}
            initSort="createdAt"
            initDirection="desc"
            searchData={searchData}
            searchApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/layout/search`}
          >
            {(data: any, startIndex: number) =>
              data.map((item: LayoutDto, index: number) => {
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>
                    <td>{item?.brandName}</td>
                    <td>{item?.name}</td>
                    <td>{getOption(item)}</td>
                    <td>
                      <LayoutTableItem item={item}/>
                    </td>
                    <td>{item?.programCount}</td>
                    <td className="flex space-x-2">
                      <>
                        <button
                          onClick={() => onEditLayout(item.id)}
                          className="btn btn-xs"
                          disabled={!getUseEditFromAuth('6', item?.brandId, brandAuth || [])}
                        >
                          수정
                        </button>
                        <button
                          onClick={() => onDelete(item)}
                          className="btn btn-xs btn-error"
                          disabled={!getUseEditFromAuth('6', item?.brandId, brandAuth || [])}
                        >
                          삭제
                        </button>
                      </>
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}

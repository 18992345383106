import { useEffect, useRef, useState } from "react";
import { useReactToPrint } from 'react-to-print';
import Modal from "./interface/Modal";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { ContentsDtoWithId, DynamicProgramDto, LayoutDto, ProgramDto } from "../types/dto";
import { useQuery } from "@tanstack/react-query";
import { getSchedules } from "../services/schedule";
import { Part, Tool } from "../types/models";
import LayoutTableItem from "../pages/Admin/Layout/LayoutTableItem";

export default function ScheduleExcel() {
  const { date } = useParams();

  //--> brandId 가져오기
  const location = useLocation();
  const queryString = location.search;
  const params = new URLSearchParams(queryString);
  const brandId = params.get("brandId")

  const componentRef: any = useRef();
  const handlePrint = useReactToPrint({content: () =>{return componentRef.current as any}});

  const [programDataList, setProgramDataList] = useState<ProgramDto[]>();
  const [dynamicProgramDataList, setDynamicProgramDataList] = useState<DynamicProgramDto[]>();
  const [layoutDataList, setLayoutDataList] = useState<LayoutDto[]>();
  
  const [videoUrl, setVideoUrl] = useState<string>();
  

  const { data:scheduleData } = useQuery<any, Error>(["getSchedules"], () =>getSchedules(date || '', date || '', Number(brandId)) );
  
  useEffect(() => {
    getProgram()
  }, [scheduleData]);

  const getProgram = async ()=>{
    //--> 프로그램에 필요한 정보 받아서 정리
    if(scheduleData && scheduleData[date || ""])
    {
      const scheduleProgramList = scheduleData[date || ""]

      let programIds = scheduleProgramList.map((item:any)=>{ return item.id})
      const programsimpleRes = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/programsimple`, { withCredentials: true, params:{ids:programIds.toString()} });
      
      const _programDataList = []
      const _dynamicProgramDataList = []
      const _layoutDataList = []
      if(programsimpleRes && programsimpleRes.data) {
        for (let i = 0; i < programsimpleRes.data.length; i++) {
          const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/dynamicprogram/${programsimpleRes.data[i].id}`, { withCredentials: true });
          if(programsimpleRes.data[i].actionType == 1) {
            _programDataList.push(response.data)
          }
          else
          {
            console.log('response.data : ', response.data)
            const layoutresponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/layout/${response.data.program.layoutId}`, { withCredentials: true });
            _layoutDataList.push(layoutresponse.data)
            _dynamicProgramDataList.push(response.data)
            
          }
        }
      }

      setProgramDataList(_programDataList)
      setDynamicProgramDataList(_dynamicProgramDataList)
      setLayoutDataList(_layoutDataList)
    }
  }

  //--> 카디오 정보 보기( 있는 경우 만)
  const getProgramCadioList = (item:any)=>{
    console.log('item.cadioStrength : ', item.cadioStrength)
    if(!item.cadioStrength) {
      return []
    }
    const strengthList = JSON.parse(item.cadioStrength)

    if(item.programType != '본운동')
    {
      return []
    }
    
    let result = []
    if(strengthList.length > 2)
    {
      let color = 'FREE'
      for (let j = 0; j < strengthList[0].length; j++) {
        const loopList = [strengthList[0][j], strengthList[1][j], strengthList[2][j]]
        for (let k = 0; k < loopList.length; k++) {
          color = 'FREE'
          for (let l = 0; l < loopList[k].length; l++) {
            if(loopList[k][l] == 'ROLL-UP' && color == 'FREE')
            {
              color = 'ROLL-UP'
            }
            
            if(loopList[k][l] == 'ALL-OUT')
            {
              color = 'ALL-OUT'
            }
          }
          result.push(color)
          result.push(color)
        }
      }
    }
    
    return result
  }

  //--> 툴 정보
  const getToolsFromProgram = (program:ProgramDto)=>{
    const tool1s = extractTools(program.zone1Data)
    const tool2s = extractTools(program.zone2Data)
    const tool3s = extractTools(program.zone3Data)

    const toolNames = Array.from(
      new Set([...tool1s, ...tool2s, ...tool3s])
    );

    if(toolNames)
    {
      return toolNames.join(',')
    }

    return
  }
  const getToolsFromDynamicProgram = (program:DynamicProgramDto)=>{
    const tools = extractTools(program.content)

    const toolNames = Array.from(
      new Set([...tools])
    );

    if(toolNames)
    {
      return toolNames.join(',')
    }

    return
  }
  
  //--> 파트 정보
  const getPartsFromProgram = (program:ProgramDto)=>{
    const part1s = extractParts(program.zone1Data)
    const part2s = extractParts(program.zone2Data)
    const part3s = extractParts(program.zone3Data)

    const partNames = Array.from(
      new Set([...part1s, ...part2s, ...part3s])
    );

    if(partNames)
    {
      return partNames.join(',')
    }

    return
  }
  const getPartsFromDynamicProgram = (program:DynamicProgramDto)=>{
    const parts = extractParts(program.content)

    const partNames = Array.from(
      new Set([...parts])
    );

    if(partNames)
    {
      return partNames.join(',')
    }

    return
  }

  //--> 시간 정보
  const getDynamicProgramTime = (program:DynamicProgramDto)=>{
    let _exerciseTime = program.program.exerciseTime
    let _moveTime = program.program.moveTime

    if(_exerciseTime == 0) {
      const _exerciseTimes = program.program.exerciseTimes.split(',')
      if(_exerciseTimes.length > 0) {
        _exerciseTime = parseInt(_exerciseTimes[0])
      }
    }
    if(_moveTime == 0) {
      const _moveTimes = program.program.moveTimes.split(',')
      if(_moveTimes.length > 0) {
        _moveTime = parseInt(_moveTimes[0])
      }
    }

    return `${_exerciseTime}/${_moveTime}`
  }

  //--> 옵션 정보
  const getDynamicProgramOption = (program:DynamicProgramDto)=>{
    let result = ''
    if(layoutDataList && (layoutDataList.length > 0)) {
      for (let i = 0; i < layoutDataList.length; i++) {
        if(layoutDataList[i].id == program.program.layoutId) {
          result = layoutDataList[i].optionType == 'team'?'팀로테이션':'전체로테이션'
          if(layoutDataList[i].optionType == 'team'){
            result += `/${layoutDataList[i].optionTeamCount}팀`
      
            if(layoutDataList[i].optionTeamRotate == 1) {
              result += `/팀교대`
            }
          }
          if(layoutDataList[i].optionMission == 1) {
            result += `/미션`
          }
          if(layoutDataList[i].optionOver == 1) {
            result += `/OVER`
          }
          if(layoutDataList[i].optionAdd == 1) {
            result += `/ADD`
          }
          if(layoutDataList[i].optionWod == 1) {
            result += `/WOD`
          }
          return result
        }
        
      }
    }

    return result
  }
  
  //--> 레이아웃 수
  const getDynamicProgramLayoutCount = (program:DynamicProgramDto)=>{
    if(layoutDataList && (layoutDataList.length > 0)) {
      for (let i = 0; i < layoutDataList.length; i++) {
        if(layoutDataList[i].id == program.program.layoutId) {
          return layoutDataList[i].totalCount
        }
      }
    }
    return 0
  }
  
  //--> 추가운동
  const getDynamicProgramAddProgram = (program:DynamicProgramDto)=>{
    if(program.program.addPrograms) {
      let addprogram = JSON.parse(program.program.addPrograms)

      let result = `${addprogram.length}회 (`

      for (let i = 0; i < addprogram.length; i++) {
        if(i != 0) {
          result += ', '
        }
        result += `${addprogram[i].set}set`
      }
      result += `)`

      return result;
    }

    return '없음'
  }
  
  //--> 물먹는 시간 정보
  const getDynamicProgramWater = (program:DynamicProgramDto)=>{
    if(program.program.waterTimes) {
      let waterTimes = JSON.parse(program.program.waterTimes)

      let result = `${waterTimes.length}회 (`

      for (let i = 0; i < waterTimes.length; i++) {
        if(i != 0) {
          result += ', '
        }
        result += `${waterTimes[i].set}set ${waterTimes[i].time}초`
      }
      result += `)`

      return result;
    }
    
    return '없음'
  }
  
  //--> 레이아웃 정보
  const getDynamicProgramLayout = (program:DynamicProgramDto)=>{
    if(layoutDataList && (layoutDataList.length > 0)) {
      for (let i = 0; i < layoutDataList.length; i++) {
        if(layoutDataList[i].id == program.program.layoutId) {
          return layoutDataList[i]
        }
      }
    }
    return null
  }

  //--> 툴 정보
  const getToolsFromContent = (content:ContentsDtoWithId)=>{
    const toolNames = Array.from( new Set( content.tools.map((tool: Tool) => tool.name).flat() ) )
    
    return toolNames
  }

  //--> 파트 정보
  const getPartsFromContent = (content:ContentsDtoWithId)=>{
    const partNames = Array.from( new Set( content.parts.map((part: Part) => part.name).flat() ) )
    
    return partNames
  }

  //--> 툴 정보
  const extractTools = (arr: ContentsDtoWithId[]) => {
    const toolNames = Array.from(
      new Set(
        arr
          .map((item: ContentsDtoWithId) =>
            item.tools.map((tool: Tool) => tool.name)
          )
          .flat()
      )
    );

    return toolNames;
  };

  //--> 파트 정보
  const extractParts = (arr: ContentsDtoWithId[]) => {
    const partNames = Array.from(
      new Set(
        arr
          .map((item: ContentsDtoWithId) =>
            item.parts.map((part: Part) => part.name)
          )
          .flat()
      )
    );

    return partNames;
  };

  //--> 컨텐츠 정보 불러오기
  const getContent = (program:ProgramDto, contentId:number)=>{
    let result = program.zone1Data.filter((_content:ContentsDtoWithId)=>_content.content.id == contentId)

    if(result && result.length > 0)
    {
      return result[0]
    }
    result = program.zone2Data.filter((_content:ContentsDtoWithId)=>_content.content.id == contentId)

    if(result && result.length > 0)
    {
      return result[0]
    }
    result = program.zone3Data.filter((_content:ContentsDtoWithId)=>_content.content.id == contentId)

    if(result && result.length > 0)
    {
      return result[0]
    }
    
    return null
  }

  const openVideoModal = (item: ContentsDtoWithId) => {
    if (!item.content.videoUrl) {
      return;
    }
    setVideoUrl(item.content.videoUrl);
    //@ts-ignore
    window.videoModal.showModal();
  };

  return (
    <>
      <Modal id="videoModal" title={`영상`}>
        <video src={videoUrl} className="border mt-5" controls />
      </Modal>
      <div className='block md:hidden lg:hidden xl:hidden 2xl:hidden'>
        <div
          onClick={handlePrint}
          className="btn btn-md mx-2 my-4 btn-neutral px-10"
        >
          인쇄
        </div>
        <div ref={componentRef} className="p-1">
          {
            (dynamicProgramDataList && dynamicProgramDataList.length > 0)?
            <>
            {
              dynamicProgramDataList.map((item:DynamicProgramDto, index:number)=>{
                console.log('item : ', item)
                //const contentIds = item.program.contentsIds.split(',');
                return (
                  <table className=" table mt-1 mb-12 border-2 border-collapse border-spacing-0 border-black text-[11px]">
                    <tbody>
                        <tr>
                          <th className=" border text-center px-1 py-1">{date || ''}</th>
                          <th className=" border text-center px-1 py-1">{item.program.programType}</th>
                          <th colSpan={2} className=" border px-1 text-center py-1">{getPartsFromDynamicProgram(item)}</th>
                          <th className=" border text-center px-1 py-1">{item.program.name}</th>
                        </tr>
                        <tr>
                          <th className=" border text-center px-1 py-1">{`${item.program.totalMotions}동작 (${item.program.totalSet}R)`}</th>
                          <th colSpan={2} className=" border px-1 text-center py-1">{getToolsFromDynamicProgram(item)}</th>
                          <th className=" border text-center px-1 py-1">{getDynamicProgramTime(item)}</th>
                          <th className=" border text-center px-1 py-1">{`${((Math.floor(item.program.totalExerciseTime/60)))}:${('0' + (Math.floor(item.program.totalExerciseTime%60))).slice(-2)}`}</th>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'커리큘럼명'}</td>
                          <td colSpan={5} className=" border px-5 py-1" >{item.program.curriculumName}</td>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'옵션'}</td>
                          <td colSpan={5} className=" border px-5 py-1" >{getDynamicProgramOption(item)}</td>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'영역'}</td>
                          <td colSpan={5} className=" border px-5 py-1" >{getDynamicProgramLayoutCount(item)}영역</td>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'추가운동'}</td>
                          <td colSpan={5} className=" border px-5 py-1" >{getDynamicProgramAddProgram(item)}</td>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'물먹는시간'}</td>
                          <td colSpan={5} className=" border px-5 py-1" >{getDynamicProgramWater(item)}</td>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'레이아웃'}</td>
                          <td colSpan={5} className=" border py-1" >
                            <LayoutTableItem small={true} item={getDynamicProgramLayout(item) || undefined}/>
                          </td>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'수업\n코멘트'}</td>
                          <td colSpan={5} className=" border px-5 py-1" >{item.program.comment}</td>
                        </tr>
                        <tr className=" bg-black">
                          <th colSpan={2} className=" border border-white text-white text-center py-1">{'운동명'}</th>
                          <th colSpan={3} className=" border border-white text-white text-center py-1" >{'동작'}</th>
                        </tr>
                        <tr className=" bg-black">
                          <th colSpan={5} className=" border border-white text-white text-center py-1" >{'수업 코멘트'}</th>
                        </tr>
                        {
                          item.content.map((contentItem:ContentsDtoWithId, contentIndex:number)=>{
                            if(!contentItem)
                            {
                              return null
                            }

                            const toolNames = getToolsFromContent(contentItem)
                            const partNames = getPartsFromContent(contentItem)
                            
                            return (
                              <>
                                <tr className={contentIndex%2 == 0?' bg-white':' bg-gray-100'}>
                                  <td className=" border text-center py-1">{toolNames || ''}</td>
                                  <td className=" border text-center py-1">{partNames || ''}</td>
                                  <td align="center" rowSpan={2} colSpan={4} className=" border bg-white p-0 m-0">
                                    <img
                                      src={contentItem.content.thumbnailUrl || ''}
                                      onClick={() => openVideoModal(contentItem)}
                                      className="w-24 object-cover"
                                      alt="program background"
                                    />
                                  </td>
                                </tr>
                                <tr className={contentIndex%2 == 0?' bg-white':' bg-gray-100'}>
                                  <td colSpan={2} className=" border  text-center py-1">{contentItem.content.name}</td>
                                </tr>
                                <tr className={contentIndex%2 == 0?' bg-white':' bg-gray-100'}>
                                  <td colSpan={5}   className={` ${contentItem.content.comment?'':'text-slate-300'} border py-1`}>{contentItem.content.comment?contentItem.content.comment:'코멘트 없음'}</td>
                                </tr>
                              </>
                            )
                          })
                        }
                    </tbody>
                  </table>
                )
              })
            }
            </>
            :null
          }
          {
            (programDataList && programDataList.length > 0)?
            <>
            {
              programDataList.map((item:ProgramDto, index:number)=>{
                
                const contentIds = item.program.contentsIds.split(',');
                const cadioList = getProgramCadioList(item.program)
                return (
                  <table className=" table mt-1 mb-12 border-2 border-collapse border-spacing-0 border-black text-[11px]">
                    <tbody>
                        <tr>
                          <th className=" border text-center px-1 py-1">{date || ''}</th>
                          <th className=" border text-center px-1 py-1">{item.program.programType}</th>
                          <th colSpan={2} className=" border px-1 text-center py-1">{getPartsFromProgram(item)}</th>
                          <th className=" border text-center px-1 py-1">{item.program.name}</th>
                        </tr>
                        <tr>
                          <th className=" border text-center px-1 py-1">{`${item.program.totalMotions}동작 (${item.program.totalSet}R)`}</th>
                          <th colSpan={2} className=" border px-1 text-center py-1">{getToolsFromProgram(item)}</th>
                          <th className=" border text-center px-1 py-1">{`${item.program.exerciseTime}/${item.program.moveTime}`}</th>
                          <th className=" border text-center px-1 py-1">{`${((Math.floor(item.program.totalExerciseTime/60)))}:${('0' + (Math.floor(item.program.totalExerciseTime%60))).slice(-2)}`}</th>
                        </tr>
                        {
                          item.program.programType == '본운동'?
                          <tr>
                            <td className=" border text-center py-1">{'유산소\n미리보기'}</td>
                            <td colSpan={5} className=" border px-1 py-1" >
                              
                              <div className="flex">
                                {
                                  cadioList.map((cadioItem:string, cadioIndex:number)=>{
                                    return (
                                      <div className="ml-[2px] items-center">
                                        <div className=" text-center m-0 text-[6px]">{cadioIndex +1}</div>
                                        {
                                          cadioItem == 'FREE'?
                                          <div className={` w-[6px] h-4 bg-[#00C7F2]`}/>
                                          :
                                          cadioItem == 'ROLL-UP'?
                                          <div className={` w-[6px] h-4 bg-[#FE06F2]`}/>
                                          :
                                          cadioItem == 'ALL-OUT'?
                                          <div className={` w-[6px] h-4 bg-[#FF0000]`}/>
                                          :
                                          <div className={` w-[6px] h-4 bg-[#D2D2D2]`}/>
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </td>
                          </tr>
                          :null
                        }
                        {
                          item.program.programType == '본운동'?
                          <tr>
                            <td className=" border text-center py-1">{'FLOOR'}</td>
                            <td colSpan={5} className=" border px-5 py-1" >{item.program.floor}</td>
                          </tr>
                          :null
                        }
                        {
                          item.program.programType == '본운동'?
                          <tr>
                            <td className=" border text-center py-1">{'프로그램\n설명'}</td>
                            <td colSpan={5} className=" border px-5 py-1" >{item.program.description}</td>
                          </tr>
                          :null
                        }
                        <tr>
                          <td className=" border text-center py-1">{'수업\n코멘트'}</td>
                          <td colSpan={5} className=" border px-5 py-1" >{item.program.comment}</td>
                        </tr>
                        <tr className=" bg-black">
                          <th colSpan={2} className=" border border-white text-white text-center py-1">{'운동명'}</th>
                          <th colSpan={1} className=" border border-white text-white text-center py-1" >{'동작'}</th>
                          <th colSpan={2} className=" border border-white text-white text-center py-1" >{'세트'}</th>
                        </tr>
                        <tr className=" bg-black">
                          <th colSpan={5} className=" border border-white text-white text-center py-1" >{'수업 코멘트'}</th>
                        </tr>
                        {
                          contentIds.map((contentId:string, contentIndex:number)=>{
                            const contentItem = getContent(item, parseInt(contentId))

                            if(!contentItem)
                            {
                              return null
                            }

                            const toolNames = getToolsFromContent(contentItem)
                            const partNames = getPartsFromContent(contentItem)
                            
                            return (
                              <>
                                <tr className={contentIndex%2 == 0?' bg-white':' bg-gray-100'}>
                                  <td className=" border text-center py-1">{toolNames || ''}</td>
                                  <td className=" border text-center py-1">{partNames || ''}</td>
                                  <td align="center" rowSpan={2} className=" border bg-white p-0 m-0">
                                    <img
                                      src={contentItem.content.thumbnailUrl || ''}
                                      onClick={() => openVideoModal(contentItem)}
                                      className="w-24 object-cover"
                                      alt="program background"
                                    />
                                  </td>
                                  <td rowSpan={2} colSpan={2} className={` border text-center py-1`}>
                                    <div>
                                      {
                                        (contentItem.sets && contentItem.sets.length > 0)?
                                        contentItem.sets.map((setItem)=>{
                                          return (<div>{setItem}</div>)
                                        })
                                        :null
                                      }
                                    </div>
                                  </td>
                                </tr>
                                <tr className={contentIndex%2 == 0?' bg-white':' bg-gray-100'}>
                                  <td colSpan={2} className=" border  text-center py-1">{contentItem.content.name}</td>
                                </tr>
                                <tr className={contentIndex%2 == 0?' bg-white':' bg-gray-100'}>
                                  <td colSpan={5}   className={` ${contentItem.content.comment?'':'text-slate-300'} border py-1`}>{contentItem.content.comment?contentItem.content.comment:'코멘트 없음'}</td>
                                </tr>
                              </>
                            )
                          })
                        }
                    </tbody>
                  </table>
                )
              })
            }
            </>
            :null
          }
        </div>
      </div>
      <div className='hidden md:block lg:block xl:block 2xl:block'>
        <div
          onClick={handlePrint}
          className="btn btn-md mx-10 my-4 btn-neutral px-10"
        >
          인쇄
        </div>
        <div ref={componentRef} className=" p-10">
        {
            (dynamicProgramDataList && dynamicProgramDataList.length > 0)?
            <>
            {
              dynamicProgramDataList.map((item:DynamicProgramDto, index:number)=>{
                return (
                  <table className=" table my-10 border-2 border-black text-xs">
                    <tbody>
                        <tr>
                          <th className=" border text-center py-1">{date || ''}</th>
                          <th className=" border text-center py-1">{item.program.programType}</th>
                          <th className=" border text-center py-1">{getPartsFromDynamicProgram(item)}</th>
                          <th className=" border text-center py-1">{item.program.name}</th>
                          <th className=" border text-center py-1">{`${item.program.totalSet}Set`}</th>
                          <th className=" border text-center py-1">{getToolsFromDynamicProgram(item)}</th>
                          <th className=" border text-center py-1">{`${item.program.exerciseTime}/${item.program.moveTime}`}</th>
                          <th className=" border text-center py-1">{`${((Math.floor(item.program.totalExerciseTime/60)))}:${('0' + (Math.floor(item.program.totalExerciseTime%60))).slice(-2)}`}</th>
                        </tr>
                        {
                          Number(brandId) == 3?
                          <tr>
                            <td className=" border text-center py-1">{'BPM'}</td>
                            <td colSpan={7} className=" border px-5 py-1" >{item.program.bpm} BPM<br></br><br></br>{item.program.bpmInfo}</td>
                          </tr>
                          :null
                        }
                        <tr>
                          <td className=" border text-center py-1">{'커리큘럼명'}</td>
                          <td colSpan={7} className=" border px-5 py-1" >{item.program.curriculumName}</td>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'옵션'}</td>
                          <td colSpan={7} className=" border px-5 py-1" >{getDynamicProgramOption(item)}</td>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'영역'}</td>
                          <td colSpan={7} className=" border px-5 py-1" >{getDynamicProgramLayoutCount(item)}영역</td>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'추가운동'}</td>
                          <td colSpan={7} className=" border px-5 py-1" >{getDynamicProgramAddProgram(item)}</td>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'물먹는 시간'}</td>
                          <td colSpan={7} className=" border px-5 py-1" >{getDynamicProgramWater(item)}</td>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'레이아웃'}</td>
                          <td colSpan={7} className=" border px-5 py-1" >
                            <LayoutTableItem item={getDynamicProgramLayout(item) || undefined}/>
                          </td>
                        </tr>
                        <tr>
                          <td className=" border text-center py-1">{'수업\n코멘트'}</td>
                          <td colSpan={7} className=" border px-5 py-1" >{item.program.comment}</td>
                        </tr>
                        <tr className=" bg-black">
                          <th colSpan={2} className=" border border-white text-white text-center py-1">{'운동명'}</th>
                          <th className=" border border-white text-white text-center py-1" >{'동작'}</th>
                          <th colSpan={5} className=" border border-white text-white text-center py-1" >{'수업 코멘트'}</th>
                        </tr>
                        {
                          item.content.map((contentItem:ContentsDtoWithId, contentIndex:number)=>{
                            if(!contentItem)
                            {
                              return null
                            }

                            const toolNames = getToolsFromContent(contentItem)
                            const partNames = getPartsFromContent(contentItem)
                            
                            return (
                              <>
                                <tr className={contentIndex%2 == 0?' bg-white':' bg-gray-100'}>
                                  <td className=" border text-center py-1">{toolNames || ''}</td>
                                  <td className=" border text-center py-1">{partNames || ''}</td>
                                  <td align="center" rowSpan={2} className=" border bg-white p-0 m-0">
                                    <img
                                      src={contentItem.content.thumbnailUrl || ''}
                                      className="w-24 object-cover"
                                      alt="program background"
                                      onClick={() => openVideoModal(contentItem)}
                                    />
                                  </td>
                                  <td rowSpan={2} colSpan={5}  className=" border py-1">{contentItem.content.comment}</td>
                                </tr>
                                <tr className={contentIndex%2 == 0?' bg-white':' bg-gray-100'}>
                                  <td colSpan={2} className=" border  text-center py-1">{contentItem.content.name}</td>
                                </tr>
                              </>
                            )
                          })
                        }
                    </tbody>
                  </table>
                )
              })
            }
            </>
            :null
          }
          {
            (programDataList && programDataList.length > 0)?
            <>
            {
              programDataList.map((item:ProgramDto, index:number)=>{
                
                const contentIds = item.program.contentsIds.split(',');
                const cadioList = getProgramCadioList(item.program)
                return (
                  <table className=" table my-10 border-2 border-black text-xs">
                    <tbody>
                        <tr>
                          <th className=" border text-center py-1">{date || ''}</th>
                          <th className=" border text-center py-1">{item.program.programType}</th>
                          <th className=" border text-center py-1">{getPartsFromProgram(item)}</th>
                          <th className=" border text-center py-1">{item.program.name}</th>
                          <th className=" border text-center py-1">{`${item.program.totalMotions}동작 (${item.program.totalSet}R)`}</th>
                          <th className=" border text-center py-1">{getToolsFromProgram(item)}</th>
                          <th className=" border text-center py-1">{`${item.program.exerciseTime}/${item.program.moveTime}`}</th>
                          <th className=" border text-center py-1">{`${((Math.floor(item.program.totalExerciseTime/60)))}:${('0' + (Math.floor(item.program.totalExerciseTime%60))).slice(-2)}`}</th>
                        </tr>
                        {
                          item.program.programType == '본운동'?
                          <tr>
                            <td className=" border text-center py-1">{'유산소\n미리보기'}</td>
                            <td colSpan={7} className=" border px-5 py-1" >
                              
                              <div className="flex">
                                {
                                  cadioList.map((cadioItem:string, cadioIndex:number)=>{
                                    return (
                                      <div className="ml-2 items-center">
                                        <div className=" text-center text-xs">{cadioIndex +1}</div>
                                        {
                                          cadioItem == 'FREE'?
                                          <div className={` w-4 h-7 bg-[#00C7F2]`}/>
                                          :
                                          cadioItem == 'ROLL-UP'?
                                          <div className={` w-4 h-7 bg-[#FE06F2]`}/>
                                          :
                                          cadioItem == 'ALL-OUT'?
                                          <div className={` w-4 h-7 bg-[#FF0000]`}/>
                                          :
                                          <div className={` w-4 h-7 bg-[#D2D2D2]`}/>
                                        }
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </td>
                          </tr>
                          :null
                        }
                        {
                          item.program.programType == '본운동'?
                          <tr>
                            <td className=" border text-center py-1">{'FLOOR'}</td>
                            <td colSpan={7} className=" border px-5 py-1" >{item.program.floor}</td>
                          </tr>
                          :null
                        }
                        {
                          item.program.programType == '본운동'?
                          <tr>
                            <td className=" border text-center py-1">{'프로그램\n설명'}</td>
                            <td colSpan={7} className=" border px-5 py-1" >{item.program.description}</td>
                          </tr>
                          :null
                        }
                        <tr>
                          <td className=" border text-center py-1">{'수업\n코멘트'}</td>
                          <td colSpan={7} className=" border px-5 py-1" >{item.program.comment}</td>
                        </tr>
                        <tr className=" bg-black">
                          <th colSpan={2} className=" border border-white text-white text-center py-1">{'운동명'}</th>
                          <th className=" border border-white text-white text-center py-1" >{'동작'}</th>
                          <th className=" border border-white text-white text-center py-1" >{'세트'}</th>
                          <th colSpan={5} className=" border border-white text-white text-center py-1" >{'수업 코멘트'}</th>
                        </tr>
                        {
                          contentIds.map((contentId:string, contentIndex:number)=>{
                            const contentItem = getContent(item, parseInt(contentId))

                            if(!contentItem)
                            {
                              return null
                            }

                            const toolNames = getToolsFromContent(contentItem)
                            const partNames = getPartsFromContent(contentItem)
                            
                            return (
                              <>
                                <tr className={contentIndex%2 == 0?' bg-white':' bg-gray-100'}>
                                  <td className=" border text-center py-1">{toolNames || ''}</td>
                                  <td className=" border text-center py-1">{partNames || ''}</td>
                                  <td align="center" rowSpan={2} className=" border bg-white p-0 m-0">
                                    <img
                                      src={contentItem.content.thumbnailUrl || ''}
                                      className="w-24 object-cover"
                                      alt="program background"
                                      onClick={() => openVideoModal(contentItem)}
                                    />
                                  </td>
                                  <td rowSpan={2} className={` border text-center py-1`}>
                                    <div>
                                      {
                                        (contentItem.sets && contentItem.sets.length > 0)?
                                        contentItem.sets.map((setItem)=>{
                                          return (<div>{setItem}</div>)
                                        })
                                        :null
                                      }
                                    </div>
                                  </td>
                                  <td rowSpan={2} colSpan={5}  className=" border py-1">{contentItem.content.comment}</td>
                                </tr>
                                <tr className={contentIndex%2 == 0?' bg-white':' bg-gray-100'}>
                                  <td colSpan={2} className=" border  text-center py-1">{contentItem.content.name}</td>
                                </tr>
                              </>
                            )
                          })
                        }
                    </tbody>
                  </table>
                )
              })
            }
            </>
            :null
          }
        </div>
      </div>
    </>
  );
}

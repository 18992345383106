import { useNavigate, useParams } from "react-router-dom";
import AdminLayout from "../../../components/Layout/AdminLayout";
import LayoutDetail from "../../../components/LayoutDetail";

export default function AddLayout() {
  const { layoutId } = useParams();
  const navigate = useNavigate();
  const onBackBtn = () => {
    navigate(`/admin/layout`);
  };
  return (
    <>
      <AdminLayout>
        <div className="flex space-x-5">
          <h1
            onClick={onBackBtn}
            className="text-gray-800 font-bold  text-2xl cursor-pointer"
          >
            레이아웃 관리
          </h1>
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            -
          </h1>
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
          레이아웃 {layoutId ? "수정" : "등록"}
          </h1>
        </div>
        <LayoutDetail layoutId={layoutId} />
      </AdminLayout>
    </>
  );
}

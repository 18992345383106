import AdminLayout from "../../components/Layout/AdminLayout";
import { useEffect, useState } from "react";
import LoadingMsg from "../../components/LoadingMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faClock,
  faGear,
  faPenToSquare,
  faPlus,
  faTrash,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  GetHHMM,
  cls,
  getBoxBackgroundClass,
  getDayClassNames,
} from "../../libs/utils";
import axios from "axios";
import { ProgramSchedule } from "../../types/dto";
import WeekButton from "../../components/WeekButton";
import Modal from "../../components/interface/Modal";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import BranchAddTimeModal from "../../components/Modal/BranchAddTimeModal";
import { useNavigate, useParams } from "react-router-dom";
import { BranchSchedule } from "../../types/models";
import ScheduleSettingsModal from "../../components/Modal/ScheduleSettingsModal";
import BranchScheduleBox from "../../components/BranchScheduleBox";
import { useQuery } from "@tanstack/react-query";
import { getBranchSchedules } from "../../services/branchSchedule";
import Button from "../../components/interface/Button";
import { getProgramCategoryByDateRange } from "../../services/schedule";

export default function BranchScheduleHome() {
  const navigate = useNavigate();

  const { branchCode } = useParams();
  const [daysOfWeek, setDaysOfWeek] = useState<any>();
  const [mutate, setMutate] = useState(false);
  const [startDate, setStartDate] = useState<string>();
  const [endDate, setEndDate] = useState<string>();

  const [addModalDate, setAddModalDate] = useState<string>("");

  const [addModalIsOpen, setIsModalIsOpen] = useState(false);
  const [scheduleSettingslIsOpen, setScheduleSettingsIsOpen] = useState(false);

  const [dropdown, setDropdown] = useState({
    show: false,
    x: 0,
    y: 0,
  });
  const [selectData, setSelectData] = useState<BranchSchedule>();
  const [scheduleLoading, setSheduleLoading] = useState<number[]>([]);

  useEffect(() => {
    if (daysOfWeek) {
      setStartDate(daysOfWeek[0].format("YYYY-MM-DD"));
      setEndDate(daysOfWeek[daysOfWeek.length - 1].format("YYYY-MM-DD"));
    }
  }, [daysOfWeek]);

  const {
    data: scheduleByDay,
    isLoading,
    isError,
    refetch,
  } = useQuery<any[], Error>(
    ["getBranchSchedules", startDate, endDate, branchCode],
    () => getBranchSchedules(startDate!, endDate!, branchCode!),
    {
      enabled: !!startDate && !!endDate && !!branchCode,
    }
  );

  const { data: programByDay, isLoading: programByDayLoading } = useQuery<
    any[],
    Error
  >(
    ["getProgramCategoryByDateRanges", startDate, endDate, branchCode],
    () => getProgramCategoryByDateRange(startDate!, endDate!, branchCode!),
    {
      enabled: !!startDate && !!endDate,
    }
  );

  const addTime = async (date: string) => {
    setAddModalDate(date);
    showModal();
  };

  //모달 열기
  const showModal = () => {
    setSelectData(undefined);
    //@ts-ignore
    window.addModal.showModal();
    setIsModalIsOpen(!addModalIsOpen);
  };

  const showEditModal = () => {
    //@ts-ignore
    window.addModal.showModal();
    setIsModalIsOpen(!addModalIsOpen);
  };

  const onDelete = async () => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result || !selectData) {
      return;
    }

    setSheduleLoading([...scheduleLoading, selectData.id]);

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_SERVER_URL}api/branch/schedule/${selectData.id}`, {withCredentials: true}
      );
      refetch();
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }

    const newLoadingArray = scheduleLoading.filter(
      (item: number) => item !== selectData.id
    );

    setSheduleLoading(newLoadingArray);

    setDropdown({
      show: false,
      x: 0,
      y: 0,
    });
  };

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (
        dropdown.show &&
        !e.target.closest(".dropdown-content") &&
        !e.target.closest(".menu")
      ) {
        setDropdown({
          show: false,
          x: 0,
          y: 0,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdown]);

  const handleProgramClick = (e: any, selData: BranchSchedule) => {
    e.preventDefault();
    setDropdown({
      show: true,
      x: e.clientX,
      y: e.clientY,
    });

    setSelectData(selData);
    const dateTimeString = selData.startTime;
    const [date] = dateTimeString.split("T");
    setAddModalDate(date);
  };

  const onScheduleSettingsModal = () => {
    //@ts-ignore
    window.scheduleSettingsModal.showModal();
    setScheduleSettingsIsOpen(!scheduleSettingslIsOpen);
  };

  const onAssignUser = () => {
    navigate(`/branch/${branchCode}/assign/${selectData?.id}`);
  };

  console.log("programByDay : ", programByDay)

  return (
    <AdminLayout>
      <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
        지점별 예약자 그룹 배정
      </h1>
      <>
        <BranchAddTimeModal
          date={addModalDate}
          isOpen={addModalIsOpen}
          refetch={refetch}
          branchCode={branchCode!}
          selectData={selectData}
        />
        {daysOfWeek && daysOfWeek.length === 7 && (
          <ScheduleSettingsModal
            branchCode={branchCode!}
            initStartDate={daysOfWeek[0].format("YYYY-MM-DD")}
            initEndDate={daysOfWeek[daysOfWeek.length - 1].format("YYYY-MM-DD")}
            isOpen={scheduleSettingslIsOpen}
            refetch={refetch}
          />
        )}
        <div className="">
          <div className="w-full bg-white px-5 py-10 drop-shadow-md ">
            <div className="flex flex-col justify-center ">
              <div className="flex flex-col xl:flex-none xl:grid xl:grid-cols-3 mb-5">
                {/* {isLoading ? <LoadingMsg /> : <div />} */}
                <div className="hidden md:block"></div>
                <WeekButton
                  daysOfWeek={daysOfWeek}
                  setDaysOfWeek={setDaysOfWeek}
                />
                <div className="place-self-end hidden lg:block ">
                  <Button
                    title={`수업 시간표 일괄 설정`}
                    onClick={onScheduleSettingsModal}
                    iconComponent={<FontAwesomeIcon icon={faGear} />}
                    disabled={!daysOfWeek || daysOfWeek.length !== 7}
                  />
                </div>
              </div>
              <BranchScheduleBox
                daysOfWeek={daysOfWeek}
                scheduleByDay={scheduleByDay}
                handleProgramClick={handleProgramClick}
                addTime={addTime}
                programByDay={programByDay}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>

        {dropdown.show && (
          <div style={{ position: "fixed", top: dropdown.y, left: dropdown.x }}>
            <ul className="p-2 shadow menu dropdown-content z-[1] bg-base-100 rounded-box text-xs ">
              <li onClick={onAssignUser}>
                <a>
                  <FontAwesomeIcon icon={faUserGroup} />
                  그룹배정
                </a>
              </li>
              <li onClick={showEditModal}>
                <a>
                  <FontAwesomeIcon icon={faPenToSquare} />
                  수정
                </a>
              </li>
              <li onClick={onDelete}>
                <a>
                  <FontAwesomeIcon icon={faTrash} /> 삭제
                </a>
              </li>
            </ul>
          </div>
        )}
      </>
    </AdminLayout>
  );
}

import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faBook,
  faCirclePlus,
  faCircleXmark,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faLocation,
  faSign,
  faTriangleExclamation,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";

import { useEffect, useState } from "react";

import { SecondsToMinutes, StringToDate } from "../../libs/utils";
import Table from "../interface/Table";
import { useUserStore } from "../../store";

import { useNavigate } from "react-router-dom";
import FullPageLoading from "../interface/FullPageLoading";
import Button from "../interface/Button";
import { TableHeaderForProgram } from "../../constants/TableHeader";
import { SearchDataForProgram } from "../../constants/SearchData";
import { ProgramDto } from "../../types/dto";
import { DynamicProgram, Program } from "../../types/models";
import Modal from "../interface/Modal";
import ProgramDetail from "../ProgramDetail";

interface AddProgramsSearchModalProps {
  onAddItem?: any;
}

export default function AddProgramsSearchModal({
  onAddItem,
}: AddProgramsSearchModalProps) {
  const user = useUserStore((state) => state.user);

  const navigate = useNavigate();
  const [mutate, setMutate] = useState(false);

  const tableHeader = [
    { title: "프로그램명" },
    { title: "운동명" },
    { title: "운동시간" },
    { title: "설정횟수" },
    { title: "관리" },
  ];

  const [loading, setLoading] = useState(false);

  const [modalProgramId, setModalProgramId] = useState("");

  const onAddBtn = async (item: DynamicProgram) => {
    try {
      setLoading(true);
      onAddItem(item)

      //@ts-ignore
      window.addProgram.close();
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {loading && <FullPageLoading />}
      <div className="mt-5">
        <Table
          dataApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/dynamicprogram/searchadd`}
          headerArray={tableHeader}
          mutate={mutate}
          initSort="createdAt"
          initDirection="desc"
          searchData={[]}
          searchApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/dynamicprogram/searchadd`}
        >
          {(data: DynamicProgram[], startIndex: number) =>
            data.map((item: DynamicProgram, index: number) => {
              // console.log(item);
              return (
                <tr key={item.id}>
                  <td>{item?.name}</td>
                  <td>{item?.exerciseNames}</td>
                  <td><SecondsToMinutes seconds={item?.totalExerciseTime} /></td>
                  <td>{item?.useCount}</td>
                  <td>
                    <Button
                      loading={loading}
                      title="추가"
                      onClick={() => onAddBtn(item)}
                      iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
                    />
                  </td>
                </tr>
              );
            })
          }
        </Table>
      </div>
    </>
  );
}

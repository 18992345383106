import { useForm } from "react-hook-form";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faDumbbell,
  faHouse,
  faPersonBiking,
  faSquarePollVertical,
  faTv,
  faUser,
  faPersonWalking,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { cls } from "../../libs/utils";
import { useStore } from "zustand";
import { useBranchStore, useUserStore } from "../../store";
import {
  adminMenu,
  branchMenu,
  branchMenuWithAdmin,
} from "../../constants/AdminMenu";
import { Branch } from "../../types/models";
import { BranchDto, BrandDto } from "../../types/dto";
import { useQuery } from "@tanstack/react-query";
import { fetchBranchNameByCode, fetchBranches, fetchBrandIdByCode, fetchBrands } from "../../services/branch";
import AdminMenu from "../Menu/AdminMenu";
import MobileMenu from "../Menu/MobileMenu";
import { useWebSocket } from "../../hooks/useWebSocket";

export default function AdminLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const user = useUserStore((state) => state.user);
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);
  const [mainDomain, setMainDomain] = useState<string>();
  const [branchCode, setBranchCode] = useState<string>("");
  const [layouType, setLayoutType] = useState<string>();
  const [selectBrand, setSelectBrand] = useState(0);
  
  const setBranchName = useBranchStore((state) => state.setBranchName);
  const setStoreBranchCode = useBranchStore((state) => state.setBranchCode);
  const setBrandId = useBranchStore((state) => state.setBrandId);
  const storeBranchName = useBranchStore((state) => state.branchName);
  const storeBranchCode = useBranchStore((state) => state.branchCode);
  const storeBrandId = useBranchStore((state) => state.brandId);

  

  //리액트 쿼리를 통해 브랜치 이름과 리스트를 불러오는 코드는 캐싱처리
  const { data: branchData = [] } = useQuery<BranchDto[]>({
    queryKey: ["getAllBranch"],
    queryFn: fetchBranches,
    enabled: user?.branchCode === "main",
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 30,
  });
  
  const { data: brandData = [] } = useQuery<BranchDto[]>({
    queryKey: ["getAllBrand"],
    queryFn: fetchBrands,
    enabled: user?.branchCode === "main",
    staleTime: 1000 * 60 * 5,
    cacheTime: 1000 * 60 * 30,
  });

  const { data: branchName } = useQuery<string, Error>(
    ["getBranchNameByCode", branchCode],
    () => fetchBranchNameByCode(branchCode),
    {
      enabled: !!branchCode && mainDomain !== "/admin",
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 30,
    }
  );

  const { data: brandId } = useQuery<string, Error>(
    ["getBrandIdByCode", branchCode],
    () => fetchBrandIdByCode(branchCode),
    {
      enabled: !!branchCode && mainDomain !== "/admin",
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 30,
    }
  );


  //브랜치네임, 브랜치코드 전역 저장
  useEffect(() => {
    if (branchName) {
      setBranchName(branchName);
    }
  }, [branchName]);

  useEffect(() => {
    if (branchCode) {
      setStoreBranchCode(branchCode);
    }
  }, [branchCode]);
  useEffect(() => {
    if (brandId) {
      console.log('aaaa : ', brandId)
      setSelectBrand(Number(brandId));
    }
  }, [brandId]);

  // 현재 경로 저장 및 mainDomain 설정
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const rootPath = `/${pathSegments[1]}`;
    if (rootPath !== mainDomain) {
      setMainDomain(rootPath);
    }
  }, [location.pathname, mainDomain]);

  // 현재 경로 저장 및 branchCode 설정
  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    const branchCodeFromUrl = pathSegments[2]; // 두 번째 세그먼트
    if (branchCodeFromUrl !== branchCode) {
      setBranchCode(branchCodeFromUrl);
    }
  }, [location.pathname, branchCode]);

  //본사, 지점에 따른 레이아웃 구분
  useEffect(() => {
    if (mainDomain === "/admin") {
      setLayoutType("admin");
    } else if (mainDomain === "/branch") {
      setLayoutType("branch");
    }
  }, [mainDomain]);

  const menu =
    layouType === "admin"
      ? adminMenu
      : layouType === "branch" && user?.role === "employee"
      ? branchMenu
      : layouType === "branch" && user?.role === "admin"
      ? branchMenuWithAdmin
      : null;

  const onLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}api/public/logout`, {}, { withCredentials: true });
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  const handleBrandSelectChange = (event: any) => {
    const selectedBrandId = event.target.value;
    
    setSelectBrand(selectedBrandId)
    if (selectedBrandId == 0) {
      window.location.href = `/admin`;
    }
  };

  const handleSelectChange = (event: any) => {
    const selectedBranchCode = event.target.value;
    console.log(selectedBranchCode);
    if(selectedBranchCode == 0)
    {
      return;
    }

    if (selectedBranchCode === "main") {
      window.location.href = `/admin`;
    } else {
      window.location.href = `/branch/${selectedBranchCode}`;
    }
  };

  const [menuModal, setMenuModal] = useState(false);

  const onMenu = () => {
    setMenuModal((current) => !current);
  };

  //웹소켓 연결
  // const [socketData, socketError, client]: any = useWebSocket(
  //  // `${process.env.REACT_APP_WS_SERVER}/ws/auth/${user?.id}`
  //  'ws://192.168.0.5:1234'
  // );

  //웹소켓으로 수신한 데이터를 등록
  // useEffect(() => {
  //   if (socketData) {
  //     if (socketData.operation === "logout") {
  //       window.location.reload();
  //     }
  //   }
  // }, [socketData]);

  return (
    <div>
      <div className="flex bg-gray-50 flex-col ">
        <div className="w-full flex justify-between items-center bg-gymmate z-50  text-white p-3 ">
          <div className="flex w-full text-black items-center space-x-5">
            <h1 className="text-white font-bold ">
              {storeBranchName
                ? `지점관리자 - ${storeBranchName} `
                : "본사관리자"}
            </h1>

            {user?.branchCode === "main" &&  (
              <select
                onChange={handleBrandSelectChange}
                value={selectBrand}
                className="select select-xs select-bordered w-28 max-w-xs"
              >
                {/* {branchData &&
                  branchData?.map((branch) => (
                    <option value={branch.code}>{branch.name}</option>
                  ))} */}
                  <option value={0}>{'본사'}</option>
                  {brandData &&
                  brandData?.map((brand) => (
                    <option value={brand.id}>{brand.name}</option>
                  ))}
                  
              </select>
              
            )}
            <select
              onChange={handleSelectChange}
              value={storeBranchCode}
              className="select select-xs select-bordered w-28 max-w-xs"
            >
              <option value={0}>-</option>
              {
                branchData &&
                  branchData?.map((branch) => {
                    console.log('storeBrandId : ', selectBrand)
                    if(branch.code == 'main' || branch.brandId != selectBrand)
                    {
                      return null
                    }
                    return (<option value={branch.code}>{branch.name}</option>)
                  }
                )
              }
            </select>
          </div>
          <div className="flex items-center space-x-3  md:hidden">
            <label className="swap swap-rotate">
              <input type="checkbox" checked={menuModal} />
              <svg
                className="swap-off fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 512 512"
                onClick={onMenu}
              >
                <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
              </svg>
              <svg
                className="swap-on fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 512 512"
                onClick={onMenu}
              >
                <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
              </svg>
            </label>
          </div>
          <div className="text-xs font-bold space-x-2  whitespace-nowrap hidden md:block ">
            <span className="  ">
              {user?.branchName} - {user?.manager}({user?.userId})님
            </span>
            <button onClick={onLogout}>로그아웃</button>
          </div>
        </div>
        <MobileMenu
          menu={menu}
          currentPath={currentPath}
          menuModal={menuModal}
          setMenuModal={setMenuModal}
        />
        <div className="w-full min-h-screen flex  ">
          <AdminMenu menu={menu} currentPath={currentPath} />
          <main className=" p-5 w-full">{children}</main>
        </div>
      </div>
    </div>
  );
}

import AdminLayout from "../../components/Layout/AdminLayout";
import { useEffect, useState } from "react";
import LoadingMsg from "../../components/LoadingMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faClock,
  faGear,
  faPenToSquare,
  faPlus,
  faPlusCircle,
  faTrash,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  GetHHMM,
  cls,
  getBoxBackgroundClass,
  getDayClassNames,
} from "../../libs/utils";
import axios from "axios";
import { ProgramSchedule } from "../../types/dto";
import WeekButton from "../../components/WeekButton";
import Modal from "../../components/interface/Modal";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import BranchAddTimeModal from "../../components/Modal/BranchAddTimeModal";
import { useNavigate, useParams } from "react-router-dom";
import { BranchGroup, BranchSchedule } from "../../types/models";
import ScheduleSettingsModal from "../../components/Modal/ScheduleSettingsModal";
import AssignUserModal from "../../components/Modal/AssignUserModal";
import BranchGroupGrid from "../../components/BranchGroupGrid";
import { useQuery, useQueryClient } from "@tanstack/react-query";

import { getGroupData } from "../../services/group";
import { useWebSocket } from "../../hooks/useWebSocket";
import { getScheduleData } from "../../services/branchSchedule";

export default function BranchAssignHome() {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { branchCode, scheduleId } = useParams();

  const [mutate, setMutate] = useState(false);

  const [addModalIsOpen, setIsModalIsOpen] = useState(false);
  const onBackBtn = () => {
    navigate(`/branch/${branchCode}/assign`);
  };

  const {
    data: scheduleData,
    isLoading: scheduleDataLoading,
    isError: scheduleDataError,
  } = useQuery<BranchSchedule, Error>(
    ["getScheduleData", scheduleId],
    () => getScheduleData(scheduleId!),
    {
      enabled: !!scheduleId,
    }
  );

  const { data, isLoading, isError, refetch } = useQuery<BranchGroup[], Error>(
    ["getGroupData", scheduleId],
    () => getGroupData(scheduleId!),
    {
      enabled: !!scheduleId,
    }
  );

  //모달 열기
  const showModal = () => {
    //@ts-ignore
    window.assignUserModal.showModal();
    setIsModalIsOpen(!addModalIsOpen);
  };

  //웹소켓 연결
  const [socketData, socketError, client]: any = useWebSocket(
    `${process.env.REACT_APP_WS_SERVER}/ws/group/${scheduleId}`
  );

  //fix
  //웹소켓으로 수신한 데이터를 등록
  useEffect(() => {
    if (socketData) {
      const oldData = queryClient.getQueryData<BranchGroup[]>([
        "getGroupData",
        scheduleId,
      ]);

      let newData;
      switch (socketData.operation) {
        case "post":
          newData = [...(oldData || []), socketData.data];
          break;
        case "delete":
          newData = (oldData || []).filter(
            (item) => item.id !== socketData.data.id
          );
          break;
        case "update":
          newData = (oldData || []).map((item) =>
            item.id === socketData.data.id ? socketData.data : item
          );
          break;
        default:
          return;
      }

      queryClient.setQueryData(["getGroupData", scheduleId], newData);
    }
  }, [socketData, queryClient, scheduleId]);

  return (
    <>
      {scheduleId && (
        <AssignUserModal
          isOpen={addModalIsOpen}
          refetch={refetch}
          branchCode={branchCode!}
          scheduleId={scheduleId}
        />
      )}
      <AdminLayout>
        <div className="flex space-x-5 ">
          <h1
            onClick={onBackBtn}
            className="text-gray-800 font-bold text-2xl cursor-pointer"
          >
            지점별 예약자 그룹 배정
          </h1>
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            -
          </h1>
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
            {scheduleData?.startTime?.replace("T", " ").slice(0, -3)} 그룹배정
          </h1>
        </div>
        <div className="w-full bg-white p-5 drop-shadow-md">
          <div className="flex items-center space-x-5 justify-between">
            <h2 className="text-xl font-bold">
              {scheduleData?.startTime?.replace("T", " ").slice(0, -3)} (
              {data?.length}/{scheduleData?.headcount})
            </h2>

            <button
              className=" btn-primary btn w-fit   text-white mb-5 "
              onClick={showModal}
              // disabled={!daysOfWeek || daysOfWeek.length !== 7}
            >
              <FontAwesomeIcon icon={faPlusCircle} />
              그룹 배정 등록
            </button>
          </div>
          <BranchGroupGrid
            headcount={Number(scheduleData?.headcount)}
            data={data}
            refetch={refetch}
            scheduleId={scheduleId}
          />
        </div>
      </AdminLayout>
    </>
  );
}

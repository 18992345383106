import { AdsDtoWithVideo, BranchScheduleResponse } from "../types/dto";

import { getAds } from "../services/ads";
import AdsSlide from "../components/Slide/AdsSlide";
import { useQuery } from "@tanstack/react-query";
import { useWebSocket } from "../hooks/useWebSocket";
import { useEffect, useState } from "react";

export default function AdsSlideWithData({ schedule }: { schedule: boolean }) {
  const [adsData, setAdsData] = useState();

  //웹소켓 연결
  const [socketData, socketError, client] = useWebSocket(
    `${process.env.REACT_APP_WS_SERVER}/ws/ads`,
    false
  );

  const getData = async () => {
    const data = await getAds(0);
    console.log(data);
    setAdsData(data);
  };

  //수신한 소켓 데이터를 바인딩
  useEffect(() => {
    getData();
  }, []);

  //수신한 소켓 데이터를 바인딩
  useEffect(() => {
    if (socketData) {
      getData();
    }
  }, [socketData]);

  return (
    <div className="flex justify-center items-center min-h-screen">
      {!adsData && (
        <span className="loading loading-spinner w-96 h-96 bg-white"></span>
      )}

      {adsData && <AdsSlide data={adsData} schedule={schedule} />}
    </div>
  );
}

import useMutation from "./useMutation";

// 타입 정의
type HandlePlayArgs = {
  programId: number;
  branchCode: string;
  type: string;
};

const useCreateStreamingPlay = () => {
  const [createStreamingPlay, { loading, data }] = useMutation(
    "/api/branch/streaming"
  );

  const createStreaming = async ({
    programId,
    branchCode,
    type,
  }: HandlePlayArgs) => {
    createStreamingPlay({ programId, branchCode, type });
  };

  return { createStreaming, loading, data };
};

export default useCreateStreamingPlay;

export const ProgramType = [
  { name: "본운동" },
  { name: "준비운동" },
  { name: "마무리운동" },
  { name: "스트레칭" },
];

export const ProgramCategory = [
  { name: "상체" },
  { name: "하체" },
  { name: "전신" },
  { name: "코어" },
  { name: "오리지널" },
];

import { useQuery } from "@tanstack/react-query";
import { getContentsByIds } from "../../services/contents";
import { ContentsWithOnlyVideoDto } from "../../types/dto";
import { BranchStreaming, Program } from "../../types/models";
import { useEffect, useRef, useState } from "react";
import { useBranchStore } from "../../store";
import BottomProgress from "./BottomProgress";
import { motion } from "framer-motion";
import { count } from "console";
import useUpdateStremingIndex from "../../hooks/useUpdateStremingIndex";
import { useParams } from "react-router-dom";

interface CompleteScreenProps {
  streamingType: string;
  programType: string;
}

export default function CompleteScreen({
  streamingType,
  programType,
}: CompleteScreenProps) {
  const [title, setTitle] = useState("");

  useEffect(() => {
    if (programType === "본운동") {
      if (streamingType === "tutorial") {
        setTitle("곧 오늘의 수업이 시작됩니다");
      } else {
        setTitle("본운동이 모두 끝났어요!");
      }
    } else if (programType === "마무리운동") {
      setTitle("대단해요\n오늘의 운동을 완벽히 끝냈어요!");
    } else if (programType === "준비운동") {
      setTitle("곧 오늘의 본운동이 시작됩니다");
    } else if (programType === "스트레칭") {
      setTitle("대단해요\n오늘의 운동을 완벽히 끝냈어요!");
    }
  }, [streamingType, programType]);

  return (
    <div className="text-white">
      <h1 className="text-9xl font-bold">{title}</h1>
    </div>
  );
}

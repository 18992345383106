import { useForm } from "react-hook-form";
import axios from "axios";

import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faDumbbell,
  faHouse,
  faPersonBiking,
  faSquarePollVertical,
  faTv,
  faUser,
  faPersonWalking,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { cls } from "../../libs/utils";
import { useStore } from "zustand";
import { useBranchStore, useUserStore } from "../../store";
import { adminMenu, branchMenu, checkRanderAdminMenu } from "../../constants/AdminMenu";
import { Branch } from "../../types/models";
import { BranchDto } from "../../types/dto";
import { useQuery } from "@tanstack/react-query";
import { fetchBranchNameByCode, fetchBranches } from "../../services/branch";
import AdminMenu from "../Menu/AdminMenu";

export default function MobileMenu({
  menu,
  currentPath,
  menuModal,
  setMenuModal,
}: any) {
  const user = useUserStore((state) => state.user);
  const onLinkClick = () => {
    setMenuModal(false);
  };

  const onLogout = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}api/public/logout`, {}, { withCredentials: true });
      window.location.reload();
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div
      className={cls(
        "absolute flex min-h-screen z-30 bg-opacity-50 w-full  flex-col items-center bg-white  p-5 text-black backdrop-blur-lg  transition-opacity duration-700",
        menuModal ? "opacity-100  " : "opacity-0 pointer-events-none"
      )}
    >
      <ul className="mt-[48px] w-full font-sans text-base text-black font-bold uppercase">
        {menu?.map((item: any, index: number) =>{
            if(!checkRanderAdminMenu(user, item.url))
            {
              return null
            }
          return (
            <Link to={item.url} key={index} className="w-full">
              <div onClick={onLinkClick}>
                <li className=" cursor-pointer border-b border-black border-opacity-10 hover:bg-primary ">
                  <div className="relative flex w-full space-x-2 items-center justify-center py-4 ">
                    <FontAwesomeIcon
                      icon={item.icon}
                      color={currentPath === item.url ? "#2ea7e0" : "#666577"}
                    />
                    <p
                      className={cls(
                        "",
                        currentPath === item.url
                          ? "text-gymmate"
                          : "text-[#666577]"
                      )}
                    >
                      {" "}
                      {item.title}
                    </p>
                  </div>
                </li>
              </div>
            </Link>
          )
        }
        )}
        <div onClick={onLogout}>
          <li className=" cursor-pointer border-b border-black border-opacity-10 hover:bg-primary ">
            <div className="relative flex w-full space-x-2 items-center justify-center py-4 ">
              <p className="text-[#666577]">로그아웃</p>
            </div>
          </li>
        </div>
      </ul>
    </div>
  );
}

import AdminLayout from "../../components/Layout/AdminLayout";
import { useEffect, useState } from "react";
import axios from "axios";
import { SecondsToMinutes, StringToDate } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { SearchDataForProgram } from "../../constants/SearchData";
import { TableHeaderForStatistics } from "../../constants/TableHeader";
import { BrandDto, ProgramDto } from "../../types/dto";
import { Program, Programtype } from "../../types/models";
import Modal from "../../components/interface/Modal";
import ProgramDetail from "../../components/ProgramDetail";
import BrandLayout from "../../components/Layout/BrandLayout";
import DynamicProgramDetail from "../../components/DynamicProgramDetail";

export default function Statistics() {
  const [mutate, setMutate] = useState(false);
  const tableHeader = TableHeaderForStatistics;
  const [programId, setProgramId] = useState("");
  const [actionType, setActionType] = useState(0);
  const [brandData, setBrandData] = useState<BrandDto[]>();
  const [programTypeAllData, setProgramTypeAllData] = useState<Programtype[]>();

  const showProgramDetailModal = (id: string, actionType:number) => {
    setProgramId(id);
    setActionType(actionType)
    //@ts-ignore
    window.programDetail.showModal();
  };
  useEffect(() => {
    const getBrands = async () => {
      try {
        const getbrand = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`, {
          withCredentials: true,
        });
        setBrandData(getbrand.data)
      } catch (error) {
      }
    };
    const getProgramTypes = async () => {
      try {
        const getProgramType = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/programtype/all`, {
          withCredentials: true,
        });
        let result = []
        if(getProgramType.data) {
          for (let i = 0; i < getProgramType.data.length; i++) {
            console.log(getProgramType.data[i])
            result.push({...getProgramType.data[i], sname:getProgramType.data[i].name, name:`[${getProgramType.data[i].brandName}] - ${getProgramType.data[i].name}`})
            
          }
          result.sort((a:Programtype, b:Programtype)=>{
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          })
        }
        setProgramTypeAllData(result)
      } catch (error) {
      }
    };

    getBrands()
    getProgramTypes()
  }, []);

  
  const searchData = [
    { title: "브랜드", value: brandData, searchKey: "id", searchFiled: "brandIds" },
    {
      title: "프로그램 종류",
      value: [
        { name: "본운동" },
        { name: "준비운동" },
        { name: "마무리운동" },
        { name: "스트레칭" },
        { name: "추가운동" },
      ],
      searchKey: "name",
      searchFiled: "programType",
    },
    { title: "운동타입", value: programTypeAllData, searchKey: "sname", searchFiled: "programCategory" }
  ];

  return (
    <>
      <Modal
        id="programDetail"
        title={`프로그램 상세보기`}
        size="2xlarge"
        overflow="hidden"
      >
        <div className="mt-5">
          {
            actionType == 1?
            <ProgramDetail programId={programId} readOnly={true} />
            :
            <DynamicProgramDetail programId={programId} readOnly={true} />
          }
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">{'프로그램 예약 통계'}</h1>
          
          <Table
            dataApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/dynamicprogram`}
            headerArray={tableHeader}
            mutate={mutate}
            initSort="createdAt"
            initDirection="desc"
            searchData={searchData}
            searchApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/dynamicprogram/search`}
          >
            {(data: Program[], startIndex: number) =>
              data.map((item: any, index: number) => {
                // console.log(item);
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>
                    <td>
                      <StringToDate dateString={item?.createdAt} />
                    </td>
                    <td>{item?.brandName}</td>
                    <td>{item?.programType}</td>
                    <td>{item?.programCategory}</td>
                    <td
                      className=" cursor-pointer"
                      onClick={() => showProgramDetailModal(item.id, item.actionType)}
                    >
                      {item?.name}
                    </td>
                    <td className="">
                      <p> {item?.totalMotions}</p>

                      <p className="text-xs">{`(${item.exerciseTime}/${item.moveTime})`}</p>
                    </td>
                    <td className="">
                      <p>{item.totalSet}</p>

                      <p className="text-xs">
                        {" "}
                        <SecondsToMinutes seconds={item?.totalExerciseTime} />
                      </p>
                    </td>
                    <td>{item?.registrationCount}</td>
                    <td>{item?.reservationCount}</td>
                    <td>
                      {item?.averageCount !== null &&
                      item?.averageCount !== undefined
                        ? Number(Number(item.averageCount/100).toFixed(2))
                        : 0}
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}

import {
  faBars,
  faBarsStaggered,
  faBullhorn,
  faCalendarDays,
  faChalkboardUser,
  faCheck,
  faCircleInfo,
  faDumbbell,
  faHouse,
  faIcons,
  faNoteSticky,
  faPersonBiking,
  faPersonWalking,
  faPlay,
  faSquarePollVertical,
  faTableCells,
  faTv,
  faTvAlt,
  faUser,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import { UserAccountDto } from "../types/dto";

export const adminMenu = [
  // { icon: faHouse, title: "지점 관리", url: "/admin/branch" },
  { icon: faHouse, title: "브랜드 관리", url: "/admin/brand" },
  { icon: faUser, title: "관리자 계정 관리", url: "/admin" },
  {
    icon: faPersonWalking,
    title: "운동 카테고리 관리",
    url: "/admin/category",
  },
  {
    icon: faDumbbell, 
    title: "운동 콘텐츠 관리",
    url: "/admin/contents"
  },
  {
    icon: faTableCells, 
    title: "레이아웃 관리",
    url: "/admin/layout"
  },
  {
    icon: faIcons, 
    title: "운동타입 관리",
    url: "/admin/programtype"
  },
  {
    icon: faBarsStaggered, 
    title: "커리큘럼 관리",
    url: "/admin/curriculum"
  },
  // {
  //   icon: faBars,
  //   title: "프로그램 분류관리",
  //   url: "/admin/programcategory",
  // },
  {
    icon: faPersonBiking,
    title: "운동 프로그램 관리",
    url: "/admin/program",
  },
  { icon: faCircleInfo, title: "운동 프로그램 안내", url: "/admin/notice" },
  {
    icon: faCalendarDays,
    title: "일별 프로그램 관리",
    url: "/admin/schedule",
  },
  {
    icon: faSquarePollVertical,
    title: "프로그램 예약 통계",
    url: "/admin/statistics",
  },
  { icon: faBullhorn, title: "광고 관리", url: "/admin/ads" },
  // { icon: faTv, title: "모니터 출력 화면", url: "/screen" },
];

// URL에서 branchCode 추출
const currentPath = window.location.pathname;
const pathSegments = currentPath.split("/");
const branchCodeFromUrl = pathSegments[2]; // 두 번째 세그먼트

export const branchMenu = [
  // {
  //   icon: faPlay,
  //   title: "지점별 동작 재생",
  //   url: `/branch/${branchCodeFromUrl}`,
  // },
  {
    icon: faCalendarDays,
    title: "일별 프로그램",
    url: `/branch/${branchCodeFromUrl}/program`,
  },
  {
    icon: faUserGroup,
    title: "지점별 예약자 그룹 배정",
    url: `/branch/${branchCodeFromUrl}/assign`,
  },
  {
    icon: faCircleInfo,
    title: "운동 프로그램 안내",
    url: `/branch/${branchCodeFromUrl}/notice`,
  },
  // {
  //   icon: faTv,
  //   title: "모니터 출력 화면",
  //   url: `/screen/${branchCodeFromUrl}`,
  // },
];

export const branchMenuWithAdmin = [
  // {
  //   icon: faPlay,
  //   title: "지점별 동작 재생",
  //   url: `/branch/${branchCodeFromUrl}`,
  // },
  {
    icon: faCalendarDays,
    title: "일별 프로그램",
    url: `/branch/${branchCodeFromUrl}/program`,
  },
  {
    icon: faUserGroup,
    title: "지점별 예약자 그룹 배정",
    url: `/branch/${branchCodeFromUrl}/assign`,
  },
  {
    icon: faCircleInfo,
    title: "운동 프로그램 안내",
    url: `/branch/${branchCodeFromUrl}/notice`,
  },
  // {
  //   icon: faUser,
  //   title: "지점 계정관리",
  //   url: `/branch/${branchCodeFromUrl}/account`,
  // },
  // {
  //   icon: faTv,
  //   title: "모니터 출력 화면",
  //   url: `/screen/${branchCodeFromUrl}`,
  // },
];

export const checkRanderAdminMenu = (user:UserAccountDto | null, url:string)=>{
  if((user?.role == "employee") && (url == `/branch/${user?.branchCode}/assign`))
  {
    return false
  }

  let target = ''
  let brand = false

  if(url == '/admin/brand') target = '1'
  else if(url == '/admin') target = '2'
  else if(url == '/admin/category') target = '3'
  else if(url == '/admin/contents') target = '4'
  else if(url == '/admin/programcategory') { target = '1'; brand = true; }
  else if(url == '/admin/program') { target = '2'; brand = true; }
  else if(url == '/admin/schedule') { target = '3'; brand = true; }
  else if(url == '/admin/statistics') { target = '4'; brand = true; }
  else if(url == '/admin/ads') { target = '5'; brand = true; }
  else if(url == '/admin/layout') { target = '6'; brand = true; }
  else if(url == '/admin/programtype') { target = '7'; brand = true; }
  else if(url == '/admin/curriculum') { target = '8'; brand = true; }
  else if(url == '/admin/notice') { target = '9'; brand = true; }
  
  if(target == '')
  {
    return true
  }

  if(user && user.brandAuth && brand)
  {
    const brandAuth = JSON.parse(user.brandAuth);
    if(brandAuth)
    {
      for (let i = 0; i < brandAuth.length; i++) {
        for (let j = 0; j < brandAuth[i].auth.length; j++) {
          if(target == brandAuth[i].auth[j])
          {
            return true
          }
        }
      }
    }
  }
  else if(user && user.menuAuth)
  {
    const menuList = user.menuAuth.split(',')
    
    for (let i = 0; i < menuList.length; i++) {
      if(target == menuList[i])
      {
        return true
      }
    }
  }

  return false
}

export const checkBrandList = (user:UserAccountDto | null, target:string)=>{
  if(user?.role == "employee")
  {
    return []
  }

  const brandAuth = JSON.parse(user?user.brandAuth:'');
  if(brandAuth)
  {
    let result = []
    for (let i = 0; i < brandAuth.length; i++) {
      for (let j = 0; j < brandAuth[i].auth.length; j++) {
        if(target == brandAuth[i].auth[j])
        {
          result.push(brandAuth[i].id)
          break;
        }
      }
    }

    return result;
  }

  return []
}

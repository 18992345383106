import { faBuilding, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";

interface ModalProps {
  icon: any;
  title: string;
  children: React.ReactNode;
  toolTip?: string;
  required?: boolean;
  heightMax?: boolean;
}

export default function FormBox({
  icon,
  title,
  children,
  toolTip,
  required = true,
  heightMax,
}: ModalProps) {
  return (
    <div className={heightMax?"flex h-full w-full mt-3 border-b min-h-[80px] py-2 text-sm space-x-2 ":"flex items-center  w-full border-b min-h-[80px] py-2 text-sm space-x-2 "}>
      <div className={heightMax?"font-bold flex mt-3 min-w-[144px]  space-x-2 text-gray-600 ":"font-bold flex items-center min-w-[144px]  space-x-2 text-gray-600 "}>
        <div className="w-5">{icon}</div>
        <span className="flex space-x-1">
          <p>{title}</p>{" "}
          {required && <p className=" text-xs text-red-500">*</p>}
        </span>
        {toolTip && (
          <div className="">
            <FontAwesomeIcon
              icon={faCircleInfo}
              data-tooltip-id="my-tooltip"
              data-tooltip-content={toolTip}
            />
            <Tooltip id="my-tooltip" />
          </div>
        )}
      </div>

      <div className="   w-full">{children}</div>
    </div>
  );
}

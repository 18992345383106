import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCirclePlus,
  faCircleXmark,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faHandshake,
  faIcons,
  faImage,
  faLeftRight,
  faLocation,
  faPerson,
  faSign,
  faTriangleExclamation,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../components/Layout/AdminLayout";
import { Pagination } from "../../components/interface/Pagination";
import { useEffect, useRef, useState } from "react";
import Modal from "../../components/interface/Modal";
import Radio from "../../components/interface/Radio";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { StringToDate } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { useUserStore } from "../../store";
import Button from "../../components/interface/Button";
import { Category, Part, Tool } from "../../types/models";
import { TableHeaderForContents } from "../../constants/TableHeader";
import { ContentsDto } from "../../types/dto";
import {
  uploadImageToServer,
  uploadVideoToServer
} from "../../services/VimeoUpload";
import { checkRanderAdminMenu } from "../../constants/AdminMenu";

interface ContentsFormData {
  id: number;
  categories: string[];
  name: string;
  effect: string;
  description: string;
  //videoFile: File[];
  comment: string;
  icon: string[];
  icon_type: string;
  divisionAction: string;
  videoUrl: string;
  thumbnailUrl: string;
  partner: string;
}

export default function Contents() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();

  const [mutate, setMutate] = useState(false);
  const [partData, setPartData] = useState<Part[]>([]); //운동 등록시 드롭다운메뉴
  const [toolData, setToolData] = useState<Tool[]>([]); //운동 등록시 드롭다운메뉴
  const [categoryData, setCategoryData] = useState<Tool[]>([]); //운동 등록시 드롭다운메뉴

  const [selectPart, setSelectPart] = useState<Part[]>(); //운동 등록시 선택한 부위
  const [selectTool, setSelectTool] = useState<Tool[]>(); //운동 등록시 선택한 도구
  const [selectCategory, setSelectCategory] = useState<Category[]>(); //운동 등록시 선택한 도구
  const [videoState, setVideoState] = useState<number>();
  const [videoUrl, setVideoUrl] = useState<string>();
  
  const [loading, setLoading] = useState(false);
  const [uploadMsg, setUploadMsg] = useState<string>();
  const [originalVimeoUrl, setOriginalVimeoUrl] = useState<string>();
  const [videoFiles, setVideoFiles] = useState<FileList>(); //파일 비디오
  const [videoChangeTime, setVideoChangeTime] = useState(0); //파일 비디오 변경 시간
  const tableHeader = TableHeaderForContents;

  const searchData = [
    { title: "부위", value: partData, searchKey: "id", searchFiled: "partIds" },
    { title: "도구", value: toolData, searchKey: "id", searchFiled: "toolIds" },
    {
      title: "운동종류", value: categoryData, searchKey: "name", searchFiled: "categories",
    },
  ];

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    resetField,
    formState: { errors, isValid },
  } = useForm<ContentsFormData>();

  //데이터 가져오기
  useEffect(() => {
    //콘텐츠 정보 가져오기
    getPartData();
    getToolData();
    getCategoryData();
    setVideoUrl(undefined);
    if(user)
    {
      if(!checkRanderAdminMenu(user, '/admin/contents'))
      {
        navigate(`/admin/program`);
        return;
      }
    }
  }, []);

  const getPartData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/admin/part/all`, { withCredentials: true });
      setPartData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getToolData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/admin/tool/all`, { withCredentials: true });
      setToolData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCategoryData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/admin/category/all`, { withCredentials: true });
      setCategoryData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const _icontype = watch("icon_type")
  useEffect(() => {
    if(_icontype == '0')
    {
      setValue('icon', [])
      setValue('divisionAction', '')
    }
    else if(_icontype == '1')
    {
      setValue('divisionAction', '')
    }
    else
    {
      setValue('icon', [])
    }
  }, [_icontype]);

  //모달 열기
  const showModal = (isReset: boolean) => {
    window.my_modal_2.showModal();
    if (isReset) {
      reset();
      setValue('icon_type', '0')
      setSelectPart(undefined);
      setSelectTool(undefined);
      setVideoUrl(undefined);
      setVideoState(undefined);
      setOriginalVimeoUrl(undefined);

      getPartData();
      getToolData();
      getCategoryData();
      console.log("showModal");
      let canvas:any = document.getElementById('canvas');
      let ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0 , 640, 360);
    }
  };

  const showCsvModal = () => {
    //@ts-ignore
    window.csv_modal.showModal();
  };

  //썸네일 생성
  //let videoFile = watch("videoFile") ? watch("videoFile")[0] : undefined;
  let videoFile = videoFiles ? videoFiles[0] : undefined
  useEffect(() => {
    if (videoFile) {
      const url = URL.createObjectURL(videoFile);
      setVideoUrl(url);
      setTimeout(()=>{
        let videoTag:any = document.getElementById("videoTag");
        
        if(videoTag)
        {
          videoTag.currentTime = 0.1; //최근 재생된 시간 설정
      
          let canvas:any = document.getElementById('canvas');
          let ctx = canvas.getContext('2d');
          setTimeout(()=>{ //바로 출력하면 비디오가 불러오기 전이라 동작이 안됨. 잠깐의 기다림 후 캔버스에 해당 이미지를 그림.
            ctx.drawImage(videoTag, 0, 0 , 640, 360);
          }, 1000);
        }
      }, 1000);
      // URL.revokeObjectURL을 사용하여 더 이상 필요하지 않은 URL을 해제하는 것이 좋습니다.
      return () => URL.revokeObjectURL(url);
    }
  }, [videoFile]);

  //모달 닫기
  const closeModal = () => {
    reset();

    window.my_modal_2.close();
  };

  const uploadVideo = async (formData: ContentsFormData) => {
    //if (formData.videoFile && formData.videoFile[0]) {
      if(videoFiles && videoFiles[0]) {
      try {
        //const fileSize = formData.videoFile[0].size;
        const fileSize = videoFiles[0].size;
        const videoURL = await uploadVideoToServer(
          fileSize.toString(),
          formData.name,
          //formData.videoFile[0]
          videoFiles[0]
        );

        return videoURL;
      } catch (err) {
        alert("비디오 업로드 중 문제가 발생했습니다.");
        console.log('err : ', err)
        setLoading(false);
        return;
      }
    }
  };

  const dataURItoBlob = (dataURI:any)=>{
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
  
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
  
    // create a view into the buffer
    var ia = new Uint8Array(ab);
  
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
  
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
  
  }

  const onValid = async (formData: ContentsFormData) => {
    setLoading(true);
    if (!selectPart) {
      setLoading(false);
      alert("부위를 선택하세요");
      return;
    }

    if (!selectTool) {
      setLoading(false);
      alert("도구를 선택하세요");
      return;
    }

    if (!selectCategory) {
      setLoading(false);
      alert("운동 종류를 선택하세요");
      return;
    }

    if(videoChangeTime != 0 && (4000 >= (Date.now() - videoChangeTime))) {
      //setLoading(false);
      const _sleep = (ms:number)=>{
        return new Promise((r) => setTimeout(r, ms));
      }
      //alert("썸네일을 생성중입니다. 잠시후 다시 시도 하세요.");
      //return;
      await _sleep(4000 - ((Date.now() - videoChangeTime)))
    }

    const partIds = selectPart.map((item: Part) => item.id);
    const toolIds = selectTool.map((item: Tool) => item.id);
    const categories = selectCategory.map((item: Category) => item.name);

    let _videoUrl = watch('videoUrl')
    
    let _thumbnailUrl = watch('thumbnailUrl')

    //--> 비디오 업로드
    //if(formData.videoFile)
    if(videoFiles && videoFiles[0])
    {
      _videoUrl = await uploadVideo(formData)
  
      const canvas  = document.getElementById('canvas') as HTMLCanvasElement;
      const thumbnailFile = dataURItoBlob(canvas.toDataURL('image/png'))
      
      _thumbnailUrl = await uploadImageToServer(thumbnailFile)
    }

    try {
      setUploadMsg("데이터베이스 업데이트 중...");

      if (formData.id) {
        await axios.patch(`${process.env.REACT_APP_SERVER_URL}api/admin/contents/${formData.id}`, {
          partIds: partIds.toString(),
          toolIds: toolIds.toString(),
          categories: categories.toString(),
          partner: formData.partner,
          name: formData.name.toString(),
          effect: formData.effect.toString(),
          description: formData.description.toString(),
          videoUrl: _videoUrl,
          thumbnailUrl: _thumbnailUrl,
          comment: formData.comment,
          icon: formData.icon.toString(),
          divisionAction: formData.divisionAction,
          icon_type:formData.icon_type,
        }, { withCredentials: true });
        setMutate(!mutate);
        closeModal();
      } else {
        // 2. DB 저장
        await axios.post(`${process.env.REACT_APP_SERVER_URL}api/admin/contents`, {
          partIds: partIds.toString(),
          toolIds: toolIds.toString(),
          categories: categories.toString(),
          partner: formData.partner,
          name: formData.name.toString(),
          effect: formData.effect.toString(),
          description: formData.description.toString(),
          videoUrl: _videoUrl,
          thumbnailUrl: _thumbnailUrl,
          comment: formData.comment,
          icon: formData.icon.toString(),
          divisionAction: formData.divisionAction,
          icon_type:formData.icon_type,
        }, { withCredentials: true });
        setMutate(!mutate);
        closeModal();
      }
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }
    setLoading(false);
  };

  const openEditModal = (item: ContentsDto) => {
    showModal(false);

    var categoriesStr = item.content.categories;
    var categoriesArr = categoriesStr.split(",");

    console.log('categoriesArr : ', categoriesArr)
    let _selectCategory = []

    for (let i = 0; i < categoriesArr.length; i++) {
      for (let j = 0; j < categoryData.length; j++) {
        if(categoryData[j].name == categoriesArr[i]) {
          _selectCategory.push(categoryData[j])
        }
      }
    }

    var iconStr = item.content.icon || "";
    var iconArr = iconStr.split(",");

    console.log('openEditModal', item.content.videoUrl);

    setValue("id", item.content.id || 0);
    setValue("categories", categoriesArr);
    setSelectCategory(_selectCategory);
    
    setValue("partner", item.content.partner);

    console.log('item.content.partner : ', item.content.partner)
    
    setValue("name", item.content.name);
    setValue("effect", item.content.effect || "");
    setValue("description", item.content.description || "");
    setValue("thumbnailUrl", item.content.thumbnailUrl || "");
    setValue("videoUrl", item.content.videoUrl || "");
    setValue("comment", item.content.comment || "");
    setValue("icon", iconArr);
    setValue("icon_type", item.content.icon_type?item.content.icon_type:"0");
    setValue("divisionAction", item.content.divisionAction || "");
    
    setSelectPart(item.parts);
    setSelectTool(item.tools);
    setVideoUrl(item.content.videoUrl || "");
    setVideoState(item.video?.state);
    setLoading(false);
  };

  const openVideoModal = (item: ContentsDto) => {
    if (!item.content.videoUrl) {
      return;
    }
    setVideoUrl(item.content.videoUrl);
    //@ts-ignore
    window.videoModal.showModal();
  };

  const onDelete = async (item: ContentsFormData) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}api/admin/contents/${item.id}`, { withCredentials: true });
      closeModal();
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };

  const onAddTag = (type: string, item: Part) => {
    if (type === "part") {
      if (!selectPart) {
        // if selectPart is undefined or null
        setSelectPart([item]); // initialize it with the new id
      } else {
        setSelectPart([...selectPart, item]); // otherwise, append the new id
      }
      setPartData(partData.filter((listData: Part) => item.id !== listData.id));
    } else if (type === "tool") {
      if (!selectTool) {
        // if selectTool is undefined or null
        setSelectTool([item]); // initialize it with the new id
      } else {
        setSelectTool([...selectTool, item]); // otherwise, append the new id
      }
      setToolData(toolData.filter((listData: Tool) => item.id !== listData.id));
    } else if (type === "category") {
      if (!selectCategory) {
        // if selectTool is undefined or null
        setSelectCategory([item]); // initialize it with the new id
      } else {
        setSelectCategory([...selectCategory, item]); // otherwise, append the new id
      }
      //setCategoryData(categoryData.filter((listData: Category) => item.id !== listData.id));
    }
  };

  const onDeleteTag = (type: string, item: Part) => {
    if (type === "part") {
      if (selectPart) {
        // filter out the item with the provided id
        setSelectPart(
          selectPart.filter((listData: Part) => item.id !== listData.id)
        );
      }

      setPartData([...partData, item]);
    } else if (type === "tool") {
      if (selectTool) {
        // filter out the item with the provided id
        setSelectTool(
          selectTool.filter((listData: Tool) => item.id !== listData.id)
        );
      }
      setToolData([...toolData, item]);
    } else if (type === "category") {
      if (selectCategory) {
        // filter out the item with the provided id
        setSelectCategory(
          selectCategory.filter((listData: Category) => item.id !== listData.id)
        );
      }
      //setCategoryData([...categoryData, item]);
    }
  };

  const fileInputRef: any = useRef(null);

  const [csvLoading, setCsvLoading] = useState(false);

  const handleUpload = async () => {
    setCsvLoading(true);
    const file = fileInputRef.current.files[0];
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post("/api/admin/csv/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("Upload Successful:", response.data);

      setCsvLoading(false);

      setMutate(!mutate);

      //@ts-ignore
      window.csv_modal.close();
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };

  return (
    <>
      <Modal id="csv_modal" title={`CSV 대량 등록`}>
        <div className="mt-5 flex flex-col">
          <input type="file" ref={fileInputRef} />
          <Button
            loading={csvLoading}
            title={`CSV 업로드`}
            onClick={handleUpload}
            iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
          />
        </div>
      </Modal>
      <Modal id="videoModal" title={`영상`}>
        <video src={videoUrl} className="border mt-5" controls />
      </Modal>
      <Modal
        size="large"
        id="my_modal_2"
        title={`운동 ${watch("id") ? "수정" : "등록"}`}
        overflow="hidden"
      >
        <div className="flex space-x-2">
          <div className="w-1/2">
            <FormBox
              title="부위"
              icon={<FontAwesomeIcon icon={faDrumstickBite} />}
            >
              <div className="flex  items-center flex-wrap ">
                <div className="dropdown dropdown-bottom">
                  <label tabIndex={0} className="btn  btn-xs mr-1">
                    <FontAwesomeIcon icon={faCirclePlus} />
                    추가
                  </label>

                  <ul
                    tabIndex={0}
                    className="dropdown-content z-[9999] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    {partData ? (
                      partData.map((part: Part) => (
                        <li>
                          <a onClick={() => onAddTag("part", part)}>
                            {part.name}
                          </a>
                        </li>
                      ))
                    ) : (
                      <div className="flex justify-center items-center">
                        <span className="loading loading-spinner loading-xs"></span>
                      </div>
                    )}
                  </ul>
                </div>
                {selectPart &&
                  selectPart.map((part: Part) => (
                    <div className="btn btn-xs m-1 bg-blue-200">
                      {part.name}{" "}
                      <FontAwesomeIcon
                        onClick={() => onDeleteTag("part", part)}
                        icon={faCircleXmark}
                      />
                    </div>
                  ))}
              </div>
            </FormBox>
            <FormBox title="도구" icon={<FontAwesomeIcon icon={faDumbbell} />}>
              <div className="flex  items-center flex-wrap ">
                <div className="dropdown dropdown-bottom">
                  <label tabIndex={0} className="btn  btn-xs mr-1">
                    <FontAwesomeIcon icon={faCirclePlus} />
                    추가
                  </label>

                  <ul
                    tabIndex={0}
                    className="dropdown-content z-[9999] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    {toolData ? (
                      toolData.map((part: Tool) => (
                        <li>
                          <a onClick={() => onAddTag("tool", part)}>
                            {part.name}
                          </a>
                        </li>
                      ))
                    ) : (
                      <div className="flex justify-center items-center">
                        <span className="loading loading-spinner loading-xs"></span>
                      </div>
                    )}
                  </ul>
                </div>
                {selectTool &&
                  selectTool.map((part: Tool) => (
                    <div className="btn btn-xs m-1 bg-orange-200">
                      {part.name}
                      <FontAwesomeIcon
                        onClick={() => onDeleteTag("tool", part)}
                        icon={faCircleXmark}
                      />
                    </div>
                  ))}
              </div>
            </FormBox>

            <FormBox title="파트너 동작" required={false} icon={<FontAwesomeIcon icon={faHandshake} />}>
              <div className="flex space-x-5">
                <Radio
                  title="미사용"
                  value={0}
                  type="radio"
                  register={register("partner", {
                    required: false,
                  })}
                />
                <Radio
                  title="사용"
                  value={1}
                  type="radio"
                  register={register("partner", {
                    required: false,
                  })}
                />
              </div>
            </FormBox>
            
            <FormBox title="운동 종류" icon={<FontAwesomeIcon icon={faDumbbell} />}>
              <div className="flex  items-center flex-wrap ">
                <div className="dropdown dropdown-bottom">
                  <label tabIndex={0} className="btn  btn-xs mr-1">
                    <FontAwesomeIcon icon={faCirclePlus} />
                    추가
                  </label>

                  <ul
                    tabIndex={0}
                    className="dropdown-content z-[9999] menu p-2 shadow bg-base-100 rounded-box w-52"
                  >
                    {categoryData ? (
                      categoryData.map((category: Category) => {
                        const _result = selectCategory?.find((a:Category)=>{ return a.id == category.id})
                        if(_result) {
                          return null
                        }

                        return (
                          <li>
                            <a onClick={() => onAddTag("category", category)}>
                              {category.name}
                            </a>
                          </li>
                        )
                      }
                      )
                    ) : (
                      <div className="flex justify-center items-center">
                        <span className="loading loading-spinner loading-xs"></span>
                      </div>
                    )}
                  </ul>
                </div>
                {selectCategory &&
                  selectCategory.map((category: Category) => (
                    <div className="btn btn-xs m-1 bg-orange-200">
                      {category.name}
                      <FontAwesomeIcon
                        onClick={() => onDeleteTag("category", category)}
                        icon={faCircleXmark}
                      />
                    </div>
                  ))}
              </div>
            </FormBox>
            {/* <FormBox title="구분" icon={<FontAwesomeIcon icon={faLocation} />}>
              <div className="flex space-x-5">
                <Radio
                  title="프리웨이트"
                  value="프리웨이트"
                  type="checkbox"
                  register={register("categories", {
                    required: "운동종류를 선택해주세요",
                  })}
                />
                <Radio
                  title="요가"
                  value="요가"
                  type="checkbox"
                  register={register("categories", {
                    required: "운동종류를 선택해주세요",
                  })}
                />
                <Radio
                  title="필라테스"
                  value="필라테스"
                  type="checkbox"
                  register={register("categories", {
                    required: "운동종류를 선택해주세요",
                  })}
                />
              </div>
            </FormBox> */}
            <FormBox title="운동명" icon={<FontAwesomeIcon icon={faSign} />}>
              <FormInput
                placeholder="운동명"
                register={register("name", {
                  required: "운동명을 입력해주세요",
                })}
              />
            </FormBox>
            <FormBox
              title="효과"
              required={false}
              icon={<FontAwesomeIcon icon={faFire} />}
            >
              <FormInput placeholder="효과" register={register("effect")} />
            </FormBox>
            <FormBox
              title="설명"
              required={false}
              icon={<FontAwesomeIcon icon={faBook} />}
            >
              <FormInput
                placeholder="설명"
                type="textarea"
                register={register("description")}
              />
            </FormBox>
            <FormBox
              title="수업 코멘트"
              required={false}
              icon={<FontAwesomeIcon icon={faBook} />}
            >
              <FormInput
                placeholder="수업 코멘트"
                type="textarea"
                register={register("comment")}
              />
            </FormBox>
          </div>

          <div className="w-1/2 pl-2">
            <FormBox
              title="아이콘"
              required={false}
              icon={<FontAwesomeIcon icon={faLeftRight} />}
            >
              <div className="flex space-x-5">
                <Radio
                  title="없음"
                  value="0"
                  type="radio"
                  register={register("icon_type", {
                    required: false,
                  })}
                />
                <Radio
                  title="좌우"
                  value="1"
                  type="radio"
                  register={register("icon_type", {
                    required: false,
                  })}
                />
                <Radio
                  title="구분동작"
                  value="2"
                  type="radio"
                  register={register("icon_type", {
                    required: false,
                  })}
                />
                {/* <Radio
                  title="거리"
                  value="2"
                  type="radio"
                  register={register("icon_type", {
                    required: false,
                  })}
                />
                <Radio
                  title="횟수"
                  value="3"
                  type="radio"
                  register={register("icon_type", {
                    required: false,
                  })}
                /> */}
              </div>
              {
                watch("icon_type") == '1'?
                <div className="flex space-x-5">
                  <Radio
                    title="L"
                    value="L"
                    type="checkbox"
                    register={register("icon", {
                      required: false,
                    })}
                  />
                  <Radio
                    title="R"
                    value="R"
                    type="checkbox"
                    register={register("icon", {
                      required: false,
                    })}
                  />
                </div>
                :
                watch("icon_type") == '2'?
                <div className="p-2">
                  <FormInput
                    placeholder="구분동작"
                    type="textarea"
                    step={3}
                    register={register("divisionAction")}
                  />
                </div>
                // watch("icon_type") == '2'?
                // <div className="flex space-x-5 space-y-2 items-center">
                //   <div>최소 거리</div>
                //   <input
                //     placeholder={''}
                //     value={targetMinValue}
                //     className={`input w-20 h-8 border border-[#E9E9E9]`}
                //     onChange={(e)=>{
                //       var regex = /[^0-9]/g;
                //       var result = e.target.value.replace(regex, "");
                //       setTargetMinValue(result)
                //     }}
                //   />
                //   <div>최대 거리</div>
                //   <input
                //     placeholder={''}
                //     value={targetMaxValue}
                //     className={`input w-20 h-8 border border-[#E9E9E9]`}
                //     onChange={(e)=>{
                //       var regex = /[^0-9]/g;
                //       var result = e.target.value.replace(regex, "");
                //       setTargetMaxValue(result)
                //     }}
                //   />
                // </div>
                // :
                // watch("icon_type") == '3'?
                // <div className="flex space-x-5 space-y-2 items-center">
                //   <div>최소 횟수</div>
                //   <input
                //     placeholder={''}
                //     value={targetMinValue}
                //     className={`input w-20 h-8 border border-[#E9E9E9]`}
                //     onChange={(e)=>{
                //       var regex = /[^0-9]/g;
                //       var result = e.target.value.replace(regex, "");
                //       setTargetMinValue(result)
                //     }}
                //   />
                //   <div>최대 횟수</div>
                //   <input
                //     placeholder={''}
                //     value={targetMaxValue}
                //     className={`input w-20 h-8 border border-[#E9E9E9]`}
                //     onChange={(e)=>{
                //       var regex = /[^0-9]/g;
                //       var result = e.target.value.replace(regex, "");
                //       setTargetMaxValue(result)
                //     }}
                //   />
                // </div>
                :null
              }
            </FormBox>
            {/* <FormBox
              title="구분동작"
              required={false}
              icon={<FontAwesomeIcon icon={faPerson} />}
            >
              <FormInput
                placeholder="구분동작"
                type="textarea"
                register={register("divisionAction")}
              />
            </FormBox> */}
            <>
              <FormBox
                title="운동영상"
                icon={<FontAwesomeIcon icon={faVideo} />}
              >
                <div className="flex items-center">
                  <label htmlFor="file-upload-platformA" className="btn">
                    <span className="cursor-pointer">파일 선택</span>
                  </label>
                  <input
                    id="file-upload-platformA"
                    type="file"
                    accept="video/mp4"
                    className="hidden"
                    // {...register("videoFile", {
                    //   required:true
                    // })}
                    onChange={e =>{
                      setVideoChangeTime(Date.now())
                      setVideoFiles(e.target.files?e.target.files:undefined)
                    }} 
                  />
                  {/* <div className="mt-2 ml-2">{watch("videoFile")?watch("videoFile")[0]?.name:''}</div> */}
                  <div className="mt-2 ml-2">{videoFiles?videoFiles[0]?.name:''}</div>
                </div>
              </FormBox>
              <div className=" relative">
                <div className=" absolute -z-10">
                  <canvas id="canvas" width={640} height={360} ></canvas>
                </div>
              </div>
              {
                videoUrl ? (
                  <video id='videoTag' src={videoUrl} className="border mt-5" controls />
                ) : null
              }
              {/* {watch("videoFile") && (
                <FormBox
                  title="파일이름"
                  required={false}
                  icon={<FontAwesomeIcon icon={faFile} />}
                >
                  <p className="mt-2">{watch("videoFile")[0]?.name}</p>
                </FormBox>
              )} */}
            </>
          </div>
        </div>

        <div className="w-full flex flex-col justify-center mt-5">
          {watch("id") && (
            <button
              className=" btn w-full bg-black text-white  "
              onClick={() => onDelete(watch())}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} />
              운동 삭제
            </button>
          )}

          <Button
            loading={loading}
            title={`운동 ${watch("id") ? "수정" : "등록"}`}
            onClick={handleSubmit(onValid)}
            iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
            disabled={isValid && videoUrl ? false : true}
          />
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <div className="flex justify-between">
            <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
              운동 콘텐츠 관리
            </h1>
            {/* <button
              className=" btn-primary btn btn-sm   text-white  "
              onClick={() => showCsvModal()}
            >
              <FontAwesomeIcon icon={faCirclePlus} />
              CSV 대량 등록
            </button> */}
          </div>
          <Table
            dataApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/contents`}
            buttonComponent={
              <button
                className=" btn-primary btn   text-white  "
                onClick={() => showModal(true)}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                운동 등록
              </button>
            }
            headerArray={tableHeader}
            mutate={mutate}
            initSort="createdAt"
            initDirection="desc"
            searchData={searchData}
            searchApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/contents/search`}
          >
            {(data: ContentsDto[], startIndex: number) =>
              data.map((item: ContentsDto, index: number) => {
                
                return (
                  <tr key={item.content.id}>
                    <th>{startIndex - index}</th>
                    <td className="truncate">
                      <StringToDate
                        dateString={item.content.createdAt.toString()}
                      />
                    </td>

                    <td>
                      {item.parts.map((part: Part) => (
                        <p className="btn btn-xs m-1 bg-blue-200 truncate ">
                          {part.name}
                        </p>
                      ))}
                    </td>
                    <td>
                      {item.tools.map((tool: Tool) => (
                        <p className="btn btn-xs m-1 bg-orange-200 truncate">
                          {tool.name}
                        </p>
                      ))}
                    </td>
                    <td className="w-[100px] flex  justify-center ">
                        <img
                          className="h-10 cursor-pointer "
                          onClick={() => openVideoModal(item)}
                          src={item.content.thumbnailUrl || ""}
                        />
                    </td>
                    <td className="truncate">{item.content.name}</td>
                    <td className="truncate">{item.content.categories}</td>
                    <td>{item.content.registrationCount}</td>
                    <td>
                      <button
                        onClick={() => openEditModal(item)}
                        className="btn btn-xs truncate "
                      >
                        수정
                      </button>
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BranchGroup } from "../types/models";
import {
  faDeleteLeft,
  faGrip,
  faGripVertical,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import axios from "axios";
import { useDrag } from "react-dnd";
import { DragItem } from "../types/dnd";
import AdsBoxDrag from "./AdsBoxDrag";
import BranchGroupNameDrag from "./BranchGroupNameDrag";
import BranchGroupNameDrop from "./BranchGroupNameDrop";
import { useQueryClient } from "@tanstack/react-query";
import { cls } from "../libs/utils";

interface UserGroupBoxProps {
  i: number;
  item: any;
  readonly: boolean;
  moveContent?: any;
  onDelete?: any;
  size?: string;
}

export default function UserGroupBox({
  i,
  item,
  readonly,
  moveContent,
  onDelete,
  size,
}: UserGroupBoxProps) {
  const groupArr = ["A", "B", "C"];

  return (
    <div>
      <div
        className={cls(
          "bg-slate-200 relative overflow-hidden w-full  flex justify-between items-center text-sm text-black rounded-t-md",
          size === "tv" ? " h-14" : "h-10"
        )}
      >
        {readonly && (
          <div className={"ml-4 flex space-x-2 items-center "}>
            <p
              className={cls(
                "font-bold",
                size === "tv" ? "text-3xl" : "text-base"
              )}
            >
              {item && item.name}
            </p>
            <p
              className={cls(
                "font-bold",
                size === "tv" ? "text-3xl" : "text-base"
              )}
            >
              {item && `(${item.phone})`}
            </p>
          </div>
        )}
        {item && !readonly && <BranchGroupNameDrag item={item} />}
        <BranchGroupNameDrop index={i} moveContent={moveContent} />
        {item && item.id && !readonly && (
          <div
            className="cursor-pointer px-2 z-40"
            onClick={() => onDelete(item.id)}
          >
            <FontAwesomeIcon icon={faDeleteLeft} />
          </div>
        )}
      </div>

      <div className=" bg-slate-50 overflow-hidden flex h-20 text-sm text-black rounded-b-md">
        <div className="border-r  w-1/3 flex flex-col justify-center items-center text-center border-slate-200">
          <p className={cls("", size === "tv" ? "text-2xl" : "text-xs")}>
            BIKE NO.
          </p>
          <p
            className={cls(
              "",
              size === "tv" ? "text-4xl font-bold" : "text-lg font-bold"
            )}
          >
            {Math.floor(i / 2) + 1}
          </p>
        </div>
        <div className="border-r  w-1/3 flex flex-col justify-center items-center text-center border-slate-200">
          <p className={cls("", size === "tv" ? "text-2xl" : "text-xs")}>
            GROUP
          </p>
          <p
            className={cls(
              "",
              size === "tv" ? "text-4xl font-bold" : "text-lg font-bold"
            )}
          >
            {groupArr[Math.floor(i / 2) % groupArr.length]}
          </p>
        </div>
        <div
          className={cls(
            "w-1/3 flex flex-col justify-center items-center text-center border-slate-200",
            i % 2 === 0 ? "bg-blue-200" : "bg-orange-200"
          )}
        >
          <p
            className={cls(
              "",
              size === "tv" ? "text-4xl font-bold" : "text-lg font-bold"
            )}
          >
            {i % 2 === 0 ? "BIKE" : "FLOOR"}
          </p>
        </div>
      </div>
    </div>
  );
}

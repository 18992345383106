import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useBranchStore } from "../../store";
import ScreenBottomNav from "../../components/Navigation/ScreenBottomNav";
import QRCode from "qrcode.react";
import { useEffect, useState } from "react";

export default function ScreenQr() {
  const { branchCode } = useParams();
  const storeBranchName = useBranchStore((state) => state.branchName);
  const [qrUrl, setQrUrl] = useState<string>();

  useEffect(() => {
    const url = new URL(window.location.href);

    const qrLink = `${url.origin}/screen/${branchCode}/register`;
    setQrUrl(qrLink);
  }, []);

  return (
    <div className=" justify-center items-center min-h-screen flex p-5 md:p-20  flex-col space-y-5    ">
      <img src="/main-logo.png" className=" w-56  mb-5 " alt="logo" />
      <h1 className="text-2xl font-bold ">
        {storeBranchName} - 그룹배정 QR코드
      </h1>
      <div>{qrUrl && <QRCode value={qrUrl} size={300} />}</div>

      <p className="text-xl font-bold">
        스마트폰 일반 카메라로 QR코드를 스캔하세요!
      </p>
      <ScreenBottomNav />
    </div>
  );
}

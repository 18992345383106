import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCirclePlus,
  faCircleXmark,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faHandshake,
  faIcons,
  faImage,
  faLeftRight,
  faLocation,
  faPerson,
  faSign,
  faTriangleExclamation,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../components/Layout/AdminLayout";
import { Pagination } from "../../components/interface/Pagination";
import { useEffect, useRef, useState } from "react";
import Modal from "../../components/interface/Modal";
import Radio from "../../components/interface/Radio";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { StringToDate, getUseEditFromAuth } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { useUserStore } from "../../store";
import Button from "../../components/interface/Button";
import { Brand, Layout, Part, Programtype, Tool } from "../../types/models";
import { TableHeaderForCurriculum } from "../../constants/TableHeader";
import { BrandDto, ContentsDto, CurriculumDto } from "../../types/dto";
import {
  uploadImageToServer,
  uploadVideoToServer
} from "../../services/VimeoUpload";
import { checkRanderAdminMenu } from "../../constants/AdminMenu";

interface CurriculumFormData {
  id?: number;
  brandId: number;
  programType: number;
  name: string;
  layoutId: number;
  description?:string;
}

export default function Curriculum() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const [brandData, setBrandData] = useState<BrandDto[]>();
  const [programTypeAllData, setProgramTypeAllData] = useState<Programtype[]>();
  const [layoutData, setLayoutData] = useState<Layout[]>([]);
  const [selectLayout, setSelectLayout] = useState<Layout[]>();
  const [selectChanged, setSelectChanged] = useState(false);
  const [tvBadgeUrl, setTvBadgeUrl] = useState("");
  const [pervBrandId, setpervBrandId] = useState(0);
  const [brandType, setBrandType] = useState(0);
  const [tvBadgeFile, setTvBadgeFile] = useState<FileList>();
  const [homepageBadgeUrl, setHomepageBadgeUrl] = useState("");
  const [homepageBadgeFile, setHomepageBadgeFile] = useState<FileList>();
  const [brandAuth, setBrandAuth] = useState<any[]>();
  const [descriptionCount, setDescriptionCount] = useState(0);
  
  
  
  const [mutate, setMutate] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getBrands = async () => {
      try {
        const getbrand = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`, {
          withCredentials: true,
        });
        setBrandData(getbrand.data)
      } catch (error) {
      }
    };
    const getProgramTypes = async () => {
      try {
        const getProgramType = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/programtype/all`, {
          withCredentials: true,
        });
        let result = []
        if(getProgramType.data) {
          for (let i = 0; i < getProgramType.data.length; i++) {
            console.log(getProgramType.data[i])
            result.push({...getProgramType.data[i], name:`${getProgramType.data[i].name}`})
            
          }
          result.sort((a:Programtype, b:Programtype)=>{
            return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
          })
        }
        setProgramTypeAllData(result)
      } catch (error) {
      }
    };
    const getLayouts = async () => {
      try {
        const _getLayout = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/admin/layout/all`, {
          withCredentials: true,
        });
        setLayoutData(_getLayout.data)
      } catch (error) {
      }
    };

    getBrands()
    getProgramTypes()
    getLayouts()
  }, []);

  useEffect(() => {
    if(brandData && layoutData)
    {
      //setProgramTypeData()
    }
  }, [brandData, layoutData]);
  
  useEffect(() => {
    if(user) {
      const _brandAuth = JSON.parse(user.brandAuth);
      if(_brandAuth)
      {
        setBrandAuth(_brandAuth)
      }
    }
  }, [user]);

  const tableHeader = TableHeaderForCurriculum;

  const searchData = [
    { title: "브랜드", value: brandData, searchKey: "id", searchFiled: "brandIds" },
    { title: "운동타입", value: programTypeAllData, searchKey: "id", searchFiled: "programTypes" }
  ];

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    resetField,
    formState: { errors, isValid },
  } = useForm<CurriculumFormData>();

  //모달 열기
  const showModal = (isReset: boolean) => {
    window.my_modal_2.showModal();
    if (isReset) {
      reset();
      setValue('brandId', 0)
      setValue('name', "")
    }
  };

  //모달 닫기
  const closeModal = () => {
    reset();

    window.my_modal_2.close();
  };

  const uploadImage = async (url:string | undefined, file:FileList | undefined) => {
    try {
      let newImageUrl = url ? url : '';
      
      if (file && file[0]) {
        try {
          newImageUrl = await uploadImageToServer(file[0]);
        } catch (err) {
          alert("이미지 업로드 중 문제가 발생했습니다.");
          return;
        }
      }

      return newImageUrl
    } catch (error: any) {
      if (error.response.data) {
        alert(error.response.data);
      }
      console.error(error);
    }
  };

  const onValid = async (formData: CurriculumFormData) => {
    if (!selectLayout) {
      setLoading(false);
      alert("레이아웃을 선택하세요");
      return;
    }
    try {
      const _brand = brandData?.filter((brand:BrandDto)=>{
        if(brand.id == formData.brandId)
        {
          return true
        }

        return false;
      })
      const _programType = programTypeAllData?.filter((programType:Programtype)=>{
        if(programType.id == formData.programType)
        {
          return true
        }

        return false;
      })

      console.log('formData.programType : ', formData.programType, _programType)
  
      const layoutIds = selectLayout.map((item: Layout) => item.id);
      const layoutNames = selectLayout.map((item: Layout) => item.name);
      
      let tv_badge_url = await uploadImage(tvBadgeUrl, tvBadgeFile);
      let homepage_badge_url = await uploadImage(homepageBadgeUrl, homepageBadgeFile);
      if (formData.id) {
        await axios.patch(`${process.env.REACT_APP_SERVER_URL}api/admin/curriculum/${formData.id}`, {
            brandId:formData.brandId,
            brandName:_brand?_brand[0].name:'',
            programType:formData.programType,
            programTypeName:_programType?_programType[0].name:'',
            name:formData.name,
            layoutIds:layoutIds?layoutIds:[],
            layoutNames:layoutNames,
            tv_badge_url:tv_badge_url,
            homepage_badge_url:homepage_badge_url,
            description:formData.description
        }, { withCredentials: true });
        setMutate(!mutate);
        closeModal();
      } else {
        // 2. DB 저장
        await axios.post(`${process.env.REACT_APP_SERVER_URL}api/admin/curriculum`, {
          brandId:formData.brandId,
          brandName:_brand?_brand[0].name:'',
          programType:formData.programType,
          programTypeName:_programType?_programType[0].name:'',
          name:formData.name,
          layoutIds:layoutIds,
          layoutNames:layoutNames,
          tv_badge_url:tv_badge_url,
          homepage_badge_url:homepage_badge_url,
          description:formData.description
        }, { withCredentials: true });
        setMutate(!mutate);
        closeModal();
      }
    } catch (error: any) {
      if (error && error.response && error.response.data) {
        alert(error.response.data);
      }
      console.error('asdasd', error);
    }
    setLoading(false);
  };

  const openEditModal = (item: CurriculumDto) => {
    showModal(false);
    setValue("id", item.id || 0);
    setValue("brandId", item.brandId || 0);
    setpervBrandId(item.brandId)
    setValue("programType", item.programType || 0);
    setTvBadgeUrl(item.tv_badge_url?item.tv_badge_url:'');
    setHomepageBadgeUrl(item.homepage_badge_url?item.homepage_badge_url:'');
    setValue("description", item.description?item.description:'');
    let _description = item.description?item.description:''
    setDescriptionCount(_description.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length)
    
    const layoutIds = item.layoutIds.split(',');
    const layouts = []
    
    for (let j = 0; j < layoutData.length; j++) {
      for (let i = 0; i < layoutIds.length; i++) {
        if(layoutData[j].id == parseInt(layoutIds[i]))
        {
          layouts.push(layoutData[j])
        }
      }
    }
    setSelectLayout(layouts)
    setValue("name", item.name);
    setLoading(false);
  };

  const onDelete = async (_id: number) => {
    var result = window.confirm("정말 삭제하시겠어요?");

    if (!result) {
      return;
    }

    try {
      await axios.delete(`${process.env.REACT_APP_SERVER_URL}api/admin/curriculum/${_id}`, { withCredentials: true });
      closeModal();
      setMutate(!mutate);
    } catch (error: any) {
      console.error(error);
      if (error.response && error.response.data) {
        alert(error.response.data);
      }
    }
  };


  const onAddTag = (item: Layout) => {
    if (!selectLayout) {
      // if selectLayout is undefined or null
      setSelectLayout([item]); // initialize it with the new id
    } else {
      setSelectLayout([...selectLayout, item]); // otherwise, append the new id
    }
    setSelectChanged(true)
  };

  const onDeleteTag = (item: Layout) => {
    if (selectLayout) {
      // filter out the item with the provided id
      setSelectLayout(
        selectLayout.filter((listData: Layout) => item.id !== listData.id)
      );
      setSelectChanged(true)
    }
  };


  const _brandId = watch('brandId')
  
  useEffect(() => {
    if(_brandId && pervBrandId != _brandId)
    {
      setValue("programType", 0);
      setSelectLayout([])
      setpervBrandId(_brandId)
    }
    else if(!_brandId)
    {
      setValue("programType", 0);
      setSelectLayout([])
      setpervBrandId(_brandId)
    }

    if(brandData) {
      for (let i = 0; i < brandData.length; i++) {
        if(brandData[i].id == _brandId) {
          setBrandType(brandData[i].type)
          break;
        }
      }
    }
  }, [_brandId, pervBrandId]);

  useEffect(() => {
    if (tvBadgeFile && tvBadgeFile[0]) {
      setTvBadgeUrl(URL.createObjectURL(tvBadgeFile[0]));
    }
  }, [tvBadgeFile]);

  useEffect(() => {
    if (homepageBadgeFile && homepageBadgeFile[0]) {
      setHomepageBadgeUrl(URL.createObjectURL(homepageBadgeFile[0]));
    }
  }, [homepageBadgeFile]);

  return (
    <>
      <Modal
        size="large"
        id="my_modal_2"
        title={`커리큘럼 ${watch("id") ? "수정" : "등록"}`}
        overflow="hidden"
      >
        <FormBox title="브랜드" icon={<FontAwesomeIcon icon={faSign} />}>
          <select
            {...register("brandId")}
            className="select select-bordered w-full max-w-xs"
          >
            {brandData &&
              brandData.map(
                (brand: BrandDto) => {
                  if(!getUseEditFromAuth('8', brand.id, brandAuth || [])) {
                    return null
                  }
                  
                  return (
                    <option value={brand.id}>
                      {brand.name}
                    </option>
                  )
                }
              )}
          </select>
        </FormBox>
        <FormBox title="운동타입" icon={<FontAwesomeIcon icon={faSign} />}>
          <select
            {...register("programType")}
            className="select select-bordered w-full max-w-xs"
          >
            {programTypeAllData &&
              programTypeAllData.map(
                (programType: Programtype) => {
                  if(programType.brandId == watch('brandId'))
                  {
                    return (
                      <option value={programType.id}>
                        {programType.name}
                      </option>
                    )
                  }

                  return null
                }
              )}
          </select>
        </FormBox>
        <FormBox title="커리큘럼명" icon={<FontAwesomeIcon icon={faSign} />}>
          <FormInput
            placeholder="커리큘럼명"
            register={register("name", {
              required: "커리큘럼을 입력해주세요",
            })}
          />
        </FormBox>
        {
          brandType == 2?
          <FormBox title="레이아웃 설정" icon={<FontAwesomeIcon icon={faDumbbell} />}>
            <div className="flex  items-center flex-wrap ">
              <div className="dropdown dropdown-bottom">
                <label tabIndex={0} className="btn  btn-xs mr-1">
                  <FontAwesomeIcon icon={faCirclePlus} />
                  추가
                </label>
  
                <ul
                  tabIndex={0}
                  className="dropdown-content z-[9999] menu p-2 shadow bg-base-100 rounded-box w-52"
                >
                  {layoutData ? (
                    layoutData.map((layout: Layout) =>{
                      const check = selectLayout?.filter((_layout: Layout)=>layout.id == _layout.id)
                      if(check && check.length > 0)
                      {
                        return null
                      }
                      if(layout.brandId == watch("brandId"))
                      {
                        return (
                          <li>
                            <a onClick={() => onAddTag(layout)}>
                              {layout.name}
                            </a>
                          </li>
                        )
                      }
  
                      return null;
                    }
                    )
                  ) : (
                    <div className="flex justify-center items-center">
                      <span className="loading loading-spinner loading-xs"></span>
                    </div>
                  )}
                </ul>
              </div>
              {selectLayout &&
                selectLayout.map((layout: Layout) => (
                  <div className="btn btn-xs m-1 bg-orange-200">
                    {layout.name}
                    <FontAwesomeIcon
                      onClick={() => onDeleteTag(layout)}
                      icon={faCircleXmark}
                    />
                  </div>
                ))}
            </div>
          </FormBox>
          :null
        }
        <div className="flex w-full space-x-2 ">
            <div className="w-1/2">
              <FormBox title="TV용 뱃지" required={false} icon={<FontAwesomeIcon icon={faImage}/>}>
                <div className="flex">
                  <label htmlFor="file-upload-platformA" className="btn">
                    <span className="cursor-pointer">파일 선택</span>
                  </label>
                  <input
                    id="file-upload-platformA"
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    className="hidden"
                    onChange={e =>{setTvBadgeFile(e.target.files?e.target.files:undefined)}} 
                  />
                  {
                    tvBadgeUrl?
                    <img src={tvBadgeUrl} className="border ml-3 w-14 " alt="preview" />
                    :null
                  }
                  {tvBadgeFile && (
                    <p className="mt-2 ml-3">{tvBadgeFile[0]?.name}</p>
                  )}
                </div>
              </FormBox>
            </div>
            <div className="w-1/2">
              <FormBox title="홈페이지용 뱃지" required={false} icon={<FontAwesomeIcon icon={faImage}/>}>
                <div className="flex">
                  <label htmlFor="file-upload-platformB" className="btn">
                    <span className="cursor-pointer">파일 선택</span>
                  </label>
                  <input
                    id="file-upload-platformB"
                    type="file"
                    accept="image/png, image/jpeg, image/gif"
                    className="hidden"
                    onChange={e =>{setHomepageBadgeFile(e.target.files?e.target.files:undefined)}} 
                  />
                  {
                    homepageBadgeUrl?
                    <img src={homepageBadgeUrl} className="border ml-3 w-14 " alt="preview" />
                    :null
                  }
                  {homepageBadgeFile && (
                    <p className="mt-2 ml-3">{homepageBadgeFile[0]?.name}</p>
                  )}
                </div>
              </FormBox>
            </div>
        </div>
        <FormBox
          title="설명"
          required={false}
          icon={<FontAwesomeIcon icon={faBook} />}
        >
          <textarea
            //{...register("description")}
            value={watch("description")}
            onChange={(e)=>{
              let _description = e.currentTarget.value
              const _descriptionCount = _description.replace(/[\0-\x7f]|([0-\u07ff]|(.))/g, "$&$1$2").length
              if(_descriptionCount <= 220) {
                setValue("description", e.currentTarget.value)
                setDescriptionCount(_descriptionCount)
                setSelectChanged(true)
              }
            }}
            placeholder={"설명"}
            className="textarea textarea-bordered textarea-md w-full "
            maxLength={220}
          >

          </textarea>
          <div className=" flex">
            <div className=" flex-1"/>
            <div>{descriptionCount}/220 byte</div>
          </div>
          {/* <FormInput
            placeholder="설명"
            type="textarea"
            register={register("description")}
            maxLength={30}
          /> */}
        </FormBox>
        <div className="w-full flex flex-col justify-center mt-5">
          {watch("id") && (
            <button
              className=" btn w-full bg-black text-white  "
              onClick={() => onDelete(watch("id") || 0)}
            >
              <FontAwesomeIcon icon={faTriangleExclamation} />
              커리큘럼 삭제
            </button>
          )}
          <Button
            loading={loading}
            title={`커리큘럼 ${watch("id") ? "수정" : "등록"}`}
            onClick={handleSubmit(onValid)}
            iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
            disabled={(isValid || selectChanged)? false : true}
          />
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <div className="flex justify-between">
            <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">
              커리큘럼 관리
            </h1>
          </div>
          <Table
            dataApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/curriculum`}
            buttonComponent={
              <button
                className=" btn-primary btn   text-white  "
                onClick={() => showModal(true)}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                커리큘럼 등록
              </button>
            }
            headerArray={tableHeader}
            mutate={mutate}
            initSort="created_at"
            initDirection="desc"
            searchData={searchData}
            onChangeSearchCategory={(key: string, value: string, name: string)=>{
              console.log('onChangeSearchCategory : ', key, value, name)
            }}
            searchApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/curriculum/search`}
          >
            {(data: CurriculumDto[], startIndex: number) =>
              data.map((item: CurriculumDto, index: number) => {
                
                return (
                  <tr key={item.id}>
                    <th>{startIndex - index}</th>
                    <td className="truncate">
                      <StringToDate
                        dateString={item.created_at.toString()}
                      />
                    </td>
                    <td>
                      {item.brandName}
                    </td>
                    <td>
                      {item.programTypeName}
                    </td>
                    <td className="truncate">{item.name}</td>
                    <td className="w-[100px] flex  justify-center ">
                      {
                        item.tv_badge_url?
                        <img
                          className="h-10 cursor-pointer "
                          src={item.tv_badge_url || ""}
                        />
                        :null
                      }
                    </td>
                    <td className="truncate">{item.program_count}</td>
                    <td>
                      <button
                        onClick={() => openEditModal(item)}
                        className="btn btn-xs truncate "
                        disabled={!getUseEditFromAuth('8', item?.brandId, brandAuth || [])}
                      >
                        수정
                      </button>
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}

import axios from "axios";

export const getAds = async (brandId:number) => {
  try {
    let { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/ads?brandId=${brandId}`);
    data.sort((a:any, b:any)=>{
        return a.indexNum - b.indexNum
    })
    return data;
  } catch (error) {
    console.error(error);
  }
};

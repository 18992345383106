import { useDrag, useDrop } from "react-dnd";
import { ContentsDtoWithId } from "../types/dto";

interface ContentsGapDragProps {
  index: number;
  item: any;
  zoneId?: string;
  moveContent: any;
  originalZoneId?: string;
}

const ContentsGapDrag = ({
  index,
  item,
  zoneId,
  moveContent,
  originalZoneId,
}: ContentsGapDragProps) => {
  const [{ isOver }, drop] = useDrop({
    accept: "CONTENT",
    drop: (item: ContentsDtoWithId, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop && item.id) {
        console.log(index);
        moveContent(item.id, index, originalZoneId, zoneId);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const [, drag] = useDrag({
    type: "CONTENT",
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <>
      <div ref={drop} className="w-2 relative ">
        <div className="h-36 w-14 bg-green-500 z-[35] left-[-24px] bg-opacity-0  absolute " />
        {isOver && (
          <div className="h-full bg-blue-400 bg-opacity-20 w-2 z-50"></div>
        )}
      </div>
    </>
  );
};

export default ContentsGapDrag;

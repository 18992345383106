import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCirclePlus,
  faCircleXmark,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faLocation,
  faSign,
  faTriangleExclamation,
  faUpRightAndDownLeftFromCenter,
  faVideo,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import AdminLayout from "../Layout/AdminLayout";
import { Pagination } from "../../components/interface/Pagination";
import { useEffect, useState } from "react";
import Modal from "../../components/interface/Modal";
import Radio from "../../components/interface/Radio";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import { StringToDate, cls } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { useUserStore } from "../../store";
import Button from "../../components/interface/Button";
import ContentsBox from "../ContentsBox";
import { v4 as uuidv4 } from "uuid";
import { Category, Contents, Part, Tool } from "../../types/models";
import { ContentsDto, ContentsDtoWithId } from "../../types/dto";

interface ContentsSearchModalProps {
  zoneData: ContentsDtoWithId[];
  setZoneData: (value: ContentsDtoWithId[]) => void;
  exerciseTime:number;
}
export default function ContentsSearchModal({
  zoneData,
  setZoneData,
  exerciseTime
}: ContentsSearchModalProps) {
  const [checkedItems, setCheckedItems] = useState<ContentsDto[]>([]); // 체크된 아이템들의 id를 저장할 state

  const user = useUserStore((state) => state.user);

  const [mutate, setMutate] = useState(false);
  const [partData, setPartData] = useState<Part>();
  const [toolData, setToolData] = useState<Tool>();
  const [categoryData, setCategoryData] = useState<Category>(); //운동 등록시 드롭다운메뉴
  const [selectData, setSelectData] = useState<ContentsDto>();

  const tableHeader = [
    { title: "No." },
    { title: "등록일" },
    { title: "부위" },
    { title: "도구" },
    { title: "영상" },
    { title: "운동명" },
    { title: "운동종류" },
    { title: "등록 수" },
    { title: "관리" },
  ];

  const searchData = [
    { title: "부위", value: partData, searchKey: "id", searchFiled: "partIds" },
    { title: "도구", value: toolData, searchKey: "id", searchFiled: "toolIds" },
    {
      title: "운동종류",
      value: categoryData,
      searchKey: "name",
      searchFiled: "categories",
    },
    {
      title: "파트너동작",
      value: [{ name: "사용", value:1 }, { name: "미사용", value:0 }],
      searchKey: "value",
      searchFiled: "partner",
    },
  ];
  //데이터 가져오기
  useEffect(() => {
    //콘텐츠 정보 가져오기
    getPartData();
    getToolData();
    getCategoryData();
  }, []);

  const getPartData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/admin/part/all`, { withCredentials: true });
      setPartData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getToolData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/admin/tool/all`, { withCredentials: true });
      setToolData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCategoryData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/admin/category/all`, { withCredentials: true });
      setCategoryData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheck = (item: ContentsDto) => {
    if (checkedItems.includes(item)) {
      setCheckedItems(checkedItems.filter((i: ContentsDto) => i !== item)); // 체크 해제 시 id를 제거
    } else {
      item.sets = ['15x2Set', '', '']
      item.cadioTimes = [`${exerciseTime}`, '', '']
      item.cadioPatterns = ['', '', '']
      item.cadioStrength = ['', '', '']
      setCheckedItems([...checkedItems, item]); // 체크 시 id를 추가
    }
  };

  const onSubmit = () => {
    // checkedItems 배열의 각 항목에 대해 id:uuid 필드를 추가
    let newCheckedItems = checkedItems.map((item: ContentsDto) => {
      return {
        ...item, // 기존 항목의 모든 필드를 복사
        set:['15x2Set', '', ''],
        id: uuidv4(), // id 필드를 추가 (값은 UUID)
      };
    });

    
    // zoneData 배열과 새로운 checkedItems 배열을 병합
    const array = zoneData.concat(newCheckedItems);
    console.log(array);
    // 상태를 업데이트
    setZoneData(array);
    setCheckedItems([]);
  };

  const onExtend = (item: ContentsDto) => {
    setSelectData(item);
  };

  const onRemoveVideoUri = () => {
    setSelectData(undefined);
  };

  return (
    <div className="transition-all">
      <div className=" my-5 flex justify-end ">
        <button className=" btn-primary btn   text-white  " onClick={onSubmit}>
          <FontAwesomeIcon icon={faCirclePlus} />
          동작 등록
        </button>
      </div>
      <div className="flex transition-all space-x-2">
        <div
          className={cls("transition-all ", selectData ? "w-3/4" : "w-full")}
        >
          <Table
            dataApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/contents`}
            headerArray={tableHeader}
            mutate={mutate}
            initSort="createdAt"
            initDirection="desc"
            searchData={searchData}
            theme="gallery"
            initSize={50}
            searchApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/contents/search`}
          >
            {(data: ContentsDto[], startIndex: number) =>
              data.map((item: ContentsDto, index: number) => {
                return (
                  <div className="m-1">
                    <ContentsBox
                      item={item}
                      handleCheck={handleCheck}
                      checkedItems={checkedItems}
                      extend={true}
                      onExtend={onExtend}
                      registrationCount={true}
                    />
                  </div>
                );
              })
            }
          </Table>
        </div>

        <div className={cls("transition-all ", selectData ? "w-3/4" : "w-0")}>
          <div
            className={cls(
              "bg-white drop-shadow-md flex flex-col  ",
              selectData ? "p-5" : "p-0 hidden"
            )}
          >
            <div className="flex justify-end ">
              <FontAwesomeIcon
                icon={faXmark}
                className="opacity-20 hover:bg-gray-300 cursor-pointer w-3 h-3 mb-5 rounded-md"
                width={10}
                onClick={onRemoveVideoUri}
              />
            </div>
            {selectData?.video?.fileLink && (
              <video
                src={selectData?.video.fileLink}
                className="border "
                controls
              />
            )}
            <div className="text-xs">
              <FormBox
                required={false}
                title="운동명"
                icon={<FontAwesomeIcon icon={faSign} />}
              >
                {selectData?.content?.name}
              </FormBox>
              <FormBox
                required={false}
                title="부위"
                icon={<FontAwesomeIcon icon={faDrumstickBite} />}
              >
                <div className="flex  items-center flex-wrap ">
                  {selectData &&
                    selectData?.parts?.map((part: Part) => (
                      <div className="btn btn-xs m-1 bg-blue-200">
                        {part.name}
                      </div>
                    ))}
                </div>
              </FormBox>
              <FormBox
                required={false}
                title="도구"
                icon={<FontAwesomeIcon icon={faDumbbell} />}
              >
                <div className="flex  items-center flex-wrap ">
                  {selectData &&
                    selectData?.tools?.map((tool: Tool) => (
                      <div className="btn btn-xs m-1 bg-orange-200">
                        {tool.name}
                      </div>
                    ))}
                </div>
              </FormBox>

              <FormBox
                required={false}
                title="구분"
                icon={<FontAwesomeIcon icon={faLocation} />}
              >
                <div className="flex space-x-5">
                  {selectData?.content?.categories}
                </div>
              </FormBox>

              <FormBox
                title="효과"
                required={false}
                icon={<FontAwesomeIcon icon={faFire} />}
              >
                {selectData?.content?.effect}
              </FormBox>
              <FormBox
                title="설명"
                required={false}
                icon={<FontAwesomeIcon icon={faBook} />}
              >
                {selectData?.content?.description}
              </FormBox>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useForm } from "react-hook-form";
import axios from "axios";

import { Link, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faDumbbell,
  faHouse,
  faPersonBiking,
  faSquarePollVertical,
  faTv,
  faUser,
  faPersonWalking,
  faUserGroup,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { cls } from "../../libs/utils";
import { useStore } from "zustand";
import { useBranchStore, useUserStore } from "../../store";
import { adminMenu, branchMenu } from "../../constants/AdminMenu";
import { Branch } from "../../types/models";
import { BranchDto } from "../../types/dto";
import { useQuery } from "@tanstack/react-query";
import { fetchBranchNameByCode, fetchBranches } from "../../services/branch";
import Modal from "../interface/Modal";
import ScreenMenu from "../Menu/ScreenMenu";
import ScreenBottomNav from "../Navigation/ScreenBottomNav";

export default function ScreenLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  const navigate = useNavigate();
  const { branchCode } = useParams();
  const storeBranchName = useBranchStore((state) => state.branchName);

  return (
    <>
      <div className="flex min-w-[3840px] h-[2160px] bg-black flex-col ">
        <div className="w-full min-h-screen flex  ">
          <main className="w-full">{children}</main>
        </div>
        <ScreenBottomNav />
      </div>
    </>
  );
}

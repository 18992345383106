import { useForm } from "react-hook-form";
import axios from "axios";
import Input from "../components/interface/Input";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import ErrorMsg from "../components/ErrorMsg";
import { useUserStore } from "../store";

type SigninForm = {
  userId: string;
  password: string;
};

export default function Home() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm<SigninForm>();

  const onValid = async (validForm: SigninForm) => {
    try {
      const formData = new URLSearchParams();
      formData.append("userId", validForm.userId);
      formData.append("password", validForm.password);

      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}api/public/login`, formData, { withCredentials: true });

      // 로그인 성공: /admin 페이지로 리디렉션
      window.location.reload();
    } catch (error: any) {
      if (error.response && (error.response.status === 401 || error.response.status === 433)) {
        // 로그인 실패: 사용자에게 알림 표시
        alert("올바르지 않은 ID / PW 입니다");
      } else {
        // 기타 오류: 콘솔에 로그
        console.error("Something went wrong", error);
      }
    }
  };

  const onScreenPage = () => {
    navigate("/screen");
  };

  return (
    <div className=" justify-center items-center min-h-screen flex px-5  flex-col">
      {/* <img src="/main-logo.svg" className=" w-56 mb-5 " alt="logo" /> */}
      <img src="/logo.png" className=" w-56 mb-5 " alt="logo" />
      
      <form
        className="space-y-5  w-full  max-w-md  flex flex-col justify-center items-center "
        onSubmit={handleSubmit(onValid)}
      >
        <div className="w-full">
          <label className="block  mb-2 text-sm text-gray-900">아이디</label>
          <input
            {...register("userId", { required: "아이디를 입력하세요" })}
            placeholder="아이디를 입력하세요"
            className="border rounded-lg py-2 px-3 text-gray-700 w-full border-black"
          />

          <div className="mt-2">
            <ErrorMessage
              errors={errors}
              name="userId"
              render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
            />
          </div>
        </div>
        <div className="w-full">
          <label className="block text-gray-900  mb-2 text-sm ">비밀번호</label>
          <input
            {...register("password", { required: "비밀번호를 입력하세요" })}
            placeholder="비밀번호를 입력하세요"
            className="border rounded-lg py-2 px-3 text-gray-700 w-full border-black"
            type="password"
          />

          <div className="mt-2">
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => <ErrorMsg>{message}</ErrorMsg>}
            />
          </div>
        </div>
        <button className="bg-[#2ea7e0] w-full rounded-lg text-white  py-3 mt-10 ">
          로그인
        </button>
      </form>
      {/* <div className="border-b w-10 mt-5 h-2 border-black"></div>
      <div className="w-full flex justify-center items-center">
        <button
          onClick={onScreenPage}
          className="bg-slate-700 rounded-lg text-white w-96 py-3 mt-5 "
        >
          모니터 출력 화면 바로가기
        </button>
      </div> */}
    </div>
  );
}

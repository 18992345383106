import { useForm } from "react-hook-form";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faBuilding,
  faCirclePlus,
  faClock,
  faHand,
  faSign,
} from "@fortawesome/free-solid-svg-icons";

import { useEffect, useState } from "react";

import { useUserStore } from "../store";
import FormBox from "./interface/FormBox";
import Radio from "./interface/Radio";
import FormInput from "./interface/FormInput";
import Button from "./interface/Button";
import { useNavigate } from "react-router-dom";
import { getUseEditFromAuth } from "../libs/utils";
import { BrandDto, LayoutDto } from "../types/dto";
import LayoutDetailItem from "./LayoutDetailItem";

interface LayoutformData {
  id: number;
  brandId: number;
  brandName: string;
  name: string;
  optionType: string;
  optionTeamCount: number;
  optionTeamRotate: number;
  optionMission: number;
  optionOver: number;
  optionWod: number;
  optionAdd: number;
  tv1Count: number;
  tv2Count: number;
  tv3Count: number;
  tv1Ids: string;
  tv2Ids: string;
  tv3Ids: string;
  tv3State: number;
  tv1Teams: string;
  tv2Teams: string;
  tv3Teams: string;
  description: string;
  active: string;
  totalCount: number;
  nextFlag: string;
  stayFlag: number;
  moveFlag: number;
  waterFlag: number;
  burningFlag: number;
}

interface LayoutDetailProps {
  readOnly?: boolean;
  layoutId?: string;
}

export default function LayoutDetail({
  readOnly = false,
  layoutId,
}: LayoutDetailProps) {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const [brandList, setBrandList] = useState<BrandDto[]>();
  const [getLoading, setGetLoading] = useState(false);
  const [programCount, setProgramCount] = useState(0);

  useEffect(() => {
    getBrand();
    if (layoutId) {
      getOriginalData();
    }
    else
    {
      setValue('tv1Count', 1)
      setValue('tv2Count', 0)
      setValue('tv3Count', 0)
      setValue('nextFlag', 'false')
      setValue('stayFlag', 1)
      setValue('moveFlag', 1)
      setValue('waterFlag', 1)
      setValue('burningFlag', 1)
      
    }
  }, []);

  //--> 기존 데이터 셋팅
  const getOriginalData = async () => {
    setGetLoading(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/layout/${layoutId}`, { withCredentials: true });

      const responseData: LayoutDto = response.data;

      setValue("id", responseData.id);
      setValue("brandId", responseData.brandId);
      setValue("brandName", responseData.brandName);
      setValue("name", responseData.name);
      setValue("optionType", responseData.optionType);
      setValue("optionTeamCount", responseData.optionTeamCount);
      setValue("optionTeamRotate", responseData.optionTeamRotate);
      setValue("optionMission", responseData.optionMission);
      setValue("optionOver", responseData.optionOver);
      setValue("optionAdd", responseData.optionAdd);
      setValue("optionWod", responseData.optionWod);
      setValue("tv1Count", responseData.tv1Count);
      setValue("tv2Count", responseData.tv2Count);
      setValue("tv3Count", responseData.tv3Count);
      setValue("tv1Ids", responseData.tv1Ids);
      setValue("tv2Ids", responseData.tv2Ids);
      setValue("tv3Ids", responseData.tv3Ids);
      setValue("tv1Teams", responseData.tv1Teams);
      setValue("tv2Teams", responseData.tv2Teams);
      setValue("tv3Teams", responseData.tv3Teams);
      setValue("description", responseData.description);
      setValue("active", responseData.active==1?'true':'false');
      setValue("totalCount", responseData.totalCount);
      setValue("tv3State", responseData.tv3State);
      setValue('nextFlag', responseData.nextFlag==1?'true':'false');
      setValue('stayFlag', responseData.stayFlag);
      setValue('moveFlag', responseData.moveFlag);
      setValue('waterFlag', responseData.waterFlag);
      setValue('burningFlag', responseData.burningFlag);

      setProgramCount(responseData.programCount)

      console.log('responseData.active : ', responseData.active)
    } catch (error) {
      console.log(error);
    }
    setGetLoading(false);
  };

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    reset,
    formState: { errors },
  } = useForm<LayoutformData>({
    defaultValues: {
      active: "true",
    }
  });
  
  const getBrand = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`, {
        withCredentials: true,
      });

      let brandAuth = []
      if(user) {
        brandAuth = JSON.parse(user.brandAuth);
      }

      let brandlist = []
      for (let i = 0; i < response.data.length; i++) {
        if(response.data[i].type == 2)
        {
          if(brandAuth) {
            
            if(!getUseEditFromAuth('6', response.data[i].id, brandAuth)) {
              continue
            }
          }
          brandlist.push(response.data[i])
        }
      }
      setBrandList(brandlist);
      if(brandlist.length > 0)
      {
        setValue('brandId', brandlist[0].id)
      }
    } catch (error) {
      console.error(error);
      window.location.reload();
    }
  };

  //--> 레이아웃 룰을 잘 지켰는지 검사
  const checkLayout = (tvname:string, optiontype:string, tvcount:number, tvids:string, tvteam:string)=>{
    try {
      if(tvcount > 0)
      {
        console.log('checkLayout : ', tvids, tvteam)
        const idlist = tvids.split(',')
  
        for (let i = 0; i < tvcount; i++) {
          if(idlist[i] == '0')
          {
            return `${tvname} 영역의 번호를 입력해 주세요.`
          }
        }
        
        if(optiontype == 'team')
        {
          const teamlist = tvteam.split(',')
          for (let i = 0; i < tvcount; i++) {
            if(idlist[i] != '-1' && teamlist[i] == '')
            {
              return `${tvname} 영역의 팀을 선택해 주세요.`
            }
          }
        }
      }
    } catch (error) {
      return `${tvname}를 설정하세요.`
    }
    
    return ''
  }

  const numberCheck = (formData: LayoutformData)=>{
    //==> 번호가 맞게 들어갔는지 검사
    try {
      const tv1Ids = formData.tv1Ids?formData.tv1Ids.split(','):['0','0','0','0','0','0','0','0']
      const tv1Teams = formData.tv1Teams?formData.tv1Teams.split(','):['','','','','','','','']
      const tv2Ids = formData.tv2Ids?formData.tv2Ids.split(','):['0','0','0','0','0','0','0','0']
      const tv2Teams = formData.tv2Teams?formData.tv2Teams.split(','):['','','','','','','','']
      const tv3Ids = formData.tv3Ids?formData.tv3Ids.split(','):['0','0','0','0','0','0','0','0']
      const tv3Teams = formData.tv3Teams?formData.tv3Teams.split(','):['','','','','','','','']
      //-->
      if(formData.optionType == 'team') {
        //--> 팀 로테이션일 경우 각 팀의 갯수를 구한다.
        let countmap = new Map()
        
        for (let i = 0; i < tv1Ids.length; i++) {
          if(tv1Ids[i] != '-1' && tv1Teams[i] != '') {
            let _teamCount = countmap.get(tv1Teams[i])
            if(!_teamCount) {
              _teamCount = 0
            }
            countmap.set(tv1Teams[i], _teamCount+1)
          }
        }
        for (let i = 0; i < tv2Ids.length; i++) {
          if(tv2Ids[i] != '-1' && tv2Teams[i] != '') {
            let _teamCount = countmap.get(tv2Teams[i])
            if(!_teamCount) {
              _teamCount = 0
            }
            countmap.set(tv2Teams[i], _teamCount+1)
          }
        }
        for (let i = 0; i < tv3Ids.length; i++) {
          if(tv3Ids[i] != '-1' && tv3Teams[i] != '') {
            let _teamCount = countmap.get(tv3Teams[i])
            if(!_teamCount) {
              _teamCount = 0
            }
            countmap.set(tv3Teams[i], _teamCount+1)
          }
        }

        if(countmap.size != formData.optionTeamCount) {
          console.log('countmap.size : ', countmap)
          return `옵션에서 팀 갯수를 ${formData.optionTeamCount}개로 설정하였는데 TV설정의 팀 갯수가 동일하지 않습니다.`
        }
        
        //--> 갯수만큼 검사해야할 목록을 만든다
        let checklist = []
        const originteam = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']

        for (let i = 0; i < originteam.length; i++) {
          const element = originteam[i];
          const _teamCount = countmap.get(originteam[i])
          if(_teamCount) {
            for (let j = 0; j < _teamCount; j++) {
              checklist.push({id:(j+1).toString(), team:originteam[i]})
            }
          }
        }

        for (let i = 0; i < tv1Ids.length; i++) {
          for (let j = 0; j < checklist.length; j++) {
            if(tv1Ids[i] == (checklist[j].id) && tv1Teams[i] == checklist[j].team) {
              checklist.splice(j, 1);
              break;
            }
          }
        }
        for (let i = 0; i < tv2Ids.length; i++) {
          for (let j = 0; j < checklist.length; j++) {
            if(tv2Ids[i] == checklist[j].id && (formData.optionType != 'team' || (formData.optionType == 'team' && tv2Teams[i] == checklist[j].team))) {
              checklist.splice(j, 1);
              break;
            }
          }
        }
        for (let i = 0; i < tv3Ids.length; i++) {
          for (let j = 0; j < checklist.length; j++) {
            if(tv3Ids[i] == checklist[j].id && (formData.optionType != 'team' || (formData.optionType == 'team' && tv3Teams[i] == checklist[j].team))) {
              checklist.splice(j, 1);
              break;
            }
          }
        }
        if(checklist.length > 0) {
          return `TV설정시 1번부터 번호를 순서대로 입력해 주세요.`
        }
      }
      else {
        let checklist = []
        for (let i = 0; i < formData.totalCount; i++) {
          checklist.push((i+1).toString())
        }

        for (let i = 0; i < tv1Ids.length; i++) {
          for (let j = 0; j < checklist.length; j++) {
            if(tv1Ids[i] == checklist[j]) {
              checklist.splice(j, 1);
              break;
            }
          }
        }

        for (let i = 0; i < tv2Ids.length; i++) {
          for (let j = 0; j < checklist.length; j++) {
            if(tv2Ids[i] == checklist[j]) {
              checklist.splice(j, 1);
              break;
            }
          }
        }

        for (let i = 0; i < tv3Ids.length; i++) {
          for (let j = 0; j < checklist.length; j++) {
            if(tv3Ids[i] == checklist[j]) {
              checklist.splice(j, 1);
              break;
            }
          }
        }

        if(checklist.length > 0) {
          return `TV설정시 1번부터 번호를 순서대로 입력해 주세요.`
        }
      }
    } catch (error) {
      return `${error}`
    }
    return ''
  }
  
  const onValid = async (formData: LayoutformData) => {
    try {
      const _brandId = formData.brandId
      const _optionType = formData.optionType
      if(!_brandId)
      {
        alert('브랜드를 선택하세요.')
        return
      }
      if(!_optionType)
      {
        alert('옵션을 선택하세요.')
        return
      }
      const _tv1Count = formData.tv1Count
      const _tv2Count = formData.tv2Count
      const _tv3Count = formData.tv3Count
      console.log('_tv1Count : ', _tv1Count)
      if(!_tv1Count || _tv1Count == 0)
      {
        alert('1TV는 한개 이상의 영역을 선택해야 합니다.')
        return
      }
      
      const _optionTeamCount = formData.optionTeamCount
      console.log('sss : ', _optionTeamCount)
      if(_optionType == 'team' && !_optionTeamCount)
      {
        alert('팀수를 선택해 주세요.')
        return
      }

      const _tv1Ids = formData.tv1Ids
      const _tv2Ids = formData.tv2Ids
      const _tv3Ids = formData.tv3Ids
      const _tv1Teams = formData.tv1Teams
      const _tv2Teams = formData.tv2Teams
      const _tv3Teams = formData.tv3Teams
      const tvres1 = checkLayout('TV1', _optionType, _tv1Count, _tv1Ids, _tv1Teams)
      const tvres2 = checkLayout('TV2', _optionType, _tv2Count, _tv2Ids, _tv2Teams)
      const tvres3 = checkLayout('TV3', _optionType, _tv3Count, _tv3Ids, _tv3Teams)


      if(tvres1 != '') {
        alert(tvres1)
        return
      }
      if(tvres2 != '') {
        alert(tvres2)
        return
      }
      if(tvres3 != '') {
        alert(tvres3)
        return
      }

      const numberres = numberCheck(formData)
      if(numberres != '') {
        alert(numberres)
        return
      }

      const _brandName = brandList?.filter((brand:BrandDto)=>{
        if(brand.id == _brandId)
        {
          return true
        }

        return false;
      })

      if (layoutId) {
        await axios.patch(`${process.env.REACT_APP_SERVER_URL}api/admin/layout/${layoutId}`, {
          brandId: formData.brandId,
          name: formData.name,
          brandName:_brandName?_brandName[0].name:'',
          optionType: _optionType,
          optionTeamCount: _optionTeamCount?_optionTeamCount:0,
          optionTeamRotate: formData.optionTeamRotate?formData.optionTeamRotate:0,
          optionMission: formData.optionMission?formData.optionMission:0,
          optionOver: formData.optionOver?formData.optionOver:0,
          optionAdd: formData.optionAdd?formData.optionAdd:0,
          optionWod: formData.optionWod?formData.optionWod:0,
          tv1Count: _tv1Count?_tv1Count:0,
          tv2Count: _tv2Count?_tv2Count:0,
          tv3Count: _tv3Count?_tv3Count:0,
          tv1Ids: _tv1Ids?_tv1Ids:'',
          tv2Ids: _tv2Ids?_tv2Ids:'',
          tv3Ids: _tv3Ids?_tv3Ids:'',
          tv1Teams: _tv1Teams?_tv1Teams:'',
          tv2Teams: _tv2Teams?_tv2Teams:'',
          tv3Teams: _tv3Teams?_tv3Teams:'',
          description: formData.description?formData.description:'',
          active: formData.active,
          totalCount: formData.totalCount,
          tv3State: formData.tv3State,
          nextFlag: formData.nextFlag == 'true'?1:0,
          stayFlag: formData.stayFlag?formData.stayFlag:0,
          moveFlag: formData.moveFlag?formData.moveFlag:0,
          waterFlag: formData.waterFlag?formData.waterFlag:0,
          burningFlag: formData.burningFlag?formData.burningFlag:0,
        }, { withCredentials: true });
      } else {
        await axios.post(`${process.env.REACT_APP_SERVER_URL}api/admin/layout`, {
          brandId: formData.brandId,
          name: formData.name,
          brandName:_brandName?_brandName[0].name:'',
          optionType: _optionType,
          optionTeamCount: _optionTeamCount?_optionTeamCount:0,
          optionTeamRotate: formData.optionTeamRotate?formData.optionTeamRotate:0,
          optionMission: formData.optionMission?formData.optionMission:0,
          optionOver: formData.optionOver?formData.optionOver:0,
          optionAdd: formData.optionAdd?formData.optionAdd:0,
          optionWod: formData.optionWod?formData.optionWod:0,
          tv1Count: _tv1Count?_tv1Count:0,
          tv2Count: _tv2Count?_tv2Count:0,
          tv3Count: _tv3Count?_tv3Count:0,
          tv1Ids: _tv1Ids?_tv1Ids:'',
          tv2Ids: _tv2Ids?_tv2Ids:'',
          tv3Ids: _tv3Ids?_tv3Ids:'',
          tv1Teams: _tv1Teams?_tv1Teams:'',
          tv2Teams: _tv2Teams?_tv2Teams:'',
          tv3Teams: _tv3Teams?_tv3Teams:'',
          description: formData.description?formData.description:'',
          active: formData.active,
          totalCount: formData.totalCount,
          tv3State: formData.tv3State,
          nextFlag: formData.nextFlag == 'true'?1:0,
          stayFlag: formData.stayFlag?formData.stayFlag:0,
          moveFlag: formData.moveFlag?formData.moveFlag:0,
          waterFlag: formData.waterFlag?formData.waterFlag:0,
          burningFlag: formData.burningFlag?formData.burningFlag:0,
        }, { withCredentials: true });
      }
      navigate(`/admin/layout`);
    } catch (error: any) {
    }
  };

  const optionType = watch("optionType")
  const optionMission = watch("optionMission")
  const optionWod = watch("optionWod")
  const optionOver = watch("optionOver")
  const optionAdd = watch("optionAdd")
  //--> 옵션 변경 시 값 체크
  useEffect(() => {
    if(optionMission == 1)
    {
      setValue('optionWod', 0)
    }
    if(optionWod == 1)
    {
      setValue('optionMission', 0)
    }
    if(optionOver == 1)
    {
      if(watch('tv1Count') > 4 || watch('tv2Count') > 4 || watch('tv3Count') > 4)
      {
        setValue('optionOver', 0)
        alert('OVER은 4개 이하의 분할만 가능합니다.')
      }
      else
      {
        setValue('optionAdd', 0)
      }
    }
    if(optionAdd == 1)
    {
      if(watch('tv1Count') > 4 || watch('tv2Count') > 4 || watch('tv3Count') > 4)
      {
        setValue('optionAdd', 0)
        alert('ADD는 4개 이하의 분할만 가능합니다.')
      }
      else
      {
        setValue('optionOver', 0)
      }
    }
  }, [optionType, optionMission, optionWod, optionOver, optionAdd]);
  

  const tv1Count = watch("tv1Count")
  const tv1Ids = watch("tv1Ids")
  const tv1Teams = watch("tv1Teams")
  const tv2Count = watch("tv2Count")
  const tv2Ids = watch("tv2Ids")
  const tv2Teams = watch("tv2Teams")
  const tv3Count = watch("tv3Count")
  const tv3Ids = watch("tv3Ids")
  const tv3Teams = watch("tv3Teams")
  //--> 레이아웃 변경 시 입력되어 있는 값 체크
  useEffect(() => {
    let _totalCount = 0;
    let id1 = tv1Ids?tv1Ids.split(','):['0','0','0','0','0','0','0','0'];
    let id2 = tv2Ids?tv2Ids.split(','):['0','0','0','0','0','0','0','0'];
    let id3 = tv3Ids?tv3Ids.split(','):['0','0','0','0','0','0','0','0'];
    let team1 = tv1Teams?tv1Teams.split(','):['','','','','','','',''];
    let team2 = tv2Teams?tv2Teams.split(','):['','','','','','','',''];
    let team3 = tv3Teams?tv3Teams.split(','):['','','','','','','',''];

    if(!((tv1Count <= 1) || (tv2Count <= 1 && tv2Count > 0) || (tv3Count <= 1 && tv3Count > 0))) {
      setValue('nextFlag', 'false')
    }
    
    for (let i = 0; i < tv1Count; i++) {
      if(id1[i] != '-1') {
        _totalCount++
      }
    }
    for (let i = 0; i < tv2Count; i++) {
      if(id2[i] != '-1') {
        _totalCount++
      }
    }
    for (let i = 0; i < tv3Count; i++) {
      if(id3[i] != '-1') {
        _totalCount++
      }
    }

    let _isedit = false
    for (let i = 0; i < id1.length; i++) {
      if(id1[i] != '0' && tv1Count <= i)
      {
        _isedit = true
        id1[i] = '0'
      }
      if(team1[i] != '' && tv1Count <= i)
      {
        _isedit = true
        team1[i] = ''
      }
    }
    if(_isedit)
    {
      console.log('aaa', id1, team1)
      setValue('tv1Ids', id1.toString())
      setValue('tv1Teams', team1.toString())
    }

    _isedit = false
    for (let i = 0; i < id2.length; i++) {
      if(id2[i] != '0' && tv2Count <= i)
      {
        _isedit = true
        id2[i] = '0'
      }
      if(team2[i] != '' && tv2Count <= i)
      {
        _isedit = true
        team2[i] = ''
      }
    }
    if(_isedit)
    {
      setValue('tv2Ids', id2.toString())
      setValue('tv2Teams', team2.toString())
    }

    _isedit = false
    for (let i = 0; i < id3.length; i++) {
      if(id3[i] != '0' && tv3Count <= i)
      {
        _isedit = true
        id3[i] = '0'
      }
      if(team3[i] != '' && tv3Count <= i)
      {
        _isedit = true
        team3[i] = ''
      }
    }
    if(_isedit)
    {
      setValue('tv3Ids', id3.toString())
      setValue('tv3Teams', team3.toString())
    }
    
    setValue("totalCount", _totalCount)

    if(tv1Count == 1 && id1[0] == '-1') {
      id1[0] = '0'
      setValue('tv1Ids', id1.toString())
    }
    if(tv2Count == 1 && id2[0] == '-1') {
      id2[0] = '0'
      setValue('tv2Ids', id2.toString())
    }
    if(tv3Count == 1 && id2[0] == '-1') {
      id2[0] = '0'
      setValue('tv3Ids', id2.toString())
    }
    
  }, [tv1Count, tv2Count, tv3Count, tv1Ids, tv2Ids, tv3Ids]);

  return (
    <div className="bg-white drop-shadow-md p-10 ">
      <div className="w-full space-x-2 ">
          <FormBox title="브랜드" icon={<FontAwesomeIcon icon={faSign} />}>
            <select
              {...register("brandId")}
              className="select select-bordered w-full max-w-xs"
              disabled={programCount>0?true:false}
            >
              {brandList &&
                brandList.map(
                  (brand: BrandDto) => (
                      <option value={brand.id}>
                        {brand.name}
                      </option>
                    )
                )}
            </select>
          </FormBox>
          <FormBox title="레이아웃 명" icon={<FontAwesomeIcon icon={faSign} />}>
            {readOnly ? (
              watch("name")
            ) : (
              <FormInput
                placeholder="레이아웃명"
                register={register("name", {
                  required: "레이아웃명을 입력해주세요",
                })}
              />
            )}
          </FormBox>
          <FormBox title="옵션" icon={<FontAwesomeIcon icon={faClock} />}>
            <div className="flex space-x-5">
              <select
                {...register("optionType")}
                onChange={(e)=>{
                  setValue('optionWod', 0)
                  setValue('optionOver', 0)
                  setValue('optionAdd', 0)
                  setValue('optionMission', 0)
                  setValue("optionTeamCount", 0)
                  setValue("optionTeamRotate", 0)
                  setValue("optionType", e.target.value)
                  
                  setValue("tv1Teams", ",,,,,,,");
                  setValue("tv2Teams", ",,,,,,,");
                  setValue("tv3Teams", ",,,,,,,");
                }}
                className="select select-bordered w-40 max-w-xs"
                disabled={programCount>0?true:false}
              >
                <option value={'all'}>
                  {'전체 로테이션'}
                </option>
                <option value={'team'}>
                  {'팀 로테이션'}
                </option>
              </select>
              {
                watch("optionType") == "team"?
                <select
                  {...register("optionTeamCount")}
                  className="select select-bordered w-24 max-w-xs"
                  disabled={programCount>0?true:false}
                >
                  <option value={2}>{'2팀'}</option>
                  <option value={3}>{'3팀'}</option>
                  <option value={4}>{'4팀'}</option>
                  <option value={5}>{'5팀'}</option>
                  <option value={6}>{'6팀'}</option>
                  <option value={7}>{'7팀'}</option>
                  <option value={8}>{'8팀'}</option>
                  <option value={9}>{'9팀'}</option>
                  <option value={10}>{'10팀'}</option>
                </select>
                :null
              }
              {
                watch("optionType") == "team"?
                <Radio
                  title="팀교대"
                  value={1}
                  type="checkbox"
                  register={register("optionTeamRotate", {
                    required: false,
                  })}
                  disabled={programCount>0?true:false}
                />
                :null
              }
            </div>
            <div className="flex space-x-5 mt-3">
              <Radio
                title="미션"
                value={1}
                type="checkbox"
                register={register("optionMission", {
                  required: false,
                })}
                disabled={programCount>0?true:(watch("optionType") == "team"?(watch('optionWod') == 1 || watch('optionOver') == 1 || watch('optionAdd') == 1):watch('optionWod') == 1)}
              />
              <Radio
                title="WOD"
                value={1}
                type="checkbox"
                register={register("optionWod", {
                  required: false,
                })}
                disabled={programCount>0?true:(watch("optionType") == "team"?(watch('optionMission') == 1 || watch('optionOver') == 1 || watch('optionAdd') == 1):watch('optionMission') == 1)}
              />
            </div>
            <div className="flex space-x-5">
              <Radio
                title="OVER"
                value={1}
                type="checkbox"
                register={register("optionOver", {
                  required: false,
                })}
                disabled={programCount>0?true:(watch("optionType") == "team"?(watch('optionMission') == 1 || watch('optionWod') == 1 || watch('optionAdd') == 1):watch('optionAdd') == 1)}
              />
              <Radio
                title="ADD"
                value={1}
                type="checkbox"
                register={register("optionAdd", {
                  required: false,
                })}
                disabled={programCount>0?true:(watch("optionType") == "team"?(watch('optionMission') == 1 || watch('optionWod') == 1 || watch('optionOver') == 1):watch('optionOver') == 1)}
              />
            </div>
          </FormBox>
          <FormBox title="TV설정" icon={<FontAwesomeIcon icon={faClock} />}>
            <div className="flex">
              <LayoutDetailItem
                tvName={'1TV'}
                optionType={watch("optionType")}
                optionOver={watch("optionOver")}
                optionAdd={watch("optionAdd")}
                optionMission={watch("optionMission")}
                optionWod={watch("optionWod")}
                optionTeamCount={watch("optionTeamCount")}
                tvCount={watch("tv1Count")}
                tvIds={watch("tv1Ids")}
                tvTeams={watch("tv1Teams")}
                setTVCount={(tvcount:number)=>{setValue("tv1Count", tvcount)}}
                setTVIds={(ids:string)=>{console.log('ids : ', ids); setValue("tv1Ids", ids)}}
                setTVTeams={(teams:string)=>{setValue("tv1Teams", teams)}}
                isFirst={true}
                disabled={programCount>0?true:false}
              />
              <LayoutDetailItem
                tvName={'2TV'}
                optionType={watch("optionType")}
                optionOver={watch("optionOver")}
                optionAdd={watch("optionAdd")}
                optionMission={watch("optionMission")}
                optionWod={watch("optionWod")}
                optionTeamCount={watch("optionTeamCount")}
                tvCount={watch("tv2Count")}
                tvIds={watch("tv2Ids")}
                tvTeams={watch("tv2Teams")}
                setTVCount={(tvcount:number)=>{setValue("tv2Count", tvcount)}}
                setTVIds={(ids:string)=>{setValue("tv2Ids", ids)}}
                setTVTeams={(teams:string)=>{setValue("tv2Teams", teams)}}
                zeroText={'TV1과 동일'}
                disabled={programCount>0?true:false}
              />
              <LayoutDetailItem
                tvName={'3TV'}
                optionType={watch("optionType")}
                optionOver={watch("optionOver")}
                optionAdd={watch("optionAdd")}
                optionMission={watch("optionMission")}
                optionWod={watch("optionWod")}
                optionTeamCount={watch("optionTeamCount")}
                tvCount={watch("tv3Count")}
                tvIds={watch("tv3Ids")}
                tvTeams={watch("tv3Teams")}
                setTVCount={(tvcount:number)=>{setValue("tv3Count", tvcount)}}
                setTVIds={(ids:string)=>{setValue("tv3Ids", ids)}}
                setTVTeams={(teams:string)=>{setValue("tv3Teams", teams)}}
                zeroText={watch("tv2Count") == 0?'TV1과 동일':'TV2와 동일'}
                disabled={programCount>0?true:false}
                tv3StateVidible={watch("tv2Count") != 0}
                tv3State={watch("tv3State")}
                setTV3State={(tv3State:number)=>{setValue("tv3State", tv3State)}}
              />
            </div>
            {
              ((watch("tv1Count") <= 1) || (watch("tv2Count") <= 1 && watch("tv2Count") > 0) || (watch("tv3Count") <= 1 && watch("tv3Count") > 0))?
              <div className="mx-4 my-6 font-medium text-sm flex">
                <div className="flex space-x-5">
                  <div className="my-2">1분할 화면 설정 :</div>
                  <Radio
                    title="다음 동작 1개 보여주기"
                    value={false}
                    register={register("nextFlag", {
                      required: false,
                    })}
                    disabled={programCount>0?true:false}
                  />
                  <Radio
                    title="다음 동작 2개 보여주기"
                    value={true}
                    register={register("nextFlag", {
                      required: false,
                    })}
                    disabled={programCount>0?true:false}
                  />
                </div>
              </div>
              :null
            }
            <div className="m-4 font-medium text-sm">전체 영역수 : {watch("totalCount")}</div>
          </FormBox>

          <FormBox title="휴식 화면 설정" icon={<FontAwesomeIcon icon={faHand} />}>
            <div className="flex space-x-7 my-3">
              <Radio
                title="STAY IN 출력"
                value={1}
                type="checkbox"
                register={register("stayFlag", {
                  required: false,
                })}
                disabled={programCount>0?true:false}
              />
              <Radio
                title="MOVE ON 출력"
                value={1}
                type="checkbox"
                register={register("moveFlag", {
                  required: false,
                })}
                disabled={programCount>0?true:false}
              />
              <Radio
                title="WATER 출력"
                value={1}
                type="checkbox"
                register={register("waterFlag", {
                  required: false,
                })}
                disabled={programCount>0?true:false}
              />
              <Radio
                title="BURNING 출력"
                value={1}
                type="checkbox"
                register={register("burningFlag", {
                  required: false,
                })}
                disabled={programCount>0?true:false}
              />
            </div>
          </FormBox>

          <FormBox
            title="설명"
            required={false}
            icon={<FontAwesomeIcon icon={faBook} />}
          >
            {
              readOnly ?
              watch('description')
              :
              <FormInput
                placeholder="설명"
                type="textarea"
                register={register("description")}
              />
            }
          </FormBox>
          <FormBox title="사용여부" icon={<FontAwesomeIcon icon={faBuilding} />}>
            <div className="flex space-x-5">
              <Radio
                title="사용"
                value={true}
                register={register("active", {
                  required: "사용여부를 선택해주세요",
                })}
              />
              <Radio
                title="미사용"
                value={false}
                register={register("active", {
                  required: "사용여부를 선택해주세요",
                })}
              />
            </div>
          </FormBox>
      </div>
      {!readOnly && (
        <div className="w-full flex flex-col justify-center mt-5">
          <Button
            title={`레이아웃  ${layoutId ? "수정" : "등록"}`}
            onClick={handleSubmit(onValid)}
            iconComponent={<FontAwesomeIcon icon={faCirclePlus} />}
          />
        </div>
      )}
    </div>
  );
}

import AdminLayout from "../../components/Layout/AdminLayout";
import { useEffect, useState } from "react";
import axios from "axios";
import { StringToDate } from "../../libs/utils";
import Table from "../../components/interface/Table";
import { BrandDto } from "../../types/dto";
import Modal from "../../components/interface/Modal";
import { NoticeDto } from "../../types/models";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate, useParams } from "react-router-dom";

export default function Notice() {
  const { branchCode } = useParams();
  const [mutate, setMutate] = useState(false);
  const [noticeId, setNoticeId] = useState(0);
  const [brandId, setBrandId] = useState(0);
  const [brandData, setBrandData] = useState<BrandDto[]>();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (branchCode) {
      resetBrandID()
    }
  }, [branchCode]);

  const resetBrandID = async ()=>{
    if(branchCode)
    {
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/branches/brandidnew/${branchCode}`);
      setBrandId(data.id)
    }
  }

  const showNoticeDetailModal = (id: number) => {
    setNoticeId(id);
    //@ts-ignore
    window.noticeDetail.showModal();
  };

  useEffect(() => {
    const getBrands = async () => {
      try {
        const getbrand = await axios.get(`${process.env.REACT_APP_SERVER_URL}api/public/brand/all`, {
          withCredentials: true,
        });
        setBrandData(getbrand.data)
      } catch (error) {
      }
    };

    getBrands()
  }, []);

  
  const searchData = [
    { title: "브랜드", value: brandData, searchKey: "id", searchFiled: "brandIds" }
  ];

  return (
    <>
      <Modal
        id="noticeDetail"
        title={`운동 프로그램 안내 상세보기`}
        size="2xlarge"
        overflow="hidden"
      >
        <div className="mt-5">
          {/* <ProgramDetail programId={programId} readOnly={true} /> */}
        </div>
      </Modal>
      <AdminLayout>
        <div className="">
          <h1 className="text-gray-800 font-bold text-center mb-5 md:text-left text-2xl">{'운동 프로그램 안내'}</h1>
          
          <Table
            dataApiUrl={`${process.env.REACT_APP_SERVER_URL}api/public/notice?brandId=${brandId}`}
            headerArray={[
              { title: "No." },
              { title: "운영기간" },
              { title: "게시물 명" }
            ]}
            mutate={mutate}
            initSort="createdAt"
            initDirection="desc"
            searchData={searchData}
            searchApiUrl={`${process.env.REACT_APP_SERVER_URL}api/public/notice/search`}
            searchTime={true}
          >
            {(data: NoticeDto[], startIndex: number) =>
              data.map((item: any, index: number) => {
                // console.log(item);
                return (
                  
                  <tr key={item.id} onClick={() =>{ navigate(`/branch/${branchCode}/notice/${item.id}`); }} className=" cursor-pointer">
                    <th>{startIndex - index}</th>
                    <td>{item?.startTime}{` ~ `}{item?.endTime}</td>
                    <td>
                      {item?.name}
                    </td>
                  </tr>
                );
              })
            }
          </Table>
        </div>
      </AdminLayout>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { BranchStreaming, Program } from "../../types/models";
import { CurrentProgramResponse } from "../../types/response";
import ProgramInfo from "./ProgramInfo";
import StreamingButton from "./StreamingButton";
import useCreateStreamingPlay from "../../hooks/useCreateStreamingPlay";
import ProgramName from "./ProgramName";
import useUpdateStreamingPlay from "../../hooks/useUpdateStreamingFlag";
import useUpdateStremingIndex from "../../hooks/useUpdateStremingIndex";

interface handleButtonProps {
  id: number;
  programId: number;
  type: string;
  flag: string;
}
const PlayPauseStopButton = ({
  branchStreaming,
  program,
  size = "large",
  type = "default",
}: {
  branchStreaming?: BranchStreaming;
  program: Program;
  size?: "small" | "large";
  type?: string;
}) => {
  const queryClient = useQueryClient();
  const { branchCode } = useParams();

  const {
    createStreaming,
    loading: createStreamingPlayLoading,
    data: createStreamingData,
  } = useCreateStreamingPlay();

  const {
    updateFlag,
    loading: updateFlagLoading,
    data: updateFlagData,
  } = useUpdateStreamingPlay();

  const {
    updateIndex,
    loading: updateIndexLoading,
    data: updateIndexData,
  } = useUpdateStremingIndex();

  //도구 추출
  useEffect(() => {
    if (updateFlagData || createStreamingData || updateIndexData) {
      console.log("update flag data");
      queryClient.invalidateQueries(["getCurrentProgram", branchCode]);
      queryClient.invalidateQueries(["getBranchStreamingToday", branchCode]);
    }
  }, [updateFlagData, createStreamingData, updateIndexData]);

  return (
    <div className="flex items-center justify-center w-full">
      {branchStreaming ? (
        <div className="flex space-x-3 justify-center items-center w-full">
          {branchStreaming.flag === "playing" ? (
            <div className="flex flex-col w-full">
              <div className="collapse w-full rounded-none bg-base-200">
                <input type="checkbox" />
                <div className="collapse-title  font-medium   ">
                  영상 재생 변경하기
                </div>

                <div className="collapse-content flex items-center  justify-center space-x-5">
                  {branchStreaming.type !== "tutorial" && (
                    <StreamingButton
                      onClick={() =>
                        updateIndex({
                          id: branchStreaming.id,
                          branchCode: branchCode!,
                          type: "backward",
                          broadcast: true,
                        })
                      }
                      isLoading={updateIndexLoading}
                      size="small"
                      flag={"backward"}
                    />
                  )}
                  <StreamingButton
                    onClick={() =>
                      updateFlag({
                        id: branchStreaming.id,
                        branchCode: branchCode!,
                        flag: "pause",
                      })
                    }
                    isLoading={updateFlagLoading}
                    size={size}
                    flag={"pause"}
                  />
                  {branchStreaming.type !== "tutorial" && (
                    <StreamingButton
                      onClick={() =>
                        updateIndex({
                          id: branchStreaming.id,
                          branchCode: branchCode!,
                          type: "forward",
                          broadcast: true,
                        })
                      }
                      isLoading={updateIndexLoading}
                      size="small"
                      flag={"forward"}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <StreamingButton
              onClick={() =>
                updateFlag({
                  id: branchStreaming.id,
                  branchCode: branchCode!,
                  flag: "playing",
                })
              }
              isLoading={updateFlagLoading}
              size={size}
              flag={"playing"}
            />
          )}

          {branchStreaming.flag === "pause" && (
            <StreamingButton
              onClick={() =>
                updateFlag({
                  id: branchStreaming.id,
                  branchCode: branchCode!,
                  flag: "stop",
                })
              }
              isLoading={updateFlagLoading}
              size={size}
              flag="stop"
            />
          )}
        </div>
      ) : (
        <StreamingButton
          onClick={() =>
            createStreaming({
              programId: program.id,
              branchCode: branchCode!,
              type: type,
            })
          }
          isLoading={createStreamingPlayLoading}
          size={size}
          flag="playing"
        />
      )}
    </div>
  );
};

export default PlayPauseStopButton;

import AdminLayout from "../../components/Layout/AdminLayout";
import { useEffect, useState } from "react";
import LoadingMsg from "../../components/LoadingMsg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faClock,
  faGear,
  faPenToSquare,
  faPlay,
  faPlus,
  faTrash,
  faUpRightAndDownLeftFromCenter,
  faUser,
  faUserGroup,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {
  GetHHMM,
  SecondsToMinutes,
  cls,
  getBoxBackgroundClass,
  getDayClassNames,
} from "../../libs/utils";
import axios from "axios";
import { ProgramDtoWithScheduleId, ProgramSchedule } from "../../types/dto";
import WeekButton from "../../components/WeekButton";
import Modal from "../../components/interface/Modal";
import FormBox from "../../components/interface/FormBox";
import FormInput from "../../components/interface/FormInput";
import BranchAddTimeModal from "../../components/Modal/BranchAddTimeModal";
import { useNavigate, useParams } from "react-router-dom";
import { BranchSchedule, Program } from "../../types/models";
import ScheduleSettingsModal from "../../components/Modal/ScheduleSettingsModal";
import BranchScheduleBox from "../../components/BranchScheduleBox";
import { useQuery } from "@tanstack/react-query";
import { getBranchSchedules } from "../../services/branchSchedule";
import Button from "../../components/interface/Button";
import { getSchedulesToday } from "../../services/schedule";
import { ProgramType } from "../../constants/ProgramCategory";
import { getBranchStreamingToday } from "../../services/branchStreaming";
import { handleAxiosError } from "../../utils/handleAxiosError";
import useMutation from "../../hooks/useMutation";
import StreamingButton from "../../components/BrnachController/StreamingButton";
import BottomController from "../../components/BrnachController/BottomContorller";
import ProgramInfo from "../../components/BrnachController/ProgramInfo";
import useCreateStreamingPlay from "../../hooks/useCreateStreamingPlay";
import useUpdateStreamingPlay from "../../hooks/useUpdateStreamingFlag";
import PlayPauseStopButton from "../../components/BrnachController/PlayPauseStopButton";
import { useWebSocket } from "../../hooks/useWebSocket";
import { fetchBranchIdByCode } from "../../services/branch";
import { CurrentProgramResponse } from "../../types/response";
import TutorialDetail from "../../components/BrnachController/TutorialDetail";

export default function BranchController() {
  const navigate = useNavigate();

  const { branchCode } = useParams();
  const [selTab, setSelTab] = useState(ProgramType[0].name);

  const { data, isLoading, isError, refetch } = useQuery<any, Error>(
    ["getSchedulesToday"],
    () => getSchedulesToday()
  );

  const {
    data: streamingData,
    isLoading: streamingLoading,
    refetch: streamingRefetch,
  } = useQuery<any, Error>(
    ["getBranchStreamingToday", branchCode],
    () => getBranchStreamingToday(branchCode!),
    {
      enabled: !!branchCode,
    }
  );

  const handleTab = (type: string) => {
    setSelTab(type);
  };

  //웹소켓 연결을 위한 브랜치 아이디 조회
  const { data: branchId } = useQuery<CurrentProgramResponse, Error>(
    ["fetchBranchIdByCode", branchCode],
    () => fetchBranchIdByCode(branchCode!),
    {
      enabled: !!branchCode,
    }
  );

  //웹소켓 연결
  const [socketData, socketError, client] = useWebSocket(
    `${process.env.REACT_APP_WS_SERVER}/ws/streaming/${branchId}`
  );

  console.log(streamingData);

  //수신한 소켓 데이터를 바인딩
  useEffect(() => {
    if (socketData) {
      console.log(socketData);

      switch (socketData.operation) {
        case "playing-screen":
          streamingRefetch();
          break;
        case "stop":
          streamingRefetch();
          break;

        default:
          return;
      }
    }
  }, [socketData, branchCode]);

  const [
    stopStreamingPlay,
    { loading: stopStreamingPlayLoading, data: stopStreamingPlayData },
  ] = useMutation("/api/branch/streaming/all-stop");

  const handleStop = () => {
    var userResponse = window.confirm("정말로 강제 종료하시겠습니까?");

    if (userResponse) {
      stopStreamingPlay({ branchCode });
    }
  };

  //수신한 소켓 데이터를 바인딩
  useEffect(() => {
    if (stopStreamingPlayData) {
      streamingRefetch();
    }
  }, [stopStreamingPlayData]);

  
  useEffect(() => {
    navigate(`/branch/${branchCode}/program`);
    //navigate(`/admin/branch?id=${item.id}&name=${item.name}`);
  }, []);

  return (
    <AdminLayout>
      <h1 className="text-gray-800 font-bold  w-full text-center mb-5 md:text-left text-2xl ">
        지점별 동작 재생
      </h1>
      <div className="flex justify-center max-w-xl ">
        <div className="flex flex-col justify-center items-center w-full ">
          <div className="tabs">
            {ProgramType.map((type: { name: string }, index: number) => (
              // eslint-disable-next-line jsx-a11y/anchor-is-valid
              <a
                key={index}
                className={cls(
                  "tab tab-lifted",
                  selTab === type.name ? "tab-active" : ""
                )}
                onClick={() => handleTab(type.name)}
              >
                {type.name}
              </a>
            ))}
          </div>
          <div className=" bg-white w-full p-5 shadow-md ">
            {data &&
              data[selTab] &&
              data[selTab].map((program: Program, index: number) => (
                <div key={program.id}>
                  {program.programType === "본운동" && (
                    <div className="flex flex-col ">
                      <ProgramInfo program={program} type="tutorial" />
                      {((streamingData &&
                        streamingData[program.id]?.flag === "playing") ||
                        (streamingData &&
                          streamingData[program.id]?.flag === "pause")) &&
                        streamingData &&
                        streamingData[program.id]?.type === "tutorial" && (
                          <div className="w-full">
                            <TutorialDetail
                              program={program}
                              branchStreaming={streamingData[program.id]}
                            />
                          </div>
                        )}
                      <div className="pt-5 mb-5 flex w-full justify-center items-center">
                        <PlayPauseStopButton
                          type="tutorial"
                          program={program}
                          branchStreaming={
                            streamingData
                              ? streamingData[program.id]?.type === "tutorial"
                                ? streamingData[program.id]
                                : undefined
                              : undefined
                          }
                        />
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col">
                    <ProgramInfo program={program} />
                    <div className="pt-5 flex justify-center items-center">
                      <PlayPauseStopButton
                        program={program}
                        branchStreaming={
                          streamingData
                            ? streamingData[program.id]?.type !== "tutorial"
                              ? streamingData[program.id]
                              : undefined
                            : undefined
                        }
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="collapse w-full rounded-none bg-base-200 mt-5">
            <input type="checkbox" />
            <div className="collapse-title font-medium ">고급 제어 옵션</div>

            <div className="collapse-content flex items-center  justify-center space-x-5">
              <Button
                onClick={handleStop}
                title="현재 재생중 운동 강제종료"
                loading={stopStreamingPlayLoading}
              />
            </div>
          </div>
          {/* <BottomController /> */}
        </div>
      </div>
    </AdminLayout>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBook,
  faCirclePlus,
  faCircleXmark,
  faClone,
  faDrumstickBite,
  faDumbbell,
  faFile,
  faFire,
  faL,
  faLocation,
  faSign,
  faTriangleExclamation,
  faUpRightAndDownLeftFromCenter,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { cls } from "../libs/utils";
import { useRef, useState } from "react";

import { ContentsDto } from "../types/dto";
interface ContentsBoxProps {
  item: ContentsDto;
  handleCheck?: (item: ContentsDto) => void;
  checkedItems?: ContentsDto[];
  hover?: boolean;
  deleteMode?: boolean;
  onDelete?: any;
  extend?: boolean;
  onExtend?: (item: ContentsDto) => void;
  duplicate?: boolean;
  onDuplicate?: any;
  registrationCount?: boolean;
  tutorialCheckbox?: boolean;
  tutorialId?: number[];
  setTutorialId?: any;
  readOnly?: boolean;

  //--> 다분할용
  heartbeat?: boolean;
  heartbeatValue?: number;
  setHeartbeat?: any;
}
export default function ContentsBox({
  item,
  handleCheck,
  checkedItems,
  hover = true,
  deleteMode = false,
  onDelete,
  extend = false,
  onExtend,
  duplicate = false,
  onDuplicate,
  registrationCount = false,
  tutorialCheckbox = false,
  tutorialId,
  setTutorialId,
  readOnly = false,
  heartbeat = false,
  heartbeatValue,
  setHeartbeat,
}: ContentsBoxProps) {
  const videoRef: any = useRef();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    if (videoRef.current) {
      const promise = videoRef.current.play();

      if (promise !== undefined) {
        promise
          .catch((error: any) => {
            console.log(error);
          })
          .finally(() => {});
      }
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!tutorialId || !setTutorialId) {
      return;
    }
    const isChecked = e.target.checked;

    if (isChecked) {
      console.log('isChecked : ', item.content.id, tutorialId)
      // 체크된 경우 배열에 id 추가하기
      if (!tutorialId.includes(item.content.id)) {
        let _tutorialId = [...tutorialId, item.content.id]
        console.log('_tutorialId : ', _tutorialId)
        setTutorialId(_tutorialId);
      }
    } else {
      // 체크 해제된 경우 배열에서 id 제거하기
      
      let _tutorialId = [...tutorialId]
      _tutorialId = _tutorialId.filter((id) => id !== item.content.id)
      console.log('_tutorialId : ', _tutorialId)
      setTutorialId(_tutorialId.filter((id) => id !== item.content.id));
    }
  };
  
  return (
    <div className="">
      <div
        onClick={() => (handleCheck ? handleCheck(item) : null)}
        className={cls(
          "relative w-40 h-36  border rounded-md shadow-md bg-base-100 overflow-hidden",
          hover ? "cursor-pointer" : ""
        )}
      >
        <div className="">
          <div className="relative">
            {checkedItems && handleCheck && (
              <input
                type="checkbox"
                className="checkbox checkbox-xs absolute z-50 m-2"
                checked={checkedItems.includes(item)}
                onChange={() => handleCheck(item)}
              />
            )}
            {extend && onExtend && (
              <FontAwesomeIcon
                icon={faUpRightAndDownLeftFromCenter}
                className="absolute m-2 z-50 right-0 opacity-20 hover:bg-gray-300 cursor-pointer w-3 h-3 p-1 rounded-md"
                width={10}
                onClick={() => onExtend(item)}
              />
            )}

            {deleteMode && (
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="absolute m-2 z-50 right-0 opacity-20 hover:bg-gray-300 cursor-pointer w-3 h-3 p-1 rounded-md"
                width={10}
                onClick={onDelete}
              />
            )}
            {duplicate && (
              <FontAwesomeIcon
                icon={faClone}
                width={10}
                onClick={onDuplicate}
                className="absolute m-2 z-50 right-0 top-5 opacity-20 hover:bg-gray-300 cursor-pointer w-3 h-3 p-1 rounded-md"
              />
            )}
            <div
              className="border-b w-full z-0 h-[88.88px]  relative"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {item.video && (
                // eslint-disable-next-line jsx-a11y/alt-text
                <img
                  src={item.content.thumbnailUrl || ""}
                  className={`absolute inset-0 m-auto ${
                    isHovered ? "hidden" : ""
                  } h-full`}
                />
              )}
              {item.video && (
                <video
                  ref={videoRef}
                  src={item.content.videoUrl || ""}
                  className={`absolute inset-0 m-auto ${
                    isHovered ? "" : "hidden"
                  } h-full`}
                  controls={false}
                  muted
                />
              )}
            </div>
          </div>
          <div className="flex flex-col p-4">
            <div className="flex items-center  justify-between w-full">
              <h2 className="text-xs truncate ">{item.content.name}</h2>
              {registrationCount && (
                <p className="text-xs ml-1 ">
                  ({item.content.registrationCount})
                </p>
              )}
              {tutorialCheckbox && (
                <div className="form-control z-50 ">
                  <label className="cursor-pointer space-x-1 flex">
                    <input
                      type="checkbox"
                      className="checkbox checkbox-xs"
                      onChange={handleCheckboxChange}
                      checked={
                        tutorialId
                          ? tutorialId.includes(item.content.id)
                          : false
                      }
                      disabled={readOnly ? true : false}
                    />
                    <span className="label-text text-xs truncate ">설명</span>
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {
        (heartbeat && !readOnly)?
        <div className="dropdown dropdown-bottom">
          <label
            className={
              heartbeatValue == 1?" select select-sm m-2 w-28 bg-[#FF0000] text-black font-medium":
              heartbeatValue == 2?" select select-sm m-2 w-28 bg-[#FF6600] text-black font-medium":
              heartbeatValue == 3?" select select-sm m-2 w-28 bg-[#FE06F2] text-black font-medium":
              heartbeatValue == 4?" select select-sm m-2 w-28 bg-[#00B050] text-black font-medium":
              heartbeatValue == 5?" select select-sm m-2 w-28 bg-[#01C8F1] text-black font-medium":
              "select select-sm m-2 w-28 bg-[#FFFFFF] text-black font-medium"}
            tabIndex={0}
          >
            {
              heartbeatValue == 1?'90%':
              heartbeatValue == 2?'80~89%':
              heartbeatValue == 3?'70~79%':
              heartbeatValue == 4?'60~69%':
              heartbeatValue == 5?'0~59%':
              '심박 설정'
            }
          </label>
          <div>
            <ul
              tabIndex={0}
              className="dropdown-content z-[9999] menu p-2 shadow bg-base-100 rounded-box w-52"
              onMouseDown={(e)=>{
                let targetEl = e.currentTarget;
                if(targetEl){
                  setTimeout(function(){
                    targetEl.blur();
                  }, 500);
                }
              }}
            >
              <li>
                <a onClick={() =>{setHeartbeat(0)}}>{'없음'}</a>
                <a onClick={() =>{setHeartbeat(1)}}>{'90%'}</a>
                <a onClick={() =>{setHeartbeat(2)}}>{'80~89%'}</a>
                <a onClick={() =>{setHeartbeat(3)}}>{'70~79%'}</a>
                <a onClick={() =>{setHeartbeat(4)}}>{'60~69%'}</a>
                <a onClick={() =>{setHeartbeat(5)}}>{'0~59%'}</a>
              </li>
            </ul>
          </div>
        </div> 
        :
        heartbeat?
        <div className="m-1">
          {
            heartbeatValue == 1?'90%':
            heartbeatValue == 2?'80~89%':
            heartbeatValue == 3?'70~79%':
            heartbeatValue == 4?'60~69%':
            heartbeatValue == 5?'0~59%':
            '-'
          }
        </div>
        :null
      }
    </div>
  );
}

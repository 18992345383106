import AccountTable from "../../components/AccountTable";

export default function AdminHome() {
  return (
    <>
      <AccountTable
        apiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/users`}
        newPasswordApiUrl={`${process.env.REACT_APP_SERVER_URL}api/admin/users/changePassword`}
      />
    </>
  );
}

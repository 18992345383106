import AdminLayout from "../../components/Layout/AdminLayout";

import "moment/locale/ko";

import ScheduleCalender from "../../components/ScheduleCalender";
import { useUserStore } from "../../store";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AccountTable from "../../components/AccountTable";

export default function BranchAccount() {
  const user = useUserStore((state) => state.user);
  const navigate = useNavigate();
  const { branchCode } = useParams();

  useEffect(() => {
    if (user?.role !== "admin") {
      navigate(`/`);
    }
  }, [user, navigate]);

  return (
    <AccountTable
      apiUrl={`${process.env.REACT_APP_SERVER_URL}api/branch/users`}
      newPasswordApiUrl={`${process.env.REACT_APP_SERVER_URL}api/branch/users/changePassword`}
      branchCode={branchCode}
    />
  );
}

import { motion } from "framer-motion";
import { ContentsWithOnlyVideoDto } from "../../types/dto";
import { useEffect } from "react";
interface NonMainScreenProps {
  data: ContentsWithOnlyVideoDto[];
  currentIndex: number;
  isMoveTime: boolean;
  counter: number;
}

export default function NonMainScreen({
  data,
  currentIndex,
  counter,
  isMoveTime,
}: NonMainScreenProps) {
  return (
    <div className="flex w-full    ">
      <div className="w-4/5 bg-white  h-[1687.5px] ">
        {isMoveTime ? (
          <div className="flex justify-center items-center flex-col space-y-10 h-full">
            <h1 className="text-[200px] text-blue-500 font-bold ">
              {Math.floor(counter)}
            </h1>
            <h1 className="text-8xl font-bold ">NEXT EXERCISE</h1>
            <div className=" w-[2000px] flex items-center justify-center bg-white ">
              <img
                src={data[currentIndex].video?.thumbnail || ""}
                className={`object-cover`}
                alt="Thumbnail"
              />
            </div>
          </div>
        ) : (
          <div className=" relative h-full">
            <h1 className="text-black text-[150px] font-bold absolute right-0 p-5  ">
              {data[currentIndex]?.content.name}
            </h1>
            {data[currentIndex]?.content.divisionAction && (
              <h1 className="text-blue-400 text-[100px] font-bold text-right absolute bottom-0 right-0 p-5 whitespace-pre-wrap  ">
                {data[currentIndex].content.divisionAction}
              </h1>
            )}
            {data[currentIndex]?.content.icon && (
              <div className="flex absolute bottom-0 left-0 m-5 space-x-5">
                {data[currentIndex].content.icon?.split(",").includes("L") && (
                  <h1 className="text-white rounded-full text-[150px] font-bold  bg-black w-52 h-52 justify-center items-center pb-3 flex ">
                    L
                  </h1>
                )}
                {data[currentIndex]?.content.icon?.split(",").includes("R") && (
                  <h1 className="text-white rounded-full text-[150px] font-bold  bg-black w-52 h-52 justify-center items-center pb-3 flex ">
                    R
                  </h1>
                )}
              </div>
            )}
            <video
              src={
                data[currentIndex]
                  ? data[currentIndex].video?.fileLink || ""
                  : ""
              }
              className="w-full h-full"
              autoPlay
              loop
              muted
              preload="auto"
            />
          </div>
        )}
      </div>
      <div className="w-1/5 h-[1687.5px]   bg-black ml-10 overflow-hidden">
        <motion.div
          initial={{ y: 0 }} // 초기 위치
          animate={{ y: -((currentIndex + 1) * 449.5) }} // 현재 인덱스에 따른 위치를 조정
          transition={{ duration: 1 }} // 애니메이션 지속 시간을 1초로 설정
        >
          {data.map((content, index) => (
            <div
              key={index}
              className="w-full bg-white h-[409.5px] overflow-hidden flex justify-center items-center mb-10"
            >
              <img
                src={content.video?.thumbnail || ""}
                className="object-contain h-full"
                alt="Thumbnail"
              />
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}
